import React, { useEffect, useState } from 'react'
import '../../../styles/theme.scss'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'

const SlashContainer = ({
  backgroundColor,
  children,
  width,
  offset,
  position
}) => {
  const [slashAngle, setSlashAngle] = useState(0)
  const [slashContainerRef, { height: currentHeight, width: currentWidth }] =
    useMeasure()

  useEffect(() => {
    if (position === 'top') {
      const angle = Math.atan((offset * 2) / currentWidth) * (180 / Math.PI)
      setSlashAngle(angle)
    } else {
      const angle = Math.atan((offset * 2) / currentHeight) * (180 / Math.PI)
      setSlashAngle(angle)
    }
  }, [currentHeight, offset, currentWidth])

  const getTransform = () => {
    switch (position) {
      case 'slash':
        return {
          transform: `skewX(-${slashAngle}deg)`,
          left: `${offset}px`,
          top: 0
        }
      case 'backslash':
        return {
          transform: `skewX(${slashAngle}deg)`,
          left: `${offset}px`,
          top: 0
        }
      case 'top':
        return {
          transform: `skewY(-${slashAngle}deg)`,
          left: 0,
          top: `${offset}px`
        }
      default:
        return {
          transform: `skewX(-${slashAngle}deg)`,
          left: `${offset}px`,
          top: 0
        }
    }
  }

  const getContainerStyle = () => {
    const style = {
      position: 'absolute'
    }
    if (position === 'top') {
      return {
        ...style,
        width: '100%',
        height: `${offset * 2}px`,
        top: `-${offset * 2}px`,
        left: 0
      }
    }
    return {
      ...style,
      width: `${offset * 2}px`,
      height: currentHeight,
      right: `-${offset * 2}px`
    }
  }

  const renderSlash = () => (
    <div
      className='relative d-flex f-row justify-end align-center'
      style={getContainerStyle()}
    >
      <div
        className='full-size absolute'
        style={{
          background: backgroundColor,
          ...getTransform()
        }}
      />
      {position !== 'top' ? (
        <div
          className='absolute full-height'
          style={{
            width: `${offset * 2}px`,
            background: backgroundColor,
            top: 0,
            left: 0
          }}
        />
      ) : null}
    </div>
  )

  return (
    <div
      className='relative d-flex'
      style={{
        width: width,
        marginTop: position === 'top' ? `${offset * 2}px` : 0
      }}
    >
      <div
        ref={slashContainerRef}
        className='fgIndex'
        style={{ background: backgroundColor, width: '100%' }}
      >
        {children}
      </div>
      {renderSlash()}
    </div>
  )
}

SlashContainer.propTypes = {
  backgroundColor: PropTypes.string,
  offset: PropTypes.number,
  children: PropTypes.node,
  width: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['slash', 'backslash', 'top'])
}

SlashContainer.defaultProps = {
  backgroundColor: 'white',
  children: <div />,
  offset: 20,
  position: 'slash'
}

export default SlashContainer
