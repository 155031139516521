import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputComponent } from '@zolteam/axenergie-ui-library';
import strings from '../../constants/strings';
import {
  ReactComponent as CircleCheckIcon,
} from '../../assets/images/checkbox-circle.svg';
import {
  ReactComponent as CircleCheckGreenIcon,
} from '../../assets/images/checkbox-circle-green.svg';

// Hooks
import useIsMobile from '../../hooks/useIsMobile';
// Components
import ContactDemandContent from '../atoms/DemandContents/ContactDemandContent';
import QuotationDemandContent from '../atoms/DemandContents/QuotationDemandContent';
import InvoiceDemandContent from '../atoms/DemandContents/InvoiceDemandContent';
import SolutionsContactDemandContent from '../atoms/DemandContents/SolutionsContactDemandContent';

const DemandItem = ({
  fullName,
  isClientKnown,
  subject,
  email,
  address,
  zipcode,
  city,
  phone,
  type,
  creationDate,
  specificContent,
  isProcessed,
  toggleProcessed,
  readOnly,
}) => {
  const [isButtonHover, setIsButtonHover] = useState(false);
  const [isMobile] = useIsMobile();

  const renderContent = () => {
    switch (type) {
      case 'SOLUTIONS_CONTACT':
        return (
          <SolutionsContactDemandContent
            optInNewsletter={specificContent?.optIn}
            message={specificContent?.message}
            date={creationDate}
            hasNewsletter
          />
        );
      case 'SITE_CONTACT':
        return (
          <ContactDemandContent
            optInNewsletter={specificContent?.optInNewsletter}
            message={specificContent?.message}
            source={specificContent?.source}
            date={creationDate}
            hasNewsletter
          />
        );
      case 'EXTRANET_AGENCY_CONTACT':
        return (
          <ContactDemandContent
            isMorning={specificContent?.isAvailableInTheMorning}
            isAfternoon={specificContent?.isAvailableInTheAfternoon}
            isNoon={specificContent?.isAvailableAtMidday}
            isAllDay={specificContent?.isAvailableAllDay}
            message={specificContent?.message}
            source={specificContent?.source}
            date={creationDate}
            hasNewsletter={false}
          />
        );
      case 'AGENCY_CONTRACT_QUOTATION_ACCEPTED_CONTACT':
        return (
          <QuotationDemandContent
            number={specificContent?.quotationNumber}
            amount={specificContent?.amountIncludingTax / 100}
            state={specificContent?.quotationState}
            date={creationDate}
            fileId={specificContent?.envelopeFileId}
          />
        );
      case 'AGENCY_CONTRACT_QUOTATION_REFUSED_CONTACT':
        return (
          <QuotationDemandContent
            number={specificContent?.quotationNumber}
            amount={specificContent?.amountIncludingTax / 100}
            state={specificContent?.quotationState}
            date={creationDate}
            fileId={specificContent?.quotationFileId}
          />
        );
      case 'AGENCY_CONTRACT_INVOICE_PAID_CONTACT':
        return (
          <InvoiceDemandContent
            number={specificContent?.invoiceNumber}
            amount={specificContent?.amountIncludingTax}
            date={specificContent?.paymentDate}
            fileId={specificContent?.invoiceFileId}
          />
        );
      case 'FREE_INVOICE_PAYMENT_CONTACT':
        return (
          <InvoiceDemandContent
            number={specificContent?.invoiceNumber}
            amount={specificContent?.amountIncludingTax}
            date={specificContent?.paymentDate}
          />
        );
      case 'CLIENT_EMAIL_CHANGE_CONTACT':
        return (
          <div
            className="d-flex f-row align-center f-wrap"
          >
            <div className="form-input-width m-3">
              <InputComponent
                id="client-new-email"
                value={specificContent?.emailAddress}
                label={strings.newEmail}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      case 'CLIENT_PHONES_CHANGE_CONTACT':
        return (
          <div
            className="d-flex f-row align-center f-wrap"
          >
            <div className="form-input-width m-3">
              <InputComponent
                id="client-new-mobile"
                value={specificContent?.mobilePhoneNumber}
                label={strings.newMobilePhone}
                onChange={() => {}}
              />
            </div>
            <div className="form-input-width m-3">
              <InputComponent
                id="client-new-landline"
                value={specificContent?.landlinePhoneNumber}
                label={strings.newLandlinePhone}
                onChange={() => {}}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const getStaticSubjects = () => {
    if (type === 'AGENCY_CONTRACT_QUOTATION_ACCEPTED_CONTACT') {
      return strings.acceptedQuotation;
    }
    if (type === 'AGENCY_CONTRACT_QUOTATION_REFUSED_CONTACT') {
      return strings.refusedQuotation;
    }
    if (type === 'AGENCY_CONTRACT_INVOICE_PAID_CONTACT') {
      return strings.payedInvoice;
    }
    if (type === 'FREE_INVOICE_PAYMENT_CONTACT') {
      return strings.payedInvoiceFromSite;
    }
    if (type === 'CLIENT_EMAIL_CHANGE_CONTACT') {
      return strings.changeContactInfo;
    }
    if (type === 'CLIENT_PHONES_CHANGE_CONTACT') {
      return strings.changeContactInfo;
    }
    return '';
  };

  const getButtonTextColor = () => {
    if (isButtonHover) {
      return isProcessed ? 'green-text' : 'primary-red-text';
    }
    return 'white-text';
  };

  return (
    <div className="d-flex f-column white-background p-4">
      {/* Header */}
      <div className="d-flex f-row justify-between align-center f-wrap relative">
        <div className={`d-flex f-column ${isMobile ? 'mt-4' : ''}`}>
          <div className="d-flex f-row align-center f-wrap">
            <h3 className="grey-800-text medium-weight mv-2 mr-3">{fullName}</h3>
            {isClientKnown ? null
              : (
                <div className="mr-3">
                  <div className="primary-red-background radius-20 ph-2">
                    <span
                      className="uppercase white-text very-small-text small-line-height"
                    >
                      {strings.new}
                    </span>
                  </div>
                </div>
              )}
          </div>
          <div className="d-flex f-row f-wrap">
            <span className="grey-400-text medium-text">{`${strings.demandObject} :`}</span>
            <span className="grey-800-text medium-text medium-weight ml-2">
              {subject || getStaticSubjects()}
            </span>
          </div>
        </div>
        <div className={`d-flex f-column ${isMobile ? 'mt-4' : ''}`}>
          <span className="grey-400-text medium-text normal-weight">
            {`${email}  ${phone}`}
          </span>
          <span className="grey-400-text medium-text normal-weight">
            {type === 'SOLUTIONS_CONTACT'
              ? `${strings.zipCode}: ${zipcode}`
              : `${address} ${zipcode} ${city}`}
          </span>
        </div>
        {!readOnly ? (
          <div
            className={isMobile ? 'absolute-top-right-corner' : ''}
            onMouseEnter={() => setIsButtonHover(true)}
            onMouseLeave={() => setIsButtonHover(false)}
          >
            <button type="button" onClick={toggleProcessed}>
              <div className="d-flex f-row align-center justify-center">
                <span
                  className={`${getButtonTextColor()}
                 medium-text medium-weight mr-2`}
                >
                  {isProcessed ? strings.setAsNotProcessed : strings.setAsProcessed}
                </span>
                { isProcessed
                  ? <CircleCheckGreenIcon width={20} height={20} />
                  : <CircleCheckIcon width={20} height={20} />}
              </div>
            </button>
          </div>
        ) : null }
      </div>
      <div className="line grey-100-background light mv-3" />
      {renderContent()}
    </div>
  );
};

DemandItem.propTypes = {
  fullName: PropTypes.string.isRequired,
  isClientKnown: PropTypes.bool.isRequired,
  subject: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  address: PropTypes.string.isRequired,
  zipcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  creationDate: PropTypes.string.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  toggleProcessed: PropTypes.func.isRequired,
  specificContent: PropTypes.shape().isRequired,
  readOnly: PropTypes.bool,
};

DemandItem.defaultProps = {
  phone: '',
  subject: '',
  readOnly: false,
};

export default DemandItem;
