/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
// Components
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
// Constants
import colors from '../../constants/colors';
import strings from '../../constants/strings';

const AdminSignatureDataTable = ({
  isLoading, data, columns, tableStyle, trStyle,
}) => {
  const getFormattedData = () => {
    const groupByAgency = data?.reduce((acc, row) => {
      const accGroup = acc?.[row?.agencyName] || [];
      return { ...acc, [row?.agencyName]: accGroup?.concat([row]) };
    }, {});
    return Object.values(groupByAgency)?.reduce((acc, rowsForAgency) => acc?.concat(rowsForAgency)?.concat([{
      totalForAgency: rowsForAgency?.reduce((total, { signatureCount }) => (total + (signatureCount || 0)), 0),
      agencyName: rowsForAgency?.[0]?.agencyName,
    }]), []);
  };

  return (
    <table className={`radius-5 white-background ${tableStyle}`}>
      <thead>
        <tr className={trStyle}>
          {columns.map((colName, index) => (
            <th key={`table-header-${index}`}>
              {colName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr className={trStyle}>
            <td>
              <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
            </td>
          </tr>
        ) : null}
        {!isLoading && !data.length ? (
          <tr className={trStyle}>
            <td>
              <span className="grey-400-text">{strings.noData}</span>
            </td>
          </tr>
        ) : null}
        {!isLoading
          ? getFormattedData().map((row, index) => {
            if (row?.totalForAgency) {
              return (
                <>
                  <tr className={trStyle} key={`table-row-total-${index}`}>
                    <td colSpan="3" align="right">
                      <strong>{strings.totalSignatureCount}</strong>
                    </td>
                    <td>
                      <strong>{row?.totalForAgency}</strong>
                    </td>
                  </tr>
                </>
              );
            }

            return (
              <tr className={trStyle} key={`table-row-${index}`}>
                {Object.values(row).map((tableData, ind) => (
                  <td key={`table-data-${ind}`}>{tableData}</td>
                ))}
              </tr>
            );
          })
          : null}
      </tbody>
    </table>

  );
};

AdminSignatureDataTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableStyle: PropTypes.string,
  trStyle: PropTypes.string,
};

AdminSignatureDataTable.defaultProps = {
  tableStyle: '',
  trStyle: '',
};

export default AdminSignatureDataTable;
