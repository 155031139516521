import client from './client';

const getAdmins = ({ isHidden }) => client.get('/network-admins', { params: { isHidden } });

const getRoles = () => client.get('/roles');

const updateAdmin = ({ admin }) => client.put(`/network-admins/${admin.id}`, admin);

const createAdmin = ({ admin }) => client.post('/network-admins', admin);

const getAdmin = ({ id }) => client.get(`/network-admins/${id}`);

const changeClientEmail = (email) => client.post('/clients/me:open-email-change', {
  emailAddress: email,
  redirectUrl: `https://${process.env.REACT_APP_DOMAIN}/extranet/personal-info?emailModified=true`,
});

const changeClientPassword = (password) => client.post('/clients/me:change-password', {
  password,
});

const adminsReorder = ({ reorderedAdminId, newPrecedingAdminId }) => client.post(
  '/network-admins:reorder',
  {
    reorderedAdminId,
    newPrecedingAdminId,
  },
);

export default {
  getAdmins,
  getRoles,
  updateAdmin,
  createAdmin,
  getAdmin,
  changeClientEmail,
  changeClientPassword,
  adminsReorder,
};
