import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import colors from '../../constants/colors';

const LightSelectComponent = ({
  data,
  defaultValue,
  isDisabled,
  isLoading,
  name,
  isSearchable,
  value,
  setValue,
  placeholder,
  isClearable,
}) => {
  const customStyle = {
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({
      color: colors.primaryRed,
      cursor: 'pointer',
    }),
    control: () => ({
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: colors.primaryRed,
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 300,
      whiteSpace: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.primaryRed,
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 300,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#d01408' : '#1f232c',
      fontFamily: "'Work Sans', sans-serif",
    }),
  };

  const customTheme = (currentTheme) => ({
    ...currentTheme,
    colors: {
      ...currentTheme.colors,
      primary: colors.grey100,
      primary75: colors.primaryRed,
      primary50: colors.primaryRed,
      primary25: colors.grey100,
    },
  });
  return (
    <Select
      styles={customStyle}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      value={value}
      onChange={setValue}
      options={data}
      placeholder={placeholder}
      theme={customTheme}
    />
  );
};

LightSelectComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number || PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.shape({
    value: PropTypes.number || PropTypes.string,
    label: PropTypes.string,
  }),
  defaultValue: PropTypes.string || PropTypes.number,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

LightSelectComponent.defaultProps = {
  defaultValue: null,
  isDisabled: false,
  isLoading: false,
  isSearchable: true,
  placeholder: 'Sélection...',
  name: '',
  value: null,
  isClearable: false,
};

export default LightSelectComponent;
