const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
});

const faqByCategory = (category) => client
  ?.getEntries({
    content_type: 'faq',
    'fields.thme[in]': category,
  });

export default { faqByCategory };
