import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/input.scss'
import { useMeasure } from 'react-use'

const TextAreaComponent = ({
  value,
  onChange,
  label,
  id,
  errorMessage,
  isError,
  onBlur,
  disabled,
  rows,
  placeholder
}) => {
  const [containerRef, { width: containerWidth }] = useMeasure()

  return (
    <div ref={containerRef} className='d-flex f-column'>
      <div
        className='d-flex f-column align-center justify-between grey-100-background input-container p-3 radius-5'
        style={{
          opacity: disabled ? 0.5 : 1,
          borderColor: isError ? '#e13c32' : 'rgba(31,35,44,0.12)'
        }}
      >
        <div className='d-flex f-column full-width'>
          <label className='input-label' htmlFor={id}>
            <span
              className='mr-2 regular-text'
              style={{ color: value ? '#8890a1' : '#1f232c' }}
            >
              {label}
            </span>
          </label>
          <textarea
            style={{
              width: containerWidth - 30,
              resize: 'vertical'
            }}
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={() => {
              onBlur()
            }}
            disabled={disabled}
            rows={rows}
            placeholder={placeholder}
          />
        </div>
      </div>
      {isError ? (
        <div className='ml-1'>
          <span className='light-red-text medium-weight regular-text'>
            {errorMessage}
          </span>
        </div>
      ) : null}
    </div>
  )
}

TextAreaComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string
}

TextAreaComponent.defaultProps = {
  label: '',
  errorMessage: 'Error',
  isError: false,
  onBlur: () => {},
  disabled: false,
  rows: 5,
  placeholder: ''
}

export default TextAreaComponent
