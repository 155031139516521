export default function appReducer(state, action) {
  switch (action.type) {
    // AUTH REDUCERS
    case 'SET_AUTH': {
      return { ...state, auth: action.payload };
    }
    case 'SET_REFRESH': {
      return { ...state, refresh: action.payload };
    }
    case 'SET_POPOVER': {
      return { ...state, popover: { dontHide: false, ...action.payload } };
    }
    case 'SET_CURRENT_AGENCY': {
      return { ...state, currentAgency: action.payload };
    }
    case 'SET_CLIENT_AGENCIES': {
      return { ...state, clientAgencies: action.payload };
    }
    case 'SET_AGENCY_NAME': {
      return { ...state, agencyName: action.payload };
    }
    case 'SET_FORUMS': {
      return { ...state, forums: action.payload };
    }
    case 'SET_DOC_BASES': {
      return { ...state, docBases: action.payload };
    }
    case 'SET_PERMISSIONS': {
      return { ...state, permissions: action.payload ? JSON.stringify(action.payload) : action.payload };
    }
    case 'SET_USER': {
      return { ...state, user: action.payload };
    }
    case 'SET_LOGIN_AS': {
      return { ...state, loginAs: action.payload ? JSON.stringify(action.payload) : action.payload };
    }
    case 'SET_SHOW_EXTRANET_BACK_BUTTON': {
      return { ...state, showExtranetBackButton: action.payload };
    }
    case 'SET_PREVIOUS_SEARCH': {
      return { ...state, previousSearch: action.payload };
    }
    case 'SET_PREVIOUS_CATEGORY': {
      return { ...state, previousCategory: action.payload };
    }
    case 'SET_PREVIOUS_DOC_BASE_PAGE': {
      return { ...state, previousDocBasePage: action.payload };
    }
    case 'SET_ROLES': {
      return { ...state, roles: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
