/* eslint-disable max-len */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent, ButtonComponent } from '@zolteam/axenergie-ui-library';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import TemplateMenuHeaderClient from '../../components/Organisms/TemplateMenuHeaderClient';

// Components
import Footer from '../../components/atoms/Footer';
import TabComponent from '../../components/molecules/TabComponent';
import QuoteTab from '../../components/molecules/QuoteTab';
import InvoiceTab from '../../components/molecules/InvoiceTab';
import DocumentTab from '../../components/molecules/DocumentTab';

// Hooks
import { useAppContext } from '../../store/AppContext';
import useIntranetSpacing from '../../hooks/useIntranetSpacing';

// Service
import AgencyContractService from '../../services/AgencyContractService';

// Utils
import urlUtils from '../../utils/urlUtils';

// Constants
import strings from '../../constants/strings';

// Images
import { ReactComponent as ContractIcon } from '../../assets/images/avantage-2.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-black.svg';

import utils from '../../utils/utils';
import useIsMobile from '../../hooks/useIsMobile';
import colors from '../../constants/colors';
import ContactInfoTab from '../../components/molecules/ContactInfoTab';

const Contract = ({ match }) => {
  const [{ currentAgency, clientAgencies }, appDispatch] = useAppContext();
  const contentSpacing = useIntranetSpacing();
  const [isMobile] = useIsMobile();
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  const signed = useMemo(() => parameters.get('signed'), []);

  const tabs = [
    {
      id: 'CONTRACT',
      title: strings.tabContract.toUpperCase(),
    },
    {
      id: 'QUOTE',
      title: strings.tabQuote.toUpperCase(),
    },
    {
      id: 'INVOICE',
      title: strings.tabInvoice.toUpperCase(),
    },
    {
      id: 'DOCUMENTS',
      title: strings.tabDocuments.toUpperCase(),
    },
    {
      id: 'CONTACT_INFO',
      title: strings.tabContactInfo.toUpperCase(),
    },
  ];

  const fileType = '.pdf';

  const [contract, setContract] = useState(null);
  const [contractDocuments, setContractDocuments] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs.find(
    (tab) => tab.id === parameters.get('tab')?.toUpperCase(),
  ) || tabs[0]);

  const getMyAgencyContracts = useQuery(['get-my-agency-contracts', currentAgency], () => {
    if (currentAgency) {
      return AgencyContractService.getMyAgencyContracts({ agencyId: currentAgency });
    }
    return null;
  });

  const getMyAgencyContractDocuments = useQuery(['get-my-agency-contract-documents', contract], () => {
    if (contract && currentAgency) {
      return AgencyContractService.getMyAgencyContractDocuments({ agencyContractId: match.params.id });
    }
    return null;
  });

  const getAgencyContractDocument = useMutation('get-agency-contract-document',
    (fileId) => AgencyContractService.getAgencyContractFile(fileId));

  useEffect(() => {
    appDispatch({
      type: 'SET_SHOW_EXTRANET_BACK_BUTTON',
      payload: true,
    });
  }, []);

  useEffect(() => {
    if (getMyAgencyContracts.isSuccess) {
      setContract(getMyAgencyContracts?.data?.data?.agencyContracts?.find((c) => c.id === Number(match.params.id)));
    }
    if (getMyAgencyContracts.isError) {
      setContract(null);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyContracts, isSuccess: false },
      });
    }
  }, [getMyAgencyContracts.isSuccess, getMyAgencyContracts.isError]);

  useEffect(() => {
    if (getMyAgencyContractDocuments.isSuccess) {
      setContractDocuments(
        getMyAgencyContractDocuments?.data?.data?.agencyContractDocuments.filter(({ hasFile }) => !!hasFile),
      );
    }
    if (getMyAgencyContractDocuments.isError) {
      setContractDocuments(null);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyContractDocuments, isSuccess: false },
      });
    }
  }, [getMyAgencyContractDocuments.isSuccess, getMyAgencyContractDocuments.isError]);

  useEffect(() => {
    if (getAgencyContractDocument.isSuccess) {
      const file = getAgencyContractDocument?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractDocument.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractDocument.isSuccess, getAgencyContractDocument.isError]);

  const handleDocumentClick = (id) => {
    getAgencyContractDocument.mutate(id);
  };

  useEffect(() => {
    if (activeTab.id) {
      history.push({
        pathname: `/extranet/contract/${match.params.id}`,
        search: `?tab=${activeTab.id.toLowerCase()}`,
      });
    }
  }, [activeTab]);

  return (
    <TemplateMenuHeaderClient>
      <div className="mh-5 mt-6" style={{ paddingLeft: contentSpacing }}>
        {contract
          ? (
            <div className="d-flex f-wrap justify-between align-center" style={{ maxWidth: 1300 }}>
              <h2 className="mt-0 mb-4">
                {`${contract.route} ${contract.postalCode} ${contract.locality}`}
              </h2>
              <Link
                to={{
                  pathname: `https://${process.env.REACT_APP_SITE_DOMAIN}${urlUtils.getAgenceUrl({ id: currentAgency, title: JSON.parse(clientAgencies)[0].name })}`,
                  search: '?payment=open',
                }}
                className="btn btn-primary mb-4"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fgIndex">{strings.renewContract}</span>
              </Link>
            </div>
          )
          : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
        {parameters.get('emailModified')
          ? <p className="h6 green-text medium-weight">{strings.success.changeEmail}</p>
          : null}
        <TabComponent
          tabs={tabs.map((tab) => ({
            onClick: () => setActiveTab(tab),
            isActive: activeTab.id === tab.id,
            title: tab.title,
          }))}
          defaultActive={tabs.indexOf(activeTab)}
        />
        <div className="full-width mb-5" style={{ maxWidth: 1300 }}>
          { contract
            ? (
              <div>
                { activeTab.id === 'CONTRACT'
                  ? (
                    <div style={{ border: '1px solid #EDEEF1' }} className="d-flex f-column white-background radius-5">
                      <div className={`d-flex ${isMobile ? 'p-2' : 'p-4'}`}>
                        {isMobile ? null : <ContractIcon />}
                        <div className="mh-3 full-width">
                          <h3 className="mt-0">{strings.myContract}</h3>
                          {contract.reference ? (
                            <div className="d-flex f-row f-wrap">
                              <span className="primary-red-text bold mr-2">{strings.clientRef}</span>
                              <span className="black-text medium-weight">{contract.reference}</span>
                            </div>
                          ) : null}
                          <div className="d-flex f-row f-wrap">
                            <span className="primary-red-text bold mr-2">{strings.renewalDate}</span>
                            <span className="black-text medium-weight">
                              {contract.renewalDate
                                ? format(new Date(contract.renewalDate?.split(' ').join('T')), 'dd-MM-yyyy')
                                : strings.unspecified}
                            </span>
                          </div>
                          <div style={{ maxWidth: '500px' }} className="d-flex f-wrap">
                            <div
                              style={{ flexGrow: '0.5' }}
                              className="d-flex p-4 mr-3 f-column grey-100-background radius-5 mv-2"
                            >
                              <h6 className="grey-500-text m-0 mb-2 medium-weight">{strings.equipmentHeader}</h6>
                              <span
                                style={{ lineHeight: '20px' }}
                                className="grey-400-text medium-text"
                              >
                                {contract.equipmentType || contract.equipmentModel
                                  ? `${contract.equipmentType} ${contract.equipmentModel}`
                                  : strings.unspecified}
                              </span>
                            </div>
                            <div
                              style={{ flexGrow: '0.5' }}
                              className="d-flex p-4 f-column grey-100-background radius-5 mv-2"
                            >
                              <h6 className="grey-500-text m-0 mb-2 medium-weight">
                                {strings.lastInterventionHeader}
                              </h6>
                              <span
                                style={{ lineHeight: '20px' }}
                                className="grey-400-text medium-text"
                              >
                                {contract.lastMaintenanceDate
                                  ? format(new Date(contract.lastMaintenanceDate?.split(' ').join('T')), 'dd-MM-yyyy')
                                  : strings.unspecified}
                              </span>
                            </div>
                          </div>
                          {contractDocuments?.length ? (
                            <div style={{ maxWidth: '500px' }} className="d-flex f-wrap">
                              <div
                                style={{ flexGrow: '1' }}
                                className={`d-flex 
                              ${isMobile ? 'p-2' : 'p-4'} mt-3 f-column grey-100-background radius-5 f-wrap`}
                              >
                                <h6 className="grey-500-text m-0 mb-4 medium-weight">{strings.documentsHeader}</h6>
                                <div
                                  className="d-flex f-column align-start"
                                >
                                  {contractDocuments !== null
                                    ? (
                                      <>
                                        {contractDocuments?.map((document) => (
                                          <div className="mb-2">
                                            <ButtonComponent
                                              onClick={() => handleDocumentClick(document.id)}
                                              theme="outline-transparent"
                                            >
                                              <div className="d-flex f-row align-center justify-center f-wrap">
                                                <DownloadIcon width={24} height={24} />
                                                <span className="ml-3">{document.description + fileType}</span>
                                              </div>
                                            </ButtonComponent>
                                          </div>
                                        ))}
                                      </>
                                    ) : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                  : null }
                { activeTab.id === 'QUOTE'
                  ? <QuoteTab signed={signed} agencyContractId={match.params.id} />
                  : null }
                { activeTab.id === 'INVOICE'
                  ? <InvoiceTab agencyContractId={match.params.id} />
                  : null }
                { activeTab.id === 'DOCUMENTS'
                  ? <DocumentTab agencyContractId={match.params.id} />
                  : null }
                { activeTab.id === 'CONTACT_INFO'
                  ? (
                    <ContactInfoTab
                      email={contract?.emailAddress}
                      mobile={contract?.mobilePhoneNumber}
                      phone={contract?.landlinePhoneNumber}
                      contractId={match.params.id}
                    />
                  )
                  : null }
              </div>
            ) : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
        </div>
        <Footer />
      </div>
    </TemplateMenuHeaderClient>
  );
};

Contract.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default Contract;
