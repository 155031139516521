import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import strings from '../../constants/strings';
import Stat from './Stat';
import colors from '../../constants/colors';

import useIsMobile from '../../hooks/useIsMobile';

const Hr = () => (
  <hr
    className="m-0"
    style={{ width: '1px', border: 'none', backgroundColor: 'rgb(255, 255, 255, 0.1)' }}
  />
);

const DashboardFigures = ({
  contacts,
  showMore,
  showMoreTo,
}) => {
  const [isMobile] = useIsMobile();

  const figures = useMemo(() => {
    if (!contacts) return null;

    return {
      countToProcess: <Stat
        value={contacts?.count_to_process}
        label={strings.newActivity}
        styles={{ maxWidth: '100px' }}
      />,
      countToProcessNewClient: <Stat
        value={contacts?.count_to_process_new_client}
        valueColor={colors.green}
        label={strings.newClients}
        styles={{ maxWidth: '100px' }}
      />,
      countProcessesLastMonth: <Stat
        value={contacts?.count_processed_last_month}
        valueColor={colors.lightRed}
        label={strings.ongoingActivity}
        styles={{ maxWidth: '100px' }}
      />,
      countVerifiedClients: <Stat
        value={contacts?.count_verified_clients}
        label={strings.verifiedClients}
        styles={{ maxWidth: '100px' }}
      />,
    };
  }, [contacts]);

  const renderDesktop = useCallback(() => (
    <div className="d-flex justify-around" style={{ flexGrow: 1 }}>
      {showMore ? (
        <Link
          to={showMoreTo}
          className="absolute-top-right-corner p-3"
        >
          <span className="mr-3 mv-1 medium-text primary-red-text">{strings.seeAll}</span>
        </Link>
      ) : null}
      {figures?.countToProcess}
      <Hr />
      {figures?.countProcessesLastMonth}
      <Hr />
      {figures?.countToProcessNewClient}
      <Hr />
      {figures?.countVerifiedClients}
    </div>
  ), [figures]);

  const renderMobile = useCallback(() => (
    <>
      <div className="d-flex justify-around" style={{ flexGrow: 1 }}>
        {showMore ? (
          <Link
            to={showMoreTo}
            className="absolute-top-right-corner p-3"
          >
            <span className="mr-3 mv-1 medium-text primary-red-text">{strings.seeAll}</span>
          </Link>
        ) : null}
        {figures?.countToProcess}
        {figures?.countProcessesLastMonth}
      </div>
      <div className="d-flex justify-around mt-4" style={{ flexGrow: 1 }}>
        {figures?.countToProcessNewClient}
        {figures?.countVerifiedClients}
      </div>
    </>
  ), [figures]);

  return (
    <>
      { contacts
        ? (
          <>
            {isMobile ? renderMobile() : renderDesktop()}
          </>
        )
        : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} /> }
    </>
  );
};

DashboardFigures.propTypes = {
  contacts: PropTypes.shape({
    count_to_process: PropTypes.number,
    count_processed_last_month: PropTypes.number,
    count_to_process_new_client: PropTypes.number,
    count_verified_clients: PropTypes.number,
  }),
  showMore: PropTypes.bool,
  showMoreTo: PropTypes.string,
};

DashboardFigures.defaultProps = {
  contacts: null,
  showMore: false,
  showMoreTo: '/intranet/demands',
};

export default DashboardFigures;
