import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppContext } from '../store/AppContext';

// Services
import AuthService from '../services/AuthService';

// Utils
import strings from '../constants/strings';

const VerifyEmail = ({ isAdmin }) => {
  const [, appDispatch] = useAppContext();

  // Hooks
  const history = useHistory();
  const location = useLocation();

  const closeEmailVerification = useMutation('close-email-verification',
    (token) => AuthService.clientCloseEmailVerification(token));

  // Methods
  const goToLogin = () => {
    history.push(isAdmin ? '/intranet/login' : '/extranet/login');
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      closeEmailVerification.mutate(token);
    }
  }, []);

  useEffect(() => {
    if (closeEmailVerification.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.verifyEmailSuccess, isSuccess: true, dontHide: true,
        },
      });
      goToLogin();
    }
  }, [closeEmailVerification.isSuccess]);

  return (
    <div>
      {strings.processing}
    </div>
  );
};

VerifyEmail.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default VerifyEmail;
