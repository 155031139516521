import React from 'react';
import PropTypes from 'prop-types';
import strings from '../../constants/strings';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';

const SearchInputComponent = ({
  value,
  onChange,
  onClick,
  disabled,
  id,
  placeholder,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      className="d-flex f-row align-center justify-between grey-100-background input-container p-3 radius-5"
      style={{
        opacity: disabled ? 0.5 : 1,
        borderColor: 'rgba(31,35,44,0.12)',
      }}
    >
      <input
        style={{ width: 'calc(100% - 30px)' }}
        type="text"
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      {value ? (
        <button type="button" onClick={() => onChange('')}>
          <span className="grey-400-text small-text">X</span>
        </button>
      ) : null}
      <button type="button" onClick={onClick}>
        <SearchIcon width={24} height={24} />
      </button>
    </div>
  );
};

SearchInputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

SearchInputComponent.defaultProps = {
  disabled: false,
  placeholder: strings.searchKeyWords,
};

export default SearchInputComponent;
