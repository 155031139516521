import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// Constants
import strings from '../../constants/strings';

// Images
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';

const DocumentCard = ({
  className,
  width,
  category,
  title,
  content,
  download,
  isEditable,
  edit,
  isDownloadButton,
  date,
  open,
}) => (
  <div
    className={`${className} relative pointer`}
    role="button"
    onClick={open}
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.code === 'Enter') {
        open();
      }
    }}
  >
    <div
      className="d-flex f-column align-start radius-5"
      style={{
        border: '1px solid #EDEEF1',
        width,
      }}
    >
      <div className="d-flex f-row justify-between align-center full-width">
        <span className="small-text uppercase primary-red-text ph-3 pt-1">{category}</span>
        {
              isEditable
                ? (
                  <div className="ph-3 pv-2">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        edit();
                      }}
                    >
                      <EditIcon width={18} height={18} />
                    </button>
                  </div>
                )
                : null
            }
      </div>

      <h3 className="grey-800-text medium-weight ph-3 mb-3 mt-2">{title}</h3>
      <div
        className="d-flex f-column align-start grey-100-background"
        style={{ width }}
      >
        <div
          className="description-text ph-3 mv-2"
        >
          {content}
        </div>
        {date ? (
          <p className="small-text grey-400-text ph-3 mv-0">
            {`${strings.the} ${format(new Date(date?.split(' ').join('T')), 'dd.MM.yyyy')}`}
          </p>
        ) : null}
      </div>
      {isDownloadButton
        ? (
          <div className="d-flex f-column align-center grey-100-background" style={{ width }}>
            <div className="line" style={{ opacity: 0.1, width: width - 20 }} />
            <div className="d-flex f-row full-width justify-between align-center m-3 f-wrap">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  download();
                }}
              >
                <span className="medium-weight primary-red-text medium-text mh-2">{strings.download}</span>
              </button>
            </div>
          </div>
        )
        : null}
    </div>
  </div>
);

DocumentCard.propTypes = {
  className: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  download: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  edit: PropTypes.func,
  isEditable: PropTypes.bool,
  isDownloadButton: PropTypes.bool,
};

DocumentCard.defaultProps = {
  isEditable: false,
  isDownloadButton: false,
  edit: () => {},
};

export default DocumentCard;
