import { Redirect } from 'react-router-dom';
import Home from '../views/Extranet/Home';
import Contract from '../views/Extranet/Contract';
import ChooseAgency from '../views/Extranet/ChooseAgency';
import Faq from '../views/Extranet/FAQ';
import PersonalInfo from '../views/Extranet/PersonalInfo';
import PaymentFeedback from '../views/Extranet/PaymentFeedback';

const commonPath = '/extranet';

export default [
  {
    path: `${commonPath}`,
    exact: true,
    render: (auth, { location, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <Home /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/contract/:id`,
    exact: true,
    render: (auth, { location, match, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <Contract match={match} /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/faq`,
    exact: true,
    render: (auth, { location, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <Faq /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/personal-info`,
    exact: true,
    render: (auth, { location, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <PersonalInfo /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/payment-cancel`,
    exact: true,
    render: (auth, { location, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <PaymentFeedback isCancel />
        : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/payment-return`,
    exact: true,
    render: (auth, { location, currentAgency }) => {
      const redirection = location.pathname.includes(commonPath) ? '/extranet/login' : '/intranet/login';
      if (!currentAgency && auth) return <ChooseAgency />;
      return auth ? <PaymentFeedback />
        : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
];
