import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '../molecules/Menu';
import Header from '../molecules/Header';
import useIsMobile from '../../hooks/useIsMobile';

const TemplateWithMenuAndHeader = ({ children, isAdmin }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [agencySelectorHeight, setAgencySelectorHeight] = useState(0);

  const [isMobile] = useIsMobile();

  return (
    <div
      className="grey-100-background relative"
      style={{
        height: isMobile ? '100%' : '',
        minHeight: isMobile ? '' : '100vh',
        maxHeight: '100%',
      }}
    >
      <div className="container-with-menu ph-3 pb-5 d-flex f-column">
        <Menu
          isAdmin={isAdmin}
          smallMenuCloseHeight={headerHeight}
        />
        <Header
          setHeight={setHeaderHeight}
          setAgencySelectorHeight={setAgencySelectorHeight}
          isAdmin
        />
        <div
          className="full-width baseIndex"
          style={{ cursor: 'auto', marginTop: isMobile ? agencySelectorHeight + 16 : 0 }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

TemplateWithMenuAndHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isAdmin: PropTypes.bool,
};

TemplateWithMenuAndHeader.defaultProps = {
  isAdmin: true,
};

export default TemplateWithMenuAndHeader;
