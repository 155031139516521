import React from 'react';
import PropTypes from 'prop-types';

const ModalComponent = ({ children, closeModal }) => {
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      closeModal();
    }
  };

  return (
    <div
      role="button"
      className="small-action-modal"
      onClick={closeModal}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="small-action-modal-content"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ModalComponent;
