import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/images/arrow-down.svg';

const NestedMenuItem = ({
  isOpen, children, icon, title, links, setIsOpen,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        className={`ph-2 d-flex align-start ${links.find((link) => pathname.includes(link)) ? 'active' : ''}`}
        style={{ height: 55 }}
        onClick={() => {
          setIsMenuOpen((state) => !state);
          setIsOpen(true);
        }}
      >
        <div className="d-flex f-row align-center mv-2 menu-icon">
          {icon}
          {isOpen
            ? (
              <div className="d-flex f-row align-center">
                <span className="white-text mh-2 regular-text ws-nowrap">{title}</span>
                <Arrow
                  width={20}
                  alt="down arrow"
                  style={{ transform: isMenuOpen ? 'rotate(0)' : 'rotate(-90deg)' }}
                />
              </div>
            )
            : null}
        </div>
      </button>
      {isMenuOpen && isOpen
        ? (
          <div className="ml-4" style={{ marginTop: -20 }}>
            {children}
          </div>
        ) : null}
    </>
  );
};

NestedMenuItem.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
};

NestedMenuItem.defaultProps = {
  children: null,
};

export default NestedMenuItem;
