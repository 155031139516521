import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';

const TabButtonComponent = ({
  onClick, isSelected, title, setDimensions,
}) => {
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    setDimensions({ width });
  }, [width]);

  return (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
    >
      <span
        className={`uppercase ${isSelected ? 'bold grey-800-text' : 'grey-400-text'}`}
      >
        {title}
      </span>
    </button>
  );
};

TabButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  setDimensions: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default TabButtonComponent;
