import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

// Components
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Services
import NetworkNewsService from '../../../services/NetworkNewsService';
import FileService from '../../../services/FileService';

// Constants
import strings from '../../../constants/strings';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Images
import utils from '../../../utils/utils';
import ArticleTemplate from '../../../components/Organisms/ArticleTemplate';
import colors from '../../../constants/colors';

const NetworkNewsDetails = () => {
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [illustrations, setIllustrations] = useState([]);

  const { id } = useParams();

  // Store
  const [, appDispatch] = useAppContext();
  const history = useHistory();

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery('getOneNetworkNews',
    () => NetworkNewsService.getOneNews({ id }));

  const getFiles = useMutation('getJoinedFiles',
    (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });

  const getIllustrations = useMutation('getIllustrations',
    (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });

  const {
    isLoading: getCategoriesLoading, isError: getCategoriesError, data: categories,
  } = useQuery('get-categories', () => NetworkNewsService.getCategories());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSuccess && data?.data?.joinedFiles) {
      getFiles.mutate(data?.data?.joinedFiles.map(({ fileId }) => fileId));
    }
    if (isSuccess && data?.data?.illustrationFiles) {
      getIllustrations.mutate(data?.data?.illustrationFiles.map(({ fileId }) => fileId));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (getFiles.isSuccess) {
      setFilesToDownload(
        getFiles.data?.map((file) => ({ data: file.data, name: file.headers['x-file-sanitized-name'] })),
      );
    }
  }, [getFiles.isSuccess, getFiles.data]);

  useEffect(() => {
    if (getIllustrations.isSuccess) {
      const blobs = getIllustrations.data?.map((file) => utils.getBase64(file.data));
      Promise.all(blobs).then((base64s) => setIllustrations(base64s));
    }
  }, [getIllustrations.isSuccess, getIllustrations.data]);

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getActuality, isSuccess: false },
      });
    }
    if (getCategoriesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
  }, [isError, getCategoriesError]);

  return (
    <TemplateWithMenuAndHeader>
      {
        data?.data
          ? (
            <ArticleTemplate
              backAction={() => history.push('/intranet/network-news')}
              illustrations={illustrations}
              publicationDate={data?.data?.publicationDate}
              categories={[categories?.data?.categories?.find((cate) => cate.id === data?.data?.categoryId)?.code]}
              title={data?.data?.title}
              filesToDownload={filesToDownload}
              isLoading={isLoading || isError || getCategoriesLoading || getCategoriesError}
              content={data?.data?.content}
            />
          )
          : <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />

      }
    </TemplateWithMenuAndHeader>
  );
};

export default NetworkNewsDetails;
