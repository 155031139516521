import { useLocation } from 'react-router-dom';
import {
  endOfMonth, format, isValid, parse, subMonths,
} from 'date-fns';
import { fr } from 'date-fns/locale';

const useQueryParameters = () => new URLSearchParams(useLocation().search);

const getBinary = (fileToConvert) => new Promise((resolve) => {
  let baseURL = '';
  const reader = new FileReader();
  // Convert the file to base64 text
  reader.readAsBinaryString(fileToConvert);
  reader.onload = () => {
    baseURL = reader.result;
    resolve(baseURL);
  };
});

const getBase64 = (file) => new Promise((resolve) => {
  let baseURL = '';
  const reader = new FileReader();
  // Convert the file to base64 text
  reader.readAsDataURL(file);
  reader.onload = () => {
    baseURL = reader.result;
    resolve(baseURL);
  };
});

const getBinaries = (files) => {
  const promises = [];
  files.map((file) => promises.push(getBinary(file)));
  return Promise.all(promises);
};

const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  // Remove link from body
  document.body.removeChild(link);
};

const formatRegionsData = (regionsData) => regionsData?.map(
  ({ id, name: regionName }) => ({ value: id, label: regionName }),
);

const normalizeString = (value) => value?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();

const getLastThreeMonths = (date) => {
  const previousMonth = subMonths(date, 1);
  const prevPrevMonth = subMonths(date, 2);
  return [format(
    prevPrevMonth, 'LLLL yyyy', { locale: fr },
  ), format(
    previousMonth, 'LLLL yyyy', { locale: fr },
  ), format(
    date, 'LLLL yyyy', { locale: fr },
  )];
};

/**
 * Converts a date string into an array of formatted date strings.
 *
 * @param {string[]} dateStrings - The input date string.
 * @returns {string[]} - An array of formatted date strings in the format 'yyyy-MM-dd'.
 */
function convertToDateStrings(dateStrings) {
  if (!dateStrings.length || !dateStrings[0]) return [];
  const formatString = 'MMMM yyyy';

  const dateObjects = dateStrings.map((dateStr) => parse(dateStr, formatString, new Date(), { locale: fr }));

  // Tri des objets Date
  dateObjects.sort((a, b) => a - b);

  const formattedDateStrings = dateObjects.map((dateObj, index) => {
    if (!isValid(dateObj)) return null;
    if (index === dateObjects.length - 1) {
      return format(endOfMonth(dateObj), 'yyyy-MM-dd');
    }
    return format(dateObj, 'yyyy-MM-dd');
  });

  return formattedDateStrings;
}

export default {
  useQueryParameters,
  getBinary,
  getBase64,
  getBinaries,
  downloadBlob,
  formatRegionsData,
  normalizeString,
  getLastThreeMonths,
  convertToDateStrings,
};
