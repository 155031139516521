import React, { useEffect, useRef, useState } from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import { LoaderComponent, ButtonComponent } from '@zolteam/axenergie-ui-library';
import { useClickAway, useMeasure } from 'react-use';
// Images
import { useHistory } from 'react-router-dom';
import { ReactComponent as UserIcon } from '../../assets/images/userRed.svg';
// Hooks
import { useAppContext } from '../../store/AppContext';
import useIsMobile from '../../hooks/useIsMobile';
import useIntranetSpacing from '../../hooks/useIntranetSpacing';
// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';
// Services
import AgencyService from '../../services/AgencyService';
import NotificationService from '../../services/NotificationService';
// Components
import LightSelectComponent from '../atoms/LightSelectComponent';
import NotificationComponent from '../atoms/NotificationComponent';
import useIsIntranet from '../../hooks/useIsIntranet';

const Header = ({ setHeight, isAdmin, setAgencySelectorHeight }) => {
  const [{ auth }] = useAppContext();
  const contentSpacing = useIntranetSpacing();
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [agency, setAgency] = useState(null);
  const [agencies, setAgencies] = useState(null);

  const [{ currentAgency, loginAs, showExtranetBackButton }, appDispatch] = useAppContext();
  const [isMobile] = useIsMobile();
  const isIntranet = useIsIntranet();
  const history = useHistory();
  const queryCache = useQueryClient();

  const [headerRef, { height }] = useMeasure();
  const [agencySelectorRef, { height: agencySelectorHeight }] = useMeasure();
  const profilRef = useRef(null);
  useClickAway(profilRef, () => setIsOpen(false));

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(['getMyAgencies', auth],
    () => {
      if (!isAdmin) {
        return false;
      }
      const loginAsId = loginAs ? JSON.parse(loginAs)?.id : null;
      const adminId = loginAsId || jwtDecode(auth)?.sub;
      return AgencyService.getAgencies({
        adminId,
        isActive: 'true',
      });
    });

  const getUnreadNotifications = useQuery(['getUnreadNotifications', auth, isIntranet],
    () => {
      if (isIntranet) {
        return NotificationService.getMyUnreadNotifications();
      }
      return false;
    });

  useEffect(() => {
    setHeight(height);
  }, [height]);

  useEffect(() => {
    setAgencySelectorHeight(agencySelectorHeight);
  }, [agencySelectorHeight]);

  useEffect(() => {
    if (auth) {
      const decoded = jwtDecode(auth);
      setName(`${decoded.given_name} ${decoded.family_name}`);
    }
  },
  [auth]);

  useEffect(() => {
    if (isSuccess && data?.data?.agencies?.length) {
      const current = data?.data?.agencies?.find(({ id }) => id === currentAgency) || data?.data?.agencies?.[0];
      setAgency(current);
      setAgencies(data?.data?.agencies?.map(({ id, name: agencyName }) => ({ label: agencyName, value: id })) || []);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAgencies, isSuccess: false },
      });
    }
  }, [isError, data, isSuccess]);

  useEffect(() => {
    if (agency && agency?.id) {
      appDispatch({ type: 'SET_CURRENT_AGENCY', payload: agency.id });
      appDispatch({ type: 'SET_AGENCY_NAME', payload: agency.name });
    }
  }, [agency]);

  const logout = () => {
    appDispatch({ type: 'SET_AUTH', payload: '' });
    appDispatch({ type: 'SET_REFRESH', payload: '' });
    appDispatch({ type: 'SET_PERMISSIONS', payload: '' });
    appDispatch({ type: 'SET_LOGIN_AS', payload: '' });
    appDispatch({ type: 'SET_CURRENT_AGENCY', payload: '' });
    appDispatch({ type: 'SET_CLIENT_AGENCIES', payload: [] });
    appDispatch({ type: 'SET_AGENCY_NAME', payload: null });
  };

  const logoutAs = async () => {
    const adherent = JSON.parse(loginAs)?.adherentId;
    await appDispatch({ type: 'SET_LOGIN_AS', payload: '' });
    await appDispatch({ type: 'SET_USER', payload: jwtDecode(auth) });
    appDispatch({ type: 'SET_CURRENT_AGENCY', payload: '' });
    appDispatch({ type: 'SET_AGENCY_NAME', payload: null });
    appDispatch({ type: 'SET_CLIENT_AGENCIES', payload: [] });
    queryCache.clear();
    return history.push(`/intranet/adherent?id=${adherent}`);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setIsOpen((state) => !state);
    }
  };

  const renderAgencySelector = () => {
    if (agencies?.length > 1) {
      return (
        <div className="form-input-width small">
          <LightSelectComponent
            data={agencies}
            value={({ value: agency?.id, label: agency?.name })}
            setValue={({ value }) => setAgency(data?.data?.agencies.find(({ id }) => id === value))}
          />
        </div>
      );
    }
    if (agency) {
      return (
        <div className={`${isMobile ? 'full-width mt-3' : 'form-input-width small ml-2'}`}>
          <span className="primary-red-text normal-weight">{agency?.name}</span>
        </div>
      );
    }
    return null;
  };

  const handleIntranetGoBackClick = () => {
    appDispatch({
      type: 'SET_SHOW_EXTRANET_BACK_BUTTON',
      payload: false,
    });
    history.push('/extranet');
  };

  return (
    <>
      <div ref={headerRef} className="index-2">
        <div
          className={`white-background 
          ${isMobile ? 'pl-1 relative' : 'ph-5'} ${isAdmin ? 'header-with-menu' : ''} pv-4 d-flex justify-between 
          relative f-wrap`}
          style={{
            alignItems: isMobile ? 'flex-end' : 'center',
            paddingLeft: !isAdmin ? contentSpacing : null,
          }}
        >
          { showExtranetBackButton
            ? (
              <div className="ml-5">
                <ButtonComponent onClick={handleIntranetGoBackClick} theme="grey">
                  {strings.goBack}
                </ButtonComponent>
              </div>
            ) : null}
          {/* Agency select block */}
          <div
            className={`d-flex f-row 
            ${isMobile && agency ? 'absolute white-background pv-2 full-screen-width justify-end mt-1' : ''}`}
            ref={agencySelectorRef}
            style={{
              top: height,
              left: -90,
            }}
          >
            {
        agency?.logoFilePath
          ? (
            <img
              alt="agency-logo"
              src={`${process.env.REACT_APP_IMAGE_URL}/${agency.logoFilePath}`}
              height={50}
            />
          ) : null
          }
            <div className={isMobile ? 'mh-3' : ''}>
              {isLoading
                ? <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />
                : renderAgencySelector()}
            </div>
          </div>
          <div className={`d-flex f-row align-center f-wrap ${isMobile ? 'justify-end' : 'ml-5 mr-3'}`}>
            {isIntranet ? (
              <NotificationComponent
                unRead={getUnreadNotifications?.data?.data?.unreadNotificationsCounter}
                refresh={() => getUnreadNotifications.refetch()}
                containerHeight={height}
              />
            ) : null}
            <div
              className="d-flex f-row"
              style={{ maxWidth: '100%' }}
            >
              <p className={`bold grey-800-text bold mv-0 t-overflow-ellipsis 
            ${isMobile ? 'text-align-right' : 'text-align-left'}`}
              >
                {name}
              </p>
              <button
                ref={profilRef}
                className="relative mr-2"
                type="button"
                onClick={() => setIsOpen((state) => !state)}
              >
                <UserIcon width={25} />
                {
              isOpen
                ? (
                  <div
                    role="button"
                    onClick={() => setIsOpen((state) => !state)}
                    onKeyDown={onKeyDown}
                    tabIndex={0}
                    className="absolute d-flex f-column radius-5 header-profile-modal-container fgIndex"
                  >
                    <div className="grey-800-background pv-3 ph-4 top-radius">
                      <h6 className="white-text medium-weight m-0">Mon profil</h6>
                    </div>
                    {
                      isIntranet ? null
                        : (
                          <button
                            type="button"
                            className="grey-100-background pv-3 ph-4 bottom-radius"
                            onClick={() => history.push('/extranet/personal-info')}
                          >
                            <span className="ws-nowrap">{strings.myPersonalInfo}</span>
                          </button>
                        )
                    }
                    <button
                      type="button"
                      className="grey-100-background pv-3 ph-4 bottom-radius"
                      onClick={logout}
                    >
                      <span className="ws-nowrap">{strings.logMeOut}</span>
                    </button>
                  </div>
                )
                : null
            }
              </button>
            </div>
          </div>
        </div>
      </div>
      { loginAs && JSON.parse(loginAs)?.id
        ? (
          <div className={`primary-red-background fgIndex
        ${isMobile ? 'ph-1' : 'ph-5'} pv-2 d-flex justify-between header-with-menu f-wrap sticky`}
          >
            <span
              className="normal-weight white-text ml-5"
            >
              {`${strings.youAreLoggedAs} ${JSON.parse(loginAs)?.name}`}
            </span>
            <button type="button" onClick={logoutAs}>
              <span className="white-text normal-weight underline">{strings.logMeOut}</span>
            </button>
          </div>
        )
        : null}
    </>
  );
};

Header.propTypes = {
  setHeight: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  setAgencySelectorHeight: PropTypes.func,
};

Header.defaultProps = {
  isAdmin: true,
  setAgencySelectorHeight: () => {},
};

export default Header;
