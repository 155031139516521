import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonComponent, InputComponent, LoaderComponent, SelectComponent, RadioButtons,
} from '@zolteam/axenergie-ui-library';
import { useMutation, useQuery } from 'react-query';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraftjs from 'html-to-draftjs';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import ModalComponent from '../../../components/atoms/ModalComponent';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';
import utils from '../../../utils/utils';

// Service
import NetworkNewsService from '../../../services/NetworkNewsService';
import FileService from '../../../services/FileService';

// Hooks
import { useAppContext } from '../../../store/AppContext';

const EditNetworkNews = () => {
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [contentError, setContentError] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const attachments = [
    [file1, setFile1],
    [file2, setFile2],
    [file3, setFile3],
    [file4, setFile4],
  ];
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const images = [
    [image1, setImage1],
    [image2, setImage2],
  ];
  const [imageIds, setImageIds] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [receiver, setReceiver] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const receivers = [
    { code: strings.onlyAdherent, id: 0 },
    { code: strings.everyone, id: 1 },
  ];

  // Store
  const [, appDispatch] = useAppContext();

  // Navigation
  const history = useHistory();
  const parameters = utils.useQueryParameters();

  // Format
  const formatFilesForApiCall = (toFormat) => toFormat.reduce((acc, id) => {
    if (Object.values(id)[0]) {
      return [...acc, { fileId: parseInt(Object.values(id), 10) }];
    }
    return acc;
  }, []);

  // Queries
  const getCategories = useQuery('getCategories', () => NetworkNewsService.getCategories());
  const { isSuccess, data, isError } = useQuery('getNews',
    () => {
      if (parameters.get('id')) {
        return NetworkNewsService.getOneNews({ id: parameters.get('id') });
      }
      return null;
    });
  const createNews = useMutation('addNetworkNews',
    async (networkNews) => {
      let newImages = formatFilesForApiCall(imageIds);
      let newAttachements = formatFilesForApiCall(attachmentIds);
      if (image1 || image2) {
        const imageToUpload = images.reduce((acc, [image]) => {
          if (image) {
            return [...acc, image];
          }
          return acc;
        }, []);
        const imageRequests = imageToUpload.map((image) => FileService.addFile({ binary: image, typeId: 9 }));
        const results = await Promise.all(imageRequests);
        newImages = [...newImages, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      if (file1 || file2 || file3 || file4) {
        const fileToUpload = attachments.reduce((acc, [file]) => {
          if (file) {
            return [...acc, file];
          }
          return acc;
        }, []);
        const fileRequests = fileToUpload.map((file) => FileService.addFile({ binary: file, typeId: 10 }));
        const results = await Promise.all(fileRequests);
        newAttachements = [...newAttachements, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      return NetworkNewsService.addNews({
        news: {
          ...networkNews,
          illustrationFiles: newImages,
          joinedFiles: newAttachements,
        },
      });
    });
  const updateNews = useMutation('update-network-news',
    async (networkNews) => {
      let newImages = formatFilesForApiCall(imageIds);
      let newAttachements = formatFilesForApiCall(attachmentIds);
      if (image1 || image2) {
        const imageToUpload = images.reduce((acc, [image]) => {
          if (image) {
            return [...acc, image];
          }
          return acc;
        }, []);
        const imageRequests = imageToUpload.map((image) => FileService.addFile({ binary: image, typeId: 9 }));
        const results = await Promise.all(imageRequests);
        newImages = [...newImages, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      if (file1 || file2 || file3 || file4) {
        const fileToUpload = attachments.reduce((acc, [file]) => {
          if (file) {
            return [...acc, file];
          }
          return acc;
        }, []);
        const fileRequests = fileToUpload.map((file) => FileService.addFile({ binary: file, typeId: 10 }));
        const results = await Promise.all(fileRequests);
        newAttachements = [...newAttachements, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      return NetworkNewsService.updateNews({
        news: {
          ...networkNews,
          illustrationFiles: newImages,
          joinedFiles: newAttachements,
        },
      });
    });
  const deleteNews = useMutation('deleteNews', (id) => NetworkNewsService.deleteNews(id));

  // Methods

  const deleteOne = (id) => {
    deleteNews.mutate({ id });
  };

  const addNews = () => {
    createNews.mutate({
      title,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
      illustrationFiles: formatFilesForApiCall(imageIds),
      joinedFiles: formatFilesForApiCall(attachmentIds),
      isPublic: receiver,
      categoryId: category.value,
    });
  };

  const editNews = () => {
    updateNews.mutate({
      ...data?.data,
      title,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
      illustrationFiles: formatFilesForApiCall(imageIds),
      joinedFiles: formatFilesForApiCall(attachmentIds),
      isPublic: receiver,
      categoryId: category.value,
    });
  };

  const onSubmit = () => {
    if (titleError || !category || contentError || receiver === null) {
      return false;
    }
    if (parameters.get('id')) {
      editNews();
    } else {
      addNews();
    }
    return true;
  };

  const formatCategories = (toFormat) => toFormat?.map(({ id, code }) => ({ label: code, value: id }));

  const goToList = () => history.push('/intranet/network-news');

  useEffect(() => {
    if (deleteNews.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteNews, isSuccess: true },
      });
      setIsDeleteModalOpen(false);
      goToList();
    }
    if (deleteNews.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteNews, isSuccess: false },
      });
    }
  }, [deleteNews.isSuccess, deleteNews.isError]);

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setTitle(data.data.title);
      setContent(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.content)),
      ));
      setReceiver(data?.data?.isPublic ? 1 : 0);
      setImageIds(data.data.illustrationFiles?.map(({ fileId }, index) => ({ [index + 1]: fileId })) || []);
      setAttachmentIds(data.data.joinedFiles?.map(({ fileId }, index) => ({ [index + 1]: fileId })) || []);
      setImage1(null);
      setImage2(null);
      setFile1(null);
      setFile2(null);
      setFile3(null);
      setFile4(null);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.newsLoading, isSuccess: false },
      });
      goToList();
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (getCategories.isSuccess && getCategories.data) {
      setCategories(formatCategories(getCategories.data?.data?.categories));
      if (!category) {
        if (data?.data?.categoryId) {
          setCategory(formatCategories(getCategories.data?.data?.categories)
            .find(({ value }) => value === data?.data?.categoryId));
        } else {
          setCategory(formatCategories(getCategories.data?.data?.categories)?.[0]);
        }
      }
    }
    if (getCategories.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
  }, [getCategories.isSuccess, getCategories.isError, getCategories.data]);

  useEffect(() => {
    if (createNews.isSuccess || updateNews.isSuccess) {
      goToList();
    }
    if (createNews.isError) {
      const toDisplay = Object.values(createNews?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createNetworkNews, isSuccess: false },
      });
    }
    if (updateNews.isError) {
      const toDisplay = Object.values(updateNews?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateNetworkNews, isSuccess: false },
      });
    }
  }, [createNews.isSuccess, createNews.isError, updateNews.isSuccess, updateNews.isError]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/network-news">
          <h3 className="mr-3 mv-1">{strings.networkNews}</h3>
        </Link>
        <h3
          className="mh-0 mv-1 text-align-left"
        >
          {` > ${data?.data?.title || strings.newNetworkNews} `}
        </h3>
      </div>
      <div className="pv-4 d-flex f-column f-wrap full-width align-start">
        <div className="d-flex f-row align-end f-wrap">
          <div className="form-input-width mv-3 mr-3">
            <InputComponent
              onChange={setTitle}
              id="news-title"
              value={title}
              label={strings.title}
              isError={titleError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setTitleError, title)}
            />
          </div>
          <div className="form-input-width mv-3 text-align-left">
            <SelectComponent
              data={categories}
              name="network-news-categories"
              value={category}
              setValue={setCategory}
              theme="dark"
              isLoading={getCategories.isLoading}
              placeholder={strings.category}
              label={strings.category}
            />
          </div>
        </div>
        <div className="d-flex f-row f-wrap">
          {
                  images.map(([image, setImage], index) => (
                    <div
                          // eslint-disable-next-line react/no-array-index-key
                      key={`image-${index}`}
                      className="form-file-input-width mr-3 mv-3"
                    >
                      <FileInputWithAcceptAndSize
                        setFile={(file) => {
                          setImage(file);
                          setImageIds((state) => state.filter((id) => {
                            if (file !== null) return true;
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return false;
                            }
                            return true;
                          }).map((id) => {
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return { [index + 1]: null };
                            }
                            return id;
                          }));
                        }}
                        file={image}
                        canDelete={image || imageIds[index]}
                        label={`${imageIds[index] ? strings.changeImage : strings.illustrationImage} ${index + 1}`}
                        fileType={9}
                        initName={`${strings.changeImage} ${index + 1}`}
                      />
                    </div>
                  ))
              }
        </div>
        <div className="full-width mv-3">
          <TextEditorComponent
            hasTitles
            label={strings.newsContent}
            setValue={setContent}
            value={content}
            isError={contentError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setContentError,
              convertToRaw(content.getCurrentContent()).blocks[0].text)
            )}
          />
        </div>
        <div className="d-flex f-row f-wrap">
          {
                  attachments.map(([file, setFile], index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`logo-${index}`}
                      className="form-file-input-width small mr-3 mv-3"
                    >
                      <FileInputWithAcceptAndSize
                        setFile={(newFile) => {
                          setFile(newFile);
                          setAttachmentIds((state) => state.filter((id) => {
                            if (newFile !== null) return true;
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return false;
                            }
                            return true;
                          }).map((id) => {
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return { [index + 1]: null };
                            }
                            return id;
                          }));
                        }}
                        file={file}
                        canDelete={file || attachmentIds[index]}
                        label={`${attachmentIds[index] ? strings.changeFile : strings.file} ${index + 1}`}
                        fileType={10}
                        initName={`${strings.changeFile} ${index + 1}`}
                      />
                    </div>
                  ))
              }
        </div>
        <h4 className="medium-weight">{strings.receivers}</h4>
        <div className="d-flex f-row align-center justify-start f-wrap">
          <RadioButtons setValue={setReceiver} value={receiver} options={receivers} />
        </div>
      </div>
      { !title
      || !category
      || !convertToRaw(content.getCurrentContent()).blocks[0].text
      || receiver === null
        ? null : (
          <div className="mv-3 text-align-left d-flex f-wrap">
            <ButtonComponent onClick={onSubmit}>
              <div className="mv-2">
                {createNews.isLoading || updateNews.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="uppercase m-5">{strings.save}</span>}
              </div>
            </ButtonComponent>
            {
              parameters.get('id') && data?.data
                ? (
                  <div className="m-2">
                    <ButtonComponent
                      onClick={() => setIsDeleteModalOpen(true)}
                      theme="outline"
                    >
                      <div className="mv-2">
                        {deleteNews.isLoading
                          ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                          : <span className="uppercase m-5">{strings.delete}</span>}
                      </div>
                    </ButtonComponent>
                  </div>
                )
                : null
            }
          </div>
        )}
      {
        isDeleteModalOpen ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deleteNews}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => deleteOne(data?.data?.id)}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default EditNetworkNews;
