import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalTemplate } from '@zolteam/axenergie-ui-library';
import { useQuery } from 'react-query';
import PrestationService from '../../services/PrestationService';
import strings from '../../constants/strings';
import { useAppContext } from '../../store/AppContext';

const PrestationDetailsModal = ({ prestation, closeModal }) => {
  const [, appDispatch] = useAppContext();
  // Queries
  const {
    isSuccess: typesSuccess, isError: typesError, data: allTypes,
  } = useQuery('getTypes', PrestationService.getPrestationTypes);

  useEffect(() => {
    if (typesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.prestationTypeLoading, isSuccess: false },
      });
    }
  }, [typesError, typesSuccess, allTypes]);

  return (
    <ModalTemplate
      closeModal={closeModal}
      title={(
        <div className="d-flex f-column">
          <div className="d-flex f-row f-wrap align-center">
            <h1 className="m-0">{prestation.title}</h1>
            <div className="primary-red-background radius-20 pv-2 ph-4 mh-4 d-flex">
              <span className="white-text medium-weight regular-text">
                {allTypes?.data?.prestationTypes?.find(({ id }) => id === prestation.prestationTypeId)?.code}
              </span>
            </div>
          </div>
          <h5 className="normal-weight grey-400-text m-0 mt-3">
            {prestation.subtitle}
          </h5>
        </div>
            )}
    >
      <div
        className="d-flex f-column mt-3"
        style={{ maxWidth: 1000 }}
      >
        <div className="ph-5">
          <h5 className="uppercase grey-800-text medium-weight mv-3">
            {`${strings.argument} :`}
          </h5>
          <div
            className="grey-600-text medium-text wysiwyg-text pl-3"
            dangerouslySetInnerHTML={{ __html: prestation.argumentation }}
          />
          <h5 className="uppercase grey-800-text medium-weight mv-3">
            {`${strings.details} :`}
          </h5>
          <div
            className="grey-600-text medium-text wysiwyg-text pl-3"
            dangerouslySetInnerHTML={{ __html: prestation.details }}
          />
          { prestation.goodReasonsBlock
            ? (
              <>
                <h5 className="uppercase grey-800-text medium-weight mv-3">
                  {`${strings.fiveGoodReasonBlock} :`}
                </h5>
                <h6 className="grey-800-text medium-weight pl-3 m-0">
                  {`${prestation.goodReasonsBlock.intro}`}
                </h6>
                <ol className="pl-5">
                  {
                    prestation.goodReasonsBlock?.goodReasons?.map(({ reason }) => (
                      <li className="grey-800-text mv-3">{reason}</li>
                    ))
                }
                </ol>
              </>
            )
            : null}
        </div>
      </div>
    </ModalTemplate>
  );
};

PrestationDetailsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  prestation: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    argumentation: PropTypes.string,
    details: PropTypes.string,
    prestationTypeId: PropTypes.number,
    goodReasonsBlock: PropTypes.shape(),
  }).isRequired,
};

export default PrestationDetailsModal;
