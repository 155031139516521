import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

// Images
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg';

// Services
import SignatureService from '../../services/SignatureService';

// Constants
import strings from '../../constants/strings';

const DownloadSignature = ({ id }) => {
  const [files, setFiles] = useState();
  const { data, isSuccess } = useQuery(['getSignatureFiles', id], () => SignatureService.getSignatureFiles(id));

  useEffect(() => {
    if (isSuccess) {
      setFiles(URL.createObjectURL(new Blob([data?.data], { type: 'application/zip' })));
    }
  }, [isSuccess, data?.data]);

  return (
    <a href={files} download={strings.downloadArchiveName}>
      <DownloadIcon
        width={20}
        height={20}
        alt=""
      />
    </a>
  );
};

DownloadSignature.propTypes = {
  id: PropTypes.number.isRequired,
};

export default DownloadSignature;
