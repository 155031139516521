import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useMeasure } from 'react-use';

// Components
import { ButtonComponent, LoaderComponent, PaginationComponent } from '@zolteam/axenergie-ui-library';
import ForumFilters from '../../../components/molecules/ForumFilters';
import ForumThreadCard from '../../../components/atoms/ForumThreadCard';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import ForumService from '../../../services/ForumService';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Images
import { ReactComponent as AddCircleIcon } from '../../../assets/images/add-circle.svg';

const ForumList = () => {
  const [forumName, setForumName] = useState('');
  // Pagination related variables
  const [currentList, setCurrentList] = useState([]);
  const [totalLength, setTotalLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // Filters related states
  const [theme, setTheme] = useState(null);
  const [search, setSearch] = useState('');
  // Measures relative variables
  const [containerRef, { width }] = useMeasure();

  // Hooks
  const { forum } = useParams();
  const history = useHistory();
  const [{ forums, previousSearch }, appDispatch] = useAppContext();

  // Queries
  const getThemes = useQuery(['getForumThemes', forum], () => ForumService.getThemes({ forum }));
  const getThreads = useQuery(['getThreads', forum, theme, currentPage],
    () => ForumService.getThreads({
      forum,
      search: previousSearch || search || null,
      themeId: theme,
      pageNumber: currentPage,
      pageSize: itemsPerPage,
    }));

  const doSearch = () => {
    getThreads.refetch();
  };

  const goToForumThread = (id) => {
    if (search) {
      appDispatch({
        type: 'SET_PREVIOUS_SEARCH',
        payload: search,
      });
    }
    history.push(`/intranet/forum/${forum}/thread/${id}`);
  };

  useEffect(() => {
    if (previousSearch) {
      setSearch(previousSearch);
      appDispatch({
        type: 'SET_PREVIOUS_SEARCH',
        payload: '',
      });
    } else setSearch('');
  }, [forum]);

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (forums) {
      setForumName(forums?.find(({ id }) => id.toString() === forum)?.title);
    }
  }, [forums, forum]);

  useEffect(() => {
    if (getThemes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getForumThemes, isSuccess: false },
      });
    }
  }, [getThemes.isError]);

  useEffect(() => {
    if (getThreads.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getThreads, isSuccess: false },
      });
    }
    if (getThreads.isSuccess) {
      setCurrentList(getThreads.data?.data?.forumThreads);
      setTotalLength(getThreads.data?.data?.pageCount);
    }
  }, [getThreads.isError, getThreads.isSuccess, getThreads.data]);

  const renderEmptyList = () => {
    if (getThreads.isLoading
        || getThreads.isError) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noThread}</span>
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start max-list-container-width" ref={containerRef}>
        <div className="d-flex f-row justify-between align-center f-wrap">
          <div className="mr-5 d-flex f-column align-start">
            <h2 className="medium-weight mh-0">
              <span>{strings.forum}</span>
              <span className="mh-2">{forumName}</span>
            </h2>
            <span className="grey-400-text mb-5">{strings.forumSubtitle}</span>
          </div>
          <div className="mv-2">
            <ButtonComponent onClick={() => history.push(`/intranet/forum/${forum}/create-thread`)}>
              <div className="d-flex align-center pv-2 ph-4">
                <span className="ws-nowrap mr-2">{strings.newThread}</span>
                <AddCircleIcon width={24} height={24} />
              </div>
            </ButtonComponent>
          </div>
          {/* Filters */}
          <ForumFilters
            doSearch={doSearch}
            search={search}
            theme={theme}
            width={width}
            setTheme={setTheme}
            setSearch={setSearch}
            themes={getThemes.data?.data?.forumThemes || []}
          />
          {/* List */}
          <div style={{ width }}>
            {
            currentList?.length && !getThreads.isLoading
              ? currentList.map(({
                title,
                forumThemeId,
                publicationDate,
                author,
                agencyName,
                content,
                isClosed,
                numberOfAnswers,
                lastUpdateDate,
                id: itemId,
              }) => (
                <ForumThreadCard
                  key={itemId}
                  title={title}
                  theme={getThemes?.data?.data?.forumThemes?.find(({ id }) => id === forumThemeId)?.name}
                  isClosed={isClosed}
                  publicationDate={publicationDate}
                  author={author}
                  numberOfAnswers={numberOfAnswers}
                  lastUpdateDate={lastUpdateDate}
                  agencyName={agencyName}
                  content={content}
                  open={() => goToForumThread(itemId)}
                />
              ))
              : renderEmptyList()
          }
            {totalLength > 1 ? (
              <div className="white-background full-width">
                <div className="p-4">
                  <PaginationComponent
                    setCurrentList={setCurrentList}
                    totalPage={totalLength || 1}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default ForumList;
