import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

// constants
import colors from '../../constants/colors';
import strings from '../../constants/strings';

// Services
import FileService from '../../services/FileService';

// Utils
import utils from '../../utils/utils';

// Images
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as OpenMailIcon } from '../../assets/images/open-envelope.svg';

const NewsCard = ({
  className,
  category,
  title,
  content,
  date,
  image,
  width,
  open,
  editAction,
  isRead,
  read,
  unread,
  canRead,
}) => {
  const [base64, setBase64] = useState(null);

  const {
    isSuccess, data,
  } = useQuery(['getFile', image], () => {
    if (image) {
      return FileService.getFile({ id: image });
    }
    return Promise.resolve();
  });

  useEffect(() => {
    if (isSuccess && data) {
      utils.getBase64(data.data).then((result) => {
        setBase64(result);
      });
    }
  }, [isSuccess, data]);

  return (
    <button type="button" onClick={open} className={`${className}`}>
      <div
        className="d-flex f-column align-start radius-5"
        style={{ border: '1px solid #EDEEF1', width }}
      >
        <div className="d-flex f-row justify-between align-center full-width">
          <span className="very-small-text uppercase primary-red-text ph-3 pv-2">{category}</span>
          {canRead ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                return isRead ? unread() : read();
              }}
              className="tooltip d-flex justify-center"
            >
              {
              isRead
                ? <OpenMailIcon width={18} height={18} />
                : <MailIcon width={18} height={18} />
            }
              <span className="tooltiptext">{isRead ? strings.markAsUnread : strings.markAsread}</span>
            </button>
          ) : null}
          {editAction ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                editAction();
              }}
            >
              <EditIcon width={16} height={16} />
            </button>
          ) : null}
        </div>
        <h3 className={`${isRead ? 'grey-400-text' : 'grey-800-text'} medium-weight ph-3 mv-2`}>{title}</h3>
        {image
          ? (
            <div
              className="full-width bg-cover"
              style={{
                height: 200,
                marginBottom: '-20px',
                filter: `grayscale(${isRead ? 1 : 0})`,
                backgroundImage: `url("${base64}")`,
              }}
            />
          )
          : null}
        <SlashContainer width="100%" backgroundColor={colors.grey100} offset={image ? 10 : 0} position="top">
          <div className="d-flex f-column align-start" style={{ width }}>
            <div
              className="description-text ph-3 wysiwyg-text"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {date ? (
              <p className="small-text grey-400-text ph-3">
                {`${strings.the} ${format(new Date(date.split(' ').join('T')), 'dd.MM.yyyy')}`}
              </p>
            ) : null}
          </div>
        </SlashContainer>
      </div>
    </button>
  );
};

NewsCard.propTypes = {
  className: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  open: PropTypes.func.isRequired,
  isRead: PropTypes.bool.isRequired,
  canRead: PropTypes.bool.isRequired,
  editAction: PropTypes.func,
  read: PropTypes.func,
  unread: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NewsCard.defaultProps = {
  image: '',
  editAction: null,
  read: () => {},
  unread: () => {},
};

export default NewsCard;
