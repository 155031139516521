import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { InputComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/strings';

const PriceInput = ({ onPriceChange, id, defaultPrice }) => {
  const [price, setPrice] = useState(defaultPrice);

  useEffect(() => {
    setPrice(defaultPrice);
  }, [defaultPrice]);

  return (
    <div className="d-flex f-row mv-2 align-center">
      <p>{strings.price}</p>
      <div className="mh-2" style={{ width: 200 }}>
        <InputComponent
          onChange={setPrice}
          id={id}
          value={price.toString()}
          label=""
          onBlur={() => onPriceChange(price)}
          placeholder={strings.priceInputExample}
        />
      </div>
    </div>

  );
};

PriceInput.propTypes = {
  onPriceChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  defaultPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PriceInput.defaultProps = {
  defaultPrice: '',
};

export default PriceInput;
