import client from './client';
import rpcClient from './clientRPC';

const getPrestationColors = () => client.get('/prestation-colors');

const getPrestationTypes = () => client.get('/prestation-types');

// Network prestations
const getNetworkPrestation = ({ id }) => client.get(`/network-prestations/${id}`);
const getNetworkPrestations = () => client.get('/network-prestations');
const updateNetworkPrestation = ({ prestation }) => client.put(`/network-prestations/${prestation.id}`, prestation);
const createNetworkPrestation = ({ prestation }) => client.post('/network-prestations', prestation);
const deleteNetworkPrestation = ({ id }) => client.delete(`/network-prestations/${id}`);
const networkPrestationReorder = ({ reorderedPrestationId, newPrecedingPrestationId }) => client.post(
  '/network-prestations:reorder',
  {
    reorderedPrestationId,
    newPrecedingPrestationId,
  },
);

// Agency prestations
const getAgencyPrestations = ({ agencyId }) => client.get('/agency-prestations', { params: { agencyId } });
const getAgencyPrestation = ({ id }) => client.get(`/agency-prestations/${id}`);
const getAllAgencyPrestations = ({ agencyId }) => rpcClient.get('/all-agency-prestations', { params: { agencyId } });
const updateAgencyPrestation = ({ prestation }) => client.put(`/agency-prestations/${prestation.id}`, prestation);
const createAgencyPrestation = ({ prestation }) => client.post('/agency-prestations', prestation);
const deleteAgencyPrestation = ({ id }) => client.delete(`/agency-prestations/${id}`);
const agencyPrestationReorder = ({ reorderedPrestationId, newPrecedingPrestationId }) => client.post(
  '/agency-prestations:reorder',
  {
    reorderedPrestationId,
    newPrecedingPrestationId,
  },
);

// Network agency prestations
const updateNetworkAgencyPrestation = ({ prestation }) => client
  .put(`/agency-network-prestations/${prestation.id}`, prestation);
const createNetworkAgencyPrestation = ({ prestation }) => client.post('/agency-network-prestations', prestation);

// equipments
const getEquipmentTypes = () => client.get('/equipment-types');
const getEquipmentType = ({ id, agencyId }) => client.get(`/equipment-types/${id}`,
  { params: { agencyId: agencyId || 0 } });
const addDetail = ({ detail }) => client.post('/equipment-types-details', { ...detail });
const updateDetail = ({ detail }) => client.put(`/equipment-types-details/${detail.id}`, { ...detail });

const getEnergyTypes = () => client.get('/energy-types');
const getBrands = () => client.get('/brands');

export default {
  getNetworkPrestations,
  getNetworkPrestation,
  updateNetworkPrestation,
  getPrestationColors,
  getPrestationTypes,
  createNetworkPrestation,
  deleteNetworkPrestation,
  getEquipmentTypes,
  getEquipmentType,
  addDetail,
  updateDetail,
  getAgencyPrestations,
  getAgencyPrestation,
  getAllAgencyPrestations,
  updateAgencyPrestation,
  createAgencyPrestation,
  deleteAgencyPrestation,
  updateNetworkAgencyPrestation,
  createNetworkAgencyPrestation,
  networkPrestationReorder,
  agencyPrestationReorder,
  getEnergyTypes,
  getBrands,
};
