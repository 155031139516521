import React from 'react';
import PropTypes from 'prop-types';
// Components
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
// Constants
import colors from '../../constants/colors';
import strings from '../../constants/strings';

const DataTable = ({
  isLoading, data, columns, tableStyle, trStyle,
}) => (
  <table className={`radius-5 white-background ${tableStyle}`}>
    <thead>
      <tr className={trStyle}>
        {columns.map((colName, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={`table-header-${index}`}>
            {colName}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {
        isLoading
          ? (
            <tr className={trStyle}>
              <td>
                <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
              </td>
            </tr>
          )
          : null
      }
      {
          !isLoading && !data.length
            ? (
              <tr className={trStyle}>
                <td>
                  <span className="grey-400-text">{strings.noData}</span>
                </td>
              </tr>
            )
            : null
      }
      {
        !isLoading
          ? data.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr className={trStyle} key={`table-row-${index}`}>
              {
                Object.values(row).map((tableData, ind) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={`table-data-${ind}`}>{tableData}</td>
                ))
              }
            </tr>
          ))
          : null
      }
    </tbody>
  </table>

);

DataTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableStyle: PropTypes.string,
  trStyle: PropTypes.string,
};

DataTable.defaultProps = {
  tableStyle: '',
  trStyle: '',
};

export default DataTable;
