import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { CSVLink } from 'react-csv';

// Components
import { InputFileComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/strings';

// Images
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';

// Utils
import utils from '../../utils/utils';

// Services and hooks
import AgencyService from '../../services/AgencyService';
import { useAppContext } from '../../store/AppContext';

const PostalCodesSelector = ({ agencyId }) => {
  const [postalCodes, setPostalCodes] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const [, appDispatch] = useAppContext();

  const addZipcodes = useMutation('addZipcodes', (data) => AgencyService.setAgencyPostalCodes(data));
  const getZipcodes = useQuery(['getZipcodes', addZipcodes.isSuccess, agencyId],
    () => AgencyService.getAgencyPostalCodes({ id: agencyId }));

  useEffect(() => {
    if (postalCodes) {
      utils.getBinary(postalCodes)
        .then((postalCodeString) => addZipcodes.mutate({
          id: agencyId,
          file: postalCodeString,
          type: postalCodes.type,
        }));
    }
  }, [postalCodes]);

  useEffect(() => {
    if (addZipcodes.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.setZipcodes, isSuccess: true },
      });
    }
    if (addZipcodes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.setZipcodes, isSuccess: false },
      });
      setPostalCodes(null);
    }
  }, [addZipcodes.isSuccess, addZipcodes.isError]);

  useEffect(() => {
    if (getZipcodes.isSuccess) {
      setCsvData(getZipcodes.data.data);
    }
    if (getZipcodes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.fileDownload, isSuccess: false },
      });
      setPostalCodes(null);
    }
  }, [getZipcodes.isSuccess, getZipcodes.isError, getZipcodes.data]);

  return (
    <div className="d-flex f-row full-width">
      <div className="d-flex f-column align-start mh-5">
        <h3 className="medium-weight mv-2">{strings.servedZipCodes}</h3>
        <div className="form-file-input-width small mv-2">
          <InputFileComponent
            file={postalCodes}
            setFile={setPostalCodes}
            label={strings.loadFile}
          />
        </div>
        {csvData?.length ? (
          <CSVLink
            data={csvData}
            filename="code-postaux"
            target="_blank"
          >
            <span className="d-flex align-center">
              <EyeIcon width={16} height={16} />
              <span className="ml-2 primary-red-text">{strings.seeZipcodeList}</span>
            </span>
          </CSVLink>
        ) : null}
      </div>
    </div>
  );
};

PostalCodesSelector.propTypes = {
  agencyId: PropTypes.number.isRequired,
};

export default PostalCodesSelector;
