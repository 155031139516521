import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Map } from '@vis.gl/react-google-maps/dist/index.umd';

// components
import ClusteredMapPins from './ClusteredMapPins';
import AdherentCard from '../atoms/AdherentCard';

const AdherentMap = ({ dimensions, agencyAdherentAdmins }) => {
  const formatAdherentAdmins = (agencies) => (agencies ? agencies.reduce((acc, agency) => {
    const { adherentAdmins, ...agencyData } = agency;
    if (agency.agencyLatitude && agency.agencyLongitude) {
      return [
        ...acc,
        ...adherentAdmins.map((admin) => ({
          ...agencyData,
          ...admin,
          key: `${admin.email}-${agency.agencyName}`,
          position: { lat: parseFloat(agency.agencyLatitude), lng: parseFloat(agency.agencyLongitude) },
        })),
      ];
    }
    return acc;
  }, []) : null);

  const adherentAdminList = useMemo(
    () => formatAdherentAdmins(agencyAdherentAdmins), [agencyAdherentAdmins],
  );

  const renderAdherentCard = (selectedAdherent) => (
    <AdherentCard
      key={selectedAdherent?.email}
      image={selectedAdherent?.avatarFileId}
      width={300}
      title={selectedAdherent?.agencyName}
      subtitle={null}
      region={selectedAdherent?.region}
      className="grid-item"
      contactName={`${selectedAdherent?.firstName} ${selectedAdherent?.lastName}`}
      address={selectedAdherent?.agencyAddress1}
      zipcode={selectedAdherent?.agencyPostalCode}
      city={selectedAdherent?.agencyCity}
      phone={selectedAdherent?.phone}
      email={selectedAdherent?.email}
    />
  );
  return (
    <Map
      key={adherentAdminList}
      mapId="adherent-map"
      style={dimensions}
      defaultCenter={{ lat: 46.603354, lng: 1.888334 }}
      defaultZoom={6}
      gestureHandling={false}
      reuseMaps={false}
      disableDefaultUI
    >
      {adherentAdminList ? (
        <ClusteredMapPins
          points={adherentAdminList}
          renderInfo={renderAdherentCard}
        />
      ) : null}
    </Map>
  );
};

AdherentMap.propTypes = {
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  agencyAdherentAdmins: PropTypes.arrayOf(PropTypes.shape({
    agencyName: PropTypes.string,
    agencyLatitude: PropTypes.string,
    agencyLongitude: PropTypes.string,
    adherentAdmins: PropTypes.arrayOf(PropTypes.shape({
      email: PropTypes.string,
      avatarFileId: PropTypes.string,
      agencyName: PropTypes.string,
      region: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      agencyAddress1: PropTypes.string,
      agencyPostalCode: PropTypes.string,
      agencyCity: PropTypes.string,
      phone: PropTypes.string,
    })),
  })),
};

AdherentMap.defaultProps = {
  agencyAdherentAdmins: [],
};

export default AdherentMap;
