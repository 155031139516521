import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import TemplateMenuHeaderClient from '../../components/Organisms/TemplateMenuHeaderClient';
import useIntranetSpacing from '../../hooks/useIntranetSpacing';
import Footer from '../../components/atoms/Footer';
import { useAppContext } from '../../store/AppContext';
import strings from '../../constants/strings';
import PaymentService from '../../services/PaymentService';
import utils from '../../utils/utils';

const PaymentFeedback = ({ isCancel }) => {
  const [, appDispatch] = useAppContext();
  const history = useHistory();
  const contentSpacing = useIntranetSpacing();
  const parameters = utils.useQueryParameters();
  const id = parameters.get('id');

  useEffect(() => {
    appDispatch({
      type: 'SET_SHOW_EXTRANET_BACK_BUTTON',
      payload: true,
    });
  }, []);

  const getPaymentStatusQuery = useQuery('getPaymentStatusQuery', () => PaymentService.getPaymentStatus(id), {
    enabled: !!id,
  });

  const state = useMemo(() => {
    if (isCancel) {
      return 'cancelled';
    }
    if (getPaymentStatusQuery?.data?.data?.is_paid) {
      return 'success';
    }
    return 'failed';
  }, [getPaymentStatusQuery?.data?.data]);

  const isError = useMemo(() => isCancel || state === 'failed', [state, isCancel]);
  // eslint-disable-next-line prefer-template
  const title = useMemo(() => strings?.[state + 'Transaction'], [state, strings]);
  // eslint-disable-next-line prefer-template
  const subtitle = useMemo(() => strings?.[state + 'TransactionSubtitle'], [state, strings]);

  return (
    <TemplateMenuHeaderClient>
      <div
        className="mh-5 d-flex f-column align-center justify-between"
        style={{
          paddingLeft: contentSpacing,
          minHeight: 'calc(100vh - 100px)',
        }}
      >
        <div className="d-flex align-center f-column">
          <h1 className="medium-weight grey-800-text mv-5">
            { state
              ? <span className={isError ? 'primary-red-text' : 'green-text'}>{title}</span>
              : null}
          </h1>
          <p className="medium-weight grey-400-text mb-6">
            { state
              ? <span>{subtitle}</span>
              : null}
          </p>
          <ButtonComponent onClick={() => history.push('/extranet')}>
            <div className="mv-2">
              {strings.backHome}
            </div>
          </ButtonComponent>
        </div>
        <Footer />
      </div>
    </TemplateMenuHeaderClient>
  );
};

PaymentFeedback.propTypes = {
  isCancel: PropTypes.bool,
};

PaymentFeedback.defaultProps = {
  isCancel: false,
};

export default PaymentFeedback;
