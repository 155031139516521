import React, { useEffect, useState } from 'react';

import { useMeasure, useWindowSize } from 'react-use';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { CheckboxComponent, InputComponent, SelectComponent } from '@zolteam/axenergie-ui-library';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import FilterButton from '../../../components/atoms/FilterButton';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';

// Constants
import strings from '../../../constants/strings';

// Services and hooks
import AdherentBookService from '../../../services/AdherentBookService';
import { useAppContext } from '../../../store/AppContext';
import AdherentService from '../../../services/AdherentService';
import AdherentMap from '../../../components/molecules/AdherentMap';
import {
  ACTIVITIES, EMPLOYEE_NUMBERS, JOBS, ROLES,
  SALES_FIGURES,
} from '../../../constants/adherents';

const AdherentBookMap = () => {
  const location = useLocation();

  // User searching by url param
  const [userSearchedUrl, setUserSearchedUrl] = useState(false);
  const userQueried = new URLSearchParams(location.search).get('query') || null;

  // Filters related states
  const [regionFilter, setRegionFilter] = useState(undefined);
  const [search, setSearch] = useState('');
  const [cooperativeRoles, setCooperativeRoles] = useState([]);
  const [jobType, setJobType] = useState(null);
  const [activities, setActivities] = useState([]);
  const [employeeAmount, setEmployeeAmount] = useState(0);
  const [enteredNetworkAt, setEnteredNetworkAt] = useState(null);
  const [salesFigures, setSalesFigures] = useState(null);

  // Measures relative variables
  const itemsMargin = 24;
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const columnWidth = screenWidth < 295 + 2 * itemsMargin + 24 ? screenWidth - 2 * itemsMargin - 24 : 295;

  // hooks
  const [, appDispatch] = useAppContext();

  // Queries
  const getRegions = useQuery('getRegions', () => AdherentService.getRegions());
  const getAll = useQuery({
    queryKey: [
      'getAllAdherents',
      regionFilter,
      cooperativeRoles,
      jobType?.value,
      activities,
      employeeAmount?.value,
      enteredNetworkAt,
      salesFigures?.value,
    ],
    queryFn: () => AdherentBookService.getAllAdherent({
      search: search || (userSearchedUrl ? false : userQueried) || undefined,
      regionId: regionFilter,
      cooperativeRoles: cooperativeRoles.length > 0 ? cooperativeRoles : undefined,
      jobType: jobType?.value,
      activities: activities.length > 0 ? activities : undefined,
      employeeAmount: employeeAmount?.value,
      salesFigures: salesFigures?.value,
      enteredNetworkAt: enteredNetworkAt || undefined,
    }),
  });

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 4) {
      nbColumns = 4;
    }
    return (nbColumns * columnWidth) + itemsMargin;
  };

  const doSearch = () => {
    getAll.refetch();
  };

  useEffect(() => {
    if (userQueried) {
      setUserSearchedUrl(true);
      setSearch(userQueried);
    }
  }, []);

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (getAll.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
  }, [getAll.isError]);

  useEffect(() => {
    if (getRegions.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getRegion, isSuccess: false },
      });
    }
  }, [getRegions.isError]);

  const renderCheckboxGroup = (fullList, setValue, value) => {
    const columns = 3;
    const itemsPerColumn = Math.ceil(fullList.length / columns);
    const columnItems = Array.from({ length: columns },
      (_, index) => fullList.slice(index * itemsPerColumn, (index + 1) * itemsPerColumn));

    const renderColumn = (items) => items.map(({ code, id }) => (
      <CheckboxComponent
        key={id}
        handleChange={() => {
          setValue((state) => {
            if (state.filter((item) => item === id)?.length === 0) {
              return state.concat([id]);
            }
            return state.filter((item) => item !== id);
          });
        }}
        label={code}
        id={id}
        value={value?.filter((item) => item === id)?.length > 0}
      />
    ));

    return (
      <div className="d-flex full-width">
        {
          columnItems.map((column) => (
            <div className="flex-1 mr-3">{renderColumn(column)}</div>
          ))
        }
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start pl-4" ref={containerRef}>
        <div
          className="d-flex f-row f-wrap justify-between align-center mt-4"
          style={{ width: getContainerWidth() }}
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{strings.adherentMap}</h2>
            <span className="grey-400-text mb-5">{strings.regionBookSubtitle}</span>
          </div>
        </div>
        {/* Filters */}

        <div
          className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
          style={{ width: getContainerWidth() }}
        >
          <div>
            <FilterButton
              onClick={() => setRegionFilter(undefined)}
              isSelected={!regionFilter}
              title={strings.allRegions}
            />
            {
              getRegions?.data?.data?.regions?.map(({ id, name }) => (
                <FilterButton
                  key={id}
                  onClick={() => setRegionFilter(id)}
                  isSelected={id === regionFilter}
                  title={name}
                />
              ))
            }
          </div>
          <div className="form-input-width mh-3 mv-2">
            <SearchInputComponent
              onChange={setSearch}
              id="adherent-search"
              value={search}
              onClick={doSearch}
            />
          </div>
          <div className="d-flex f-wrap">
            <div className="m-3">
              <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentRole}</p>
              <div>
                {renderCheckboxGroup(ROLES, setCooperativeRoles, cooperativeRoles)}
              </div>
            </div>
            <div className="m-3">
              <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentSearchActivities}</p>
              {renderCheckboxGroup(ACTIVITIES, setActivities, activities)}
            </div>
            <div className="form-input-width small m-3">
              <SelectComponent
                name="adherent-job"
                label={strings.adherentJob}
                data={JOBS}
                value={jobType}
                setValue={setJobType}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <SelectComponent
                name="employee-number"
                label={strings.employeeNumber}
                data={EMPLOYEE_NUMBERS}
                value={employeeAmount}
                setValue={setEmployeeAmount}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <SelectComponent
                name="employee-number"
                label={strings.salesFigures}
                data={SALES_FIGURES}
                value={salesFigures}
                setValue={setSalesFigures}
                isClearable
                theme="dark"
              />
            </div>
            <div className="form-input-width small m-3">
              <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentSearchEntry}</p>
              <InputComponent
                onChange={setEnteredNetworkAt}
                value={enteredNetworkAt}
                id="adherent-entry"
                name="adherent-entry"
                type="number"
              />
            </div>
          </div>

        </div>

        <AdherentMap
          agencyAdherentAdmins={getAll.data?.data?.agencyAdherentAdmins}
          dimensions={{ width: getContainerWidth(), height: 750 }}
        />

      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default AdherentBookMap;
