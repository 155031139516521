import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@zolteam/axenergie-ui-library/dist/index.css';
import './assets/styles/index.scss';

// You shouldn't modify this JS File. If you want to add wrapper, search for App.js instead

Sentry.init({
  dsn: 'https://8fd13283a1504cdaa1a3d51dc37ccc52@o125949.ingest.sentry.io/6147779',
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
