import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps/dist/index.umd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import MapPins from '../atoms/MapPins';

const ClusteredMapPins = ({ points, renderInfo }) => {
  const [markers, setMarkers] = useState({});
  const [selectedPointKey, setSelectedPointKey] = useState(null);

  const selectedPoint = useMemo(
    () => (points && selectedPointKey
      ? points.find((t) => t.key === selectedPointKey)
      : null),
    [points, selectedPointKey],
  );

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({
      map,
    });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.markers.forEach((marker) => marker.setMap(null));
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((marks) => {
      if ((marker && marks[key]) || (!marker && !marks[key])) return marks;

      if (marker) {
        return { ...marks, [key]: marker };
      }
      const { [key]: _, ...newMarkers } = marks;
      return newMarkers;
    });
  }, [setMarkers]);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPointKey(null);
  }, [setSelectedPointKey]);

  const handleMarkerClick = useCallback((point) => {
    setSelectedPointKey(point.key);
  }, [setSelectedPointKey]);

  return (
    <>
      {points?.map((point) => (
        <MapPins
          key={point.key}
          point={point}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
        />
      ))}

      {selectedPointKey && (
        <InfoWindow
          anchor={markers[selectedPointKey]}
          onCloseClick={handleInfoWindowClose}
          maxHeight={600}
        >
          {renderInfo(selectedPoint)}
        </InfoWindow>
      )}
    </>
  );
};

ClusteredMapPins.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  renderInfo: PropTypes.func.isRequired,
};

export default ClusteredMapPins;
