import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import { useAppContext } from '../../../store/AppContext';
import DocBaseService from '../../../services/DocBaseService';
import FileService from '../../../services/FileService';
import utils from '../../../utils/utils';
import strings from '../../../constants/strings';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ArticleTemplate from '../../../components/Organisms/ArticleTemplate';
import colors from '../../../constants/colors';

const DocDetails = () => {
  const [filesToDownload, setFilesToDownload] = useState([]);
  const [illustrations, setIllustrations] = useState([]);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);

  // Store
  const [, appDispatch] = useAppContext();
  const history = useHistory();
  const { base, id } = useParams();

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery('getOneDoc',
    () => DocBaseService.getOneDoc({ id }));

  const getFiles = useMutation('getJoinedFiles',
    (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });

  const getIllustrations = useMutation('getIllustrations',
    (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });
  const {
    isLoading: getCategoriesLoading, isError: getCategoriesError, data: categories, isSuccess: getCategoriesSuccess,
  } = useQuery('getCategories', () => DocBaseService.getCategories({ base }));

  const {
    isLoading: getTypesLoading, isError: getTypesError, data: types, isSuccess: getTypesSuccess,
  } = useQuery(['getTypes', category], () => {
    if (category?.id) {
      return DocBaseService.getTypes({ category: category.id });
    }
    return false;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isSuccess && data?.data?.joinedFiles) {
      getFiles.mutate(data?.data?.joinedFiles.map(({ fileId }) => fileId));
    }
    if (isSuccess && data?.data?.illustrationFiles) {
      getIllustrations.mutate(data?.data?.illustrationFiles.map(({ fileId }) => fileId));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (getFiles.isSuccess) {
      setFilesToDownload(
        getFiles.data?.map((file) => ({ data: file.data, name: file.headers['x-file-sanitized-name'] })),
      );
    }
  }, [getFiles.isSuccess, getFiles.data]);

  useEffect(() => {
    if (getIllustrations.isSuccess) {
      const blobs = getIllustrations.data?.map((file) => utils.getBase64(file.data));
      Promise.all(blobs).then((base64s) => setIllustrations(base64s));
    }
  }, [getIllustrations.isSuccess, getIllustrations.data]);

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getDoc, isSuccess: false },
      });
    }
    if (getCategoriesError || getTypesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
    if (getCategoriesSuccess && categories) {
      setCategory(categories?.data?.documentBaseItemCategories?.find(
        (cate) => cate.id === data?.data?.categoryId,
      ));
    }
    if (getTypesSuccess && types) {
      setType(types?.data?.documentBaseItemTypes?.find(
        (oneType) => oneType.id === data?.data?.typeId,
      ));
    }
  }, [isError, getCategoriesError, getTypesError, categories, getCategoriesSuccess, getTypesSuccess, types, data]);

  return (
    <TemplateWithMenuAndHeader>
      {
          data?.data
            ? (
              <ArticleTemplate
                backAction={() => history.push(`/intranet/doc-base/${base}`)}
                illustrations={illustrations}
                publicationDate={data?.data?.lastEditionDate}
                categories={[
                  category?.name,
                  type?.name,
                ]}
                title={data?.data?.title}
                filesToDownload={filesToDownload}
                isLoading={isLoading || isError || getCategoriesLoading || getTypesLoading}
                content={data?.data?.content}
              />
            )
            : <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />

      }
    </TemplateWithMenuAndHeader>
  );
};

export default DocDetails;
