import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';

// Components
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import Switch from 'react-switch';
import DataTable from '../molecules/DataTable';
import ModalComponent from '../atoms/ModalComponent';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Services
import AgencyService from '../../services/AgencyService';

// Hooks
import { useAppContext } from '../../store/AppContext';

// Images
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

const AgencyContentsComponent = ({ agencyId, title, handleEdit }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);

  // Store
  const [, appDispatch] = useAppContext();

  // Queries
  const updateContent = useMutation('updateContent',
    (contentData) => AgencyService.updateAgencyContents(contentData));

  const deleteContent = useMutation('deleteContent',
    (id) => AgencyService.deleteAgencyContents(id));

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(['getContents', updateContent.isSuccess, deleteContent.isSuccess],
    () => AgencyService.getAgencyContents({ id: agencyId }));

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getContents, isSuccess: false },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (deleteContent.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteContent, isSuccess: true },
      });
    }
    if (deleteContent.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteContent, isSuccess: false },
      });
    }
  }, [deleteContent.isError, deleteContent.isSuccess]);

  useEffect(() => {
    if (updateContent.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateContent, isSuccess: false },
      });
    }
  }, [updateContent.isError]);

  const deleteOne = (content) => {
    if (content) {
      deleteContent.mutate({ id: content.id });
      setIsDeleteModalOpen(false);
    }
  };

  const updateIsActive = (content) => {
    if (content) {
      updateContent.mutate({ content: { ...content, isActive: !content.isActive } });
    }
  };

  const renderEditButton = (id) => (
    <button type="button" onClick={() => handleEdit(id)}>
      <EditIcon width={30} />
    </button>
  );

  const renderDeleteButton = (content) => (
    <button
      type="button"
      onClick={() => {
        setIsDeleteModalOpen(true);
        setCurrentContent(content);
      }}
    >
      <DeleteIcon width={30} />
    </button>
  );

  const renderToggleButton = (value, content) => (
    <Switch
      checked={value}
      onChange={() => updateIsActive(content)}
      onColor="#8d9bba"
      onHandleColor={colors.primaryRed}
      handleDiameter={25}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      className="react-switch"
      id="disable-admin-switch"
      disabled={false}
    />
  );

  const formatTableData = (contents) => contents?.reduce((acc, content) => [
    ...acc,
    {
      title: content.title,
      edit: renderEditButton(content.id),
      delete: renderDeleteButton(content),
      disabled: renderToggleButton(content.isActive, content),
    },
  ], []);

  return (
    <>
      <div className="d-flex f-row justify-start align-center ph-4 f-wrap mb-3">
        {title ? (
          <h3 className="mr-5 text-align-left">
            {title}
          </h3>
        ) : null}
        <ButtonComponent onClick={() => handleEdit()}>
          <span>{strings.addContent}</span>
        </ButtonComponent>
      </div>
      {isLoading || isError
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <DataTable
            isLoading={isLoading || isError || updateContent.isLoading}
            data={isSuccess ? formatTableData(data?.data?.agencyPageContents) : []}
            columns={[
              ' ',
              ' ',
              ' ',
              ' ',
            ]}
          />
        )}
      {
        isDeleteModalOpen && currentContent ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deletePrestation}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => deleteOne(currentContent)}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </>
  );
};

AgencyContentsComponent.propTypes = {
  agencyId: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  title: PropTypes.string,
};

AgencyContentsComponent.defaultProps = {
  title: `${strings.myNews} / ${strings.myContents}`,
};

export default AgencyContentsComponent;
