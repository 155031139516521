import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonComponent, CheckboxComponent, InputComponent, LoaderComponent, SelectComponent, TextAreaComponent,
} from '@zolteam/axenergie-ui-library';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';
import {
  ACTIVITIES, EMPLOYEE_NUMBERS, JOBS, ROLES,
  SALES_FIGURES,
} from '../../../constants/adherents';

// Utils
import utils from '../../../utils/utils';
import { validateNotEmptyField } from '../../../utils/validators';

// Services
import AgencyService from '../../../services/AgencyService';
import FileService from '../../../services/FileService';

const EditAgency = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [zone, setZone] = useState('');
  const [zoneError, setZoneError] = useState('');
  const [image, setImage] = useState(undefined);
  const [imageId, setImageId] = useState(undefined);
  const [logo, setLogo] = useState(undefined);
  const [logoId, setLogoId] = useState(undefined);
  const [advertising, setAdvertising] = useState(undefined);
  const [advertisingId, setAdvertisingId] = useState(undefined);
  const [googleMyBusiness, setGoogleMyBusiness] = useState(undefined);
  const [googleMyBusinessUrl, setGoogleMyBusinessUrl] = useState(undefined);
  const [googleMyBusinessError, setGoogleMyBusinessError] = useState(undefined);
  const [payplug, setPayplug] = useState(undefined);
  const [freePayment, setFreePayment] = useState(false);
  const [externalLink, setExternalLink] = useState({
    label: '',
    url: '',
  });
  const [cooperativeRoles, setCooperativeRoles] = useState([]);
  const [jobType, setJobType] = useState(null);
  const [salesFigures, setSalesFigures] = useState(null);
  const [activities, setActivities] = useState([]);
  const [employeeAmount, setEmployeeAmount] = useState(0);
  const [enteredNetworkAt, setEnteredNetworkAt] = useState(null);

  // Navigation
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  // Queries
  const createAgency = useMutation('createAgency',
    async (createAgencyData) => {
      let newImage = imageId;
      let newLogo = logoId;
      let newAdvertising = advertisingId;
      if (image) {
        const uploadImage = await FileService.addFile({ binary: image, typeId: 3 });
        setImageId(uploadImage.data.id);
        newImage = uploadImage.data.id;
      }
      if (logo) {
        const uploadLogo = await FileService.addFile({ binary: logo, typeId: 2 });
        setLogoId(uploadLogo.data.id);
        newLogo = uploadLogo.data.id;
      }
      if (advertising) {
        const uploadAdvertising = await FileService.addFile({ binary: advertising, typeId: 18 });
        setAdvertisingId(uploadAdvertising.data.id);
        newAdvertising = uploadAdvertising.data.id;
      }
      return AgencyService.createAgency({
        agency:
        {
          ...createAgencyData,
          illustrationFileId: newImage,
          logoFileId: newLogo,
          extranetAdvertisementFileId: newAdvertising,
        },
      });
    });
  const updateAgency = useMutation('updateAgency', async (updateData) => {
    let newImage = imageId;
    let newLogo = logoId;
    let newAdvertising = advertisingId;
    if (image) {
      const uploadImage = await FileService.addFile({ binary: image, typeId: 3 });
      setImageId(uploadImage.data.id);
      newImage = uploadImage.data.id;
    }
    if (logo) {
      const uploadLogo = await FileService.addFile({ binary: logo, typeId: 2 });
      setLogoId(uploadLogo.data.id);
      newLogo = uploadLogo.data.id;
    }
    if (advertising) {
      const uploadAdvertising = await FileService.addFile({ binary: advertising, typeId: 18 });
      setAdvertisingId(uploadAdvertising.data.id);
      newAdvertising = uploadAdvertising.data.id;
    }
    return AgencyService.updateAgency({
      agency:
      {
        ...updateData,
        illustrationFileId: newImage,
        logoFileId: newLogo,
        extranetAdvertisementFileId: newAdvertising,
      },
    });
  });
  const {
    isSuccess, data, isError,
  } = useQuery('getAgency', () => {
    if (parameters.get('id')) {
      return AgencyService.getAgency({ id: parameters.get('id') });
    }
    return null;
  });

  // Store
  const [, appDispatch] = useAppContext();

  const goToAdherent = () => history.push(`/intranet/adherent?id=${parameters.get('adherent-id')}`);

  const isExternalLinkValid = () => {
    if (externalLink?.label.length > 0 && externalLink?.url.length > 0) {
      return true;
    }
    return false;
  };

  const addAgency = () => {
    createAgency.mutate({
      adherentId: parseInt(parameters.get('adherent-id'), 10),
      name,
      description,
      descriptionIntervention: zone,
      illustrationFileId: imageId,
      logoFileId: logoId,
      externalLink: isExternalLinkValid() ? externalLink : null,
      googleMyBusinessId: googleMyBusiness,
      googleMyBusinessUrl,
      payPlugSecretKey: payplug,
      isFreeInvoicePaymentEnabled: freePayment,
      extranetAdvertisementFileId: advertisingId,
      cooperativeRoles,
      jobType: jobType?.value,
      salesFigures: salesFigures?.value,
      activities,
      employeeAmount: employeeAmount?.value,
      enteredNetworkAt,
    });
  };

  const editAgency = () => {
    updateAgency.mutate({
      ...data?.data,
      adherentId: parseInt(parameters.get('adherent-id'), 10),
      name,
      description,
      descriptionIntervention: zone,
      illustrationFileId: imageId,
      logoFileId: logoId,
      externalLink: isExternalLinkValid() ? externalLink : null,
      googleMyBusinessId: googleMyBusiness,
      googleMyBusinessUrl,
      payPlugSecretKey: payplug,
      isFreeInvoicePaymentEnabled: freePayment,
      extranetAdvertisementFileId: advertisingId,
      cooperativeRoles,
      jobType: jobType?.value,
      salesFigures: salesFigures?.value,
      activities,
      employeeAmount: employeeAmount?.value,
      enteredNetworkAt,
    });
  };

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setName(data.data.name);
      setDescription(data.data.description);
      setZone(data.data.descriptionIntervention);
      setImage(null);
      setImageId(data.data.illustrationFileId);
      setLogo(null);
      setLogoId(data.data.logoFileId);
      setGoogleMyBusiness(data.data.googleMyBusinessId);
      setGoogleMyBusinessUrl(data.data.googleMyBusinessUrl);
      setPayplug(data.data.payPlugSecretKey);
      setFreePayment(data.data.isFreeInvoicePaymentEnabled);
      setExternalLink(data.data.externalLink);
      setAdvertising(null);
      setAdvertisingId(data.data.extranetAdvertisementFileId);
      setCooperativeRoles(data.data.cooperativeRoles);
      setJobType(JOBS.find(({ value }) => value === data.data.jobType));
      setSalesFigures(SALES_FIGURES.find(({ value }) => value === data.data.salesFigures));
      setActivities(data.data.activities);
      setEmployeeAmount(EMPLOYEE_NUMBERS.find(({ value }) => value === data.data.employeeAmount));
      setEnteredNetworkAt(data.data.enteredNetworkAt);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyLoading, isSuccess: false },
      });
      goToAdherent();
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (
      createAgency.isSuccess
      || updateAgency.isSuccess
    ) {
      goToAdherent();
    }
    if (updateAgency.isError) {
      const toDisplay = Object.values(updateAgency?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAgency, isSuccess: false },
      });
    }
    if (createAgency.isError) {
      const toDisplay = Object.values(createAgency?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createAgency, isSuccess: false },
      });
    }
  }, [
    createAgency.isSuccess,
    updateAgency.isSuccess,
    createAgency.isError,
    updateAgency.isError,
  ]);
  const onSubmit = () => {
    if (
      nameError
      || descriptionError
      || zoneError
    ) {
      return false;
    }
    if (parameters.get('id')) {
      return editAgency();
    }
    return addAgency();
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/adherents">
          <h3 className="mr-3 mv-1 text-align-left">{strings.adherents}</h3>
        </Link>
        <Link to={`/intranet/adherent?id=${parameters.get('adherent-id')}`}>
          <h3 className="mr-3 mv-1 text-align-left">{` > ${parameters.get('adherent')} `}</h3>
        </Link>
        <h3 className="mv-1 text-align-left">{` > ${data?.data?.name || strings.newAgency}`}</h3>
      </div>
      <div className="pv-4 d-flex f-column f-wrap full-width align-start">
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setName}
            id="agency-name"
            value={name}
            label={strings.name}
            isError={nameError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setNameError, name)}
          />
        </div>
        <div className="form-file-text-area-width m-3">
          <TextAreaComponent
            onChange={setDescription}
            id="agency-description"
            value={description}
            label={strings.agencyDescription}
            isError={descriptionError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setDescriptionError, description)}
          />
        </div>
        <div className="form-file-text-area-width m-3">
          <TextAreaComponent
            onChange={setZone}
            id="agency-description"
            value={zone}
            label={strings.interventionZone}
            isError={zoneError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setZoneError, zone)}
          />
        </div>
        <div className="form-file-input-width m-3">
          <FileInputWithAcceptAndSize
            setFile={(file) => {
              setImage(file);
              setImageId(null);
            }}
            file={image}
            canDelete={image || imageId}
            label={imageId ? strings.changeAgencyPicture : strings.agencyPicture}
            fileType={3}
            initName={strings.changeAgencyPicture}
          />
        </div>
        <div className="form-file-input-width m-3">
          <FileInputWithAcceptAndSize
            setFile={(file) => {
              setLogo(file);
              setLogoId(null);
            }}
            file={logo}
            canDelete={logo || logoId}
            label={logoId ? strings.changeAgencyLogo : strings.agencyLogo}
            fileType={2}
            initName={strings.changeAgencyLogo}
          />
        </div>
        <div className="form-file-text-area-width m-3 h3">
          <h5 className="m-0">{strings.externalLink}</h5>
        </div>
        <div className="form-file-text-area-width m-3">
          <InputComponent
            onChange={(value) => setExternalLink({ ...externalLink, label: value })}
            id="agency-external-link-label"
            value={externalLink?.label}
            label={strings.externalLinkLabel}
          />
        </div>
        <div className="form-file-text-area-width ml-3 mt-3 mr-3 mb-5">
          <InputComponent
            onChange={(value) => setExternalLink({ ...externalLink, url: value })}
            id="agency-external-link-url"
            value={externalLink?.url}
            label={strings.externalLinkUrl}
          />
        </div>
        <div className="form-file-text-area-width m-3">
          <InputComponent
            onChange={setGoogleMyBusiness}
            id="agency-google-my-business-id"
            value={googleMyBusiness}
            label={strings.googleMyBusinessId}
            isError={googleMyBusinessError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setGoogleMyBusinessError, googleMyBusiness)}
          />
        </div>
        <div className="form-file-text-area-width m-3">
          <InputComponent
            onChange={setGoogleMyBusinessUrl}
            id="agency-google-my-business-url"
            disabled
            value={googleMyBusinessUrl}
            label={strings.googleMyBusinessUrl}
            isError={googleMyBusinessError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setGoogleMyBusinessError, googleMyBusinessUrl)}
          />
        </div>
        <div className="form-file-text-area-width m-3">
          <InputComponent
            onChange={setPayplug}
            id="agency-payplug-key"
            value={payplug}
            label={strings.payplugApiKey}
          />
        </div>
        <div className="m-3">
          <CheckboxComponent
            label={strings.sitePayment}
            handleChange={() => setFreePayment((state) => !state)}
            id="free-payment"
            value={freePayment}
          />
        </div>
        <div className="form-file-text-area-width m-3 mt-5 h3">
          <h5 className="m-0">{strings.manageAdvertising}</h5>
        </div>
        <div className="form-file-input-width m-3">
          <p className="grey-500-text small-text mt-0">{strings.optimalAdImageSize('800', '600')}</p>
          <FileInputWithAcceptAndSize
            setFile={(file) => {
              setAdvertising(file);
              setAdvertisingId(null);
            }}
            file={advertising}
            canDelete={advertising || advertisingId}
            label={advertisingId ? strings.changeAgencyAdvertising : strings.agencyAdvertising}
            fileType={18}
            initName={strings.changeAgencyAdvertising}
          />
        </div>
        <div className="form-file-text-area-width m-3 mt-5 h3">
          <h5 className="m-0">{strings.adherentSheet}</h5>
        </div>
        <div className="form-file-input-width m-3">
          {ROLES.map(({ code, id }) => (
            <CheckboxComponent
              handleChange={() => {
                setCooperativeRoles((state) => {
                  if (state.filter((item) => item === id)?.length === 0) {
                    return state.concat([id]);
                  }
                  return state.filter((item) => item !== id);
                });
              }}
              label={code}
              id={id}
              value={cooperativeRoles?.filter((item) => item === id)?.length > 0}
            />
          ))}
        </div>
        <div className="form-file-input-width m-3">
          <SelectComponent
            name="adherent-job"
            label={strings.adherentJob}
            data={JOBS}
            value={jobType}
            setValue={setJobType}
            theme="dark"
          />
        </div>
        <div className="form-file-input-width m-3">
          <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentActivities}</p>
          {ACTIVITIES.map(({ code, id }) => (
            <CheckboxComponent
              handleChange={() => {
                setActivities((state) => {
                  if (state.filter((item) => item === id)?.length === 0) {
                    return state.concat([id]);
                  }
                  return state.filter((item) => item !== id);
                });
              }}
              label={code}
              id={id}
              value={activities?.filter((item) => item === id)?.length > 0}
            />
          ))}
        </div>
        <div className="form-file-input-width m-3">
          <SelectComponent
            name="employee-number"
            label={strings.employeeNumber}
            data={EMPLOYEE_NUMBERS}
            value={employeeAmount}
            setValue={setEmployeeAmount}
            theme="dark"
          />
        </div>
        <div className="form-input-width m-3">
          <p className="grey-400-text medium-text mh-2 mv-0">{strings.adherentEntry}</p>
          <InputComponent
            onChange={setEnteredNetworkAt}
            value={enteredNetworkAt}
            id="adherent-entry"
            name="adherent-entry"
            label=""
            type="number"
          />
        </div>
        <div className="form-file-input-width m-3">
          <SelectComponent
            name="adherent-sales"
            label={strings.salesFigures}
            data={SALES_FIGURES}
            value={salesFigures}
            setValue={setSalesFigures}
            theme="dark"
          />
        </div>
      </div>
      <div className="pb-4">
        {!name
          || !description
          || !zone
          || !googleMyBusiness
          ? null : (
            <div className="m-3">
              <ButtonComponent onClick={onSubmit}>
                <div className="mv-2">
                  {
                    createAgency?.isLoading || updateAgency?.isLoading
                      ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                      : <span className="uppercase m-5">{strings.save}</span>
                  }
                </div>
              </ButtonComponent>
            </div>
          )}
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default EditAgency;
