import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ButtonComponent from '../Button/ButtonComponent'
import '../../../styles/input.scss'
import { ReactComponent as UploadIcon } from '../../../images/upload.svg'
import { ReactComponent as OkIcon } from '../../../images/checkbox-circle.svg'

const InputFileComponent = ({
  file,
  setFile,
  label,
  initName,
  isError,
  errorMessage,
  disabled,
  accept,
  maxSize
}) => {
  const [fileName, setFileName] = useState('')
  const [isTooBig, setIsTooBig] = useState(false)
  const fileInputRef = useRef()

  const handleFileInputClick = () => {
    fileInputRef.current.click()
  }

  const isSizeOk = (file) => {
    if (maxSize) {
      return file.size <= maxSize
    }
    return true
  }

  return (
    <div
      className='l-margin-vertical d-flex f-column'
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <input
        type='file'
        ref={fileInputRef}
        value={fileName}
        disabled={disabled}
        className='custom-file-input s-margin-right'
        onChange={(e) => {
          if (isSizeOk(e.target.files[0])) {
            setIsTooBig(false)
            setFile(e.target.files[0])
          } else {
            setIsTooBig(true)
          }
        }}
        accept={accept}
      />
      {!file ? (
        <ButtonComponent onClick={handleFileInputClick} theme='outline'>
          <div className='d-flex align-center justify-center full-width button-file-container'>
            <UploadIcon
              className='mr-2 button-file-icon'
              alt=''
              width={24}
              height={24}
            />
            <span className='regular-text medium-weight button-file-name'>
              {label}
            </span>
          </div>
        </ButtonComponent>
      ) : (
        <ButtonComponent
          onClick={() => {
            setFile(undefined)
            setFileName('')
          }}
          theme='outline'
        >
          <div className='d-flex align-center justify-center full-width button-file-container'>
            <OkIcon
              className='mr-2 button-file-icon'
              alt=''
              width={24}
              height={24}
            />
            <span className='regular-text medium-weight t-overflow-ellipsis button-file-name'>
              {file.name || initName}
            </span>
          </div>
        </ButtonComponent>
      )}
      {isError ? (
        <div className='ml-1'>
          <span className='light-red-text medium-weight regular-text'>
            {errorMessage}
          </span>
        </div>
      ) : null}
      {isTooBig ? (
        <div className='ml-1'>
          <span className='light-red-text medium-weight regular-text'>
            {`Le fichier ne doit pas dépasser ${maxSize / 1000}Ko`}
          </span>
        </div>
      ) : null}
    </div>
  )
}

InputFileComponent.propTypes = {
  file: PropTypes.shape({}),
  setFile: PropTypes.func.isRequired,
  label: PropTypes.string,
  initName: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  maxSize: PropTypes.number
}

InputFileComponent.defaultProps = {
  label: 'Choisir un fichier...',
  errorMessage: 'Error',
  initName: '',
  isError: false,
  disabled: false,
  accept: '*',
  maxSize: null,
  file: null
}

export default InputFileComponent
