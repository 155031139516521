import React, { useState, useEffect } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import { useMutation, useQuery } from 'react-query';
import htmlToDraftjs from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';

// Services and hooks
import FileService from '../../../services/FileService';
import AgencyService from '../../../services/AgencyService';
import { useAppContext } from '../../../store/AppContext';

const MyAgency = () => {
  // contact details states
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [address1, setAddress1] = useState('');
  const [address1Error, setAddress1Error] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [latitudeError, setLatitudeError] = useState(false);
  const [longitude, setLongitude] = useState('');
  const [longitudeError, setLongitudeError] = useState(false);
  const [address2, setAddress2] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState(false);
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false);
  const [openingHours, setOpeningHours] = useState(() => EditorState.createEmpty());
  const [openingHoursError, setOpeningHoursError] = useState(false);

  // Contact us states
  const [phone1, setPhone1] = useState({ phone: '', label: '' });
  const [phone1Error, setPhone1Error] = useState(false);
  const [phone2, setPhone2] = useState({ phone: '', label: '' });
  const [email1, setEmail1] = useState({ phone: '', label: '' });
  const [email1Error, setEmail1Error] = useState(false);
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');

  // Legal info states
  const [siren, setSiren] = useState('');
  const [legalOfficer, setLegalOfficer] = useState('');
  const [socialReason, setSocialReason] = useState('');
  const [generalTerms, setGeneralTerms] = useState(null);
  const [generalTermsId, setGeneralTermsId] = useState('');

  const [{ currentAgency }, appDispatch] = useAppContext();

  // Queries
  const {
    isError, data, isSuccess, isLoading,
  } = useQuery(['getAgency', currentAgency],
    () => {
      if (currentAgency) {
        return AgencyService.getAgency({ id: currentAgency });
      }
      return null;
    });
  const updateInfos = useMutation('updateAgencyInfos',
    (updateData) => AgencyService.updateAgencyInfo(updateData));

  useEffect(() => {
    if (isSuccess && data) {
      setName(data?.data?.name);
      setAddress1(data?.data?.address1);
      setLatitude(data?.data?.latitude);
      setLongitude(data?.data?.longitude);
      setAddress2(data?.data?.address2);
      setZipcode(data?.data?.postalCode);
      setCity(data?.data?.city);
      setOpeningHours(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data?.data?.openingHours)),
      ));
      const phones = data?.data?.phones;
      setPhone1(phones.length > 0 ? phones[0] : { phone: '', label: '' });
      setPhone2(phones.length > 1 ? phones[1] : { phone: '', label: '' });
      const emails = data?.data?.emails;
      setEmail1(emails.length > 0 ? emails[0] : { emailAddress: '', label: '' });
      const socialLinks = data?.data?.socialLinks;
      setFacebook(socialLinks.find(({ socialLinkLabelId }) => socialLinkLabelId === 1)?.link);
      setLinkedin(socialLinks.find(({ socialLinkLabelId }) => socialLinkLabelId === 2)?.link);
      setInstagram(socialLinks.find(({ socialLinkLabelId }) => socialLinkLabelId === 3)?.link);
      setTwitter(socialLinks.find(({ socialLinkLabelId }) => socialLinkLabelId === 4)?.link);
      setSiren(data?.data?.sirenNumber);
      setLegalOfficer(data?.data?.legalOfficer);
      setSocialReason(data?.data?.businessName);
      setGeneralTermsId(data?.data?.generalTermsFileId);
      setGeneralTerms(null);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyLoading, isSuccess: false },
      });
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (updateInfos.isError) {
      const toDisplay = Object.values(updateInfos?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAgency, isSuccess: false },
      });
    }
  }, [updateInfos.isError, updateInfos.error]);

  const onSubmit = async () => {
    let generalTermsFileId = generalTermsId;
    if (generalTerms) {
      const uploadedImage = await FileService.addFile({ binary: generalTerms, typeId: 16 });
      setGeneralTermsId(uploadedImage.data.id);
      generalTermsFileId = uploadedImage.data.id;
    }
    updateInfos.mutate({
      id: currentAgency || undefined,
      infos: {
        generalTermsFileId,
        name,
        address1,
        latitude,
        longitude,
        address2,
        postalCode: zipcode,
        city,
        openingHours: draftToHtml(convertToRaw(openingHours.getCurrentContent())),
        sirenNumber: siren,
        legalOfficer,
        businessName: socialReason,
        phones: [
          phone1.phone && phone1.label ? phone1 : null,
          phone2.phone && phone2.label ? phone2 : null,
        ].filter((i) => !!i),
        emails: [
          email1.emailAddress && email1.label ? email1 : null,
        ].filter((i) => !!i),
        socialLinks: [
          {
            socialLinkLabelId: 1,
            link: facebook,
          },
          {
            socialLinkLabelId: 2,
            link: linkedin,
          },
          {
            socialLinkLabelId: 3,
            link: instagram,
          },
          {
            socialLinkLabelId: 4,
            link: twitter,
          },
        ].filter(({ link }) => !!link),
      },
    });
  };

  return (
    <TemplateWithMenuAndHeader>
      <h1 className="mb-5 text-align-left">{strings.myAgency}</h1>
      { isLoading
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <>
            {/* Contact details section */}
            <h3 className="mv-1 mh-3 text-align-left">{strings.myContactDetails}</h3>
            <div className="mv-4 d-flex f-row f-wrap full-width align-center">
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setName}
                  id="agency-name"
                  value={name}
                  label={strings.agencyName}
                  isError={nameError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setNameError, name)}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setAddress1}
                  id="agency-address-1"
                  value={address1}
                  label={`${strings.address} 1`}
                  isError={address1Error}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setAddress1Error, address1)}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setAddress2}
                  id="agency-address-2"
                  value={address2}
                  label={`${strings.address} 2`}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setZipcode}
                  id="agency-zipcode"
                  value={zipcode}
                  label={strings.zipcode}
                  isError={zipcodeError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setZipcodeError, zipcode)}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setCity}
                  id="agency-city"
                  value={city}
                  label={strings.city}
                  isError={cityError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateNotEmptyField(setCityError, city)}
                />
              </div>
              <div className="d-flex f-row f-wrap">
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setLatitude}
                    type="number"
                    id="latitude"
                    value={latitude}
                    label={strings.latitude}
                    isError={latitudeError}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setLatitudeError, latitude)}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setLongitude}
                    type="number"
                    id="longitude"
                    value={longitude}
                    label={strings.longitude}
                    isError={longitudeError}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setLongitudeError, longitude)}
                  />
                </div>
              </div>
              <div className="full-width m-3">
                <TextEditorComponent
                  label={strings.openingHours}
                  placeholder={strings.openingHoursExample}
                  setValue={setOpeningHours}
                  value={openingHours}
                  isError={openingHoursError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => (validateNotEmptyField(setOpeningHoursError,
                    convertToRaw(openingHours.getCurrentContent()).blocks[0].text)
                  )}
                />
              </div>
            </div>
            {/* Contact us section */}
            <h3 className="mv-1 mh-3 text-align-left">{strings.contactUs}</h3>
            <div className="mv-4 d-flex f-column full-width justify-center">
              <div className="d-flex f-row f-wrap">
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setPhone1((state) => ({ label: state.label, phone: value }))}
                    id="agency-phone-1"
                    value={phone1.phone}
                    label={`${strings.phone} 1`}
                    isError={phone1Error}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setPhone1Error, phone1.phone)}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setPhone1((state) => ({ label: value, phone: state.phone }))}
                    id="agency-phone-1-label"
                    value={phone1.label}
                    label={`${strings.label}`}
                    placeholder={strings.phone1LabelExample}
                    isError={phone1Error}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setPhone1Error, phone1.label)}
                  />
                </div>
              </div>
              <div className="d-flex f-row f-wrap">
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setPhone2((state) => ({ label: state.label, phone: value }))}
                    id="agency-phone-2"
                    value={phone2.phone}
                    label={`${strings.phone} 2`}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setPhone2((state) => ({ label: value, phone: state.phone }))}
                    id="agency-phone-2-label"
                    value={phone2.label}
                    label={`${strings.label}`}
                    placeholder={strings.phone2LabelExample}
                  />
                </div>
              </div>

              <div className="d-flex f-row f-wrap">
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setEmail1((state) => ({ label: state.label, emailAddress: value }))}
                    id="agency-email"
                    value={email1.emailAddress}
                    label={`${strings.mail}`}
                    isError={email1Error}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setEmail1Error, email1.emailAddress)}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={(value) => setEmail1((state) => ({ label: value, emailAddress: state.emailAddress }))}
                    id="agency-email-1-label"
                    value={email1.label}
                    label={`${strings.label}`}
                    placeholder={strings.mail1LabelExample}
                    isError={email1Error}
                    errorMessage={strings.errors.pleaseFillField}
                    onBlur={() => validateNotEmptyField(setEmail1Error, email1.label)}
                  />
                </div>
              </div>
              <div className="d-flex f-row f-wrap">
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setFacebook}
                    id="agency-facebook"
                    value={facebook}
                    label={strings.facebook}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setLinkedin}
                    id="agency-linkedin"
                    value={linkedin}
                    label={strings.linkedin}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setInstagram}
                    id="agency-instagram"
                    value={instagram}
                    label={strings.instagram}
                  />
                </div>
                <div className="form-input-width m-3">
                  <InputComponent
                    onChange={setTwitter}
                    id="agency-twitter"
                    value={twitter}
                    label={strings.twitter}
                  />
                </div>
              </div>
            </div>
            <h3 className="mv-1 mh-3 text-align-left">{strings.legalInfo}</h3>
            <div className="mv-4 d-flex f-row f-wrap full-width align-center">
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setSiren}
                  id="agency-siren"
                  value={siren}
                  label={strings.sirenNumber}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setLegalOfficer}
                  id="agency-legal-officer"
                  value={legalOfficer}
                  label={strings.legalOfficer}
                />
              </div>
              <div className="form-input-width m-3">
                <InputComponent
                  onChange={setSocialReason}
                  id="agency-social-reason"
                  value={socialReason}
                  label={strings.socialReason}
                />
              </div>
            </div>
            <div className="mv-4 d-flex f-row f-wrap full-width align-center">
              <div className="form-file-input-width m-3">
                <FileInputWithAcceptAndSize
                  setFile={(file) => {
                    setGeneralTerms(file);
                    setGeneralTermsId(null);
                  }}
                  file={generalTerms}
                  canDelete={generalTerms || generalTermsId}
                  label={strings.generalTerms}
                  fileType={16}
                  initName={strings.generalTerms}
                />
              </div>
            </div>
            { !name
      || !address1
      || !latitude
      || !longitude
      || !zipcode
      || !city
      || !convertToRaw(openingHours.getCurrentContent()).blocks[0].text
      || !phone1.phone
      || !phone1.label
      || !email1.emailAddress
      || !email1.label
              ? null : (
                <div className="m-3">
                  <ButtonComponent onClick={onSubmit}>
                    {updateInfos.isLoading
                      ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                      : (
                        <div className="mv-2">
                          <span className="uppercase m-5">{strings.save}</span>
                        </div>
                      )}
                  </ButtonComponent>
                </div>
              )}
          </>
        )}
    </TemplateWithMenuAndHeader>
  );
};

export default MyAgency;
