import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TabButtonComponent from '../atoms/TabButtonComponent';

const TabComponent = ({ tabs, defaultActive }) => {
  const [dimensions, setDimensions] = useState({});
  const [currentActive, setCurrentActive] = useState(defaultActive);

  useEffect(() => {
    if (defaultActive > 0) {
      setCurrentActive(defaultActive);
    }
  }, [defaultActive]);

  return (
    <div
      className="d-flex f-row relative overflow-auto"
    >
      {tabs.map(({
        isActive, onClick, title,
      }, index) => (
        <div
          key={title}
          className="m-2"
        >
          <TabButtonComponent
            onClick={() => {
              setCurrentActive(index);
              onClick();
            }}
            isSelected={isActive}
            title={title}
            setDimensions={({ width }) => setDimensions(
              (state) => ({
                ...state,
                [index]: {
                  width: width + 6,
                  left: (state?.[index - 1]?.left || 6) + (state?.[index - 1]?.width || 0) + (index === 0 ? 0 : 24),
                },
              }),
            )}
          />
        </div>
      ))}

      <div
        className="tab-header-filter-line absolute"
        style={{
          width: dimensions[currentActive]?.width + 6 || 0,
          left: dimensions[currentActive]?.left || 0,
        }}
      />
    </div>
  );
};

TabComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
    title: PropTypes.strin,
  })).isRequired,
  defaultActive: PropTypes.number,
};

TabComponent.defaultProps = {
  defaultActive: 0,
};

export default TabComponent;
