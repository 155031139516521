import React from 'react';
import logo from '../assets/images/logo.webp';
import image from '../assets/images/404.jpg';
import useIsMobile from '../hooks/useIsMobile';
import strings from '../constants/strings';
import HeaderWithSlashContainer from '../components/molecules/HeaderWithSlashContainer';

const NotFoundPage = () => {
  const [isMobile] = useIsMobile();
  return (
    <div className="full-width d-flex f-row relative" style={{ minHeight: '100vh' }}>
      <HeaderWithSlashContainer imageUrl={image}>
        <div className="mh-5 mt-5">
          <img alt="logo" src={logo} width={206} />
        </div>
        <div className={`${isMobile ? 'mv-2 pl-4' : 'mv-6 pl-5'}`}>
          <h1 className="grey-800-text medium-weight" style={{ fontSize: 80 }}>404</h1>
          <h1 className="grey-800-text medium-weight">{strings.pageNotFound}</h1>
          <p className="grey-600-text normal-weight mb-5">{strings.notFoundDescription}</p>
        </div>
      </HeaderWithSlashContainer>
    </div>
  );
};

export default NotFoundPage;
