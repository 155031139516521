import strings from '../constants/strings';

const formatNotification = (notification) => {
  switch (notification?.type) {
    case 'SPARE_PART_ANNOUNCEMENT_EXPIRATION': {
      return {
        title: strings.SOSSpareParts,
        subTitle: '',
        description: `${strings.yourAnnouncement} "${notification.sparePartAnnouncementTitle}" ${strings.hasExpired}`,
        link: '/intranet/spare-parts',
      };
    }
    case 'SPARE_PART_ANNOUNCEMENT_HAVE': {
      return {
        title: notification?.ownerFullName,
        subTitle: notification?.ownerAgencyName || strings.networkHead,
        description: `${strings.have} ${strings.yourSparePart}, 
        ${strings.contactHim} : ${notification?.ownerEmail}, ${notification?.ownerPhone}
        , annonce: ${notification?.sparePartAnnouncementTitle}`,
      };
    }
    case 'SPARE_PART_ANNOUNCEMENT_WANT': {
      return {
        title: notification?.applicantFullName,
        subTitle: notification?.applicantAgencyName || strings.networkHead,
        description: `${strings.wish} ${strings.yourSparePart}, 
        ${strings.contactHim} : ${notification?.applicantEmail}, ${notification?.applicantPhone}`,
      };
    }
    case 'NEW_FORUM_THREAD': {
      return {
        title: `${strings.newThread}`,
        subTitle: '',
        description: notification?.forumThreadTitle,
        link: `/intranet/forum/${notification?.forumId}/thread/${notification?.forumThreadId}`,
      };
    }
    case 'NEW_FORUM_POST': {
      return {
        title: `${strings.forum} - ${strings.newAnswer}`,
        subTitle: '',
        description: notification?.forumThreadTitle,
        link: `/intranet/forum/${notification?.forumId}/thread/${notification?.forumThreadId}`,
      };
    }
    case 'NEW_NETWORK_ACTUALITY': {
      return {
        title: strings.newNetworkNewsNotif,
        subTitle: '',
        description: notification.networkActualityTitle,
        link: `/intranet/network-news/${notification?.networkActualityId}`,
      };
    }
    case 'NEW_DOCUMENT': {
      return {
        title: strings.newDoc,
        subTitle: `${strings.docBase} ${notification.documentBaseName?.toLowerCase()}`,
        description: notification.documentBaseItemTitle,
        link: `/intranet/doc-base/${notification?.documentBaseId}/${notification?.documentBaseItemId}`,
      };
    }
    default: {
      return {
        title: '',
        subTitle: '',
        description: '',
      };
    }
  }
};

export default {
  formatNotification,
};
