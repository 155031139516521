import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from 'react-use';
import { format, isBefore } from 'date-fns';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import strings from '../../constants/strings';
import CheckIcon from '../../assets/images/avantage-4.svg';
import AgencyContractService from '../../services/AgencyContractService';
import utils from '../../utils/utils';
import { useAppContext } from '../../store/AppContext';

const InterventionStepper = ({
  id,
  isFinished,
  contract,
  appointmentDate,
  appointmentTime,
  invoiceId,
  voucherId,
}) => {
  const [toggleAccordeon, setToggleAccordeon] = useToggle();
  const history = useHistory();
  const [, appDispatch] = useAppContext();

  // Queries
  const getDocument = useMutation('get-document',
    (fileId) => AgencyContractService.getAgencyContractFile(fileId));

  const goToPayment = () => history.push(`/extranet/contract/${contract.id}?tab=invoice`);

  const isAppointmentOver = () => {
    const date = new Date(appointmentDate);
    const now = new Date();
    return isBefore(date, now);
  };

  const renderStepIcon = (step, isStepOver, isNext) => {
    if (isStepOver) {
      return (
        <img
          src={CheckIcon}
          style={{
            height: 30, left: -15,
          }}
          className="absolute white-background"
          alt="website logo"
        />
      );
    }
    return (
      <div
        className={`${isNext ? 'primary-red-border' : 'grey-400-border'} 
        border-solid radius-20 absolute d-flex justify-center align-center white-background`}
        style={{
          height: 30, width: 30, left: -18,
        }}
      >
        <h5 className={`medium-weight ${isNext ? 'primary-red-text' : 'grey-400-text'}`}>
          {step}
        </h5>
      </div>
    );
  };

  useEffect(() => {
    if (getDocument.isSuccess) {
      const file = getDocument?.data;
      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getDocument.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getDocument.isSuccess, getDocument.isError]);

  return (
    <>
      <h6 className="medium-weight">
        {strings.demand(id, isFinished)}
        <span
          onKeyDown={null}
          tabIndex={0}
          role="button"
          onClick={setToggleAccordeon}
          className="primary-red-text ml-4 regular-text pointer"
        >
          {toggleAccordeon
            ? strings.hideIntervention
            : strings.showIntervention}
        </span>
      </h6>
      {toggleAccordeon ? (
        <div
          className="mt-2 d-flex f-column ml-4 left-border-grey"
        >
          {/* Step 1 */}
          <div className="d-flex mb-5 relative align-center">
            {renderStepIcon(1, true, false)}
            <div className="pl-5 d-flex f-column">
              <div className="medium-weight">
                {strings.concernedEquipment}
              </div>
              <div className="mt-1 grey-400-text medium-text ">
                {`${contract.equipmentType} ${contract.equipmentModel}`}
              </div>
              <div className="grey-400-text medium-text">
                {`${contract.route} ${contract.postalCode} ${contract.locality}`}
              </div>
            </div>
          </div>
          {/* Step 2 */}
          <div className="d-flex mb-5 relative align-center">
            {renderStepIcon(2, isAppointmentOver(), true)}
            <div className="pl-5 d-flex f-column">
              <div className="medium-weight">
                {strings.meeting}
              </div>
              <div className="mt-1 grey-400-text medium-text">
                {`${strings.the} 
                ${format(new Date(appointmentDate?.split(' ').join('T')), 'dd-MM-yyyy')} ${appointmentTime}`}
              </div>
            </div>
          </div>
          {/* Step 3 */}
          <div className="d-flex mb-5 relative align-center">
            {renderStepIcon(3, !!voucherId, isAppointmentOver())}
            <div className="pl-5 d-flex f-column">
              <div className="medium-weight">
                {strings.interventionReport}
              </div>
              {voucherId ? (
                <div className="mt-1 grey-400-text medium-text">
                  <button
                    type="button"
                    onClick={() => getDocument.mutate(voucherId)}
                    disabled={!voucherId}
                    className="mt-1 grey-400-text medium-text underline"
                  >
                    {strings.downloadVoucher}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {/* Step 4 */}
          <div className="d-flex mb-5 relative align-center">
            {renderStepIcon(4, !!invoiceId, !!voucherId)}
            <div className="pl-5 d-flex f-column">
              <div className="medium-weight">
                {strings.onlinePayment}
              </div>
              {invoiceId ? (
                <button
                  type="button"
                  onClick={goToPayment}
                  disabled={!invoiceId}
                  className="mt-1 grey-400-text medium-text underline"
                  style={{
                    marginLeft: -30,
                  }}
                >
                  {strings.payInvoice}
                </button>
              ) : null}
            </div>
          </div>
          {/* Step 5 */}
          <div className="d-flex relative align-center">
            {renderStepIcon(5, isFinished, !!invoiceId)}
            <div className="pl-5 d-flex f-column">
              <div className="medium-weight">
                {strings.requestProcessed}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

InterventionStepper.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isFinished: PropTypes.bool.isRequired,
  appointmentDate: PropTypes.string.isRequired,
  appointmentTime: PropTypes.string.isRequired,
  invoiceId: PropTypes.number,
  voucherId: PropTypes.number,
  contract: PropTypes.shape({
    reference: PropTypes.shape(),
    equipmentType: PropTypes.string,
    equipmentModel: PropTypes.string,
    route: PropTypes.string,
    postalCode: PropTypes.string,
    locality: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

InterventionStepper.defaultProps = {
  invoiceId: null,
  voucherId: null,
};

export default InterventionStepper;
