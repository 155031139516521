import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonComponent, InputComponent, LoaderComponent, TextAreaComponent,
} from '@zolteam/axenergie-ui-library';
import AnnouncementFilters from '../molecules/AnnouncementFilters';
import strings from '../../constants/strings';
import { validateNotEmptyField, validateMaxLengthField } from '../../utils/validators';
import colors from '../../constants/colors';

const AnnouncementForm = ({
  energyTypes,
  saveAnnouncement,
  saveLoading,
  defaultType,
  defaultEnergy,
  defaultTitle,
  defaultDescription,
  defaultContact,
}) => {
  const [typeCode, setTypeCode] = useState(null);
  const [energyTypeCode, setEnergyTypeCode] = useState(null);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(false);
  const [contact, setContact] = useState('');
  const [contactError, setContactError] = useState(false);

  useEffect(() => {
    setTypeCode(defaultType);
    setEnergyTypeCode(defaultEnergy);
    setTitle(defaultTitle);
    setDescription(defaultDescription);
    setContact(defaultContact);
  }, [defaultType,
    defaultEnergy,
    defaultTitle,
    defaultDescription,
    defaultContact]);

  const onSubmit = () => {
    if (!typeCode
        || !energyTypeCode
        || titleError
        || descriptionError
        || contactError
    ) {
      return false;
    }
    return saveAnnouncement({
      typeCode,
      energyTypeCode,
      title,
      description,
      contact,
    });
  };

  const displayError = (errorCode) => {
    switch (errorCode) {
      case ('empty'):
        return strings.errors.pleaseFillField;
      case ('max_length'):
        return strings.errors.maxLength;
      default:
        return strings.errors.pleaseFillField;
    }
  };

  return (
    <div className="mh-3">
      <AnnouncementFilters
        setType={setTypeCode}
        width="100%"
        energy={energyTypeCode}
        setEnergy={setEnergyTypeCode}
        type={typeCode}
        energyTypes={energyTypes}
      />
      <div className="mh-3 form-file-text-area-width">
        <InputComponent
          onChange={setTitle}
          id="announcement-title"
          value={title}
          label={strings.title}
          isError={titleError}
          errorMessage={displayError(titleError)}
          onBlur={() => validateNotEmptyField(setTitleError, title)}
        />
      </div>
      <p className="mh-3 grey-400-text normal-weight medium-text m-0">{strings.announcementTitleInfoDont}</p>
      <p className="mh-3 grey-400-text normal-weight medium-text m-0">{strings.announcementTitleInfoDo}</p>
      <div className="form-file-text-area-width mh-3 mt-3">
        <TextAreaComponent
          // onChange={setDescription}
          id="announcement-description"
          value={description}
          label={strings.descriptionText}
          isError={descriptionError}
          errorMessage={displayError(descriptionError)}
          onBlur={() => {
            validateNotEmptyField(setDescriptionError, description);
            validateMaxLengthField(setDescriptionError, description, 130);
          }}
          onChange={(value) => {
            validateMaxLengthField(setDescriptionError, value, 130);
            setDescription(value);
          }}
        />
      </div>
      <p className="mh-3 grey-400-text normal-weight medium-text m-0">{strings.announcementDescriptionInfo}</p>
      <p className="mh-3 grey-400-text normal-weight medium-text m-0">{strings.announcementDescriptionRequirements}</p>
      <div className="d-flex f-row f-wrap align-center">
        <div className="m-3 form-input-width">
          <InputComponent
            onChange={setContact}
            id="announcement-contact"
            value={contact}
            label={strings.phone}
            isError={contactError}
            errorMessage={displayError(contactError)}
            onBlur={() => validateNotEmptyField(setContactError, contact)}
          />
        </div>
        <span className="grey-600-text normal-weight mh-3">
          {typeCode === 'DEMANDE' ? strings.demandAnnouncementDuration : strings.offerAnnouncementDuration}
        </span>

      </div>
      { !typeCode
        || !energyTypeCode
        || !title
        || !description
        || !contact
        ? null : (
          <div className="m-3">
            <ButtonComponent onClick={onSubmit}>
              <div className="mv-2">
                {
                  saveLoading
                    ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                    : <span className="uppercase m-5">{strings.send}</span>
                }
              </div>
            </ButtonComponent>
          </div>
        )}
    </div>
  );
};

AnnouncementForm.propTypes = {
  saveAnnouncement: PropTypes.func.isRequired,
  energyTypes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })),
  defaultType: PropTypes.string,
  defaultEnergy: PropTypes.string,
  defaultTitle: PropTypes.string,
  defaultDescription: PropTypes.string,
  defaultContact: PropTypes.string,
  saveLoading: PropTypes.bool,
};

AnnouncementForm.defaultProps = {
  energyTypes: null,
  defaultType: '',
  defaultEnergy: '',
  defaultTitle: '',
  defaultDescription: '',
  defaultContact: '',
  saveLoading: false,
};

export default AnnouncementForm;
