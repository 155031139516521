import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/pagination.scss'

const PaginationComponent = ({
  currentPage,
  setCurrentPage,
  itemsPerPage,
  totalList,
  totalPage,
  setCurrentList,
  hasNext,
  hideTotal
}) => {
  const pagesTotal = useMemo(() => {
    if (totalPage) {
      return totalPage
    }
    if (totalList) {
      return Math.ceil(totalList.length / itemsPerPage)
    }
    return 0
  }, [totalPage, totalList, itemsPerPage])

  const pages = useMemo(
    () =>
      Array.from(Array(pagesTotal).keys()).reduce((acc, page, index) => {
        if (page === 0) {
          return acc
        }
        if (index % 3 === 0) {
          return [...acc, [page]]
        }
        const lastItem = acc?.length ? acc.pop() : acc
        return [...acc, [...lastItem, page]]
      }, []),
    [pagesTotal]
  )

  const setCurrentItems = (list, current) => {
    const firstIndex = (current - 1) * itemsPerPage
    const newList = new Array(...list)
    const length =
      itemsPerPage > newList.length - firstIndex
        ? newList.length - firstIndex
        : itemsPerPage
    setCurrentList(newList.splice(firstIndex, length))
  }

  const goToPage = (nb) => {
    if (!hideTotal) {
      setCurrentPage(nb)
    } else {
      const canGoNext = nb > currentPage && hasNext
      const canGoPrevious = nb < currentPage && currentPage > 1
      if (canGoNext || canGoPrevious) {
        setCurrentPage(nb)
      }
    }
    if (totalList) {
      setCurrentItems(totalList, nb)
    }
  }

  const canShowNextButton = useMemo(() => {
    if (hideTotal) {
      return hasNext
    }
    return currentPage !== pagesTotal
  }, [hasNext, currentPage, pagesTotal, hideTotal])

  useEffect(() => {
    if (totalList) {
      setCurrentItems(totalList, currentPage)
    }
  }, [totalList])

  return pagesTotal > 1 || hideTotal ? (
    <div className='d-flex f-row align-center'>
      {currentPage > 1 ? (
        <button type='button' onClick={() => goToPage(currentPage - 1)}>
          <span className='primary-red-text medium-weight'>Précédent</span>
        </button>
      ) : null}
      {!hideTotal ? (
        pages.map((pages, index, array) => {
          if (pages.includes(currentPage)) {
            return pages.map((page, pageIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`page-${pageIndex}`}>
                {index > 0 && pageIndex === 0 ? (
                  <button
                    className='m-3'
                    type='button'
                    onClick={() => goToPage(1)}
                  >
                    <span className='grey-400-text normal-weight mr-3'>1</span>
                    <span className='grey-400-text normal-weight ml-3'>
                      ...
                    </span>
                  </button>
                ) : null}
                <button
                  type='button'
                  className={`${
                    page === currentPage ? 'current-page' : ''
                  } m-3`}
                  onClick={() => goToPage(page)}
                >
                  <span
                    className={`${
                      page === currentPage
                        ? 'primary-red-text medium-weight'
                        : 'grey-400-text normal-weight'
                    }`}
                  >
                    {page}
                  </span>
                </button>
                {pageIndex === 2 && pagesTotal > 3 ? (
                  <span className='grey-400-text normal-weight m-3'> ... </span>
                ) : null}
              </div>
            ))
          }
          if (index === array.length - 1) {
            return (
              <button
                className='m-3'
                key='last-page'
                type='button'
                onClick={() => goToPage(pages[pages.length - 1])}
              >
                <span className='grey-400-text normal-weight'>
                  {pages[pages.length - 1]}
                </span>
              </button>
            )
          }
          return null
        })
      ) : (
        <div>
          {currentPage > 1 ? (
            <span className='grey-400-text normal-weight ml-3'>...</span>
          ) : null}
          <button type='button' className='current-page m-3'>
            <span className='primary-red-text medium-weight'>
              {currentPage}
            </span>
          </button>
          {hasNext ? (
            <span className='grey-400-text normal-weight mr-3'>...</span>
          ) : null}
        </div>
      )}
      {canShowNextButton ? (
        <button type='button' onClick={() => goToPage(currentPage + 1)}>
          <span className='primary-red-text medium-weight'>Suivant</span>
        </button>
      ) : null}
    </div>
  ) : (
    <div />
  )
}

PaginationComponent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  totalList: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentList: PropTypes.func.isRequired,
  hasNext: PropTypes.bool,
  hideTotal: PropTypes.bool
}

PaginationComponent.defaultProps = {
  totalPage: null,
  totalList: null,
  hasNext: false,
  hideTotal: false
}

export default PaginationComponent
