import client from './client';

const getNews = ({ search, isPublic }) => client.get(
  '/network-actualities',
  { params: { search: search || undefined, isPublic: isPublic || undefined } },
);

const getOneNews = ({ id }) => client.get(`/network-actualities/${id}`);

const getCategories = () => client.get('/network-actuality-categories');

const addNews = ({ news }) => client.post('/network-actualities', news);
const updateNews = ({ news }) => client.put(`/network-actualities/${news.id}`, news);
const readNews = ({ news, user }) => client.post('/network-actualities-read',
  { networkActualityId: news, adminId: user });
const unreadNews = ({ news }) => client.delete(`/network-actualities-unread/${news}`);
const deleteNews = ({ id }) => client.delete(`/network-actualities/${id}`);

export default {
  getNews,
  getCategories,
  getOneNews,
  addNews,
  updateNews,
  readNews,
  unreadNews,
  deleteNews,
};
