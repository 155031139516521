import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Accordeon = ({
  header, children, setOpenedList, id, openedList, hasChildren,
  buttonRight,
}) => {
  const [isOpen, setIsOpen] = useState(openedList.includes(id));

  const toggleAccordeon = () => {
    setIsOpen((state) => !state);
    if (!isOpen) {
      setOpenedList((state) => [...state, id]);
    } else {
      setOpenedList((state) => state.filter((item) => item !== id));
    }
  };

  return (
    <div className="full-width accordeon">
      {/* <div */}
      {/*  className="full-width d-flex align-center" */}
      {/*  style={{ */}
      {/*    flexDirection: buttonRight ? 'row-reverse' : 'row', */}
      {/*  }} */}
      {/* > */}
      <button
        className="full-width d-flex align-center p-0"
        style={{
          flexDirection: buttonRight ? 'row-reverse' : 'row',
        }}
        type="button"
        onClick={() => {
          if (hasChildren) {
            toggleAccordeon();
          }
        }}
      >
        { hasChildren ? (
          <h2 className="mv-0 ml-0 mr-4 normal-weight grey-400-text">{isOpen ? '-' : '+'}</h2>
        ) : <div style={{ width: 45 }} />}
        <div className="full-width">
          {header}
        </div>
      </button>
      {/* </div> */}
      <div
        className="accordeon-content"
        style={{
          height: isOpen ? 'auto' : 0,
        }}
      >
        {
            children
        }
      </div>
      <div
        className="line full-width mv-3"
        style={{ opacity: 0.2 }}
      />
    </div>
  );
};

Accordeon.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  setOpenedList: PropTypes.func.isRequired,
  openedList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hasChildren: PropTypes.bool.isRequired,
  buttonRight: PropTypes.bool,
};

Accordeon.defaultProps = {
  children: null,
  buttonRight: false,
};

export default Accordeon;
