import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';

// Constants
import strings from '../../../constants/strings';

// Services
import UserService from '../../../services/UserService';
import FileService from '../../../services/FileService';

// Utils
import utils from '../../../utils/utils';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Components
import UserFormComponent from '../../../components/Forms/UserFormComponent';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

const EditUserAdmin = () => {
  const [firstname, setFirstname] = useState('');
  const [name, setName] = useState('');
  const [job, setJob] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [profile, setProfile] = useState({});
  const [avatar, setAvatar] = useState(null);
  const [avatarId, setAvatarId] = useState(null);
  const [profileId, setProfileId] = useState(null);
  const [isHidden, setIsHidden] = useState(true);

  // Navigation
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  // Queries
  const createAdmin = useMutation('create-admin', async (createAdminData) => {
    if (avatar) {
      const uploadedFile = await FileService.addFile({ binary: avatar, typeId: 15 });
      setAvatarId(uploadedFile.data.id);
      return UserService.createAdmin({
        admin: {
          ...createAdminData?.admin,
          avatarFileId: uploadedFile.data.id,
        },
      });
    }
    return UserService.createAdmin(createAdminData);
  });
  const updateAdmin = useMutation('update-admin', async (updateAdminData) => {
    if (avatar) {
      const uploadedFile = await FileService.addFile({ binary: avatar, typeId: 15 });
      setAvatarId(uploadedFile.data.id);
      return UserService.updateAdmin({
        admin: {
          ...updateAdminData?.admin,
          avatarFileId: uploadedFile.data.id,
        },
      });
    }
    return UserService.updateAdmin(updateAdminData);
  });
  const {
    isSuccess, data, isError,
  } = useQuery('getAdmin', () => {
    if (parameters.get('id')) {
      return UserService.getAdmin({ id: parameters.get('id') });
    }
    return null;
  });

  // Store
  const [, appDispatch] = useAppContext();

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setFirstname(data.data.firstName);
      setName(data.data.lastName);
      setJob(data.data.position);
      setPhone(data.data.phone);
      setMail(data.data.emailAddress);
      setProfileId(data.data.roleId);
      setAvatar(null);
      setAvatarId(data.data.avatarFileId);
      setIsHidden(data.data.isHidden);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.userLoading, isSuccess: false },
      });
      history.push('/intranet/users');
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (createAdmin.isSuccess || updateAdmin.isSuccess) {
      history.push('/intranet/users');
    }
    if (updateAdmin.isError) {
      const toDisplay = Object.values(updateAdmin?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateUser, isSuccess: false },
      });
    }
    if (createAdmin.isError) {
      const toDisplay = Object.values(createAdmin?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createUser, isSuccess: false },
      });
    }
  }, [createAdmin.isSuccess, updateAdmin.isSuccess, createAdmin.isError, updateAdmin.isError]);

  const onSubmit = () => {
    if (parameters.get('id')) {
      return updateAdmin.mutate({
        admin: {
          ...data?.data,
          emailAddress: mail,
          firstName: firstname,
          lastName: name,
          position: job,
          phone,
          roleId: profile.value,
          avatarFileId: avatarId,
          isHidden,
        },
      });
    }
    return createAdmin.mutate({
      admin: {
        emailAddress: mail,
        firstName: firstname,
        lastName: name,
        position: job,
        phone,
        roleId: profile.value,
        avatarFileId: avatarId,
        isHidden,
      },
    });
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/users">
          <h3 className="mr-3 mv-1">{strings.users}</h3>
        </Link>
        <h3 className="mv-1 text-align-left">
          {` > ${data?.data?.lastName || strings.newUser} ${data?.data?.firstName || ''}`}
        </h3>
      </div>
      <UserFormComponent
        setMail={setMail}
        profile={profile}
        profileId={profileId}
        phone={phone}
        setPhone={setPhone}
        submit={onSubmit}
        setProfile={setProfile}
        firstname={firstname}
        name={name}
        job={job}
        setName={setName}
        setFirstname={setFirstname}
        mail={mail}
        setJob={setJob}
        adminType={1}
        hasAvatar
        avatar={avatar}
        avatarId={avatarId}
        setAvatar={(file) => {
          setAvatar(file);
          setAvatarId(null);
        }}
        isHidden={isHidden}
        setIsHidden={setIsHidden}
        isLoading={createAdmin?.isLoading || updateAdmin?.isLoading}
      />
    </TemplateWithMenuAndHeader>
  );
};

export default EditUserAdmin;
