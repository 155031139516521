import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
// Services
import FileService from '../../services/FileService';
// Utils
import utils from '../../utils/utils';
// Constants
import colors from '../../constants/colors';

const AdherentCard = ({
  image,
  width,
  title,
  subtitle,
  region,
  className,
  contactName,
  address,
  zipcode,
  city,
  phone,
  email,
}) => {
  const [base64, setBase64] = useState(null);

  const {
    isSuccess, data,
  } = useQuery(['getFile', image], () => {
    if (image) {
      return FileService.getFile({ id: image });
    }
    return Promise.resolve();
  });

  useEffect(() => {
    if (isSuccess && data) {
      utils.getBase64(data.data).then((result) => {
        setBase64(result);
      });
    }
  }, [isSuccess, data]);
  return (
    <div className={className}>
      <div
        className="d-flex f-column align-start radius-5"
        style={{ border: '1px solid #EDEEF1', width }}
      >
        {image
          ? (
            <div
              className="full-width bg-cover"
              style={{
                height: 300,
                marginBottom: '-20px',
                backgroundImage: `url("${base64}")`,
              }}
            />
          )
          : null}
        <SlashContainer width="100%" backgroundColor={colors.white} offset={image ? 10 : 0} position="top">
          <div style={{ width }}>
            <div className="d-flex f-column align-center ph-3 mb-4 mt-2">
              <span className="very-small-text uppercase primary-red-text">{region}</span>
              <h6 className="grey-800-text medium-weight m-0 mb-3">{title}</h6>
              <span className="grey-400-text normal-weight m-0 mb-3">{subtitle}</span>
              <div className="line full-width mb-3" style={{ opacity: 0.3 }} />
              {contactName
                ? <span className="medium-text grey-800-text medium-weight">{contactName}</span>
                : null}
              {
                address
                  ? <span className="small-text grey-400-text normal-weight small-line-height">{address}</span>
                  : null
              }
              { city && zipcode
                ? (
                  <span className="small-text grey-400-text normal-weight small-line-height uppercase">
                    {zipcode}
                    {' '}
                    {city}
                  </span>
                )
                : null}
              <a href={`tel:${phone}`}>
                <span className="medium-text grey-800-text normal-weight">{phone}</span>
              </a>
              <a href={`mailto:${email}`}>
                <span className="small-text primary-red-text normal-weight">{email}</span>
              </a>
            </div>
          </div>
        </SlashContainer>
      </div>
    </div>
  );
};

AdherentCard.propTypes = {
  image: PropTypes.number,
  width: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  contactName: PropTypes.string,
  region: PropTypes.string.isRequired,
  address: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AdherentCard.defaultProps = {
  className: '',
  subtitle: '',
  contactName: '',
  address: '',
  zipcode: '',
  city: '',
  image: null,
};

export default AdherentCard;
