import { Redirect } from 'react-router-dom';
import Login from '../views/Login';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import VerifyEmail from '../views/VerifyEmail';

export default [
  {
    path: '/extranet/login',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <Login isAdmin={isAdmin} />;
    },
  },
  {
    path: '/intranet/login',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <Login isAdmin={isAdmin} />;
    },
  },
  {
    path: '/extranet/forgot-password',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <ForgotPassword isAdmin={isAdmin} />;
    },
  },
  {
    path: '/intranet/forgot-password',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <ForgotPassword isAdmin={isAdmin} />;
    },
  },
  {
    path: '/extranet/reset-password',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <ResetPassword isAdmin={isAdmin} />;
    },
  },
  {
    path: '/intranet/reset-password',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <ResetPassword isAdmin={isAdmin} />;
    },
  },
  {
    path: '/extranet/verify-email',
    exact: false,
    render: (auth, { location }) => {
      const isAdmin = location.pathname.includes('/intranet');
      const redirection = isAdmin ? '/intranet' : '/extranet';
      return auth
        ? <Redirect to={{ pathname: redirection, state: { from: location } }} />
        : <VerifyEmail isAdmin={isAdmin} />;
    },
  },
];
