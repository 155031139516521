import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';

// Components
import DashboardPanel from './DashboardPanel';
import NewsElement from '../atoms/NewsElement';

// Services
import NetworkNewsService from '../../services/NetworkNewsService';
import AdherentService from '../../services/AdherentService';

// Hooks
import { useAppContext } from '../../store/AppContext';

// Utils

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Images
import { ReactComponent as ArrowBigIcon } from '../../assets/images/arrow-big.svg';
import Logo from '../../assets/images/logo.webp';
import DashboardFigures from '../atoms/DashboardFigures';
import AdherentBookService from '../../services/AdherentBookService';
import AdherentMap from './AdherentMap';

const AdherentDashboard = () => {
  // Store
  const [{
    user, permissions, docBases, currentAgency,
  }, appDispatch] = useAppContext();

  const [newsList, setNewsList] = useState([]);

  const isNetworkAdmin = () => user?.data?.roleId === 1 || user?.data?.roleId === 2 || user?.data?.roleId === 3;
  const isTech = () => user?.data?.roleId === 6;
  const isAdministrationManager = () => user?.data?.roleId === 5;
  const isAdministration = () => user?.data?.roleId === 8;

  // Queries

  const getActualities = useQuery(['get-actualities'],
    () => NetworkNewsService.getNews({ isPublic: undefined }));
  const { data: categories } = useQuery('get-categories', () => NetworkNewsService.getCategories());

  const dashboardContacts = useQuery(['my-adherent-dashboard-contacts', user, currentAgency],
    () => {
      if (user && currentAgency && !isNetworkAdmin() && !isTech()) {
        return AdherentService.myAdherentDashboardContacts(currentAgency);
      }
      if (isNetworkAdmin()) {
        return AdherentService.networkDashboardContacts();
      }
      return null;
    });

  const getAll = useQuery(['getAllAdherents'],
    () => AdherentBookService.getAllAdherent({
      search: null,
    }));

  useEffect(() => {
    if (getActualities.isSuccess) {
      if (getActualities?.data?.data?.networkActualities) {
        setNewsList(getActualities?.data?.data?.networkActualities.sort((first, second) => {
          const firstDate = new Date(first.publicationDate);
          const secondDate = new Date(second.publicationDate);
          return secondDate - firstDate;
        })
          ?.map((news) => (
            {
              ...news,
              category: categories?.data?.categories?.find(({ id }) => id === news.categoryId)?.code || '',
            }))
          ?.slice(0, 5));
      }
    }
    if (getActualities.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getActualities, isSuccess: false,
        },
      });
    }
  }, [getActualities.isError, getActualities.isSuccess, getActualities.data, categories]);

  useEffect(() => {
    if (dashboardContacts.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getMonthlyResults, isSuccess: false,
        },
      });
    }
  }, [dashboardContacts.isError]);

  const networkLinks = [
    {
      label: strings.networkBaseDoc,
      to: '/intranet/doc-base/1',
      permissions: [],
    },
    {
      label: strings.adherentForum,
      to: '/intranet/forum/1',
      permissions: [],
    },
    {
      label: strings.quickLinkCA,
      to: '/intranet/doc-base/2',
      permissions: ['document_base_ca_reading'],
    },
  ];

  const techLinks = [
    {
      label: strings.techForum,
      to: '/intranet/forum/2',
      permissions: [],
    },
    {
      label: strings.SOSSpareParts,
      to: '/intranet/spare-parts',
      permissions: [],
    },
  ];

  const administrationLinks = [
    {
      label: strings.administrationForum,
      to: '/intranet/forum/3',
      permissions: [],
    },
    {
      label: strings.SOSSpareParts,
      to: '/intranet/spare-parts',
      permissions: [],
    },
  ];

  const links = [
    {
      label: strings.quickLinkRegion,
      to: `/intranet/doc-base/${docBases.find(({ regionId }) => regionId === user?.data?.regionId)?.id}`,
      permissions: [],
    },
    {
      label: strings.quickLinkForum,
      to: '/intranet/forum/1',
      permissions: [],
    },
    {
      label: strings.quickLinkCA,
      to: '/intranet/doc-base/2',
      permissions: ['document_base_ca_reading'],
    },
  ].filter((link) => {
    for (let i = 0; i < link.permissions.length; i += 1) {
      if (!permissions?.includes(link.permissions[i])) return false;
    }
    return true;
  });

  const getLinks = () => {
    if (isNetworkAdmin()) {
      return networkLinks;
    }
    if (isTech()) {
      return techLinks;
    }
    if (isAdministrationManager()) {
      return administrationLinks;
    }
    if (isAdministration()) {
      return [administrationLinks[0]];
    }
    return links;
  };

  return (
    <div>
      <div className="adherent-dashboard mt-4 ml-6 mr-6">
        {/* network news panel */}
        <DashboardPanel
          header={strings.dashboardNews}
          className="a-news-dashboard"
          link={{ label: strings.seeAll, to: '/intranet/network-news' }}
        >
          {newsList.length > 0
            ? (
              <div className="relative" style={{ flexGrow: '1', overflowY: 'auto' }}>
                <div className="absolute full-width">
                  <ul className="p-0" style={{ listStyleType: 'none' }}>
                    { newsList.map((news) => (
                      <li
                        key={news.id}
                        className="grey-200-border d-flex justify-between"
                        style={{ minHeight: '60px', borderBottom: '1px solid #EDEEF1', marginBottom: '0.10rem' }}
                      >
                        <NewsElement
                          category={news.category}
                          date={news.publicationDate}
                          content={news.title}
                          id={news.id}
                          thumbnail={news.illustrationFiles?.[0]?.fileId || null}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )
            : (
              <div>
                {getActualities.isLoading
                  ? <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />
                  : 'Aucune news à afficher'}
              </div>
            )}
        </DashboardPanel>
        {/* network agenda panel */}
        {isTech() || isAdministrationManager() || isAdministration() ? null : (
          <DashboardPanel header={strings.dashboardAgenda} className="a-agenda-dashboard">
            <iframe
              title="network-agenda"
              src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe
                %2FParis&hl=fr&showTz=0&showCalendars=0&showTabs=1&showPrint=0&showNav=1&showTitle=0
                &mode=MONTH&src=14feg0lmsfsvu16gnet24oa7cg%40group.calendar.google.com&ctz=Europe%2FParis"
              style={{ border: 0 }}
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
            />
          </DashboardPanel>
        )}
        {/* client activity panel */}
        {
          isTech()
            ? (
              <DashboardPanel header="" className="a-activity-dashboard">
                <div className="d-flex f-column justify-center align-center">
                  <img alt="logo" src={Logo} width={200} />
                  <h5 className="grey-400-text medium-weight m-0">{strings.welcomeUserSpaceAdmin}</h5>
                </div>
              </DashboardPanel>
            )
            : (
              <DashboardPanel
                header={strings.dashboardClients}
                className="a-activity-dashboard relative"
                dark
              >
                <DashboardFigures
                  contacts={dashboardContacts?.data?.data}
                  showMore={!isNetworkAdmin() || user?.data?.roleId === 1}
                  showMoreTo={user?.data?.roleId === 1 ? '/intranet/all-demands' : '/intranet/demands'}
                />
              </DashboardPanel>
            )
        }
        {/* quick access panel */}
        <DashboardPanel
          className={isTech() || isAdministrationManager() || isAdministration()
            ? 'a-region-dashboard' : 'a-quick-access-dashboard'}
        >
          <div className="grey-800-text">
            {getLinks().map((link, i) => (
              <Link key={link.label} to={link.to}>
                <h6
                  className={`d-flex align-center
                  ${(links.length !== i + 1) ? 'mb-3' : 'mb-0'} mt-0 normal-weight`}
                >
                  <ArrowBigIcon
                    width={24}
                    height={24}
                    style={{
                      minWidth: 24,
                      maxWidth: 24,
                      minHeight: 24,
                      maxHeight: 24,
                    }}
                  />
                  <span className="ws-pre-wrap ml-2">{link.label}</span>
                </h6>
              </Link>
            ))}
          </div>
        </DashboardPanel>
        {/* adherent map panel */}
        {isAdministration() ? null : (
          <DashboardPanel
            header={strings.adherentMap}
            className={isAdministrationManager() || isTech() ? 'a-agenda-dashboard' : 'a-monthly-results-dashboard'}
            link={{ label: 'Tous les adhérents', to: '/intranet/adherent-book-map' }}
          >
            <AdherentMap
              dimensions={{ width: '100%', height: 650 }}
              agencyAdherentAdmins={getAll?.data?.data?.agencyAdherentAdmins}
            />
          </DashboardPanel>
        )}
      </div>
    </div>
  );
};

export default AdherentDashboard;
