import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/modal.scss'
import { SlashContainer } from '../../../index'
import { useClickAway, useMeasure } from 'react-use'

const ModalTemplate = ({ children, closeModal, title }) => {
  const [containerRef, { width }] = useMeasure()
  const modalContentRef = useRef()

  useClickAway(modalContentRef, closeModal)

  return (
    <div className='action-modal'>
      <div ref={modalContentRef}>
        <div
          ref={containerRef}
          className='action-modal-content'
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className='d-flex justify-between p-4 align-start'>
            <h1 className='white-text medium-weight ph-3'>{title}</h1>
            <div
              role='button'
              tabIndex={0}
              onClick={closeModal}
              className='mh-1 pointer'
            >
              <h4 className='white-text normal-weight m-0'>x</h4>
            </div>
          </div>
          <SlashContainer
            width='100%'
            backgroundColor='#fff'
            position='top'
            offset={40}
          >
            {children}
          </SlashContainer>
          <div className='white-background d-flex justify-end'>
            <div
              style={{
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: `0 0 70px ${width * 0.8}px`,
                borderColor: 'transparent transparent #d01508 transparent'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ModalTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default ModalTemplate
