import client from './client';

// Forums
const getForums = () => client.get('/forums');
const getThemes = ({ forum }) => client.get('/forum-themes', {
  params: {
    forumId: forum,
  },
});

// Threads
const getThreads = ({
  forum, search, themeId, isClosed, pageNumber, pageSize,
}) => client.get('/forum-threads', {
  params: {
    forumId: forum,
    forumThemeId: themeId,
    isClosed,
    search,
    pageNumber,
    pageSize,
  },
});
const getOneThread = (id) => client.get(`/forum-threads/${id}`);
const addThread = ({ thread }) => client.post('/forum-threads', thread);
const updateThread = ({ thread }) => client.put(`/forum-threads/${thread.id}`, thread);
const deleteThread = (id) => client.delete(`/forum-threads/${id}`);

// Posts
const getPosts = ({ thread, pageSize, pageNumber }) => client.get('/forum-posts', {
  params: {
    forumThreadId: thread,
    pageSize,
    pageNumber,
  },
});
const addPost = ({ post }) => client.post('forum-posts', post);
const updatePost = ({ post }) => client.put(`forum-posts/${post.id}`, post);
const deletePost = (id) => client.delete(`forum-posts/${id}`);

export default {
  getForums,
  getThemes,
  getThreads,
  getOneThread,
  addThread,
  updateThread,
  deleteThread,
  getPosts,
  addPost,
  updatePost,
  deletePost,
};
