import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/checkbox.scss'

const CheckboxComponent = ({ value, handleChange, label, id }) => (
  <label className='input-checkbox-container' htmlFor={id}>
    <span className='regular-text'>{label}</span>
    <input
      className='input-checkbox'
      id={id}
      type='checkbox'
      value={value}
      checked={value}
      onChange={() => handleChange((state) => !state)}
    />
    <span className='input-checkbox-checkmark' />
  </label>
)

CheckboxComponent.propTypes = {
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.string.isRequired
}

export default CheckboxComponent
