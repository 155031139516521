import React, { useEffect, useState } from 'react';
import { ButtonComponent, SelectComponent, PaginationComponent } from '@zolteam/axenergie-ui-library';

// Hooks
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../../store/AppContext';

// Components
import DataTable from '../../../components/molecules/DataTable';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Constants
import strings from '../../../constants/strings';

// Services
import AdherentService from '../../../services/AdherentService';

// Images
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';

const Adherents = () => {
  // Local states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [currentList, setCurrentList] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [search, setSearch] = useState('');
  const [regionFilter, setRegionFilter] = useState(null);

  // Queries
  const {
    isLoading: isRegionLoading, isError: isRegionError, data: regions,
  } = useQuery('getRegions', AdherentService.getRegions);

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery('getAdherents', () => AdherentService.getAdherents());

  // Store
  const [, appDispatch] = useAppContext();
  const history = useHistory();

  // Methods
  const goToAddAdherent = () => history.push('/intranet/adherent');

  const goToEditAdherent = (id) => history.push(`/intranet/adherent?id=${id}`);

  const renderEditButton = (id) => (
    <button type="button" onClick={() => goToEditAdherent(id)}>
      <EditIcon width={30} />
    </button>
  );

  const getRegionName = (id) => regions?.data?.regions.find((region) => region.id === id)?.name;

  const formatTableData = (adherents) => (adherents?.sort((a, b) => a.name.localeCompare(b.name))
    ?.reduce((acc, adherent) => [
      ...acc,
      {
        name: adherent.name,
        region: getRegionName(adherent.regionId),
        edit: renderEditButton(adherent.id),
      },
    ], []));

  const doSearch = () => {
    let newList = formatTableData(data?.data?.adherents);
    if (search) {
      newList = newList.filter(({ name }) => name.toUpperCase().includes(search.toUpperCase()));
    }
    if (regionFilter) {
      newList = newList.filter(({ region }) => regionFilter.label === region);
    }
    return setFormattedData(newList);
  };

  const formatRegionsData = (regionsData) => regionsData?.map(
    ({ id, name: regionName }) => ({ value: id, label: regionName }),
  );

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    doSearch();
  }, [regionFilter]);

  useEffect(() => {
    if (isError || isRegionError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAdherent, isSuccess: false },
      });
    }
    if (isSuccess) {
      setFormattedData(formatTableData(data?.data?.adherents));
    }
  },
  [
    isError,
    isSuccess,
    isRegionError,
    data,
  ]);

  return (
    <TemplateWithMenuAndHeader>
      <h1 className="mb-5 text-align-left">{strings.handleAdherents}</h1>
      <div
        className="d-flex f-column justify-center align-start width-fit-content"
      >
        <div className="d-flex f-row justify-start align-center ph-4 f-wrap">
          <h3 className="mr-5">{strings.myAdherents}</h3>
          <ButtonComponent onClick={goToAddAdherent}>
            <span>{strings.addAdherent}</span>
          </ButtonComponent>
        </div>
        {/* Filters */}
        <div className="d-flex f-row f-wrap align-center justify-start">
          <div className="form-input-width mh-3 mv-2">
            <SearchInputComponent
              onChange={setSearch}
              onClick={doSearch}
              id="adherent-search"
              value={search}
            />
          </div>
          { isRegionError ? null : (
            <div className="form-input-width small m-3">
              <SelectComponent
                isLoading={isRegionLoading}
                data={formatRegionsData(regions?.data?.regions) || []}
                name="adherent-region-filter"
                value={regionFilter}
                setValue={setRegionFilter}
                theme="dark"
                placeholder={strings.regions}
                isClearable
              />
            </div>
          )}
        </div>
        <div className="table-container d-flex justify-center f-column align-center ph-3">
          <DataTable
            isLoading={isLoading || isError || isRegionLoading || isRegionError}
            data={currentList}
            columns={[
              strings.name,
              strings.region,
              ' ',
            ]}
          />
          <PaginationComponent
            setCurrentList={setCurrentList}
            totalList={formattedData || []}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default Adherents;
