import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { ButtonComponent, InputComponent } from '@zolteam/axenergie-ui-library';
import jwtDecode from 'jwt-decode';
import draftToHtml from 'draftjs-to-html';
// Constants
import htmlToDraftjs from 'html-to-draftjs';
import strings from '../../constants/strings';

// Components
import TextEditorComponent from '../atoms/TextEditorComponent';
import FileInputWithAcceptAndSize from '../atoms/FileInputWithAcceptAndSize';

// Utils and hooks
import { validateNotEmptyField } from '../../utils/validators';
import { useAppContext } from '../../store/AppContext';

const ForumFormPost = ({ onSubmit, initValues, submitButtonTitle }) => {
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [contentError, setContentError] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file1Id, setFile1Id] = useState(null);
  const [file1Name, setFile1Name] = useState('');
  const [file1NameError, setFile1NameError] = useState(false);
  const [file2, setFile2] = useState(null);
  const [file2Id, setFile2Id] = useState(null);
  const [file2Name, setFile2Name] = useState('');
  const [file2NameError, setFile2NameError] = useState(false);

  const [{ agencyName, auth }] = useAppContext();

  const getMyName = () => {
    const decoded = jwtDecode(auth);
    return `${decoded?.given_name} ${decoded.family_name}`;
  };

  const resetValues = () => {
    setContent(() => EditorState.createEmpty());
    setContentError(false);
    setFile1(null);
    setFile1Name('');
    setFile1NameError(false);
    setFile2(null);
    setFile2Name('');
    setFile2NameError(false);
  };

  const setInitValues = (init) => {
    if (init) {
      if (init?.content) {
        setContent(EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraftjs(init?.content)),
        ));
      }
      setFile1Name(init.joinedFiles?.[0]?.fileName);
      setFile1Id(init.joinedFiles?.[0]?.fileId);
      setFile2Name(init.joinedFiles?.[1]?.fileName);
      setFile2Id(init.joinedFiles?.[1]?.fileId);
    }
  };

  const isFileError = (file, name, fileId) => ((file || fileId) && !name) || ((!file && !fileId) && name);

  const handleClick = () => {
    if (contentError || file1NameError || file2NameError) {
      return false;
    }
    onSubmit({
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
      file1,
      file1Id,
      file2,
      file2Id,
      file1Name,
      file2Name,
      hasError: contentError || isFileError(file1, file1Name, file1Id) || isFileError(file2, file2Name, file2Id),
    });
    return resetValues();
  };

  useEffect(() => {
    setInitValues(initValues);
  }, [initValues]);

  return (
    <div className="white-background mv-4 full-width">
      <div className="p-4">
        <div className="d-flex f-row f-wrap">
          <h5 className="grey-800-text m-0 medium-weight">
            {`${strings.the} ${
              format(new Date(), 'dd/MM/yyyy')}`}
          </h5>
          <h5 className="grey-800-text ml-1 m-0 medium-weight">
            {`${strings.by} ${getMyName()}`}
          </h5>
          {
              agencyName
                ? (
                  <h5 className="grey-800-text ml-1 m-0 medium-weight">
                    {`- ${agencyName}`}
                  </h5>
                )
                : null
            }
        </div>
        <div
          className="d-flex f-row justify-between align-center f-wrap"
        >
          <div
            className="mv-3 mr-3 text-editor-large-width"
          >
            <TextEditorComponent
              label={strings.message}
              setValue={setContent}
              value={content}
              isError={contentError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => (validateNotEmptyField(setContentError,
                convertToRaw(content.getCurrentContent()).blocks[0].text)
              )}
            />
          </div>
          <div className="d-flex f-column">
            <div className="form-file-input-width mr-2 mv-2">
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setFile1(file);
                  setFile1Id(null);
                }}
                file={file1}
                canDelete={file1 || file1Id}
                label={file1Id ? `${strings.changeFile} 1` : `${strings.file} 1`}
                fileType={13}
                initName={strings.changeFile}
              />
            </div>
            <div className="form-input-width mb-3 mv-2">
              <InputComponent
                onChange={setFile1Name}
                id="file-1-name"
                value={file1Name}
                label={`${strings.fileName} 1`}
                isError={file1NameError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => setFile1NameError(file1 ? !file1Name : false)}
              />
            </div>
            <div className="form-file-input-width mr-2 mv-2">
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setFile2(file);
                  setFile2Id(null);
                }}
                file={file2}
                canDelete={file2 || file2Id}
                label={file2Id ? `${strings.changeFile} 2` : `${strings.file} 2`}
                fileType={13}
                initName={strings.changeFile}
              />
            </div>
            <div className="form-input-width mb-2">
              <InputComponent
                onChange={setFile2Name}
                id="file-2-name"
                value={file2Name}
                label={`${strings.fileName} 2`}
                isError={file2NameError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => setFile2NameError(file2 ? !file2Name : false)}
              />
            </div>

          </div>
        </div>
        {
          !convertToRaw(content.getCurrentContent()).blocks[0].text
          || isFileError(file1, file1Name, file1Id)
          || isFileError(file2, file2Name, file2Id)
            ? null : (
              <ButtonComponent onClick={handleClick}>
                <div className="mv-2">
                  <span className="m-4 normal-weight">{submitButtonTitle}</span>
                </div>
              </ButtonComponent>
            )
        }
      </div>
    </div>
  );
};

ForumFormPost.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.shape({
    content: PropTypes.string,
    joinedFiles: PropTypes.arrayOf(PropTypes.shape({
      fileName: PropTypes.string,
      fileId: PropTypes.number,
    })),
  }),
  submitButtonTitle: PropTypes.string,
};

ForumFormPost.defaultProps = {
  initValues: null,
  submitButtonTitle: strings.toAnswer,
};

export default ForumFormPost;
