import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DashboardPanel = ({
  header, className, children, link, dark, rightElement,
}) => (
  <div className={`panel ${dark ? 'panel-dark white-text' : 'panel-white'} ${className}`}>
    <div className="full-height d-flex f-column">
      <div className={`d-flex f-row justify-between align-center full-width ${header ? 'mb-4' : ''}`}>
        { header
          ? (
            <div className={`uppercase medium-weight small-text ${dark ? 'white-text' : 'grey-600-text'}`}>
              {header}
            </div>
          ) : null}
        { rightElement || null }
        { link
          ? (
            <Link
              href
              className={`${dark ? '' : 'primary-red-text'} medium-text medium-weight`}
              to={link.to}
            >
              {link.label}
            </Link>
          )
          : null}
      </div>
      {children}
    </div>
  </div>
);

DashboardPanel.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  dark: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  rightElement: PropTypes.element,
};

DashboardPanel.defaultProps = {
  header: null,
  className: '',
  link: null,
  dark: false,
  rightElement: null,
};

export default DashboardPanel;
