import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
// Images
import arrow from '../../../assets/images/arrow-down.svg';
// Constants
import strings from '../../../constants/strings';
// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ThreadForm from '../../../components/Forms/ThreadForm';
// Services and hooks
import ForumService from '../../../services/ForumService';
import { useAppContext } from '../../../store/AppContext';
import FileService from '../../../services/FileService';

const CreateThread = () => {
  const [forumName, setForumName] = useState('');

  // hooks
  const { forum } = useParams();
  const history = useHistory();
  const [{ forums, currentAgency }, appDispatch] = useAppContext();

  // Queries
  const getThemes = useQuery('getThemes', () => ForumService.getThemes({ forum }));
  const createThread = useMutation('createThread', async ({
    file1,
    file1Name,
    file2,
    file2Name,
    thread,
  }) => {
    const joinedFiles = [];
    if (file1) {
      const uploadedFile = await FileService.addFile({ binary: file1, typeId: 13 });
      joinedFiles.push({
        fileName: file1Name,
        fileId: uploadedFile.data.id,
      });
    }
    if (file2) {
      const uploadedFile = await FileService.addFile({ binary: file2, typeId: 13 });
      joinedFiles.push({
        fileName: file2Name,
        fileId: uploadedFile.data.id,
      });
    }
    return ForumService.addThread({
      thread: {
        ...thread,
        joinedFiles: joinedFiles.length ? joinedFiles : undefined,
      },
    });
  });

  const goBackToList = () => history.push(`/intranet/forum/${forum}`);

  const onSubmit = ({
    file1,
    file1Name,
    file2,
    file2Name,
    hasError,
    theme,
    title,
    content,
  }) => {
    if (hasError) {
      return false;
    }
    return createThread.mutate({
      file1,
      file1Name,
      file2,
      file2Name,
      thread: {
        agencyId: currentAgency || undefined,
        forumId: forum,
        forumThemeId: theme,
        title,
        content,
      },
    });
  };
  useEffect(() => {
    if (getThemes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getForumThemes, isSuccess: false },
      });
    }
  }, [getThemes.isError]);

  useEffect(() => {
    if (createThread.isError) {
      const toDisplay = Object.values(createThread?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createThread, isSuccess: false },
      });
    }
    if (createThread.isSuccess) {
      goBackToList();
    }
  }, [createThread.isError, createThread.isSuccess]);

  useEffect(() => {
    if (forums) {
      setForumName(forums?.find(({ id }) => id.toString() === forum)?.title);
    }
  }, [forums, forum]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start">
        <div className="m-3 d-flex align-center">
          <ButtonComponent onClick={goBackToList} theme="white">
            <div className="d-flex justify-center align-center">
              <img
                alt="back-arrow"
                className="rotate-90"
                src={arrow}
                width={20}
              />
              <span className="normal-weight">{strings.back}</span>
            </div>
          </ButtonComponent>
          <span className="small-text grey-400-text ml-3">
            {`${strings.forum} / ${forumName}`}
          </span>
        </div>
        <div className="mh-3">
          <h2 className="grey-800-text medium-weight">{strings.newThread}</h2>
        </div>
        <ThreadForm
          themes={getThemes?.data?.data?.forumThemes}
          isLoading={createThread.isLoading}
          onSubmit={onSubmit}
        />
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default CreateThread;
