import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  ButtonComponent, CheckboxComponent, InputComponent, LoaderComponent, SelectComponent,
} from '@zolteam/axenergie-ui-library';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useMeasure } from 'react-use';
import htmlToDraftjs from 'html-to-draftjs';
import { format } from 'date-fns';
import strings from '../../../constants/strings';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import JobOfferService from '../../../services/JobOfferService';
import FilterButton from '../../../components/atoms/FilterButton';
import { useAppContext } from '../../../store/AppContext';
import { validateNotEmptyField } from '../../../utils/validators';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import utils from '../../../utils/utils';
import colors from '../../../constants/colors';

const EditJobOffer = () => {
  const [type, setType] = useState(null);
  const [isModel, setIsModel] = useState(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState(() => EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(false);
  const [zone, setZone] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [domains, setDomains] = useState([]);
  const [domain, setDomain] = useState(null);

  // Measures
  const [textContainer, { width: textContainerWidth }] = useMeasure();

  // hooks
  const [{ currentAgency, user }, appDispatch] = useAppContext();
  const history = useHistory();
  const parameters = utils.useQueryParameters();

  const isNetwork = useMemo(() => [1, 2, 3].includes(user?.data?.roleId), [user]);

  // Queries
  const getTypes = useQuery('getJobTypes',
    () => JobOfferService.getTypes());
  const getDomains = useQuery('getJobDomains',
    () => JobOfferService.getDomains());
  const getOffer = useQuery('getOffer', () => {
    if (parameters.get('id')) {
      return JobOfferService.getOffer(parameters.get('id'));
    }
    return null;
  });
  const getTemplates = useQuery('getTemplates', () => {
    if (!isNetwork) {
      return JobOfferService.getOffers({ isTemplate: 'true' });
    }
    return false;
  });
  const createOffer = useMutation('createJobOffer',
    (jobOffer) => JobOfferService.addJobOffer(jobOffer));
  const updateOffer = useMutation('updateOffer',
    (jobOffer) => JobOfferService.updateJobOffer(jobOffer));
  const deleteOffer = useMutation('deleteOffer',
    (id) => JobOfferService.deleteJobOffer(id));

  const onSubmit = () => {
    if (!type || titleError || descriptionError) {
      return false;
    }
    const formattedContacts = receiver?.split(',').map((address) => ({
      emailAddress: address,
    }));
    const contactTosend = receiver?.split(',')?.[0] ? formattedContacts : undefined;
    if (parameters.get('id')) {
      return updateOffer.mutate({
        ...getOffer?.data?.data,
        jobOfferTypeCode: type,
        jobOfferDomainCode: domain.value,
        agencyId: currentAgency || undefined,
        title,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        geographicSector: zone || undefined,
        isTemplate: isModel,
        contacts: contactTosend,
      });
    }
    return createOffer.mutate({
      jobOfferTypeCode: type,
      jobOfferDomainCode: domain.value,
      regionId: user?.data?.regionId || undefined,
      agencyId: currentAgency || undefined,
      title,
      description: draftToHtml(convertToRaw(description.getCurrentContent())),
      geographicSector: zone || undefined,
      isTemplate: isModel,
      contacts: contactTosend,
    });
  };

  const formatReceivers = (contacts) => contacts?.reduce(
    (acc, contact) => {
      if (acc && contact.emailAddress) {
        // eslint-disable-next-line prefer-template
        return acc + ',' + contact.emailAddress;
      } if (contact.emailAddress && !acc) {
        return contact.emailAddress;
      }
      return acc;
    }, '',
  );

  const initValues = (values) => {
    setType(values?.jobOfferTypeCode);
    setDomain(getDomains?.data?.data?.jobOfferDomains?.map((item) => ({
      label: item.name,
      value: item.code,
    })).find(({ value }) => value === values?.jobOfferDomainCode));
    setTitle(values?.title);
    if (values?.description) {
      setDescription(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(values?.description)),
      ));
    }
    setZone(values?.geographicSector);
    setIsModel(values?.isTemplate);
    setReceiver(formatReceivers(values?.contacts));
  };

  useEffect(() => {
    if (template) {
      initValues({
        ...template.value,
        isTemplate: false,
        expirationDate: format(new Date(), 'yyyy-MM-dd'),
      });
    }
  }, [template]);

  useEffect(() => {
    if (getTemplates.isSuccess && getTemplates.data) {
      setTemplates(getTemplates?.data?.data?.jobOffers?.map((offer) => ({
        label: offer.title,
        value: offer,
      })));
    }
  }, [getTemplates.isSuccess, getTemplates.data]);

  useEffect(() => {
    if (getDomains.isSuccess && getDomains.data) {
      setDomains(getDomains?.data?.data?.jobOfferDomains?.map((item) => ({
        label: item.name,
        value: item.code,
      })));
    }
  }, [getDomains.isSuccess, getDomains.data]);

  useEffect(() => {
    if (getOffer.isSuccess && getOffer.data && parameters.get('id')) {
      initValues(getOffer?.data?.data);
    }
  }, [getOffer.isSuccess, getOffer.data]);

  useEffect(() => {
    if (getTypes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getJobTypes, isSuccess: false },
      });
    }
  }, [getTypes.isError]);

  useEffect(() => {
    if (createOffer.isError) {
      const toDisplay = Object.values(createOffer?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createJobOffer, isSuccess: false },
      });
    }
    if (updateOffer.isError) {
      const toDisplay = Object.values(updateOffer?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateJobOffer, isSuccess: false },
      });
    }
    if (deleteOffer.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteJobOffer, isSuccess: false },
      });
    }
    if (createOffer.isSuccess || updateOffer.isSuccess || deleteOffer.isSuccess) {
      history.push('/intranet/job-offers');
    }
  }, [
    createOffer.isError,
    createOffer.isSuccess,
    updateOffer.isError,
    updateOffer.isSuccess,
    deleteOffer.isError,
    deleteOffer.isSuccess,
  ]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap full-width align-center justify-start">
        <Link to="/intranet/job-offers">
          <h3 className="mr-3 medium-weight">{strings.jobOffers}</h3>
        </Link>
        <h3 className="m-0 text-align-left medium-weight">{` > ${getOffer?.data?.data?.title || strings.newOffer}`}</h3>
      </div>
      <div>
        {
          !isNetwork
            ? (
              <div className="form-input-width m-3">
                <SelectComponent
                  data={templates}
                  name="offer-templates"
                  value={template}
                  setValue={setTemplate}
                  theme="dark"
                  isLoading={getTemplates?.isLoading}
                  label={strings.template}
                  isClearable
                />
              </div>
            )
            : null
        }
        <div className="d-flex f-row f-wrap align-center">
          <div className="mr-4">
            {
              getTypes?.data?.data?.jobOfferTypes?.map(({ name, code }) => (
                <FilterButton
                  key={code}
                  onClick={() => setType(code)}
                  isSelected={code === type}
                  title={name}
                />
              ))
          }
          </div>
          {isNetwork ? (
            <CheckboxComponent
              label={strings.defineAsTemplate}
              handleChange={() => setIsModel((state) => !state)}
              id="is-model"
              value={isModel}
            />
          ) : null}
        </div>
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setTitle}
            id="job-offer-title"
            value={title}
            label={strings.title}
            isError={titleError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setTitleError, title)}
          />
        </div>
        <div ref={textContainer} className="m-3">
          <TextEditorComponent
            label={strings.descriptionText}
            setValue={setDescription}
            value={description}
            isError={descriptionError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setDescriptionError,
              convertToRaw(description.getCurrentContent()).blocks[0].text)
            )}
          />
        </div>
        <div className="form-select-width m-3">
          <SelectComponent
            data={domains}
            name="offer-domains"
            value={domain}
            setValue={setDomain}
            theme="dark"
            isLoading={getDomains?.isLoading}
            label={strings.domain}
            isClearable
          />
        </div>
        <div className="d-flex f-row align-center f-wrap">
          <div className="form-input-width m-3">
            <InputComponent
              onChange={setZone}
              id="job-offer-zone"
              value={zone}
              label={strings.geographicZone}
            />
          </div>
          <div className="form-input-width m-3" style={{ width: textContainerWidth }}>
            <InputComponent
              onChange={setReceiver}
              id="job-offer-receiver"
              value={receiver}
              label={strings.demandReceiver}
              placeholder={strings.mailExample}
            />
          </div>
        </div>
        <div className="d-flex f-row">
          { !title
          || !convertToRaw(description.getCurrentContent()).blocks[0].text
          || !type
            ? null : (
              <div className="m-3">
                <ButtonComponent onClick={onSubmit}>
                  <div className="mv-2">
                    {
                    createOffer.isLoading || updateOffer.isLoading
                      ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                      : <span className="uppercase m-5">{strings.save}</span>
                  }
                  </div>
                </ButtonComponent>
              </div>
            )}
          {
          parameters.get('id')
            ? (
              <ButtonComponent onClick={() => deleteOffer.mutate(parameters.get('id'))} theme="grey">
                <div className="mv-2">
                  {
                    deleteOffer.isLoading
                      ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
                      : <span className="uppercase m-5">{strings.delete}</span>
                  }
                </div>
              </ButtonComponent>
            )
            : null
        }
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default EditJobOffer;
