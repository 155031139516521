import React, { useEffect, useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { useMutation, useQuery } from 'react-query';
import { ButtonComponent, PaginationComponent, MasonryComponent } from '@zolteam/axenergie-ui-library';
import { useHistory } from 'react-router-dom';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import NewsCard from '../../../components/atoms/NewsCard';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import NetworkNewsService from '../../../services/NetworkNewsService';

// Hooks
import { useAppContext } from '../../../store/AppContext';
import FilterButton from '../../../components/atoms/FilterButton';
import useIsMobile from '../../../hooks/useIsMobile';

const NetworkNews = () => {
  const [currentList, setCurrentList] = useState([]);
  const [totalList, setTotalList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeCategory, setActiveCategory] = useState(-1);
  const [search, setSearch] = useState('');
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const itemsPerPage = 10;
  const itemsMargin = 24;
  const columnWidth = screenWidth < 350 + 2 * itemsMargin + 16 ? screenWidth - 2 * itemsMargin - 16 : 350;

  // Store
  const [{ permissions, user }, appDispatch] = useAppContext();
  const [isMobile] = useIsMobile();
  const history = useHistory();

  const hasNetworkPermissions = () => JSON.parse(permissions).includes('network_actualities_administration');
  const canReadUnreadNews = () => JSON.parse(permissions).includes('network_news_reading');
  const canOnlySeePublicNews = () => [5, 6, 8].includes(user?.data?.roleId);

  // Queries
  const readNews = useMutation('readNews',
    (newsId) => NetworkNewsService.readNews({ news: newsId, user: user?.sub }));
  const unreadNews = useMutation('unreadNews', (newsId) => NetworkNewsService.unreadNews({ news: newsId }));
  const getActualities = useQuery(['get-actualities', readNews.isSuccess, unreadNews.isSuccess],
    () => NetworkNewsService.getNews({
      search,
      isPublic: canOnlySeePublicNews() ? 'true' : undefined,
    }));

  const {
    isLoading: getCategoriesLoading, isError: getCategoriesError, data: categories,
  } = useQuery('get-categories', () => NetworkNewsService.getCategories());

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 3) {
      nbColumns = 3;
    }
    return (nbColumns * columnWidth) + (itemsMargin / 2);
  };
  const getCategoryCode = (categoryId) => categories?.data?.categories?.find(({ id }) => id === categoryId)?.code;

  const doSearch = () => {
    getActualities.refetch();
  };

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (getActualities.isSuccess) {
      setCurrentList(getActualities?.data?.data?.networkActualities?.slice(0, itemsPerPage));
      setCurrentPage(1);
      if (activeCategory === -1) {
        setTotalList(getActualities.data?.data?.networkActualities);
      } else {
        setTotalList(
          getActualities.data?.data?.networkActualities?.filter(({ categoryId }) => categoryId === activeCategory),
        );
      }
    }
    if (getActualities.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getActualities, isSuccess: false },
      });
    }
  }, [getActualities.isError, getActualities.isSuccess, getActualities.data, activeCategory]);

  useEffect(() => {
    if (getCategoriesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
  }, [getCategoriesError]);

  const renderEmptyList = () => {
    if (getActualities.isLoading
        || getActualities.isError
        || getCategoriesLoading
        || getCategoriesError) {
      // return (<LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
      // );
      return null;
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noNews}</span>
      </div>
    );
  };

  const goToDetails = (id) => history.push(`/intranet/network-news/${id}`);
  const goToEdit = (id = null) => history.push(`/intranet/network-news-edit${id ? `?id=${id}` : ''}`);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start mh-3" ref={containerRef}>
        <div className="d-flex f-row justify-start align-center f-wrap">
          <div className="mr-5 mv-4">
            <h2 className="medium-weight m-0">{strings.theNetworkNews}</h2>
            <span className="grey-400-text mb-5">{strings.networkNewsSubtitle}</span>
          </div>
          {hasNetworkPermissions()
            ? (
              <div className="mb-3">
                <ButtonComponent onClick={() => goToEdit()}>
                  <span>{strings.addNetworkNews}</span>
                </ButtonComponent>
              </div>
            )
            : null}
        </div>
        <div
          className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
          style={{ width: isMobile ? '100%' : getContainerWidth() }}
        >
          {/* Category filters */}
          <div>
            <button
              type="button"
              onClick={() => setActiveCategory(-1)}
              className="ml-3 mr-2 mv-2 radius-5 pv-1 ph-3"
              style={{ backgroundColor: activeCategory === -1 ? colors.primaryRed : colors.grey100 }}
            >
              <span
                className="medium-text normal-weight uppercase"
                style={activeCategory === -1 ? { color: colors.white } : { color: colors.primaryRed }}
              >
                {strings.allNews}
              </span>
            </button>
            {
              categories?.data?.categories?.map((category) => (
                <FilterButton
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  isSelected={activeCategory === category.id}
                  title={category.code}
                />
              ))
            }
          </div>
          {/* search button */}
          <div className="form-input-width mh-3 mv-2">
            <SearchInputComponent
              onChange={setSearch}
              onClick={doSearch}
              id="news-search"
              value={search}
            />
          </div>
        </div>
        <div style={{
          width: isMobile ? '100%' : getContainerWidth(),
        }}
        >
          {
            getActualities.isLoading
            || getActualities.isError
            || getCategoriesLoading
            || getCategoriesError
            || !currentList?.length
              ? renderEmptyList()
              : (
                <div
                  className="white-background"
                  style={{
                    paddingLeft: isMobile ? (width - getContainerWidth()) / 2 + (itemsMargin / 2)
                      : itemsMargin / 2,
                    paddingTop: itemsMargin,
                  }}
                >
                  <MasonryComponent containerWidth={getContainerWidth()} columnWidth={columnWidth}>
                    {
                    currentList?.map(({
                      content, categoryId, title, illustrationFiles, id, publicationDate, isRead,
                    }) => (
                      <NewsCard
                        width={columnWidth - itemsMargin}
                        key={id}
                        content={content}
                        className="grid-item"
                        category={getCategoryCode(categoryId)}
                        title={title}
                        date={publicationDate || ''}
                        image={illustrationFiles ? illustrationFiles?.[0]?.fileId : null}
                        open={() => {
                          readNews.mutate(id);
                          goToDetails(id);
                        }}
                        editAction={hasNetworkPermissions()
                          ? () => goToEdit(id) : null}
                        isRead={isRead}
                        read={() => {
                          if (!isRead) {
                            readNews.mutate(id);
                          }
                        }}
                        unread={() => unreadNews.mutate(id)}
                        canRead={canReadUnreadNews()}
                      />
                    ))
                   }
                  </MasonryComponent>
                  <PaginationComponent
                    setCurrentList={setCurrentList}
                    totalList={totalList}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              )
          }

        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default NetworkNews;
