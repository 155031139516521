import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import {
  CheckboxComponent, SelectComponent, TextAreaComponent, RadioButtons, ButtonComponent, ModalTemplate,
} from '@zolteam/axenergie-ui-library';
import strings from '../../constants/strings';
import { useAppContext } from '../../store/AppContext';
import AgencyContractService from '../../services/AgencyContractService';
import AgencyContactService from '../../services/AgencyContactService';

const initialFormData = {
  agencyContractId: null,
  extranetAgencyContactSubjectId: null,
  source: '',
  message: '',
  isAvailableInTheMorning: false,
  isAvailableInTheAfternoon: false,
  isAvailableAtMidday: false,
  isAvailableAllDay: false,
};

const ContactModal = ({
  isOpen, onClose, source, defaultSubject, defaultContract,
}) => {
  const [{ currentAgency }, appDispatch] = useAppContext();

  const [formData, setFormData] = useState(initialFormData);
  const [agencyContractOptions, setAgencyContractOptions] = useState([]);

  const getMyAgencyContracts = useQuery(['get-agency-contracts', currentAgency], () => {
    if (currentAgency) {
      return AgencyContractService.getMyAgencyContracts({ agencyId: currentAgency });
    }
    return null;
  });

  const extranetAgencyContactSubjects = useQuery(['get-agency-contracts'], () => {
    if (currentAgency) {
      return AgencyContactService.getExtranetAgencyContactSubjects();
    }
    return null;
  });

  const setExtranetAgencyContacts = useMutation('setExtranetAgencyContacts',
    (data) => AgencyContactService.sendExtranetAgencyContacts(data));

  const validateForm = () => {
    let error = false;
    if (formData.agencyContractId === null) error = true;
    if (formData.extranetAgencyContactSubjectId === null) error = true;
    if (!formData.message) error = true;
    if (!formData.isAvailableAllDay
      && !formData.isAvailableAtMidday
      && !formData.isAvailableInTheAfternoon
      && !formData.isAvailableInTheMorning) error = true;

    if (error) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.extranetContactInvalid, isSuccess: false,
        },
      });
    }
    return !error;
  };

  useEffect(() => {
    // eslint-disable-next-line max-len
    const getAddress = (c) => `${c.equipmentType} ${c.equipmentModel}, ${c.route} ${c.postalCode} ${c.locality}`;
    if (getMyAgencyContracts.isSuccess) {
      setAgencyContractOptions(getMyAgencyContracts?.data?.data?.agencyContracts?.map((c) => ({
        label: getAddress(c),
        value: c.id,
      })));
    }
  }, [getMyAgencyContracts.isSuccess, getMyAgencyContracts.isError]);

  useEffect(() => {
    if (defaultSubject) {
      setFormData((data) => ({
        ...data,
        extranetAgencyContactSubjectId: defaultSubject,
      }));
    }
  }, [defaultSubject]);

  useEffect(() => {
    if (defaultContract) {
      setFormData((data) => ({
        ...data,
        agencyContractId: defaultContract,
      }));
    }
  }, [defaultContract]);

  useEffect(() => {
    if (setExtranetAgencyContacts.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.extranetContact, isSuccess: true,
        },
      });
      setFormData(initialFormData);
      onClose();
    }
    if (setExtranetAgencyContacts.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.extranetContact, isSuccess: false,
        },
      });
    }
  }, [setExtranetAgencyContacts.isSuccess, setExtranetAgencyContacts.isError]);

  const sendDatalayer = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form-extranet',
      category: extranetAgencyContactSubjects?.data?.data?.extranetAgencyContactSubjects?.find(
        ({ id }) => id === formData.extranetAgencyContactSubjectId,
      )?.name,
    });
  };

  const handleSendClick = () => {
    if (validateForm()) {
      sendDatalayer();
      setExtranetAgencyContacts.mutate({
        agencyContractId: formData.agencyContractId,
        extranetAgencyContactSubjectId: formData.extranetAgencyContactSubjectId,
        source,
        message: formData.message,
        isAvailableInTheMorning: formData.isAvailableInTheMorning,
        isAvailableInTheAfternoon: formData.isAvailableInTheAfternoon,
        isAvailableAtMidday: formData.isAvailableAtMidday,
        isAvailableAllDay: formData.isAvailableAllDay,
      });
    }
  };

  if (!isOpen) return null;
  return (
    <ModalTemplate closeModal={onClose} title={strings.contactTitle}>
      <div className="ph-5" style={{ maxWidth: 900 }}>
        <div className="d-flex f-row align-center justify-start f-wrap mt-4 mb-5">
          <RadioButtons
            setValue={(value) => setFormData((data) => ({ ...data, extranetAgencyContactSubjectId: value }))}
            value={formData.extranetAgencyContactSubjectId}
            options={extranetAgencyContactSubjects?.data?.data?.extranetAgencyContactSubjects?.map((sub) => ({
              id: sub.id,
              code: sub.name,
            }))}
          />
        </div>
        <div className="mb-4">
          <h5 className="m-0 mb-2 medium-weight">{strings.contactContract}</h5>
          <SelectComponent
            value={agencyContractOptions.find((contract) => contract.value === formData.agencyContractId)}
            data={agencyContractOptions}
            name="month-select"
            setValue={(contract) => setFormData((data) => ({ ...data, agencyContractId: contract.value }))}
            theme="light"
            isLoading={false}
          />
        </div>
        <div className="mb-4">
          <TextAreaComponent
            onChange={(value) => setFormData((data) => ({ ...data, message: value }))}
            value={formData.message}
            id="text-area-message"
            label={strings.contactTextAreaPlaceholder}
          />
        </div>
        <div className="d-flex f-column justify-start f-wrap">
          <h5 className="m-0 medium-weight">{strings.contactAvailability}</h5>
          <div className="mb-1 mt-3">
            <CheckboxComponent
              value={formData.isAvailableInTheMorning}
              handleChange={() => {
                setFormData((data) => ({ ...data, isAvailableInTheMorning: !data.isAvailableInTheMorning }));
              }}
              label={(
                <span className="grey-400-text medium-text normal-weight">
                  {strings.contactMorning}
                </span>
            )}
            />
          </div>
          <div className="mv-1">
            <CheckboxComponent
              value={formData.isAvailableInTheAfternoon}
              handleChange={() => {
                setFormData((data) => ({ ...data, isAvailableInTheAfternoon: !data.isAvailableInTheAfternoon }));
              }}
              label={(
                <span className="grey-400-text medium-text normal-weight">
                  {strings.contactNoon}
                </span>
            )}
            />
          </div>
          <div className="mv-1">
            <CheckboxComponent
              value={formData.isAvailableAtMidday}
              handleChange={() => {
                setFormData((data) => ({ ...data, isAvailableAtMidday: !data.isAvailableAtMidday }));
              }}
              label={(
                <span className="grey-400-text medium-text normal-weight">
                  {strings.contactAfternoon}
                </span>
            )}
            />
          </div>
          <div className="mv-1">
            <CheckboxComponent
              value={formData.isAvailableAllDay}
              handleChange={() => {
                setFormData((data) => ({ ...data, isAvailableAllDay: !data.isAvailableAllDay }));
              }}
              label={(
                <span className="grey-400-text medium-text normal-weight">
                  {strings.contactAllDay}
                </span>
            )}
            />
          </div>
        </div>
        <div className="d-flex f-row justify-center mt-4">
          <ButtonComponent
            onClick={handleSendClick}
          >
            <div className="m-2">
              {strings.send}
            </div>
          </ButtonComponent>
        </div>
        <p className="small-text grey-400-text ph-3 mt-4">
          <span className="mr-1">{strings.contactRGPD}</span>
          <a
            className="underline"
            href={`https://${process.env.REACT_APP_SITE_DOMAIN}/politique-de-confidentialite`}
          >
            <span>{strings.rgpd.privacyPolicy}</span>
          </a>
        </p>
      </div>
    </ModalTemplate>
  );
};

ContactModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  source: PropTypes.string,
  defaultSubject: PropTypes.number,
  defaultContract: PropTypes.number,
};

ContactModal.defaultProps = {
  source: 'EXTRANET',
  defaultSubject: null,
  defaultContract: null,
};

export default ContactModal;
