import React, { useEffect, useState } from 'react';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import { useMutation } from 'react-query';
// Hooks and services
import jwtDecode from 'jwt-decode';
import useIntranetSpacing from '../../hooks/useIntranetSpacing';
import { useAppContext } from '../../store/AppContext';
import UserService from '../../services/UserService';
import useIsMobile from '../../hooks/useIsMobile';
// Components
import TemplateMenuHeaderClient from '../../components/Organisms/TemplateMenuHeaderClient';
// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';
// Utils
import {
  validateEmailField,
  validateFieldPassword,
  validatePasswordConfirmation,
} from '../../utils/validators';
import utils from '../../utils/utils';
import AuthService from '../../services/AuthService';

const PersonalInfo = () => {
  const [{ user }, appDispatch] = useAppContext();

  const [email, setEmail] = useState(user.email_address);
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordBis, setPasswordBis] = useState('');
  const [passwordBisError, setPasswordBisError] = useState(false);

  const contentSpacing = useIntranetSpacing();
  const [isMobile] = useIsMobile();
  const parameters = utils.useQueryParameters();

  useEffect(() => {
    appDispatch({
      type: 'SET_SHOW_EXTRANET_BACK_BUTTON',
      payload: true,
    });
  }, []);

  // Queries
  const refreshTokenAndSave = () => AuthService.refreshToken()
    .then(({ data: { accessToken, refreshToken } }) => {
      appDispatch({ type: 'SET_AUTH', payload: accessToken });
      appDispatch({ type: 'SET_REFRESH', payload: refreshToken });
      appDispatch({ type: 'SET_USER', payload: jwtDecode(accessToken) });
      setEmail(jwtDecode(accessToken)?.email_address);
      return accessToken;
    });
  const changeEmail = useMutation('changeEmail',
    (newEmail) => UserService.changeClientEmail(newEmail));
  const changePassword = useMutation('changePassword',
    (newPassword) => UserService.changeClientPassword(newPassword));

  // Methods
  const submitEmail = () => {
    if (emailError) {
      return false;
    }
    return changeEmail.mutate(email);
  };

  const submitPassword = () => {
    if (passwordError || passwordBisError) {
      return false;
    }
    return changePassword.mutate(password);
  };

  useEffect(() => {
    refreshTokenAndSave();
  }, []);

  useEffect(() => {
    if (changeEmail.isSuccess) {
      const message = changeEmail?.data?.status === 204
        ? strings.success.changeEmail : strings.success.changeEmailVerification;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: message, isSuccess: true, dontHide: changeEmail?.data?.status === 202,
        },
      });
    }
    if (changeEmail.isError) {
      const errorMessage = changeEmail.error.response.data.code === 'same_email'
        ? strings.errors.setEmailNoChange : strings.errors.setEmail;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: errorMessage, isSuccess: false,
        },
      });
    }
  }, [changeEmail.isSuccess, changeEmail.isError]);

  useEffect(() => {
    if (changePassword.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.changePassword, isSuccess: true,
        },
      });
    }
    if (changePassword.isError) {
      const errorMessage = changePassword.error.response.data.code === 'validation_failed'
        ? changePassword.error.response.data.errors.password[0] : strings.errors.setPwd;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: errorMessage, isSuccess: false,
        },
      });
    }
  }, [changePassword.isSuccess, changePassword.isError]);

  return (
    <TemplateMenuHeaderClient>
      <div className={isMobile ? 'mh-3' : 'mh-5'} style={{ paddingLeft: contentSpacing }}>
        <h1 className="medium-weight grey-800-text">
          {strings.editMyPersonalInfo}
        </h1>
        {parameters.get('emailModified')
          ? <p className="h6 green-text medium-weight">{strings.success.changeEmail}</p>
          : null}
        <div className="white-background pv-3 mv-3 d-flex f-row f-wrap align-center width-fit-content">
          <div className="form-input-width m-3">
            <InputComponent
              onChange={setEmail}
              id="my-email"
              value={email}
              label={strings.mail}
              isError={emailError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateEmailField(setEmailError, email)}
            />
          </div>
          <div className="m-3">
            <ButtonComponent onClick={submitEmail}>
              <div className="mh-3 mv-2 align-center">
                {changeEmail.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="white-text uppercase bold">{strings.modify}</span>}
              </div>
            </ButtonComponent>
          </div>
        </div>
        <div className="white-background pv-3 mv-3 d-flex f-row f-wrap align-center width-fit-content">
          <div className="d-flex f-column">
            <div className="form-input-width m-3">
              <InputComponent
                onChange={setPassword}
                type="password"
                id="my-password"
                value={password}
                label={strings.password}
                isError={passwordError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => validateFieldPassword(setPasswordError, password)}
              />
            </div>
            <div className="form-input-width m-3">
              <InputComponent
                onChange={setPasswordBis}
                type="password"
                id="my-password-bis"
                value={passwordBis}
                label={strings.confirmation}
                isError={passwordBisError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => validatePasswordConfirmation(setPasswordBisError, passwordBis, password)}
              />
            </div>
          </div>
          <div className="m-3">
            <ButtonComponent onClick={submitPassword}>
              <div className="mh-3 mv-2 align-center">
                {changePassword.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="white-text uppercase bold">{strings.modify}</span>}
              </div>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </TemplateMenuHeaderClient>
  );
};

export default PersonalInfo;
