import React, { useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';

// Services
import client from '../services/client';
import AuthService from '../services/AuthService';
import clientRPC from '../services/clientRPC';
// Reducers
import appReducer from './appReducer';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const auth = localStorage.getItem('auth') === 'undefined' ? '' : localStorage.getItem('auth') || '';

const initStore = {
  auth,
  user: auth ? jwtDecode(auth) : null,
  refresh: localStorage.getItem('refresh') === 'undefined' ? '' : localStorage.getItem('refresh') || '',
  popover: { isOpen: false, title: '', isSuccess: true },
  currentAgency: localStorage.getItem('agency') === 'undefined' ? '' : localStorage.getItem('agency') || '',
  clientAgencies: localStorage.getItem('allAgencies') === 'undefined' ? '' : localStorage.getItem('allAgencies') || '',
  agencyName: '',
  forums: [],
  docBases: [],
  permissions: localStorage.getItem('permissions') === 'undefined' ? '' : localStorage.getItem('permissions') || '',
  loginAs: localStorage.getItem('loginAs') === 'undefined' ? '' : localStorage.getItem('loginAs') || '',
  showExtranetBackButton: false,
  previousSearch: '',
  previousCategory: null,
  previousDocBasePage: 1,
  roles: [],
};

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initStore);

  useEffect(() => {
    localStorage.setItem('auth', state.auth);
    localStorage.setItem('refresh', state.refresh);
    localStorage.setItem('agency', state.currentAgency);
    localStorage.setItem('allAgencies', state.clientAgencies);
    localStorage.setItem('permissions', state.permissions);
    localStorage.setItem('loginAs', state.loginAs);
  }, [state]);

  // Axios interceptor to add token in header if we have it
  client.interceptors.request.use((config) => {
    // Add bearer in headers
    if (state.auth && !config.headers['X-Authorization'] && config.url !== '/auth/refresh') {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        'X-Authorization': `Bearer ${state.auth}`,
      };
      if (state.loginAs) {
        if (JSON.parse(state.loginAs)?.id) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            ...config.headers,
            'X-Impersonate-Adherent-Admin-Id': JSON.parse(state.loginAs).id,
          };
        } else { // ... and remove it if we haven't
          const { 'X-Impersonate-Adherent-Admin-Id': loginAsId, ...args } = config.headers;
          // eslint-disable-next-line no-param-reassign
          config.headers = args;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error));

  // Axios interceptor to add token in header if we have it for rpc client
  clientRPC.interceptors.request.use((config) => {
    // Add bearer in headers
    if (state.auth && !config.headers['X-Authorization'] && config.url !== '/auth/refresh') {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        'X-Authorization': `Bearer ${state.auth}`,
      };
      if (state.loginAs) {
        if (JSON.parse(state.loginAs)?.id) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            ...config.headers,
            'X-Impersonate-Adherent-Admin-Id': JSON.parse(state.loginAs).id,
          };
        } else { // ... and remove it if we haven't
          const { 'X-Impersonate-Adherent-Admin-Id': loginAsId, ...args } = config.headers;
          // eslint-disable-next-line no-param-reassign
          config.headers = args;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error));

  // Refresh token
  const refreshTokenAndSave = () => AuthService.refreshToken()
    .then(({ data: { accessToken, refreshToken } }) => {
      dispatch({ type: 'SET_AUTH', payload: accessToken });
      dispatch({ type: 'SET_REFRESH', payload: refreshToken });
      dispatch({ type: 'SET_USER', payload: jwtDecode(accessToken) });
      return accessToken;
    });

  // axios response interceptor to refresh token and retry call when 401 is received
  client.interceptors.response.use((response) => response, (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 400 && error.response.config.url === '/auth/refresh') {
      dispatch({ type: 'SET_AUTH', payload: '' });
      dispatch({ type: 'SET_REFRESH', payload: '' });
    }
    if (status === 401) {
      return refreshTokenAndSave().then((accessToken) => {
        // eslint-disable-next-line no-param-reassign
        error.config.headers = {
          ...error.config.headers,
          'X-Authorization': `Bearer ${accessToken}`,
        };
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined;
        return client.request(error.config);
      })
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(error);
  });

  // axios response interceptor to refresh token and retry call when 401 is received for rpc client
  clientRPC.interceptors.response.use((response) => response, (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 400 && error.response.config.url === '/auth/refresh') {
      dispatch({ type: 'SET_AUTH', payload: '' });
      dispatch({ type: 'SET_REFRESH', payload: '' });
    }
    if (status === 401) {
      return refreshTokenAndSave().then((accessToken) => {
        // eslint-disable-next-line no-param-reassign
        error.config.headers = {
          ...error.config.headers,
          'X-Authorization': `Bearer ${accessToken}`,
        };
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined;
        return clientRPC.request(error.config);
      })
        .catch((err) => Promise.reject(err));
    }
    return Promise.reject(error);
  });

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const useAppContext = () => [useContext(AppStateContext), useContext(AppDispatchContext)];

export {
  AppProvider,
  AppStateContext,
  AppDispatchContext,
  useAppContext,
};
