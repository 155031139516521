import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../utils/validators';

// Components
import FileInputWithAcceptAndSize from '../atoms/FileInputWithAcceptAndSize';

const ApplicationForm = ({
  onSubmit, isLoading, initValues, onCancel,
}) => {
  // form related states
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(null);

  useEffect(() => {
    if (initValues) {
      setTitle(initValues?.title);
      setUrl(initValues?.url);
      setFileId(initValues?.illustrationFileId);
    }
  }, [initValues]);

  const handleSubmit = () => onSubmit({
    file,
    fileId,
    url,
    title,
    hasError: titleError || urlError || (!file && !fileId),
  });

  return (
    <div className="white-background max-list-container-width full-width">
      <div className="p-4">
        <div
          className="d-flex f-column align-center full-width"
        >
          <div
            className="mv-3 form-input-width"
          >
            <InputComponent
              onChange={setTitle}
              id="application-title"
              value={title}
              label={strings.title}
              isError={titleError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setTitleError, title)}
            />
          </div>
          <div
            className="mv-3 form-input-width"
          >
            <InputComponent
              onChange={setUrl}
              id="application-url"
              value={url}
              label={strings.url}
              placeholder={strings.urlExample}
              isError={urlError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setUrlError, url)}
            />
          </div>
          <div className="form-file-input-width mv-3">
            <FileInputWithAcceptAndSize
              setFile={(newFile) => {
                setFile(newFile);
                setFileId(null);
              }}
              file={file}
              canDelete={file || fileId}
              label={fileId ? strings.changeFile : strings.file}
              fileType={11}
              initName={strings.changeFile}
            />
          </div>

          {
            !title
            || !url
            || (!file && !fileId)
              ? null
              : (
                <div className="w-fit-content mt-3 d-flex align-center f-wrap">
                  <div className="mr-5 mb-3">
                    <ButtonComponent onClick={handleSubmit}>
                      <div className="mv-2">
                        {isLoading
                          ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                          : <span className="m-5 normal-weight">{strings.publish}</span>}
                      </div>
                    </ButtonComponent>
                  </div>
                  {
                    onCancel
                      ? (
                        <div className="mb-3">
                          <ButtonComponent onClick={onCancel} theme="grey">
                            <span className="m-5 normal-weight">{strings.cancel}</span>
                          </ButtonComponent>
                        </div>
                      ) : null
                  }
                </div>
              )
            }
        </div>
      </div>
    </div>
  );
};

ApplicationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  initValues: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    illustrationFileId: PropTypes.number,
  }),
};

ApplicationForm.defaultProps = {
  initValues: null,
  onCancel: null,
};

export default ApplicationForm;
