import React from 'react';

import TemplateWithMenuAndHeader from '../../components/Organisms/TemplateWithMenuAndHeader';
import AdherentDashboard from '../../components/molecules/AdherentDashboard';

const HomeAdmin = () => (
  <TemplateWithMenuAndHeader>
    <AdherentDashboard />
  </TemplateWithMenuAndHeader>
);

export default HomeAdmin;
