/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback } from 'react';
import PropTypes from 'prop-types';
// Hooks
import usePagination, { DOTS } from './usePagination';

import cn from '../../../utils/cn';

// Constants
export const PAGINATION_DEFAULT_NUMBER = 1;
export const PAGINATION_DEFAULT_SIZE = 30;

export function Pagination({
  pageSize,
  pageNumber,
  onPageNumberChange,
  recordsCount,
  siblingCount,
  className,
  countLabelCallback,
  breakLine,
}) {
  const paginationRange = usePagination({
    pageNumber,
    recordsCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    onPageNumberChange({ number: pageNumber + 1, size: pageSize });
  };

  const onPrevious = () => {
    onPageNumberChange({ number: pageNumber - 1, size: pageSize });
  };

  const renderDescription = useCallback(() => {
    const pageNumberMin = pageNumber === 1 ? 1 : (pageNumber - 1) * pageSize;
    const pageNumberMax = pageNumber * pageSize;
    const count = recordsCount > pageNumberMax ? recordsCount : pageNumberMax;

    return countLabelCallback(pageNumberMin, pageNumberMax, count);
  }, [pageNumber, pageSize, recordsCount]);

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={cn(['pagination', className, 'ui-pagination'])}>
      <div className={cn(['paginationResult', 'ui-pagination__result'])}>
        <ul
          className={cn([
            'paginationContainer', 'ui-pagination__container',
          ])}
        >
          <li
            className={cn([
              'paginationItem', pageNumber === 1 && 'disabled', 'ui-pagination__previous',
            ])}
            onClick={onPrevious}
          >
            Précédent
          </li>

          {paginationRange.map((rangeNumber) => {
            if (rangeNumber === DOTS) {
              return (
                <li
                  className={cn(['paginationItem', 'dots', 'ui-pagination__dots'])}
                  key="paginationBreakLine"
                >
                  {breakLine}
                </li>
              );
            }

            return (
              <li
                key={`pagination-${rangeNumber}`}
                className={
                  cn(['paginationItem', rangeNumber === pageNumber && 'selected', 'ui-pagination__item',
                  ])
}
                onClick={() => onPageNumberChange({ number: rangeNumber, size: pageSize })}
              >
                {rangeNumber}
              </li>
            );
          })}

          <li
            className={cn([
              'paginationItem', pageNumber === lastPage && 'disabled', 'ui-pagination__next',
            ])}
            onClick={onNext}
          >
            Suivant
          </li>
        </ul>

        <span className={cn(['description', 'ui-pagination__description'])}>
          {renderDescription()}
        </span>
      </div>
    </div>
  );
}

export default Pagination;

Pagination.propTypes = {
  pageSize: PropTypes.number,
  pageNumber: PropTypes.number.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  recordsCount: PropTypes.number,
  siblingCount: PropTypes.number,
  breakLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  countLabelCallback: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: PAGINATION_DEFAULT_SIZE,
  recordsCount: 1,
  siblingCount: 1,
  breakLine: '...',
  className: '',
  countLabelCallback: () => '',
};
