import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { InputFileComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import FileService from '../../services/FileService';
import colors from '../../constants/colors';
import strings from '../../constants/strings';

const FileInputWithAcceptAndSize = ({
  file, setFile, canDelete, label, initName, fileType,
}) => {
  const [accept, setAccept] = useState(null);
  const [maxSize, setMaxSize] = useState(null);

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery('getFileType',
    () => FileService.getFileTypes());

  useEffect(() => {
    if (isSuccess) {
      const type = data?.data?.fileTypes.find(({ fileTypeId }) => fileTypeId === fileType);
      setAccept(type?.allowedMimeTypes.join(', '));
      setMaxSize(type?.maxSize);
    }
  }, [isSuccess, data, isError]);
  return (
    isLoading || isError
      ? <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />
      : (
        <>
          <InputFileComponent
            file={file}
            setFile={setFile}
            label={label}
            initName={initName}
            accept={accept}
            maxSize={maxSize}
          />
          {canDelete ? (
            <div
              onClick={() => setFile(null)}
              role="button"
              tabIndex={0}
              onKeyDown={null}
              className="grey-500-text very-small-text text-align-right pointer pt-1"
            >
              {strings.delete}
            </div>
          ) : null }
        </>
      )
  );
};

FileInputWithAcceptAndSize.propTypes = {
  file: PropTypes.shape({}),
  setFile: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  label: PropTypes.string.isRequired,
  initName: PropTypes.string,
  fileType: PropTypes.number.isRequired,
};

FileInputWithAcceptAndSize.defaultProps = {
  file: null,
  canDelete: false,
  initName: '',
};
export default FileInputWithAcceptAndSize;
