import React, { useEffect, useState } from 'react';

// Hooks
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from '../../../store/AppContext';

// Components
import UserFormComponent from '../../../components/Forms/UserFormComponent';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Constants
import strings from '../../../constants/strings';

// Utils
import utils from '../../../utils/utils';

// Services
import AdherentAdminService from '../../../services/AdherentAdminService';
import FileService from '../../../services/FileService';

const EditAdherentAdmin = () => {
  const [firstname, setFirstname] = useState('');
  const [name, setName] = useState('');
  const [job, setJob] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [avatarId, setAvatarId] = useState(null);
  const [profile, setProfile] = useState({});
  const [profileId, setProfileId] = useState(null);
  const [isHidden, setIsHidden] = useState(true);

  // Navigation
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  // Queries
  const createAdherentAdmin = useMutation('createAdherentAdmin',
    async (createAdherentAdminData) => {
      if (avatar) {
        const uploadedFile = await FileService.addFile({ binary: avatar, typeId: 1 });
        setAvatarId(uploadedFile.data.id);
        return AdherentAdminService.createAdherentAdmins({
          adherentAdmin: {
            ...createAdherentAdminData,
            avatarFileId: uploadedFile.data.id,
          },
        });
      }
      return AdherentAdminService.createAdherentAdmins({ adherentAdmin: createAdherentAdminData });
    });
  const updateAdherentAdmin = useMutation('updateAdherentAdmin',
    async (updateAdherentAdminData) => {
      if (avatar) {
        const uploadedFile = await FileService.addFile({ binary: avatar, typeId: 1 });
        setAvatarId(uploadedFile.data.id);
        return AdherentAdminService.updateAdherentAdmins({
          adherentAdmin: {
            ...updateAdherentAdminData,
            avatarFileId: uploadedFile.data.id,
          },
        });
      }
      return AdherentAdminService.updateAdherentAdmins({ adherentAdmin: updateAdherentAdminData });
    });

  const {
    isSuccess, data, isError,
  } = useQuery('getAdherentAdmin', () => {
    if (parameters.get('id')) {
      return AdherentAdminService.getAdherentAdmin({ id: parameters.get('id') });
    }
    return null;
  });

  // Store
  const [, appDispatch] = useAppContext();

  // Methods

  const updateUser = () => {
    updateAdherentAdmin.mutate({
      ...data?.data,
      emailAddress: mail,
      firstName: firstname,
      lastName: name,
      position: job,
      phone,
      roleId: profile?.value,
      avatarFileId: avatarId,
      isHidden,
    });
  };

  const createUser = () => {
    createAdherentAdmin.mutate({
      adherentId: parameters.get('adherent-id'),
      emailAddress: mail,
      firstName: firstname,
      lastName: name,
      position: job,
      phone,
      roleId: profile.value,
      avatarFileId: avatarId,
      isHidden,
    });
  };

  const onSubmit = () => {
    if (!parameters.get('id')) {
      return createUser();
    }
    return updateUser(avatarId);
  };

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setFirstname(data.data.firstName);
      setName(data.data.lastName);
      setJob(data.data.position);
      setPhone(data.data.phone);
      setMail(data.data.emailAddress);
      setAvatar(null);
      setAvatarId(data.data.avatarFileId);
      setProfileId(data.data.roleId);
      setIsHidden(data.data.isHidden);
    }
    if (isError && parameters.get('id')) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.userLoading, isSuccess: false },
      });
      history.push(`/intranet/adherent?id=${parameters.get('adherent-id')}`);
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (
      createAdherentAdmin.isSuccess
      || updateAdherentAdmin.isSuccess) {
      history.push(`/intranet/adherent?id=${parameters.get('adherent-id')}`);
    }
    if (updateAdherentAdmin.isError) {
      const toDisplay = Object.values(updateAdherentAdmin?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateUser, isSuccess: false },
      });
    }
    if (createAdherentAdmin.isError) {
      const toDisplay = Object.values(createAdherentAdmin?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createUser, isSuccess: false },
      });
    }
  }, [
    createAdherentAdmin.isSuccess,
    updateAdherentAdmin.isSuccess,
    createAdherentAdmin.isError,
    updateAdherentAdmin.isError]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/adherents">
          <h3 className="mr-3 mv-1">{strings.adherents}</h3>
        </Link>
        <Link to={`/intranet/adherent?id=${parameters.get('adherent-id')}`}>
          <h3 className="mr-3 mv-1 text-align-left">{` > ${parameters.get('adherent')} `}</h3>
        </Link>
        <h3
          className="mh-0 mv-1 text-align-left"
        >
          {` > ${data?.data?.lastName || strings.newUser} ${data?.data?.firstName || ''}`}
        </h3>
      </div>
      <UserFormComponent
        setMail={setMail}
        profile={profile}
        profileId={profileId}
        setProfileId={setProfileId}
        phone={phone}
        setPhone={setPhone}
        submit={onSubmit}
        setProfile={setProfile}
        firstname={firstname}
        name={name}
        job={job}
        setName={setName}
        setFirstname={setFirstname}
        mail={mail}
        setJob={setJob}
        hasAvatar
        avatar={avatar}
        avatarId={avatarId}
        setAvatar={(file) => {
          setAvatar(file);
          setAvatarId(null);
        }}
        isHidden={isHidden}
        setIsHidden={setIsHidden}
        adminType={2}
        isLoading={createAdherentAdmin?.isLoading || updateAdherentAdmin?.isLoading}
      />
    </TemplateWithMenuAndHeader>
  );
};

export default EditAdherentAdmin;
