import client from './client';

const getExtranetAgencyContactSubjects = () => client.get('/extranet-agency-contact-subjects');

const sendExtranetAgencyContacts = (data) => client.post('/extranet-agency-contacts', data);

export default {
  getExtranetAgencyContactSubjects,
  sendExtranetAgencyContacts,
};
