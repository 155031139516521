import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  subMonths, getMonth, getYear, format,
} from 'date-fns';
import { useQuery, useMutation } from 'react-query';
import fr from 'date-fns/locale/fr';
import {
  SelectComponent, LoaderComponent, InputComponent, ButtonComponent,
} from '@zolteam/axenergie-ui-library';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Service
import MonthlyResultService from '../../../services/MonthlyResultService';
import PrestationService from '../../../services/PrestationService';

// Utils
import { swap } from '../../../utils/array';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Images
import arrow from '../../../assets/images/arrow-down.svg';
import utils from '../../../utils/utils';

const previousMonthDate = subMonths(new Date(), 1);
const getPeriods = () => {
  const lastMonth = getMonth(previousMonthDate);
  const periodsData = [];

  for (let i = 0; i < lastMonth + 1; i += 1) {
    periodsData.push({
      label: format(subMonths(new Date(), lastMonth - (i - 1)), 'MMMM yyyy', { locale: fr }),
      value: {
        month: ((lastMonth + i) - lastMonth) + 1,
        year: getYear(previousMonthDate),
      },
    });
  }
  return periodsData.reverse();
};

const MonthlyResult = () => {
  const [{ permissions, currentAgency }, appDispatch] = useAppContext();
  const periods = getPeriods();
  const [periodSelected, setPeriodSelected] = useState(periods[0]);

  const [isEditing, setIsEditing] = useState(false);
  const [monthlyResultData, setMonthlyResultData] = useState(null);

  const parameters = utils.useQueryParameters();

  // Queries
  const createMonthlyResult = useMutation('create-monthly-result',
    (createData) => MonthlyResultService.createMonthlyResult(createData));
  const updateMonthlyResult = useMutation('update-monthly-result',
    ({ id, payload }) => MonthlyResultService.updateMonthlyResult(id, payload));

  const getMonthlyResults = useQuery(
    ['get-monthly-results',
      currentAgency, createMonthlyResult.isSuccess, updateMonthlyResult.isSuccess, periodSelected], () => {
      if (currentAgency !== null) {
        return MonthlyResultService.getMonthlyResults({ agencyId: currentAgency });
      }
      return null;
    },
  );
  const getMonthlyResultEquipment = useQuery(['get-monthly-result-equipment'],
    () => MonthlyResultService.getMonthlyResultEquipments());
  const getBrands = useQuery(['get-brands'], () => PrestationService.getBrands());
  const getEquipment = useQuery(['get-equipment', currentAgency],
    () => PrestationService.getEquipmentTypes());

  useEffect(() => {
    const defaultMonth = parameters.get('month');
    if (defaultMonth) {
      setPeriodSelected(periods.find((period) => period.value.month === parseInt(defaultMonth, 10)));
    }
  }, [parameters.get('month')]);

  useEffect(() => {
    if (getMonthlyResults.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getMonthlyResults, isSuccess: false,
        },
      });
    }
  }, [getMonthlyResults.isError]);

  useEffect(() => {
    const { isError } = getBrands;

    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getEquipment, isSuccess: false,
        },
      });
    }
  }, [getBrands.isError]);

  useEffect(() => {
    const { isError } = getEquipment;

    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getEquipment, isSuccess: false,
        },
      });
    }
  }, [getEquipment.isError]);

  useEffect(() => {
    const { isError } = getMonthlyResultEquipment;

    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.getEquipment, isSuccess: false,
        },
      });
    }
  }, [getMonthlyResultEquipment.isError]);

  useEffect(() => {
    if (updateMonthlyResult.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.updateMonthlyResult, isSuccess: true,
        },
      });
    }
    if (updateMonthlyResult.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.updateMonthlyResult, isSuccess: false,
        },
      });
    }
  }, [updateMonthlyResult.isSuccess, updateMonthlyResult.isError]);

  useEffect(() => {
    if (createMonthlyResult.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.createMonthlyResult, isSuccess: true,
        },
      });
    }
    if (createMonthlyResult.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.createMonthlyResult, isSuccess: false,
        },
      });
    }
  }, [createMonthlyResult.isSuccess, createMonthlyResult.isError]);

  useEffect(() => {
    if (
      periodSelected
        && getMonthlyResults.isSuccess
        && getMonthlyResults.data
        && getBrands.isSuccess
        && getEquipment.isSuccess
        && getMonthlyResultEquipment.isSuccess
    ) {
      const monthResult = getMonthlyResults?.data?.data?.monthlyResults?.find((month) => {
        if (month.month === periodSelected.value.month
          && month.year === periodSelected.value.year) {
          return true;
        }
        return false;
      });

      const equipmentSales = getMonthlyResultEquipment?.data?.data?.monthltyResultEquipments
        .filter((equipment) => equipment.hasSales);
      const equipmentContracts = getMonthlyResultEquipment?.data?.data?.monthltyResultEquipments
        .filter((equipment) => equipment.hasContracts);
      const equipmentCommissionings = getMonthlyResultEquipment?.data?.data?.monthltyResultEquipments
        .filter((equipment) => equipment.hasCommissionings);

      if (!monthResult) {
        setMonthlyResultData({
          agencyId: currentAgency || undefined,
          year: getYear(previousMonthDate),
          month: getMonth(previousMonthDate),
          brandsValues: getBrands?.data?.data?.brands?.map((brand) => ({ brandId: brand.id, value: 0 })),
          equipmentSales: equipmentSales
            .map((equipment) => ({
              equipmentId: equipment.id,
              value: 0,
            })),
          equipmentContracts: equipmentContracts
            .map((equipment) => ({
              equipmentId: equipment.id,
              value: 0,
            })),
          equipmentCommissionings: equipmentCommissionings
            .map((equipment) => ({
              equipmentId: equipment.id,
              value: 0,
            })),
        });
        setIsEditing(false);
      } else {
        const previousResults = monthResult || [];
        const monthResultBrands = monthResult?.brandsValues?.length ? monthResult?.brandsValues : [];

        setMonthlyResultData({
          ...previousResults,
          brandsValues: [
            ...monthResultBrands,
            ...getBrands?.data?.data?.brands?.filter((brand) => {
              const found = monthResult.brandsValues.find((b) => b.brandId === brand.id);
              return (found === undefined);
            }).map((brand) => ({ brandId: brand.id, value: 0 })),
          ],
          equipmentSales: [...monthResult.equipmentSales, ...equipmentSales?.filter((equipment) => {
            const found = monthResult.equipmentSales.find((e) => e.equipmentId === equipment.id);
            return (found === undefined);
          }).map((e) => ({ equipmentId: e.id, value: 0 }))],

          equipmentContracts: [...monthResult.equipmentContracts, ...equipmentContracts?.filter((equipment) => {
            const found = monthResult.equipmentContracts.find((e) => e.equipmentId === equipment.id);
            return (found === undefined);
          }).map((e) => ({ equipmentId: e.id, value: 0 }))],

          equipmentCommissionings: [
            ...monthResult.equipmentCommissionings,
            ...equipmentCommissionings?.filter((equipment) => {
              const found = monthResult.equipmentCommissionings.find((e) => e.equipmentId === equipment.id);
              return (found === undefined);
            }).map((e) => ({ equipmentId: e.id, value: 0 }))],

        });
        setIsEditing(true);
      }
    }
  }, [
    getMonthlyResults.isSuccess,
    getMonthlyResults.data,
    periodSelected,
    getBrands.isSuccess,
    getEquipment.isSuccess,
  ]);

  const handleResultChange = (section, id, entry) => {
    if (monthlyResultData[section]) {
      const foundIndex = monthlyResultData[section].findIndex((result) => result[id] === entry[id]);
      if (foundIndex !== -1) {
        const updatedResults = monthlyResultData[section].slice(0);
        if (entry.value === 0) updatedResults.splice(foundIndex, 1, { ...entry, value: 0 });
        else updatedResults.splice(foundIndex, 1, entry);
        setMonthlyResultData({ ...monthlyResultData, [section]: updatedResults });
      } else {
        setMonthlyResultData({ ...monthlyResultData, [section]: [...monthlyResultData[section], entry] });
      }
    } else {
      setMonthlyResultData({ ...monthlyResultData, [section]: [entry] });
    }
  };

  const handleSubmitResult = () => {
    const { id, ...payload } = monthlyResultData;
    if (isEditing) {
      return updateMonthlyResult.mutate({ id, payload: { ...payload, month: periodSelected.value.month } });
    }
    return createMonthlyResult.mutate({ ...payload, month: periodSelected.value.month });
  };

  const renderBrandInputValue = (brand) => monthlyResultData?.brandsValues?.find((b) => b.brandId === brand.id)?.value;

  const getBrandSection = (brandsData) => {
    if (!brandsData) return null;
    const brandIndex = brandsData.findIndex((brand) => brand.name === 'Autres marques');
    const lastIndex = brandsData.length - 1;
    return swap(brandsData, lastIndex, brandIndex);
  };

  const sections = {
    monthlyResults: getMonthlyResults?.data?.data?.monthlyResults || null,
    brands: getBrandSection(getBrands?.data?.data?.brands),
    equipmentTypes: getEquipment?.data?.data?.equipmentTypes || null,

    // replaces equipmentTypes
    monthlyResultEquipments: getMonthlyResultEquipment?.data?.data?.monthltyResultEquipments,
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="mh-3 mt-4 d-flex align-center">
        <Link to="/intranet">
          <ButtonComponent onClick={null} theme="white">
            <div className="d-flex justify-center align-center">
              <img
                alt="back-arrow"
                className="rotate-90"
                src={arrow}
                width={20}
              />
              <span className="normal-weight">{strings.back}</span>
            </div>
          </ButtonComponent>
        </Link>
      </div>
      { permissions?.includes('monthly_result_administration')
        ? (
          <>
            <div className="d-flex f-row m-3 f-wrap">
              <h2 className="mb-5 text-align-left">{strings.monthlyResultHeader}</h2>
            </div>
            { getMonthlyResults.isSuccess && getMonthlyResults.data?.data?.monthlyResults
              ? (
                <>
                  <div className="d-flex f-row m-3 f-wrap">
                    <div className="form-input-width">
                      <SelectComponent
                        value={periodSelected}
                        data={periods}
                        name="user-profile"
                        setValue={setPeriodSelected}
                        theme="dark"
                        isLoading={false}
                        label={strings.monthlyResultSelectPlaceholder}
                      />
                    </div>
                  </div>
                  <div className="d-flex f-row m-3 f-wrap">
                    <div className="white-background max-list-container-width full-width radius-5 ph-4 mt-4">
                      <h4 className="mh-3 primary-red-text">{strings.sav}</h4>
                      <b className="ml-3 f-row">{strings.nbContracts}</b>
                      <div className="mv-3 d-flex f-row f-wrap full-width align-center">
                        {sections.monthlyResultEquipments?.length > 0
                          ? (
                            <>
                              {sections.monthlyResultEquipments
                                .filter((equipment) => equipment.hasContracts)
                                .map((equipment) => (
                                  <div key={`equipment-${equipment.id}`} className="form-figures-input-width m-3">
                                    <InputComponent
                                      type="number"
                                      onChange={(value) => handleResultChange('equipmentContracts', 'equipmentId', {
                                        equipmentId: equipment.id,
                                        value: Number(value),
                                      })}
                                      id={`equipment-contracts-${equipment?.id}-input`}
                                      value={monthlyResultData?.equipmentContracts?.find(
                                        (e) => e.equipmentId === equipment.id,
                                      )?.value}
                                      label={equipment?.name}
                                    />
                                  </div>
                                ))}
                            </>
                          )
                          : null}
                      </div>
                      <b className="ml-3 mt-3 f-row">{strings.newCommissioning}</b>
                      <div className="mt-2 mb-2 d-flex f-row f-wrap full-width align-center">
                        {sections.monthlyResultEquipments?.length > 0
                          ? (
                            <>
                              {sections.monthlyResultEquipments
                                .filter((equipment) => equipment.hasContracts)
                                .map((equipment) => (
                                  <div key={`equipment-${equipment.id}`} className="form-figures-input-width m-3">
                                    <InputComponent
                                      type="number"
                                      // eslint-disable-next-line max-len
                                      onChange={(value) => handleResultChange('equipmentCommissionings', 'equipmentId', {
                                        equipmentId: equipment.id,
                                        value: Number(value),
                                      })}
                                      id={`equipment-contracts-${equipment?.id}-input`}
                                      value={monthlyResultData?.equipmentCommissionings?.find(
                                        (e) => e.equipmentId === equipment.id,
                                      )?.value}
                                      label={equipment?.name}
                                    />
                                  </div>
                                ))}
                            </>
                          )
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex f-row m-3 f-wrap">
                    {sections.brands?.length > 0 ? (
                      <div className="white-background max-list-container-width full-width radius-5 ph-4 mt-4">
                        <h4 className="mh-3 primary-red-text">{strings.brandResult}</h4>
                        <div className="mv-4 d-flex f-row f-wrap full-width align-center">
                          { sections.brands
                            .sort((brand) => (brand.name === 'Autres marques' ? 1 : 0))
                            .map((brand) => (
                              <div key={`brands-${brand.id}`} className="form-input-width m-3">
                                <InputComponent
                                  type="number"
                                  onChange={(value) => handleResultChange('brandsValues', 'brandId', {
                                    brandId: brand.id,
                                    value: Number(value),
                                  })}
                                  id={`brand-${brand.id}-input`}
                                  value={renderBrandInputValue(brand)}
                                  label={brand.name}
                                />
                              </div>
                            )) }
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex f-row m-3 f-wrap">
                    {sections.monthlyResultEquipments?.length > 0 ? (
                      <div className="white-background max-list-container-width full-width radius-5 ph-4 mt-4">
                        <h4 className="mh-3 primary-red-text">{strings.equipmentResult}</h4>
                        <div className="mv-4 d-flex f-row f-wrap full-width align-center">
                          { sections.monthlyResultEquipments
                            .filter((equipment) => equipment.hasSales)
                            .map((equipment) => (
                              <div key={`equipmentTypes-${equipment.id}`} className="form-input-width m-3">
                                <InputComponent
                                  type="number"
                                  onChange={(value) => handleResultChange('equipmentSales', 'equipmentId', {
                                    equipmentId: equipment.id,
                                    value: Number(value),
                                  })}
                                  id={`equipment-${equipment.id}-input`}
                                  value={monthlyResultData?.equipmentSales?.find(
                                    (e) => e.equipmentId === equipment.id,
                                  )?.value}
                                  label={equipment.name === strings.heatPumpFull ? strings.heatPump : equipment.name}
                                />
                              </div>
                            )) }
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex f-row m-3 mt-4 f-wrap">
                    <ButtonComponent onClick={handleSubmitResult}>
                      <div className="mv-2">
                        {
                          (createMonthlyResult?.isLoading || updateMonthlyResult?.isLoading)
                            ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                            : <span className="uppercase m-5">{strings.save}</span>
                        }
                      </div>
                    </ButtonComponent>
                  </div>
                </>
              ) : <LoaderComponent size={20} color={colors.grey800} borderWidth={3} /> }
          </>
        ) : null }
    </TemplateWithMenuAndHeader>
  );
};

export default MonthlyResult;
