import client from './client';

const getAdherentAdmins = ({ adherentId, adminId }) => client.get('/adherent-admins',
  { params: { adherentId, adminId } });
const getAdherentAdmin = ({ id }) => client.get(`/adherent-admins/${id}`);
const updateAdherentAdmins = ({ adherentAdmin }) => client.put(`/adherent-admins/${adherentAdmin.id}`, adherentAdmin);
const createAdherentAdmins = ({ adherentAdmin }) => client.post('/adherent-admins', adherentAdmin);

export default {

  getAdherentAdmins,
  updateAdherentAdmins,
  getAdherentAdmin,
  createAdherentAdmins,
};
