import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useClickAway, useMeasure, useWindowScroll } from 'react-use';
import { useMutation } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
// Images
import { useHistory } from 'react-router-dom';
import { ReactComponent as NotifIcon } from '../../assets/images/notification.svg';

// Constants
import colors from '../../constants/colors';
import strings from '../../constants/strings';

// Services and hooks
import NetworkNewsService from '../../services/NetworkNewsService';
import NotificationService from '../../services/NotificationService';
import { useAppContext } from '../../store/AppContext';
import notificationTypes from '../../utils/notificationTypes';
import useIsMobile from '../../hooks/useIsMobile';

const NotificationComponent = ({ unRead, refresh }) => {
  const [isOpen, setIsOpen] = useState(false);
  // Measures related variables
  const [notifContainerRef, { width: notifContainerWidth }] = useMeasure();
  const panelRef = useRef(null);
  const { y } = useWindowScroll();

  useEffect(() => {
    if (y !== 0) {
      setIsOpen(false);
    }
  }, [y]);

  // hooks
  useClickAway(panelRef, () => setIsOpen(false));
  const [{ user }, appDispatch] = useAppContext();
  const [isMobile,, screenWidth] = useIsMobile();
  const history = useHistory();

  // Queries
  const readOneNotification = useMutation('readOne',
    (id) => NotificationService.readOne(id));
  const readAllNotification = useMutation('readAll',
    () => NotificationService.readAll());
  const deleteAllNotification = useMutation('deleteAll',
    () => NotificationService.deleteAll());
  const getNotifications = useMutation('getAllNotifications',
    () => NotificationService.getMyNotifications());

  const getNotifStyle = (isRead) => `d-flex full-width f-row align-center
  ${isRead ? 'white-background' : 'grey-100-background'}`;
  const getCircleStyle = (isRead) => `small-circle mr-2 ${isRead ? 'grey-400-background' : 'primary-red-background'}`;

  useEffect(() => {
    if (getNotifications.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getNotifications, isSuccess: false },
      });
    }
  }, [getNotifications.isError]);

  useEffect(() => {
    if (readOneNotification.isError || readAllNotification.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateNotifications, isSuccess: false },
      });
    }
    if (readOneNotification.isSuccess || readAllNotification.isSuccess || deleteAllNotification.isSuccess) {
      refresh();
      getNotifications.mutate({});
    }
    if (deleteAllNotification.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteNotifications, isSuccess: false },
      });
    }
  }, [
    readOneNotification.isError,
    readOneNotification.isSuccess,
    readAllNotification.isError,
    readAllNotification.isSuccess,
    deleteAllNotification.isError,
    deleteAllNotification.isSuccess,
  ]);

  return (
    <button
      ref={panelRef}
      type="button"
      onClick={() => {
        setIsOpen((state) => !state);
        getNotifications.mutate({});
      }}
      className={`${isMobile ? 'mh-2' : 'mh-4'} relative fgIndex ${unRead > 0 ? 'grey-800-svg' : 'grey-400-svg'}`}
    >
      <NotifIcon width={24} height={24} />
      {
        unRead > 0 ? (
          <div
            ref={notifContainerRef}
            className="absolute d-flex justify-center align-center
            primary-red-background p-1 very-small-text white-text"
            style={{
              borderRadius: '50%',
              top: -5,
              right: 0,
              height: notifContainerWidth,
              borderColor: colors.white,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            {unRead > 99 ? '99+' : unRead}
          </div>

        ) : null
    }
      {
        isOpen
          ? (
            <div
              className="absolute-right-corner grey-800-background radius-5 popin-shadow fgIndex"
              style={{
                position: isMobile ? 'fixed' : 'absolute',
                maxWidth: screenWidth - 90,
                width: 380,
              }}
            >
              <div className="d-flex f-row align-center p-3 f-wrap">
                <h6 className="white-text medium-weight m-0 mr-5">{strings.notifications}</h6>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    readAllNotification.mutate({});
                    refresh();
                  }}
                  className="p-0"
                >
                  <span className="medium-text normal-weight primary-red-text ws-nowrap">
                    {strings.markAllAsRead}
                  </span>
                </button>
              </div>
              <div>
                <div
                  className="d-flex f-column full-width full-height"
                  style={{
                    overflow: 'auto',
                    maxHeight: 380,
                  }}
                >
                  { getNotifications.isLoading || getNotifications.isError
                    ? (
                      <div className="m-5">
                        <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                      </div>
                    )
                    : getNotifications?.data?.data?.notifications?.map(({
                      type, isRead, id, data,
                    }) => {
                      const notification = notificationTypes.formatNotification({ ...data, type });
                      return (
                        <div key={id}>
                          <button
                            type="button"
                            onClick={async (e) => {
                              e.stopPropagation();
                              readOneNotification.mutate(id);
                              if (type === 'NEW_NETWORK_ACTUALITY') {
                                NetworkNewsService.readNews({
                                  news: data?.networkActualityId, user: user?.sub,
                                });
                              }
                              if (notification?.link) {
                                history.push(notification.link);
                                window.location.reload();
                              }
                            }}
                            className={getNotifStyle(isRead)}
                          >
                            <div className={getCircleStyle(isRead)} />
                            <div className="d-flex f-column justify-start align-start">
                              <div className="d-flex f-row f-wrap justify-start align-start">
                                <span className="medium-text medium-weight grey-800-text mr-2">
                                  {notification.title}
                                </span>
                                <span className="medium-text normal-weight grey-400-text">
                                  {notification.subTitle}
                                </span>
                              </div>
                              <div className="d-flex f-row justify-start align-start">
                                <span className="grey-600-text medium-text normal-weight text-align-left">
                                  {notification.description}
                                </span>
                              </div>
                            </div>
                          </button>
                          <div className="line light" />
                        </div>
                      );
                    })}
                </div>
                <div
                  className="white-background d-flex bottom-radius ph-3 pv-1"
                >
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAllNotification.mutate({});
                      refresh();
                    }}
                  >
                    <span className="medium-text medium-weight primary-red-text ws-nowrap">
                      {strings.emptyList}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ) : null
        }
    </button>
  );
};

NotificationComponent.propTypes = {
  unRead: PropTypes.number,
  refresh: PropTypes.func.isRequired,
};

NotificationComponent.defaultProps = {
  unRead: 0,
};

export default NotificationComponent;
