import client from './client';

/**
 * Get all adherent users
 * @param adherentId
 * @param adminId
 * @param agencyId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAdherentUsers = ({ adherentId, adminId, agencyId }) => client.get('/adherent-users',
  { params: { adherentId, adminId, agencyId } });

/**
 * Update one adherent user
 * @param user
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateAdherentUser = ({ user }) => client.put(`/adherent-users/${user.id}`, user);

/**
 * Create one adherent user
 * @param user
 * @returns {Promise<AxiosResponse<any>>}
 */
const createAdherentUser = ({ user }) => client.post('/adherent-users', user);

/**
 * Get one adherent user info
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAdherentUser = ({ id }) => client.get(`/adherent-users/${id}`);

/**
 * Archive adherent user
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const archiveAdherentUser = ({ id }) => client.post(`/adherent-users/${id}:archive`);

export default {
  getAdherentUsers,
  updateAdherentUser,
  createAdherentUser,
  getAdherentUser,
  archiveAdherentUser,
};
