import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { width: screenWidth } = useWindowSize();

  useEffect(() => {
    if (screenWidth <= 772) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [screenWidth]);

  return [isMobile, setIsMobile, screenWidth];
};

export default useIsMobile;
