import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Switch from 'react-switch';
import { format } from 'date-fns';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';

// Constants
import { useHistory } from 'react-router-dom';
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import DataTable from '../../../components/molecules/DataTable';

// Services and hooks
import useIsMobile from '../../../hooks/useIsMobile';
import JobOfferService from '../../../services/JobOfferService';
import { useAppContext } from '../../../store/AppContext';

// Images
import { ReactComponent as AddCircleIcon } from '../../../assets/images/add-circle.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import ModalComponent from '../../../components/atoms/ModalComponent';

const JobOffers = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [isMobile] = useIsMobile();
  const [{ currentAgency, user }, appDispatch] = useAppContext();
  const history = useHistory();

  // Queries
  const updateOffer = useMutation('updateJobOffer',
    (offer) => JobOfferService.updateJobOffer(offer));

  const deleteOffer = useMutation('deleteJobOffer',
    (id) => JobOfferService.deleteJobOffer(id));

  const isNetwork = useMemo(() => [1, 2, 3].includes(user?.data?.roleId), [user]);

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(['getJobOffers', updateOffer.data, deleteOffer.isSuccess, currentAgency],
    () => JobOfferService.getOffers({
      agencyId: currentAgency || undefined,
      isNetwork: isNetwork ? 'true' : undefined,
    }));

  const renderEditButton = (id) => (
    <button type="button" onClick={() => history.push(`/intranet/job-offer?id=${id}`)}>
      <EditIcon width={30} />
    </button>
  );

  const renderDeleteButton = (id) => (
    <button
      type="button"
      onClick={() => {
        setCurrentOffer(id);
        setIsDeleteModalOpen(true);
      }}
    >
      <DeleteIcon width={30} />
    </button>
  );

  const renderToggleButton = (value, offer) => (
    <Switch
      checked={value}
      onChange={() => updateOffer.mutate({
        ...offer,
        isActive: !offer?.isActive,
        publicationDate: format(new Date(), 'yyyy-MM-dd'),
      })}
      onColor="#8d9bba"
      onHandleColor={colors.primaryRed}
      handleDiameter={25}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      className="react-switch"
      id="disable-admin-switch"
    />
  );

  const renderTag = ({ backgroundColor, color, title }) => (
    <div
      className="ml-3 mr-2 mv-2 radius-20 pv-1 ph-3"
      style={{ backgroundColor }}
    >
      <span
        className="medium-text normal-weight uppercase"
        style={{ color }}
      >
        {title}
      </span>
    </div>
  );

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getJobOffers, isSuccess: false },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (updateOffer.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateJobOffer, isSuccess: false },
      });
    }
  }, [updateOffer.isError]);

  useEffect(() => {
    if (deleteOffer.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteJobOffer, isSuccess: false },
      });
    }
    if (deleteOffer.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteJobOffer, isSuccess: true },
      });
    }
  }, [deleteOffer.isError, deleteOffer.isSuccess]);

  const formatTableData = (offers) => offers.reduce((acc, offer) => [
    ...acc,
    {
      type: renderTag({
        backgroundColor: colors.grey100,
        color: colors.grey800,
        title: offer.jobOfferTypeCode,
      }),
      title: offer.title,
      isTemplate: offer.isTemplate
        ? renderTag({
          backgroundColor: colors.primaryRed,
          color: colors.white,
          title: strings.template,
        }) : ' ',
      date: format(new Date(offer.publicationDate.split(' ').join('T')), 'dd.MM.yyyy'),
      edit: renderEditButton(offer.id),
      delete: renderDeleteButton(offer.id),
      disabled: renderToggleButton(offer.isActive, offer),
    },
  ], []);

  return (
    <TemplateWithMenuAndHeader>
      <div
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
      >
        <div
          className="full-width d-flex f-row f-wrap align-center mt-4"
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{strings.jobOfferTitle}</h2>
            <span className="grey-400-text mb-5">{strings.jobOfferSubtitle}</span>
          </div>
          <div className="mb-3">
            <ButtonComponent onClick={() => history.push('/intranet/job-offer')}>
              <div className="d-flex align-center pv-2 ph-4">
                <span className="ws-nowrap mr-2">{strings.newOffer}</span>
                <AddCircleIcon width={24} height={24} />
              </div>
            </ButtonComponent>
          </div>
        </div>
        <div className="table-container full-width">
          <DataTable
            isLoading={isLoading || isError}
            data={isSuccess ? formatTableData(data?.data?.jobOffers) : []}
            columns={[
              strings.type,
              strings.title,
              ' ',
              strings.publicationDate,
              ' ',
              ' ',
              ' ',
            ]}
          />
        </div>
      </div>
      {
        isDeleteModalOpen && currentOffer ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deleteJobOffer}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (currentOffer) {
                      deleteOffer.mutate(currentOffer);
                    }
                    setIsDeleteModalOpen(false);
                  }}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};
export default JobOffers;
