import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppContext } from '../store/AppContext';
import AdherentUserService from '../services/AdherentUserService';

const useIsMobile = () => {
  const [myUsers, setMyUsers] = useState(null);
  const [{ user, loginAs }] = useAppContext();
  const myRole = useMemo(() => user?.data?.roleId, [user]);
  const myId = useMemo(() => {
    if (loginAs) {
      const { id: adherentId } = JSON.parse(loginAs);
      return adherentId;
    }
    return user?.sub;
  }, [user, loginAs]);

  const { isSuccess, data } = useQuery(['getMyUsers', myId],
    () => {
      if (myRole !== 5 && myRole !== 6) {
        return AdherentUserService.getAdherentUsers({ adminId: myId });
      }
      return false;
    });

  useEffect(() => {
    if (isSuccess && data) {
      setMyUsers(data.data?.adherentUsers?.map(({ id }) => id));
    }
  }, [isSuccess, data]);

  const canEditMessage = (authorId) => {
    if (myRole === 5 || myRole === 6) {
      return authorId === myId;
    }
    if ((myRole === 4 || myRole === 7) && myUsers?.length) {
      return myUsers.includes(authorId) || authorId === myId;
    }
    if (myRole === 1 || myRole === 2 || myRole === 3) {
      return true;
    }
    return false;
  };

  return [canEditMessage];
};

export default useIsMobile;
