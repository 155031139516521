import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import PropTypes from 'prop-types';
// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';
// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TabComponent from '../../../components/molecules/TabComponent';
import DemandItem from '../../../components/molecules/DemandItem';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';
// Hooks and services
import useIsMobile from '../../../hooks/useIsMobile';
import DemandService from '../../../services/DemandService';
import { useAppContext } from '../../../store/AppContext';

const DemandList = ({ contactTypes, title, subtitle }) => {
  const [processed, setProcessed] = useState(false);
  const [search, setSearch] = useState('');

  const [isMobile] = useIsMobile();
  const [{ currentAgency }, appDispatch] = useAppContext();

  // Queries
  const toggleProcessed = useMutation('toggleProcessedDemand',
    ({ id: demandId, isNowProcessed }) => {
      if (isNowProcessed) {
        return DemandService.setAsProcessed(demandId);
      }
      return DemandService.setAsToProcess(demandId);
    });

  const getDemands = useQuery(['getDemands', currentAgency, processed, toggleProcessed.isSuccess],
    () => DemandService.getContacts({
      agencyId: currentAgency || undefined,
      isProcessed: processed,
      search: search || undefined,
      contactTypes,
    }));

  const doSearch = () => getDemands.refetch();

  useEffect(() => {
    if (!search) {
      doSearch();
    }
  }, [search]);

  useEffect(() => {
    if (getDemands.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.demandsLoading, isSuccess: false },
      });
    }
    if (toggleProcessed.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateDemand, isSuccess: false },
      });
    }
  }, [getDemands.isError, toggleProcessed.isError]);

  const renderEmptyList = () => {
    if (getDemands.isLoading
            || getDemands.isError
    ) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noDemand}</span>
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
      >
        <div
          className="d-flex f-row f-wrap justify-between align-center mt-4"
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{title}</h2>
            <span className="grey-400-text mb-5">{subtitle}</span>
          </div>
          <div className="form-input-width mh-3 mv-2">
            <SearchInputComponent
              onChange={setSearch}
              onClick={doSearch}
              id="adherent-search"
              value={search}
            />
          </div>
        </div>
        <TabComponent tabs={[
          {
            onClick: () => setProcessed(false),
            isActive: !processed,
            title: strings.toHandle,
          },
          {
            onClick: () => setProcessed(true),
            isActive: processed,
            title: strings.handledPlural,
          },
        ]}
        />
        <div className="full-width" style={{ maxWidth: 1300 }}>
          {
              !getDemands?.data?.data?.contacts?.length
                ? renderEmptyList()
                : (
                  <div className="d-flex f-column">
                    {
                        getDemands?.data?.data?.contacts?.map(({
                          address,
                          postalCode,
                          city,
                          isClientKnown,
                          data,
                          fullName,
                          emailAddress,
                          creationDate,
                          id,
                          type,
                          isProcessed,
                          phone,
                        }) => (
                          <div key={id} className="mb-3">
                            <DemandItem
                              id={id}
                              address={address}
                              zipcode={postalCode}
                              city={city}
                              isClientKnown={isClientKnown}
                              subject={data?.subject}
                              fullName={fullName}
                              email={emailAddress}
                              type={type}
                              creationDate={creationDate}
                              specificContent={data}
                              isProcessed={isProcessed}
                              phone={phone}
                              toggleProcessed={() => toggleProcessed.mutate({ id, isNowProcessed: !isProcessed })}
                            />
                          </div>
                        ))
                    }
                  </div>
                )
          }
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

DemandList.propTypes = {
  contactTypes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default DemandList;
