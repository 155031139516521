import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../constants/colors';

const FilterButton = ({ onClick, isSelected, title }) => (
  <button
    type="button"
    onClick={onClick}
    className="ml-3 mr-2 mv-2 radius-5 pv-1 ph-3"
    style={{ backgroundColor: isSelected ? colors.primaryRed : colors.grey100 }}
  >
    <span
      className="medium-text normal-weight uppercase"
      style={isSelected ? { color: colors.white } : { color: colors.primaryRed }}
    >
      {title}
    </span>
  </button>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default FilterButton;
