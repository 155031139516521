import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// Components
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import ContactModal from './ContactModal';

// Service
import AgencyService from '../../services/AgencyService';

// Constant
// import colors from '../../constants/colors';
import strings from '../../constants/strings';

// Hooks
import { useAppContext } from '../../store/AppContext';

// Icon
import { ReactComponent as AddIcon } from '../../assets/images/add-expand.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import LightSelectComponent from '../atoms/LightSelectComponent';

const ClientBottomMenu = () => {
  const [{ currentAgency, clientAgencies }, appDispatch] = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [agency, setAgency] = useState(null);

  const handleContactOpen = (e) => {
    e.stopPropagation();
    setContactModalOpen(true);
  };

  const {
    isSuccess, data: agencyData,
  } = useQuery(['getAgency', currentAgency],
    () => {
      if (currentAgency) {
        return AgencyService.getAgency({ id: currentAgency });
      }
      return null;
    });

  const formatAgenciesItems = () => (
    JSON.parse(clientAgencies)?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  );

  useEffect(() => {
    if (isSuccess && agencyData) {
      setAgency({ value: agencyData?.data?.id, label: agencyData?.data?.name });
    }
  }, [isSuccess, agencyData]);

  useEffect(() => {
    if (agency?.value) {
      appDispatch({ type: 'SET_CURRENT_AGENCY', payload: agency.value });
    }
  }, [agency]);

  return (
    <div
      className={`grey-800-background d-flex ${isOpen ? 'justify-start' : 'align-center'} justify-between ph-3`}
      style={{
        height: isOpen ? '100vh' : '80px', position: 'sticky', bottom: 0, transition: 'height 300ms', zIndex: 2,
      }}
    >
      { !isOpen
        ? (
          <>
            <button
              className="full-width d-flex justify-between"
              type="button"
              onClick={() => setIsOpen(((open) => !open))}
            >
              <div className="white-text">{agencyData && isSuccess ? agencyData?.data?.name : ''}</div>
              <span
                className="pointer"
                role="button"
                onKeyDown={null}
                tabIndex={0}
                onClick={() => setIsOpen(((open) => !open))}
              >
                <AddIcon />
              </span>
            </button>
          </>
        ) : (
          <div className="m-3">
            {agencyData && isSuccess ? (
              <>
                <div className="mb-5">
                  {JSON.parse(clientAgencies)?.length ? (
                    <div className="form-input-width small">
                      <LightSelectComponent
                        data={formatAgenciesItems()}
                        value={agency}
                        setValue={setAgency}
                      />
                    </div>
                  ) : null}
                  <h3 className="white-text medium-weight">{agencyData?.data?.name}</h3>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {`${agencyData?.data.address1} ${agencyData?.data.postalCode} ${agencyData?.data.city}`}
                  </h6>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {agencyData?.data.emails[0]?.emailAddress}
                  </h6>
                  <h6 className="grey-400-text m-0">
                    {agencyData?.data.phones[0]?.phone}
                  </h6>
                </div>

                <div className="mb-4">
                  <h6 className="white-text m-0 mv-3 normal-weight">{strings.openingHours}</h6>
                  <span
                    className="regular-text grey-400-text"
                    dangerouslySetInnerHTML={{ __html: agencyData?.data?.openingHours }}
                  />
                </div>
                <div>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {strings.contactLabel}
                  </h6>
                </div>
                <div className="mb-3">
                  <ButtonComponent onClick={handleContactOpen}>
                    {strings.contactUsSecondary}
                  </ButtonComponent>
                </div>
                <div>
                  <ButtonComponent theme="outline-transparent">
                    {strings.goToFaq}
                  </ButtonComponent>
                </div>
              </>
            ) : null }
            <div
              className="absolute pointer"
              role="button"
              onKeyDown={null}
              tabIndex={0}
              onClick={() => setIsOpen(((open) => !open))}
              style={{ bottom: '25px', right: '16px' }}
            >
              <CloseIcon />
            </div>
          </div>
        )}
      <ContactModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        soource="MENU EXTRANET"
        defaultSubject={3}
      />
    </div>
  );
};

export default ClientBottomMenu;
