import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useQuery } from 'react-query';

// Services
import FileService from '../../services/FileService';

// Constants
import strings from '../../constants/strings';

// Utils
import utils from '../../utils/utils';

const NewsElement = ({
  category, date, content, id, thumbnail,
}) => {
  const [base64, setBase64] = useState(null);

  const {
    isSuccess, data,
  } = useQuery(['getFile', thumbnail], () => {
    if (thumbnail !== null) {
      return FileService.getFile({ id: thumbnail });
    }
    return Promise.resolve();
  });

  useEffect(() => {
    if (isSuccess && data) {
      utils.getBase64(data.data).then((result) => {
        setBase64(result);
      });
    }
  }, [isSuccess, data]);

  return (
    <>
      <div className="">
        <div className="d-flex f-row justify-between align-center full-width">
          <div>
            <span className="very-small-text uppercase primary-red-text pr-1">{category}</span>
            <span className="small-text grey-400-text ph-3">
              {`${strings.the} ${format(new Date(date.split(' ').join('T')), 'dd.MM.yyyy')}`}
            </span>
          </div>
        </div>
        <div style={{ fontSize: '18px' }}>
          <Link to={`/intranet/network-news/${id}`}>{content}</Link>
        </div>
      </div>
      {thumbnail ? (
        <div className="d-flex relative">
          <img
            className="list-thumbnail"
            alt={`thumbnail-${content}`}
            src={base64}
          />
        </div>
      ) : null}
    </>
  );
};

NewsElement.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  thumbnail: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NewsElement.defaultProps = {
  thumbnail: null,
};

export default NewsElement;
