import client from './client';

const getAllAgencies = () => client.get('/agencies');
const getAgencies = ({ adherentId, adminId, isActive }) => client.get('/agencies',
  { params: { adherentId, adminId, isActive } });
const getAgency = ({ id }) => client.get(`/agencies/${id}`);
const updateAgency = ({ agency }) => client.put(`/agencies/${agency.id}`, agency);
const createAgency = ({ agency }) => client.post('/agencies', agency);
const updateAgencyInfo = ({ id, infos }) => client.put(`/agencies/${id}/infos`, infos);

const getAgencyContents = ({ id }) => {
  const config = id ? { params: { agencyId: id } } : null;
  return client.get('/agency-page-contents', config);
};
const getAgencyContent = ({ id }) => client.get(`/agency-page-contents/${id}`);
const updateAgencyContents = ({ content }) => client.put(`/agency-page-contents/${content.id}`, content);
const addAgencyContents = ({ content }) => client.post('/agency-page-contents', content);
const deleteAgencyContents = ({ id }) => client.delete(`/agency-page-contents/${id}`);

const getAgencyPostalCodes = ({ id }) => client.get(`agencies/${id}/export-postal-codes`);
const setAgencyPostalCodes = ({ id, file, type }) => client.post(`agencies/${id}/import-postal-codes`,
  Buffer.from(file),
  {
    headers: {
      'Content-Type': type,
    },
  });

export default {
  getAllAgencies,
  getAgencies,
  updateAgency,
  getAgency,
  createAgency,
  updateAgencyInfo,
  getAgencyContents,
  getAgencyContent,
  updateAgencyContents,
  addAgencyContents,
  deleteAgencyContents,
  getAgencyPostalCodes,
  setAgencyPostalCodes,
};
