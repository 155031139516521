import client from './client';
import clientRpc from './clientRPC';

const getMyNotifications = () => client.get('/notifications/mine');
const getMyUnreadNotifications = () => clientRpc.get('/count-all-unread-notifications');

const readOne = (id) => client.post(`/notifications/${id}:read`);
const deleteAll = () => clientRpc.delete('/delete-all-notifications-read');
const readAll = () => clientRpc.put('/read-all-notifications');

export default {
  getMyNotifications,
  getMyUnreadNotifications,
  readOne,
  readAll,
  deleteAll,
};
