import React from 'react';
import PropTypes from 'prop-types';
// Components
import FilterButton from '../atoms/FilterButton';
import SearchInputComponent from '../atoms/SearchInputComponent';

const ForumFilters = ({
  width, themes, theme, setTheme, search, doSearch, setSearch,
}) => (
  <div
    className="white-background pv-3 mb-2 d-flex f-row align-start justify-between f-wrap"
    style={{ width }}
  >
    <div className="d-flex f-row f-wrap">
      {
        themes.map(({ name, id }) => (
          <FilterButton
            key={id}
            onClick={() => {
              setSearch('');
              if (theme === id) {
                return setTheme(null);
              }
              return setTheme(id);
            }}
            isSelected={theme === id}
            title={name}
          />
        ))
      }
    </div>
    <div className="form-input-width mh-3 mv-2">
      <SearchInputComponent
        value={search}
        onClick={doSearch}
        onChange={(value) => {
          if (value) setTheme(null);
          setSearch(value);
        }}
        id="forum-search"
      />
    </div>
  </div>
);

ForumFilters.propTypes = {
  width: PropTypes.number.isRequired,
  themes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  setTheme: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  theme: PropTypes.number,
};

ForumFilters.defaultProps = {
  theme: null,
};

export default ForumFilters;
