import React from 'react';
import PropTypes from 'prop-types';
// Constants
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import strings from '../../../constants/strings';
import FileService from '../../../services/FileService';
import utils from '../../../utils/utils';
// Hooks

const InvoiceDemandContent = ({
  number,
  amount,
  date,
  fileId,
}) => {
  const download = useMutation('getFileAndDownload',
    async ({ id, fileName }) => {
      const file = await FileService.getFile({ id });
      return utils.downloadBlob(file.data, fileName);
    });

  return (
    <div
      className="d-flex f-row justify-between align-center f-wrap"
    >
      <div className="d-flex f-row f-wrap">
        <span className="grey-400-text medium-text">{`${strings.invoiceNumber} :`}</span>
        <span className="grey-800-text medium-text medium-weight ml-2">
          {number}
        </span>
      </div>
      <div className="d-flex f-row f-wrap">
        <span className="grey-400-text medium-text">{`${strings.invoiceAmount} :`}</span>
        <span className="grey-800-text medium-text medium-weight ml-2">
          {amount / 100}
          €
        </span>
      </div>
      {date ? (
        <div className="d-flex f-row f-wrap">
          <span className="grey-400-text medium-text">
            {`${strings.paidThe} ${format(new Date(date?.split(' ').join('T')), 'dd MMMM yyyy', { locale: frLocale })}`}
          </span>
        </div>
      ) : null}
      { fileId ? (
        <button
          type="button"
          className="low-opacity-hover"
          onClick={() => download.mutate({ id: fileId, fileName: number })}
        >
          <span className="primary-red-text bold mh-3">
            {strings.seeInvoice}
          </span>
        </button>
      ) : null}
    </div>
  );
};

InvoiceDemandContent.propTypes = {
  number: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  fileId: PropTypes.string,
};

InvoiceDemandContent.defaultProps = {
  number: null,
  amount: null,
  date: null,
  fileId: null,
};

export default InvoiceDemandContent;
