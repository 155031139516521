import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

// Components
import { SlashContainer, ButtonComponent } from '@zolteam/axenergie-ui-library';
import { useMeasure } from 'react-use';

// Service
import { useHistory } from 'react-router-dom';
import ContactModal from './ContactModal';
import AgencyService from '../../services/AgencyService';

// Constant
import colors from '../../constants/colors';
import strings from '../../constants/strings';

// Hooks
import { useAppContext } from '../../store/AppContext';
import useIsMobile from '../../hooks/useIsMobile';
import LightSelectComponent from '../atoms/LightSelectComponent';

const ClientMenu = ({ smallMenuCloseHeight, setMenuHeight, greySlashHeight }) => {
  const [isMobile] = useIsMobile();
  const history = useHistory();

  const [containerRef, { height }] = useMeasure();
  const [greySlashContainerRef, { height: greySlashContainerHeight }] = useMeasure();

  const [{ currentAgency, clientAgencies }, appDispatch] = useAppContext();

  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [agency, setAgency] = useState(null);

  const handleContactOpen = (e) => {
    e.stopPropagation();
    setContactModalOpen(true);
  };

  useEffect(() => {
    setMenuHeight(greySlashContainerHeight + smallMenuCloseHeight);
  }, [height]);

  const {
    isSuccess, data: agencyData,
  } = useQuery(['getAgency', currentAgency],
    () => {
      if (currentAgency) {
        return AgencyService.getAgency({ id: currentAgency });
      }
      return null;
    });

  const formatAgenciesItems = () => (
    JSON.parse(clientAgencies)?.map(({ name, id }) => ({
      label: name,
      value: id,
    }))
  );

  useEffect(() => {
    if (isSuccess && agencyData) {
      setAgency({ value: agencyData?.data?.id, label: agencyData?.data?.name });
    }
  }, [isSuccess, agencyData]);

  useEffect(() => {
    if (agency?.value) {
      appDispatch({ type: 'SET_CURRENT_AGENCY', payload: agency.value });
    }
  }, [agency]);

  return (
    <div
      ref={containerRef}
      className="absolute menu-container-no-animation"
      style={{
        height: isMobile ? smallMenuCloseHeight : '',
      }}
    >
      <div className="slash-container-shadow relative index-2">
        <SlashContainer width={!isMobile ? '484px' : '90px'} backgroundColor={colors.white} offset={3}>
          <div className="d-flex align-center mh-4 f-wrap" style={{ height: smallMenuCloseHeight }}>
            <div className="mr-4">
              <img
                alt="agency-logo"
                src={`${process.env.REACT_APP_IMAGE_URL}/${agencyData?.data?.logoFilePath}`}
                height={smallMenuCloseHeight - 32}
              />
            </div>
            {clientAgencies && JSON.parse(clientAgencies)?.length ? (
              <div className="form-input-width small">
                <LightSelectComponent
                  data={formatAgenciesItems()}
                  value={agency}
                  setValue={setAgency}
                />
              </div>
            ) : null}
          </div>
        </SlashContainer>
      </div>
      <div ref={greySlashContainerRef}>
        <SlashContainer width={!isMobile ? '330px' : '90px'} backgroundColor={colors.grey800} offset={40}>
          <div
            className={`pl-6 d-flex f-column grey-800-background
              ${!isMobile ? 'align-start' : 'justify-center align-center'}`}
            style={isMobile
              ? { height: smallMenuCloseHeight }
              : { minHeight: `calc(100vh - ${smallMenuCloseHeight}px)`, height: greySlashHeight + 32 }}
          >
            {agencyData && isSuccess ? (
              <>
                <div className="mb-5">
                  <h3 className="white-text medium-weight">{agencyData?.data?.name}</h3>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {`${agencyData?.data.address1} ${agencyData?.data.postalCode} ${agencyData?.data.city}`}
                  </h6>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {agencyData?.data.emails[0]?.emailAddress}
                  </h6>
                  <h6 className="grey-400-text m-0">
                    {agencyData?.data.phones[0]?.phone}
                  </h6>
                </div>

                <div className="mb-4">
                  {/* <span>{agencyData}</span> */}
                  <h6 className="white-text m-0 mv-3 normal-weight">{strings.openingHours}</h6>
                  <span
                    className="regular-text grey-400-text"
                    dangerouslySetInnerHTML={{ __html: agencyData?.data?.openingHours }}
                  />
                </div>
                <div>
                  <h6 className="grey-400-text m-0 mb-3 normal-weight">
                    {strings.contactLabel}
                  </h6>
                </div>
                <div className="mb-3">
                  <ButtonComponent onClick={handleContactOpen}>
                    {strings.contactUsSecondary}
                  </ButtonComponent>
                </div>
                <div>
                  <ButtonComponent
                    theme="outline-transparent"
                    onClick={() => history.push('/extranet/faq')}
                  >
                    {strings.goToFaq}
                  </ButtonComponent>
                </div>
              </>
            ) : null }
          </div>
        </SlashContainer>
      </div>
      <ContactModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        source="MENU EXTRANET"
        defaultSubject={3}
      />
    </div>

  );
};

ClientMenu.propTypes = {
  smallMenuCloseHeight: PropTypes.number,
  greySlashHeight: PropTypes.number,
  setMenuHeight: PropTypes.func,
};

ClientMenu.defaultProps = {
  smallMenuCloseHeight: 105,
  greySlashHeight: 0,
  setMenuHeight: () => {},
};

export default ClientMenu;
