import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import { format } from 'date-fns';
import { useMeasure } from 'react-use';
// Images
import arrow from '../../assets/images/arrow-down.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-black.svg';
// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';
// Utils and hooks
import utils from '../../utils/utils';
import useIsMobile from '../../hooks/useIsMobile';

const ArticleTemplate = ({
  backAction,
  isLoading,
  title,
  illustrations,
  filesToDownload,
  categories,
  publicationDate,
  content,
}) => {
  const [containerRef, { width }] = useMeasure();

  const [isMobile] = useIsMobile();

  const imageWidth = useMemo(() => (width < 400 - 64 ? width - 64 : 400 - 64), [width]);

  return (
    <div className="d-flex justify-start f-column align-start">
      <div className="m-3">
        <ButtonComponent onClick={backAction} theme="white">
          <div className="d-flex justify-center align-center">
            <img
              alt="back-arrow"
              className="rotate-90"
              src={arrow}
              width={20}
            />
            <span className="normal-weight">{strings.back}</span>
          </div>
        </ButtonComponent>
      </div>
      {isLoading
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <div className="full-width">
            <h2 className="medium-weight grey-800-text text-align-left">{title}</h2>
            <div
              className="white-background pv-3 max-list-container-width full-width"
              ref={containerRef}
            >
              <div
                className="d-flex f-row f-wrap justify-between ph-3"
              >
                <div
                  className="p-3 d-flex f-column justify-between"
                >
                  <div style={{ width: isMobile ? width - 64 : width - imageWidth - 64 }}>
                    <div
                      className="d-flex f-row align-center f-wrap"
                    >
                      {categories.map((category) => (
                        <div
                          key={category}
                          className="grey-100-background ph-2 pv-1 mv-2 radius-5 mr-3"
                        >
                          <span className="primary-red-text uppercase small-text">
                            {category}
                          </span>
                        </div>
                      ))}
                      <span className="grey-600-text ph-3 normal-weight">
                        {`Le ${format(new Date(publicationDate?.split(' ').join('T')), 'dd.MM.yyyy')}`}
                      </span>
                    </div>
                    <div
                      className="grey-100-background mv-3"
                      style={{ height: 3, width: isMobile ? width - 64 : width - imageWidth - 64 }}
                    />
                    <div
                      className="d-flex f-column align-start justify-start wysiwyg-text"
                      style={{ width: isMobile ? width - 64 : width - imageWidth - 64 }}
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                </div>
                <div className={isMobile ? 'd-flex justify-center align-center full-width' : ''}>
                  {illustrations.length
                    ? (
                      <div className="d-flex f-column" style={{ width: imageWidth }}>
                        {
                              illustrations.map((file, index) => (
                                <img
                                      // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  alt=""
                                  src={file}
                                  width={imageWidth}
                                  className="mv-2"
                                />
                              ))
                            }
                      </div>
                    ) : null}
                </div>
              </div>
              {filesToDownload.length ? (
                <div
                  className="radius-5 d-flex f-column justify-start m-3 w-fit-content"
                  style={{ border: '1px solid #EDEEF1', maxWidth: isMobile ? '100%' : width - imageWidth - 128 }}
                >
                  <div className="p-3">
                    <h3 className="grey-800-text mv-2 medium-weight">
                      {strings.toDownload}
                    </h3>
                    <div className="d-flex f-column align-start full-width">
                      {
                        filesToDownload.map(({ name, data: fileData }) => (
                          <div
                            className="mv-2"
                            key={name}
                          >
                            <ButtonComponent
                              onClick={() => utils.downloadBlob(fileData, name)}
                              theme="outline"
                            >
                              <div className="d-flex f-row align-center">
                                <DownloadIcon width={24} height={24} />
                                <span className="ml-3">{name}</span>
                              </div>
                            </ButtonComponent>
                          </div>
                        ))
                       }
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
    </div>
  );
};

ArticleTemplate.propTypes = {
  backAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  illustrations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filesToDownload: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  publicationDate: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default ArticleTemplate;
