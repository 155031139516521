import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

// Components
import {
  ButtonComponent, CheckboxComponent, InputComponent, LoaderComponent, SelectComponent, RadioButtons,
} from '@zolteam/axenergie-ui-library';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraftjs from 'html-to-draftjs';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Constants
import strings from '../../../constants/strings';
import allColors from '../../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';
import utils from '../../../utils/utils';

// Services
import PrestationService from '../../../services/PrestationService';
import FileService from '../../../services/FileService';
import PriceInput from '../../../components/atoms/PriceInput';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

const EditPrestation = () => {
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [subTitle, setSubTitle] = useState('');
  const [subTitleError, setSubTitleError] = useState(false);
  const [reasonsIntro, setReasonIntro] = useState('');
  const [reasonsIntroError] = useState(false);
  const [reasons1, setReason1] = useState('');
  const [reasons1Error, setReason1Error] = useState(false);
  const [reasons2, setReason2] = useState('');
  const [reasons2Error, setReason2Error] = useState(false);
  const [reasons3, setReason3] = useState('');
  const [reasons3Error, setReason3Error] = useState(false);
  const [reasons4, setReason4] = useState('');
  const [reasons4Error, setReason4Error] = useState(false);
  const [reasons5, setReason5] = useState('');
  const [reasons5Error, setReason5Error] = useState(false);
  const [color, setColor] = useState(1);
  const [colorItems, setColorItems] = useState([]);
  const [requiredForAll, setRequiredForAll] = useState(false);
  const [price, setPrice] = useState('');
  const [type, setType] = useState(0);
  const [types, setTypes] = useState([]);
  const [argumentation, setArgumentation] = useState(() => EditorState.createEmpty());
  const [details, setDetails] = useState(() => EditorState.createEmpty());
  const [detailsError, setDetailsError] = useState(false);
  const [argumentationError, setArgumentationError] = useState(false);
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState(null);

  // Navigation
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  // Store
  const [{ permissions, currentAgency }, appDispatch] = useAppContext();

  // Permissions check
  const canEditNetworkPrestation = () => JSON.parse(permissions).includes('network_prestation_administration');

  // Queries
  const {
    isLoading: isColorLoading, isError: isColorError, isSuccess: isColorSuccess, data: colors,
  } = useQuery('getColors', PrestationService.getPrestationColors);

  const {
    isLoading: isTypesLoading, isSuccess: typesSuccess, isError: typesError, data: allTypes,
  } = useQuery('getTypes', PrestationService.getPrestationTypes);

  const createPrestation = useMutation('createPrestation',
    async (createPrestationData) => {
      let illustration = imageId;
      if (image) {
        const uploadedFile = await FileService.addFile({ binary: image, typeId: 12 });
        setImageId(uploadedFile.data.id);
        illustration = uploadedFile.data.id;
      }
      const goodReasonsBlock = illustration ? {
        ...createPrestationData?.goodReasonsBlock,
        illustrationFileId: illustration,
      } : null;
      if (canEditNetworkPrestation()) {
        return PrestationService.createNetworkPrestation({
          prestation: {
            ...createPrestationData,
            goodReasonsBlock,
          },
        });
      }
      return PrestationService.createAgencyPrestation({
        prestation: {
          ...createPrestationData,
          goodReasonsBlock,
        },
      });
    });

  const updatePrestation = useMutation('update-prestation',
    async (updatePrestationData) => {
      let illustration = imageId;
      if (image) {
        const uploadedFile = await FileService.addFile({ binary: image, typeId: 12 });
        setImageId(uploadedFile.data.id);
        illustration = uploadedFile.data.id;
      }
      const goodReasonsBlock = illustration ? {
        ...updatePrestationData?.goodReasonsBlock,
        illustrationFileId: illustration,
      } : null;
      if (canEditNetworkPrestation()) {
        return PrestationService.updateNetworkPrestation({
          prestation: {
            ...updatePrestationData,
            goodReasonsBlock,
          },
        });
      }
      return PrestationService.updateAgencyPrestation({
        prestation: {
          ...updatePrestationData,
          goodReasonsBlock,
        },
      });
    });

  const {
    isSuccess, data, isError,
  } = useQuery('getPrestation', () => {
    if (parameters.get('prestation')) {
      if (canEditNetworkPrestation()) {
        return PrestationService.getNetworkPrestation({ id: parameters.get('prestation') });
      }
      return PrestationService.getAgencyPrestation({ id: parameters.get('prestation') });
    }
    return null;
  });
  const goToPrestation = () => history.push('/intranet/prestations');

  const validateGoodReasons = () => {
    if ((!imageId && !image) && (reasonsIntro || reasons1 || reasons2 || reasons3 || reasons4 || reasons5)) {
      return false;
    }
    return true;
  };

  const getIsLoading = () => (parameters.get('prestation') ? updatePrestation?.isLoading : createPrestation?.isLoading);

  const addPrestation = () => {
    let prestationToAdd = {
      equipmentTypeId: Number(parameters.get('equipment')),
      prestationTypeId: type,
      prestationColorId: color.value,
      title,
      subtitle: subTitle,
      details: draftToHtml(convertToRaw(details.getCurrentContent())),
      argumentation: draftToHtml(convertToRaw(argumentation.getCurrentContent())),
      isMandatory: requiredForAll,
      isActive: true,
      price,
    };
    if (!canEditNetworkPrestation()) {
      prestationToAdd = {
        ...prestationToAdd,
        agencyId: currentAgency,
      };
    }
    if (reasonsIntro) {
      prestationToAdd = {
        ...prestationToAdd,
        goodReasonsBlock: {
          intro: reasonsIntro,
          illustrationFileId: imageId,
          goodReasons: [
            { reason: reasons1 },
            { reason: reasons2 },
            { reason: reasons3 },
            { reason: reasons4 },
            { reason: reasons5 },
          ],
        },
      };
    }
    createPrestation.mutate(prestationToAdd);
  };

  const editPrestation = () => {
    let prestationToUpdate = {
      ...data?.data,
      equipmentTypeId: Number(parameters.get('equipment')),
      prestationTypeId: type,
      prestationColorId: color.value,
      title,
      subtitle: subTitle,
      details: draftToHtml(convertToRaw(details.getCurrentContent())),
      argumentation: draftToHtml(convertToRaw(argumentation.getCurrentContent())),
      isMandatory: requiredForAll,
      price,
    };
    if (reasonsIntro) {
      prestationToUpdate = {
        ...prestationToUpdate,
        goodReasonsBlock: {
          intro: reasonsIntro,
          illustrationFileId: imageId,
          goodReasons: [
            { reason: reasons1 },
            { reason: reasons2 },
            { reason: reasons3 },
            { reason: reasons4 },
            { reason: reasons5 },
          ],
        },
      };
    }
    updatePrestation.mutate(prestationToUpdate);
  };

  useEffect(() => {
    if (isSuccess && data && parameters.get('prestation')) {
      setTitle(data.data.title);
      setSubTitle(data.data.subtitle);
      setDetails(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.details)),
      ));
      setArgumentation(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.argumentation)),
      ));
      setColor(colorItems.find(({ value }) => value === data.data.prestationColorId));
      setRequiredForAll(data.data.isMandatory);
      setPrice(data.data.price);
      setType(data.data.prestationTypeId);
      setReasonIntro(data.data.goodReasonsBlock?.intro);
      setReason1(data.data.goodReasonsBlock?.goodReasons[0].reason);
      setReason2(data.data.goodReasonsBlock?.goodReasons[1].reason);
      setReason3(data.data.goodReasonsBlock?.goodReasons[2].reason);
      setReason4(data.data.goodReasonsBlock?.goodReasons[3].reason);
      setReason5(data.data.goodReasonsBlock?.goodReasons[4].reason);
      setImage(null);
      setImageId(data.data.goodReasonsBlock?.illustrationFileId);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.prestationLoading, isSuccess: false },
      });
      goToPrestation();
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (
      createPrestation.isSuccess
    || updatePrestation.isSuccess
    ) {
      goToPrestation();
    }
    if (updatePrestation.isError) {
      const toDisplay = Object.values(updatePrestation?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updatePrestation, isSuccess: false },
      });
    }
    if (createPrestation.isError) {
      const toDisplay = Object.values(createPrestation?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createPrestation, isSuccess: false },
      });
    }
  }, [
    createPrestation.isSuccess,
    updatePrestation.isSuccess,
    createPrestation.isError,
    updatePrestation.isError,
  ]);
  const onSubmit = () => {
    if (
      titleError
        || subTitleError
        || !color
        || !type
        || detailsError
        || argumentationError
    ) {
      return false;
    }
    if (parameters.get('prestation')) {
      return editPrestation();
    }
    return addPrestation();
  };

  useEffect(() => {
    if (isColorSuccess && colors) {
      const formattedColors = colors.data.prestationColors.map(({ id, code }) => ({ value: id, label: code }));
      setColorItems(formattedColors);
      if (currentAgency) {
        setColor(formattedColors?.find(({ value }) => value === 4));
      }
    }
    if (isColorError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.colorLoading, isSuccess: false },
      });
    }
  }, [isColorError, isColorSuccess, colors]);

  useEffect(() => {
    if (typesSuccess && allTypes) {
      setTypes(allTypes.data.prestationTypes);
    }
    if (typesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.prestationTypeLoading, isSuccess: false },
      });
    }
  }, [typesError, typesSuccess, allTypes]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/prestations">
          <h3 className="mr-3 mv-1 text-align-left">
            {canEditNetworkPrestation() ? strings.networkPrestations : strings.myPrestationsAndVisibility}
          </h3>
        </Link>
        <h3 className="mr-3 mv-1 text-align-left">{` > ${parameters.get('equipment-name')}`}</h3>
        <h3 className="mv-1 text-align-left">{` > ${data?.data?.title || strings.newPrestation}`}</h3>
      </div>
      <div className="pv-4 mh-4 d-flex f-column f-wrap align-start">
        <div className="full-width d-flex f-row align-center f-wrap">
          <div className="form-input-width mv-3 mr-3">
            <InputComponent
              onChange={setTitle}
              id="prestation-title"
              value={title}
              label={strings.title}
              isError={titleError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setTitleError, title)}
            />
          </div>
          <div className="form-input-width mv-3">
            <SelectComponent
              data={colorItems}
              name="prestation-color"
              value={color}
              setValue={setColor}
              theme="dark"
              isLoading={isColorLoading}
              placeholder={strings.color}
              isDisabled={!!currentAgency}
            />
          </div>
        </div>
        <div className="full-width mv-3 d-flex f-row align-start f-wrap">
          {canEditNetworkPrestation() ? (
            <div className="mr-4 mb-2">
              <CheckboxComponent
                handleChange={setRequiredForAll}
                value={requiredForAll}
                label={strings.prestationRequiredForAll}
                id="required-for-all-checkbox"
              />
            </div>
          ) : null}
          <div className="d-flex f-row align-center f-wrap">
            {
              !isTypesLoading
                ? <RadioButtons setValue={setType} value={type} options={types} />
                : null
            }
          </div>
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setSubTitle}
            id="prestation-subtitle"
            value={subTitle}
            label={strings.subtitle}
            isError={subTitleError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setSubTitleError, subTitle)}
          />
        </div>
        <div className="full-width mv-3">
          <TextEditorComponent
            label={strings.argument}
            setValue={setArgumentation}
            value={argumentation}
            isError={argumentationError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setArgumentationError,
              convertToRaw(argumentation.getCurrentContent()).blocks[0].text)
            )}
          />
        </div>

        <div className="full-width mv-3">
          <TextEditorComponent
            label={strings.details}
            setValue={setDetails}
            value={details}
            hasTitles
            isError={detailsError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setDetailsError,
              convertToRaw(details.getCurrentContent()).blocks[0].text))}

          />
        </div>
        {canEditNetworkPrestation()
          ? null
          : <PriceInput onPriceChange={setPrice} id="price-input" defaultPrice={price} />}
      </div>
      <div className="d-flex f-row mv-3 f-wrap">
        <h3 className="mv-1 text-align-left">
          <span>{strings.fiveGoodReasonBlock}</span>
          <span className="grey-300-text">{` - ${strings.notMandatory}`}</span>
        </h3>
      </div>
      <div className="pv-4 mh-4 d-flex f-column f-wrap align-start">
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReasonIntro}
            id="reason-intro"
            value={reasonsIntro}
            label={strings.intro}
            isError={reasonsIntroError}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReason1}
            id="reason-1"
            value={reasons1}
            label={`${strings.reason} 1`}
            isError={reasons1Error}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (reasonsIntro ? validateNotEmptyField(setReason1Error, reasons1) : setReason1Error(false))}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReason2}
            id="reason-2"
            value={reasons2}
            label={`${strings.reason} 2`}
            isError={reasons2Error}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (reasonsIntro ? validateNotEmptyField(setReason2Error, reasons2) : setReason2Error(false))}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReason3}
            id="reason-3"
            value={reasons3}
            label={`${strings.reason} 3`}
            isError={reasons3Error}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (reasonsIntro ? validateNotEmptyField(setReason3Error, reasons3) : setReason3Error(false))}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReason4}
            id="reason-4"
            value={reasons4}
            label={`${strings.reason} 4`}
            isError={reasons4Error}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (reasonsIntro ? validateNotEmptyField(setReason4Error, reasons4) : setReason4Error(false))}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setReason5}
            id="reason-5"
            value={reasons5}
            label={`${strings.reason} 5`}
            isError={reasons5Error}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (reasonsIntro ? validateNotEmptyField(setReason5Error, reasons5) : setReason5Error(false))}
          />
        </div>
        <div className="form-file-input-width mv-3">
          <FileInputWithAcceptAndSize
            setFile={(file) => {
              setImage(file);
              setImageId(null);
            }}
            file={image}
            canDelete={image || imageId}
            label={imageId ? strings.changeImage : strings.illustrationImage}
            fileType={12}
            initName={strings.changeImage}
          />
        </div>

      </div>
      <div className="pb-4">
        { !title
          || !subTitle
            || !color
            || !type
            || !convertToRaw(details.getCurrentContent()).blocks[0].text
            || !convertToRaw(argumentation.getCurrentContent()).blocks[0].text
            || (!canEditNetworkPrestation() && !price)
            || !validateGoodReasons()
          ? null : (
            <div className="m-3">
              <ButtonComponent onClick={onSubmit}>
                <div className="mv-2">
                  {
                    getIsLoading()
                      ? <LoaderComponent size={30} borderWidth={5} color={allColors.white} />
                      : <span className="uppercase m-5">{strings.save}</span>
                  }
                </div>
              </ButtonComponent>
            </div>
          )}
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default EditPrestation;
