import client from './client';

const getAnnouncements = ({
  pageNumber, pageSize, typeCode, energyTypeCode, authorId, isExpired,
}) => client.get('/spare-part-announcements',
  {
    params: {
      pageNumber,
      pageSize,
      typeCode,
      energyTypeCode,
      authorId,
      isExpired,
    },
  });

const deleteAnnouncement = ({ id }) => client.delete(`/spare-part-announcements/${id}`);

const createAnnouncement = ({ announcement }) => client.post('/spare-part-announcements', announcement);
const updateAnnouncement = ({ announcement }) => client.put(
  `/spare-part-announcements/${announcement?.id}`,
  announcement,
);

const getSparePartEnergyTypes = () => client.get('/spare-part-energy-types');

const wantIt = ({ agencyId, sparePartAnnouncementId }) => client.post('/spare-part-announcements:want',
  { agencyId, sparePartAnnouncementId });

const gotIt = ({ agencyId, sparePartAnnouncementId }) => client.post('/spare-part-announcements:have',
  { agencyId, sparePartAnnouncementId });

export default {
  getAnnouncements,
  getSparePartEnergyTypes,
  deleteAnnouncement,
  createAnnouncement,
  updateAnnouncement,
  wantIt,
  gotIt,
};
