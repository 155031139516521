import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/radio.scss'

const RadioButtons = ({ options, value, setValue }) =>
  options.map(({ id, code }) => (
    <div key={`radio-button-${id}`} className='mr-4'>
      <label className='input-radio-container' htmlFor={`radio-type-${id}`}>
        <span className='regular-text'>{code}</span>
        <input
          className='input-radio'
          id={`radio-type-${id}`}
          type='radio'
          value={value || ''}
          checked={value === id}
          onChange={() => setValue(id)}
          name='radio-prestation-types'
        />
        <span className='input-radio-checkmark' />
      </label>
    </div>
  ))

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      code: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func.isRequired
}

RadioButtons.defaultProps = {
  value: ''
}

export default RadioButtons
