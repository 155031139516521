import client from './client';

const getOffers = ({ agencyId, isNetwork, isTemplate }) => client.get('/job-offers', {
  params: {
    agencyId,
    isNetwork,
    isTemplate,
  },
});

const getOffer = (id) => client.get(`/job-offers/${id}`);

const addJobOffer = (jobOffer) => client.post('/job-offers', jobOffer);
const updateJobOffer = (jobOffer) => client.put(`/job-offers/${jobOffer.id}`, jobOffer);
const deleteJobOffer = (id) => client.delete(`/job-offers/${id}`);

const getTypes = () => client.get('/job-offer-types');

const getDomains = () => client.get('/job-offer-domains');

export default {
  getOffers,
  addJobOffer,
  updateJobOffer,
  deleteJobOffer,
  getTypes,
  getDomains,
  getOffer,
};
