import client from './client';

const getApplications = () => client.get('/network-applications');
const deleteApp = (id) => client.delete(`/network-applications/${id}`);
const addApplication = ({ title, url, illustrationFileId }) => client.post('/network-applications', {
  title,
  url,
  illustrationFileId,
});
const updateApplication = ({
  title, url, illustrationFileId, id,
}) => client.put(`/network-applications/${id}`, {
  title,
  url,
  illustrationFileId,
});

export default {
  getApplications,
  deleteApp,
  addApplication,
  updateApplication,
};
