import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import '../../../styles/select.scss'

const SelectComponent = ({
  data,
  defaultValue,
  isDisabled,
  isLoading,
  name,
  isSearchable,
  value,
  setValue,
  placeholder,
  isClearable,
  label,
  theme
}) => {
  const customStyle = {
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: () => ({
      color: '#4e5b74',
      margin: '5px',
      cursor: 'pointer'
    }),
    control: () => ({
      backgroundColor: theme === 'dark' ? '#F4F5F9' : '#FFF',
      borderColor: theme === 'dark' ? 'rgba(31,35,44,0.12)' : '#F4F5F9',
      alignItems: 'center',
      borderRadius: '5px',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: '11px 0'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#1f232c',
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 500
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 500
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#d01408' : '#1f232c',
      fontFamily: "'Work Sans', sans-serif"
    })
  }

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#f4f5f9',
      primary75: '#d01408',
      primary50: '#d01408',
      primary25: '#f4f5f9'
    }
  })
  return (
    <div>
      {label ? (
        <span
          className={`${
            theme === 'dark' ? 'grey-400-text' : 'white-text light-label'
          } medium-text ml-2`}
        >
          {label}
        </span>
      ) : null}
      <Select
        styles={customStyle}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        name={name}
        value={value}
        onChange={setValue}
        options={data}
        placeholder={placeholder}
        theme={customTheme}
      />
    </div>
  )
}

SelectComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number || PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({})
    ]),
    label: PropTypes.string
  }),
  defaultValue: PropTypes.string || PropTypes.number,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
}

SelectComponent.defaultProps = {
  defaultValue: null,
  isDisabled: false,
  isLoading: false,
  isSearchable: true,
  placeholder: 'Sélection...',
  label: '',
  theme: 'light'
}

export default SelectComponent
