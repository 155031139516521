import client from './client';

const getMyAgencyContracts = (params) => client.get('/agency-contracts/mine', {
  params,
});
const getMyAgencyContractDocuments = (params) => client.get('/agency-contract-documents/mine', {
  params,
});
const getAgencyContractFile = (fileId) => client.get(`/agency-contract-documents/${fileId}/file`, {
  headers: {
    'Content-Type': 'text/html',
  },
  responseType: 'blob',
});

const getAgencyContractQuotations = (params) => client.get('/agency-contract-quotations', {
  params,
});

const getMyAgencyContractQuotations = (params) => client.get('/agency-contract-quotations/mine', {
  params,
});
const getAgencyContractQuotationFile = (fileId) => client.get(`/agency-contract-quotations/${fileId}/file`, {
  headers: {
    'Content-Type': 'text/html',
  },
  responseType: 'blob',
});
const getAgencyContractQuotationSignedFile = (contractId) => client.get(
  `/agency-contract-quotations/${contractId}/envelope`, {
    headers: {
      'Content-Type': 'text/html',
    },
    responseType: 'blob',
  },
);

const acceptAgencyContractQuotation = (quotationId) => client.post(`/agency-contract-quotations/${quotationId}:accept`);

const refuseAgencyContractQuotation = (quotationId) => client.post(`/agency-contract-quotations/${quotationId}:refuse`);

const getMyAgencyContractInvoices = (params) => client.get('/agency-contract-invoices/mine', {
  params,
});
const getAgencyContractInvoiceFile = (fileId) => client.get(`/agency-contract-invoices/${fileId}/file`, {
  headers: {
    'Content-Type': 'text/html',
  },
  responseType: 'blob',
});

const changeMyContractAddress = ({ contractId, email }) => client.post(
  `/agency-contracts/${contractId}:open-email-address-change`, {
    emailAddress: email,
    redirectUrl: `https://${process.env.REACT_APP_DOMAIN}/extranet/contract/${contractId}?emailModified=true`,
  },
);

const changeMyContactPhones = ({ contractId, mobile, phone }) => client.post(
  `/agency-contracts/${contractId}:change-phones`, {
    mobilePhoneNumber: mobile,
    landlinePhoneNumber: phone,
  },
);

const initializeAgencyContractQuotationSignature = (quotationId, contractId) => client.post(
  `/agency-contract-quotations/${quotationId}:initialize-signature`, {
    redirectUrl: `https://${process.env.REACT_APP_DOMAIN}/extranet/contract/${contractId}?tab=quote&signed=true`,
  },
);

export default {
  getMyAgencyContracts,
  getMyAgencyContractDocuments,
  getAgencyContractFile,
  getAgencyContractQuotations,
  getMyAgencyContractQuotations,
  getAgencyContractQuotationFile,
  getAgencyContractQuotationSignedFile,
  acceptAgencyContractQuotation,
  refuseAgencyContractQuotation,
  getMyAgencyContractInvoices,
  getAgencyContractInvoiceFile,
  changeMyContractAddress,
  changeMyContactPhones,
  initializeAgencyContractQuotationSignature,
};
