import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// Components
import Switch from 'react-switch';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import jwtDecode from 'jwt-decode';
import DataTable from '../../../components/molecules/DataTable';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Images
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services and hooks
import AuthService from '../../../services/AuthService';
import { useAppContext } from '../../../store/AppContext';
import AdherentUserService from '../../../services/AdherentUserService';
import UserService from '../../../services/UserService';
import AdherentAdminService from '../../../services/AdherentAdminService';
import ModalComponent from '../../../components/atoms/ModalComponent';

const AdherentUsers = () => {
  // Store
  const [{ auth, currentAgency, loginAs }, appDispatch] = useAppContext();
  // local states
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);

  // Queries
  const updateUser = useMutation('updateUser', (updateData) => AdherentUserService.updateAdherentUser(updateData));
  const archiveUserMutation = useMutation('archiveUser',
    (id) => AdherentUserService.archiveAdherentUser({ id }), {
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        setToDelete(null);
      },
      onError: () => {
        setIsDeleteModalOpen(false);
        setToDelete(null);
        appDispatch({
          type: 'SET_POPOVER',
          payload: { isOpen: true, title: strings.errors.deleteUser, isSuccess: false },
        });
      },
    });

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(['getAdherentUsers', updateUser.isSuccess, currentAgency, archiveUserMutation.isSuccess],
    async () => {
      const myId = loginAs ? JSON.parse(loginAs)?.id : jwtDecode(auth)?.sub;
      const admins = await AdherentAdminService.getAdherentAdmins({ adminId: myId });
      const users = await AdherentUserService.getAdherentUsers({ adminId: myId, agencyId: currentAgency || undefined });
      return [...admins?.data?.adherentAdmins, ...users.data?.adherentUsers];
    });

  const {
    isLoading: isRoleLoading, isError: isRoleError, data: roles,
  } = useQuery('getRoles', UserService.getRoles);
  const sendConnectionLink = useMutation('send-password',
    (sendPasswordData) => AuthService.adminResetPassword(sendPasswordData));

  // Navigation
  const history = useHistory();

  // Methods
  const addUser = () => history.push('/intranet/adherent-user');

  const editUser = (id) => history.push(`/intranet/adherent-user?id=${id}`);

  const renderEditButton = (id) => (
    <button type="button" onClick={() => editUser(id)}>
      <EditIcon width={30} />
    </button>
  );

  const updateIsActive = (user) => {
    if (user) {
      updateUser.mutate({ user: { ...user, isActive: !user.isActive } });
    }
  };

  const handleArchiveUser = (id) => {
    if (id) {
      archiveUserMutation.mutate(id);
    }
  };

  const onSendLink = (email) => {
    if (email) {
      sendConnectionLink.mutate({ email, context: 'init' });
    }
  };

  useEffect(() => {
    if (updateUser.isSuccess) {
      history.push('/intranet/adherent-users');
    }
    if (updateUser.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateUser, isSuccess: false },
      });
    }
    if (isError || isRoleError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
    if (sendConnectionLink.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.sendConnectionLink, isSuccess: true },
      });
    }
    if (sendConnectionLink.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.sendConnectionLink, isSuccess: false },
      });
    }
  },
  [
    updateUser.isSuccess,
    updateUser.isError,
    isError,
    isRoleError,
    sendConnectionLink.isSuccess,
    sendConnectionLink.isError,
  ]);

  const renderToggleButton = (value, user) => (
    <Switch
      checked={value}
      onChange={() => updateIsActive(user)}
      onColor="#8d9bba"
      onHandleColor={colors.primaryRed}
      handleDiameter={25}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      className="react-switch"
      id="disable-admin-switch"
    />
  );

  const renderConnectionLink = (email) => (
    <ButtonComponent onClick={() => onSendLink(email)} theme="outline">
      <span className="uppercase">{strings.connectionLink}</span>
    </ButtonComponent>
  );

  const renderDeleteBtn = (id) => (
    <ButtonComponent
      onClick={() => {
        setIsDeleteModalOpen(true);
        setToDelete(id);
      }}
      theme="outline"
    >
      <span className="uppercase">{strings.deleteUser}</span>
    </ButtonComponent>
  );

  const getRoleName = (id) => roles?.data?.roles.find((role) => role.id === id)?.name;

  const isAdmin = (user) => user.roleId === 4 || user.roleId === 7;

  const formatTableData = (users) => users.reduce((acc, user) => [
    ...acc,
    {
      name: `${user.firstName} ${user.lastName}`,
      position: user.position,
      mail: user.emailAddress,
      phone: user.phone,
      role: getRoleName(user.roleId),
      edit: isAdmin(user) ? null : renderEditButton(user.id),
      disabled: isAdmin(user) ? null : renderToggleButton(user.isActive, user),
      connectionLink: isAdmin(user) ? null : renderConnectionLink(user.emailAddress),
      deleteBtn: isAdmin(user) ? null : renderDeleteBtn(user.id),
    },
  ], []);

  return (
    <TemplateWithMenuAndHeader>
      <h1 className="mb-5 text-align-left">{strings.handleAdherentUsers}</h1>
      <div className="d-flex f-row justify-start align-center ph-4 f-wrap">
        <h3 className="mr-5 text-align-left">{strings.myUsers}</h3>
        <ButtonComponent onClick={addUser}>
          <span>{strings.addUser}</span>
        </ButtonComponent>
      </div>
      <div className="table-container full-width">
        <DataTable
          isLoading={isLoading || isError || isRoleLoading || isRoleError || updateUser.isLoading}
          data={isSuccess ? formatTableData(data) : []}
          columns={[
            strings.name,
            strings.job,
            strings.mail,
            strings.phone,
            strings.role,
            ' ',
            ' ',
            ' ',
          ]}
        />
      </div>
      {
        isDeleteModalOpen && toDelete ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deleteUser}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleArchiveUser(toDelete)}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default AdherentUsers;
