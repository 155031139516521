import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/loader.scss'

const LoaderComponent = ({ size, color, borderWidth }) => {
  return (
    <div
      className='loader'
      style={{
        width: size,
        height: size,
        border: `${borderWidth}px solid transparent`,
        borderTop: `${borderWidth}px solid ${color}`,
        borderRight: `${borderWidth}px solid ${color}`,
        borderBottom: `${borderWidth}px solid ${color}`
      }}
    />
  )
}

LoaderComponent.propTypes = {
  size: PropTypes.number.isRequired,
  borderWidth: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default LoaderComponent
