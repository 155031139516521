import React, { useEffect } from 'react';
import {
  Route, Switch, useLocation,
} from 'react-router-dom';
import routes from './index';
// Context
import { useAppContext } from '../store/AppContext';
// Views
import NotFoundScreen from '../views/404';

const Routes = () => {
  const [{
    auth, currentAgency, user, agencyName, roles,
  }] = useAppContext();
  const location = useLocation();

  const getRoleName = (id) => roles.find((role) => role.id === id)?.name;

  useEffect(() => {
    if (agencyName !== null && roles.length > 0) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'VirtualPageView',
        role: getRoleName(user.data.roleId),
        path: location?.pathname,
        userID: user.sub,
        adherentName: agencyName,
      });
    }
  }, [location, roles.length, agencyName]);
  const renderRoutes = routes.map((route) => (
    route.render
    // eslint-disable-next-line react/jsx-props-no-spreading
      ? <Route key={route.path} {...route} render={(args) => route.render(!!auth, { ...args, currentAgency })} />
    // eslint-disable-next-line react/jsx-props-no-spreading
      : <Route key={route.path} {...route} />
  ));

  return (
    <Switch>
      {renderRoutes}
      <Route component={NotFoundScreen} />
    </Switch>
  );
};

export default Routes;
