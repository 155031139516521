import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// constants
import { useMeasure } from 'react-use';
import strings from '../../constants/strings';

// Images
import { ReactComponent as DownloadIcon } from '../../assets/images/download-black.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

// Components
import ForumFormPost from './ForumFormPost';
import ModalComponent from '../atoms/ModalComponent';

const ForumAnswerBlock = ({
  publicationDate, content, agencyName, author, joinedFiles, getFileAndDownload, edit, deletePost, isEditable,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileContainer, { width: fileWidth }] = useMeasure();
  const [containerRef, { width: containerWidth }] = useMeasure();
  return (
    <div className="white-background full-width mv-2">
      {isEditing
        ? (
          <ForumFormPost
            onSubmit={(values) => {
              edit(values);
              setIsEditing(false);
            }}
            submitButtonTitle={strings.save}
            initValues={{
              content,
              joinedFiles,
            }}
          />
        )
        : (
          <div className="p-4 d-flex f-row align-center justify-between f-wrap relative">
            <div className="d-flex f-column full-width">
              <div className="d-flex full-width f-row justify-between align-center">
                <div className="grey-600-text medium-weight mb-2">
                  <span>
                    {`${strings.the} ${format(new Date(publicationDate?.split(' ').join('T')), 'dd/MM/yyyy')}`}
                  </span>
                  <span className="ml-2">
                    {`${strings.by} ${author}`}
                  </span>
                  {agencyName
                    ? (
                      <span className="ml-2">
                        {`- ${agencyName}`}
                      </span>
                    ) : null}
                </div>
                {isEditable ? (
                  <div className="mv-3 d-flex justify-end">
                    <button type="button" onClick={() => setIsEditing(true)}>
                      <EditIcon width={24} height={24} />
                    </button>
                    <button type="button" onClick={() => setIsDeleteModalOpen(true)}>
                      <DeleteIcon width={24} height={24} />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div ref={containerRef} className="d-flex full-width f-row justify-between align-center f-wrap">
              <div
                style={{
                  width: containerWidth - fileWidth - 16 < fileWidth
                    ? '100%'
                    : `calc(100% - ${fileWidth}px - 16px)`,
                }}
                className="grey-600-text medium-text wysiwyg-text"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              {joinedFiles?.length
                ? (
                  <div className="d-flex f-column" ref={fileContainer}>
                    {
                    joinedFiles?.map(({ fileName, fileId }) => (
                      <button
                        key={fileId}
                        type="button"
                        style={{ width: 200 }}
                        onClick={() => getFileAndDownload({ fileId, fileName })}
                      >
                        <div className="d-flex f-row align-center red-svg mv-2">
                          <DownloadIcon width={24} height={24} />
                          <span
                            style={{ width: 200 - 24 }}
                            className="ml-2 primary-red-text medium-weight medium-text "
                          >
                            {fileName}
                          </span>
                        </div>
                      </button>
                    ))
                  }
                  </div>
                )
                : null}
            </div>
          </div>
        )}
      {
        isDeleteModalOpen ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deletePost}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    deletePost();
                    setIsDeleteModalOpen(false);
                  }}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </div>
  );
};

ForumAnswerBlock.propTypes = {
  publicationDate: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  agencyName: PropTypes.string,
  joinedFiles: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string,
    fileId: PropTypes.number,
  })),
  getFileAndDownload: PropTypes.func.isRequired,
  edit: PropTypes.func,
  deletePost: PropTypes.func,
  isEditable: PropTypes.bool.isRequired,
};

ForumAnswerBlock.defaultProps = {
  agencyName: '',
  joinedFiles: [],
  edit: null,
  deletePost: null,
};

export default ForumAnswerBlock;
