import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { AdvancedMarker } from '@vis.gl/react-google-maps/dist/index.umd';
import { ReactComponent as AxLogo } from '../../assets/images/AX.svg';

const MapPins = ({ onClick, setMarkerRef, point }) => {
  const handleClick = useCallback(() => onClick(point), [onClick, point]);
  const ref = useCallback(
    (marker) => setMarkerRef(marker, point.key),
    [setMarkerRef, point.key],
  );

  return (
    <AdvancedMarker position={point.position} ref={ref} onClick={handleClick}>
      <AxLogo width={35} height={35} />
    </AdvancedMarker>
  );
};
MapPins.propTypes = {
  onClick: PropTypes.func.isRequired,
  setMarkerRef: PropTypes.func.isRequired,
  point: PropTypes.shape({
    key: PropTypes.string.isRequired,
    position: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MapPins;
