import React from 'react';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const TextEditorComponent = ({
  value, setValue, label, isError, errorMessage, onBlur, disabled, placeholder, hasTitles,
}) => {
  const getOptions = () => {
    if (hasTitles) {
      return ['inline', 'list', 'link', 'blockType'];
    }
    return ['inline', 'list', 'link'];
  };
  return (
    <div className="d-flex f-column full-width">
      <div
        className="rich-text-wrapper d-flex f-column relative pt-5 ph-3 pb-3"
        style={{
          opacity: disabled ? 0.5 : 1,
          borderColor: isError ? '#e13c32' : 'rgba(31,35,44,0.12)',
        }}
      >
        <div
          className="input-label d-flex f-column width-fit-content"
        >
          <span
            className="mr-2 regular-text normal-weight"
            style={{
              color:
                    convertToRaw(value.getCurrentContent()).blocks[0].text
                      ? '#8890a1' : '#1f232c',
            }}
          >
            {label}
          </span>
        </div>
        <Editor
          onBlur={onBlur}
          editorState={value}
          onEditorStateChange={setValue}
          editorClassName="rich-text-editor"
          toolbarClassName="d-flex f-wrap rich-text-toolbar"
          placeholder={placeholder}
          stripPastedStyles
          localization={
            { locale: 'fr' }
          }
          toolbar={{
            options: getOptions(),
            inline: {
              inDropdown: false,
              className: 'd-flex',
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              className: 'd-flex',
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
            },
            link: {
              inDropdown: false,
              className: 'd-flex',
              component: undefined,
              popupClassName: 'text-editor-popup-classname',
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link', 'unlink'],
            },
            blockType: {
              className: 'text-editor-titles',
              dropdownClassName: 'dropdown-custom',
              options: [
                'Normal',
                'H2',
                'H3',
                'H4',
                'H5',
                'H6',
              ],
            },

          }}
        />
      </div>
      {isError ? (
        <div className="ml-1">
          <span className="light-red-text medium-weight regular-text">
            {errorMessage}
          </span>
        </div>
      ) : null}
    </div>
  );
};

TextEditorComponent.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  hasTitles: PropTypes.bool,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
};

TextEditorComponent.defaultProps = {
  errorMessage: 'Error',
  isError: false,
  disabled: false,
  onBlur: () => {},
  placeholder: '',
  hasTitles: false,
};

export default TextEditorComponent;
