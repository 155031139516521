import React, { useState, useEffect } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

// Components
import {
  ButtonComponent, LoaderComponent, SelectComponent, PaginationComponent, MasonryComponent,
} from '@zolteam/axenergie-ui-library';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import DocBaseService from '../../../services/DocBaseService';

// Hooks
import { useAppContext } from '../../../store/AppContext';
import DocumentCard from '../../../components/molecules/DocumentCard';
import FilterButton from '../../../components/atoms/FilterButton';
import SearchInputComponent from '../../../components/atoms/SearchInputComponent';
import FileService from '../../../services/FileService';
import utils from '../../../utils/utils';
import useIsMobile from '../../../hooks/useIsMobile';

const DocBaseList = () => {
  const [baseName, setBaseName] = useState('');
  // Pagination related variables
  const [currentList, setCurrentList] = useState([]);
  const [totalLength, setTotalLength] = useState(1);
  const itemsPerPage = 12;
  const itemsMargin = 24;
  // Filters related variables
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [search, setSearch] = useState('');
  // Measures relative variables
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const columnWidth = screenWidth < 500 + 2 * itemsMargin + 16 ? screenWidth - 2 * itemsMargin - 16 : 500;

  // Store
  const [{
    permissions, user, docBases, previousSearch, previousCategory, previousDocBasePage: currentPage,
  }, appDispatch] = useAppContext();
  const { base } = useParams();
  const history = useHistory();
  const [isMobile] = useIsMobile();

  // Queries
  const canOnlySeePublicDocs = () => [5, 6].includes(user?.data?.roleId);

  const getItems = useQuery(['getDocs', base, categoryFilter, typeFilter, currentPage],
    () => DocBaseService.getItems({
      base,
      categoryId: previousCategory?.value || categoryFilter?.value,
      typeId: typeFilter,
      search: previousSearch || search || null,
      pageNumber: currentPage,
      pageSize: itemsPerPage,
      isPublic: canOnlySeePublicDocs() ? 'true' : undefined,
    }));

  const { isError: getCategoriesError, data: categories } = useQuery(['getDocCategories', base],
    () => DocBaseService.getCategories({ base }));

  const { isError: getTypesError, isLoading: getTypesLoading, data: types } = useQuery(['getDocTypes', categoryFilter],
    () => {
      if (categoryFilter?.value) {
        return DocBaseService.getTypes({ category: categoryFilter.value });
      }
      return false;
    });

  const getFiles = useMutation('getAttachements',
    async (ids) => {
      const promises = [];
      ids.map((fileId) => promises.push(FileService.getFile({ id: fileId })));
      return Promise.all(promises);
    });

  useEffect(() => {
    if (docBases) {
      setBaseName(docBases?.find(({ id }) => id.toString() === base)?.name?.toLowerCase());
    }
  }, [docBases, base]);

  useEffect(() => {
    setCategoryFilter(null);
    if (previousSearch) {
      setSearch(previousSearch);
      appDispatch({
        type: 'SET_PREVIOUS_SEARCH',
        payload: '',
      });
    } else setSearch('');
    if (previousCategory) {
      setCategoryFilter(previousCategory);
      appDispatch({
        type: 'SET_PREVIOUS_CATEGORY',
        payload: null,
      });
    } else setCategoryFilter(null);
  }, [base]);

  useEffect(() => {
    if (getFiles.isSuccess) {
      getFiles?.data?.map(
        (toDownload) => utils.downloadBlob(toDownload.data, toDownload.headers['x-file-sanitized-name']),
      );
    }
    if (getFiles.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.fileDownload, isSuccess: false },
      });
    }
  }, [getFiles.isSuccess, getFiles.isError, getFiles.data]);

  useEffect(() => {
    if (getItems.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAnnouncements, isSuccess: false },
      });
    }
    if (getItems.isSuccess) {
      setCurrentList(getItems.data?.data?.documentBaseItems);
      setTotalLength(getItems.data?.data?.pageCount);
    }
  }, [getItems.isError, getItems.isSuccess, getItems.data]);

  useEffect(() => {
    if (getCategoriesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
  }, [getCategoriesError]);

  useEffect(() => {
    if (getTypesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getTypes, isSuccess: false },
      });
    }
  }, [getTypesError]);

  const doSearch = () => {
    getItems.refetch();
  };

  useEffect(() => {
    if (search === '') {
      doSearch();
    }
  }, [search]);

  const setCurrentPage = (page) => {
    appDispatch({
      type: 'SET_PREVIOUS_DOC_BASE_PAGE',
      payload: page,
    });
  };

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 3) {
      nbColumns = 3;
    }
    return (nbColumns * columnWidth) + (itemsMargin / 2);
  };

  const getCategoryName = (categoryId) => categories?.data?.documentBaseItemCategories?.find(
    ({ id }) => id === categoryId,
  )?.name;

  const formatTypesData = (typesData) => typesData?.map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const renderEmptyList = () => {
    if (getItems.isLoading
            || getItems.isError
    ) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noDocument}</span>
      </div>
    );
  };

  const canEditDoc = () => {
    // If user as permission to edit docs
    if (JSON.parse(permissions).includes('document_base_administration')) {
      const userRegion = user?.data?.regionId;
      // If user is related to a region (not network-admin), then return true only for his region's doc base
      if (userRegion) {
        const userBase = docBases.find(({ regionId }) => regionId === userRegion)?.id;
        return userBase?.toString() === base?.toString();
      }
      // else (user is network-admin) return true
      return true;
    }
    return false;
  };

  const goToEdit = (id = null) => history.push(
    `/intranet/doc-base/${base}/edit-doc${id ? `?id=${id}` : ''}`,
  );
  const goToDetails = (id) => {
    if (search) {
      appDispatch({
        type: 'SET_PREVIOUS_SEARCH',
        payload: search,
      });
    }
    if (categoryFilter?.value) {
      appDispatch({
        type: 'SET_PREVIOUS_CATEGORY',
        payload: categoryFilter,
      });
    }
    history.push(
      `/intranet/doc-base/${base}/${id}`,
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start f-wrap" ref={containerRef}>
        <div className="d-flex justify-start f-row align-center f-wrap">
          <div className="mr-5 d-flex f-column align-start">
            <h2 className="medium-weight mh-0">
              <span>{strings.docBase}</span>
              <span className="mh-2 capitalize">{baseName}</span>
            </h2>
            <span className="grey-400-text mb-5">{strings.docBaseSubTitle}</span>
          </div>
          {canEditDoc()
            ? (
              <div className="mb-3">
                <ButtonComponent onClick={() => goToEdit()}>
                  <span>{strings.addDocument}</span>
                </ButtonComponent>
              </div>
            )
            : null}
        </div>
        {/* Filters */}
        <div
          className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
          style={{ width: isMobile ? '100%' : getContainerWidth() }}
        >
          <div className={`d-flex ${isMobile ? 'f-column' : 'f-row'} align-center justify-between full-width`}>
            {/* Categories filter */}
            { !getCategoriesError && categories?.data?.documentBaseItemCategories ? (
              <div className="form-input-width m-3">
                <SelectComponent
                  isLoading={getTypesLoading}
                  data={formatTypesData(categories?.data?.documentBaseItemCategories)}
                  name="doc-base-category-filter"
                  value={categoryFilter}
                  setValue={(value) => {
                    if (value) setSearch('');
                    setCategoryFilter(value);
                  }}
                  theme="dark"
                  isClearable
                  placeholder={strings.category}
                />
              </div>
            ) : null}
            {/* search button */}
            <div className="form-input-width mh-3 mv-2">
              <SearchInputComponent
                onChange={(value) => {
                  if (value) setCategoryFilter(null);
                  setSearch(value);
                }}
                onClick={doSearch}
                id="document-search"
                value={search}
              />
            </div>
          </div>
          {/* Sub Categories filters */}
          <div className="d-flex f-row f-wrap align-center justify-center">
            {
                types?.data?.documentBaseItemTypes?.map((type) => (
                  <FilterButton
                    key={type.id}
                    onClick={() => setTypeFilter((state) => (state === type.id ? undefined : type.id))}
                    isSelected={typeFilter === type.id}
                    title={type.name}
                  />
                ))
              }
          </div>
        </div>

        <div style={{
          width: isMobile ? '100%' : getContainerWidth(),
        }}
        >
          <div
            className="white-background"
            style={{
              paddingLeft: isMobile ? (width - getContainerWidth()) / 2 + (itemsMargin / 2)
                : itemsMargin / 2,
              paddingTop: itemsMargin,
            }}
          >
            {
            currentList?.length
            && !getItems.isLoading
              ? (
                <MasonryComponent containerWidth={getContainerWidth()} columnWidth={columnWidth}>
                  {
                    currentList?.map(({
                      id, title, categoryId, shortDescription, lastEditionDate, joinedFiles,
                    }) => (
                      <DocumentCard
                        key={id}
                        download={() => {
                          if (joinedFiles?.length) {
                            getFiles.mutate(joinedFiles?.map(({ fileId }) => fileId));
                          }
                        }}
                        isDownloadButton={!!joinedFiles?.length}
                        width={columnWidth - itemsMargin}
                        title={title}
                        className="grid-item"
                        category={getCategoryName(categoryId)}
                        content={shortDescription}
                        date={lastEditionDate}
                        isEditable={canEditDoc()}
                        edit={() => goToEdit(id)}
                        open={() => goToDetails(id)}
                      />
                    ))
                  }
                </MasonryComponent>
              )
              : renderEmptyList()
            }
            <PaginationComponent
              setCurrentList={setCurrentList}
              totalPage={totalLength || 1}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default DocBaseList;
