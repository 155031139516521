import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { LoaderComponent, SelectComponent } from '@zolteam/axenergie-ui-library';
import PropTypes from 'prop-types';
// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';
// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TabComponent from '../../../components/molecules/TabComponent';
import DemandItem from '../../../components/molecules/DemandItem';
// Hooks and services
import useIsMobile from '../../../hooks/useIsMobile';
import DemandService from '../../../services/DemandService';
import AgencyService from '../../../services/AgencyService';
import AdherentService from '../../../services/AdherentService';

import { useAppContext } from '../../../store/AppContext';

const AllDemands = ({ contactTypes, title, subtitle }) => {
  const [agencySelected, setAgencySelected] = useState(null);
  const [processed, setProcessed] = useState(false);

  const [isMobile] = useIsMobile();
  const [, appDispatch] = useAppContext();

  // Queries
  const getAgencies = useQuery(['getAgencies'], () => AgencyService.getAllAgencies());
  const getAdherents = useQuery(['get-adherents'], () => AdherentService.getAdherents());

  const getDemands = useQuery(['getDemands', processed, agencySelected],
    () => {
      if (agencySelected) {
        return (DemandService.getContacts({
          agencyId: agencySelected.value || undefined,
          isProcessed: processed,
          search: undefined,
          contactTypes,
        }));
      }
      return null;
    });

  const getAdherentName = (agency, adherents) => adherents.find((adherent) => adherent.id === agency.adherentId);

  const formatAgenciesData = (agencies, adherents) => agencies?.map(
    (agency) => {
      const adherent = getAdherentName(agency, adherents);
      // eslint-disable-next-line
      return ({ value: agency.id, label: adherent?.name + " - " + agency.name });
    },
  ).sort((a, b) => {
    if (a.label < b.label) return -1;
    return 1;
  });

  useEffect(() => {
    if (!getAgencies.isLoading && getAgencies.isSuccess && getAgencies?.data?.data?.agencies.length
      && !getAdherents.isLoading && getAdherents.isSuccess && getAdherents?.data?.data?.adherents.length) {
      const { agencies } = getAgencies?.data?.data;
      const { adherents } = getAdherents?.data?.data;

      const region = getAdherentName(agencies[0], adherents);
      setAgencySelected({
        value: agencies[0].id,
        // eslint-disable-next-line
        label: region?.name + " - " + agencies[0].name,
      });
    }
  }, [getAgencies.isLoading,
    getAgencies.isSuccess,
    getAgencies?.data,
    getAdherents.isLoading,
    getAdherents.isSuccess,
    getAdherents?.data,
  ]);

  useEffect(() => {
    if (getDemands.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.demandsLoading, isSuccess: false },
      });
    }
    if (getAgencies.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAgencies, isSuccess: false },
      });
    }
  }, [getDemands.isError, getAgencies.isError]);

  const renderEmptyList = () => {
    if (getDemands.isLoading
            || getDemands.isError
    ) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noDemand}</span>
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
      >
        <div
          className="d-flex f-row f-wrap justify-between align-center mt-4"
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{title}</h2>
            <span className="grey-400-text mb-5">{subtitle}</span>
          </div>
          <div className="form-input-width mh-3 mv-2">
            <SelectComponent
              isLoading={!getAgencies?.data && !getAdherents?.data}
              data={formatAgenciesData(getAgencies?.data?.data?.agencies, getAdherents?.data?.data?.adherents)}
              name="agency-filter"
              value={agencySelected}
              setValue={(value) => setAgencySelected(value)}
              theme="dark"
              placeholder={strings.selectAgency}
            />
          </div>
        </div>
        <TabComponent tabs={[
          {
            onClick: () => setProcessed(false),
            isActive: !processed,
            title: strings.toHandle,
          },
          {
            onClick: () => setProcessed(true),
            isActive: processed,
            title: strings.handledPlural,
          },
        ]}
        />
        <div className="full-width" style={{ maxWidth: 1300 }}>
          {
              !getDemands?.data?.data?.contacts?.length
                ? renderEmptyList()
                : (
                  <div className="d-flex f-column">
                    {
                        getDemands?.data?.data?.contacts?.map(({
                          address,
                          postalCode,
                          city,
                          isClientKnown,
                          data,
                          fullName,
                          emailAddress,
                          creationDate,
                          id,
                          type,
                          isProcessed,
                          phone,
                        }) => (
                          <div key={id} className="mb-3">
                            <DemandItem
                              id={id}
                              address={address}
                              zipcode={postalCode}
                              city={city}
                              isClientKnown={isClientKnown}
                              subject={data?.subject}
                              fullName={fullName}
                              email={emailAddress}
                              type={type}
                              creationDate={creationDate}
                              specificContent={data}
                              isProcessed={isProcessed}
                              phone={phone}
                              toggleProcessed={() => null}
                              readOnly
                            />
                          </div>
                        ))
                    }
                  </div>
                )
          }
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

AllDemands.propTypes = {
  contactTypes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default AllDemands;
