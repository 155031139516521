import React from 'react';
import PropTypes from 'prop-types';
// Constants
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import strings from '../../../constants/strings';
import FileService from '../../../services/FileService';
import utils from '../../../utils/utils';
// Hooks

const QuotationDemandContent = ({
  number,
  amount,
  date,
  state,
  fileId,
}) => {
  const downloadQuotation = useMutation('getFileAndDownload',
    async ({ id, fileName }) => {
      const file = await FileService.getFile({ id });
      return utils.downloadBlob(file.data, fileName);
    });

  return (
    <div>
      <div
        className="d-flex f-row justify-between align-center f-wrap"
      >
        <div className="d-flex f-row f-wrap">
          <span className="grey-400-text medium-text">{`${strings.quotationNumber} :`}</span>
          <span className="grey-800-text medium-text medium-weight ml-2">
            {number}
          </span>
        </div>
        <div className="d-flex f-row f-wrap">
          <span className="grey-400-text medium-text">{`${strings.quotationAmount} :`}</span>
          <span className="grey-800-text medium-text medium-weight ml-2">
            {amount}
            €
          </span>
        </div>
        <div className="d-flex f-row f-wrap">
          <span className="grey-400-text medium-text">{state}</span>
        </div>
        {fileId ? (
          <button
            type="button"
            className="low-opacity-hover pr-0"
            onClick={() => downloadQuotation.mutate({ id: fileId, fileName: number })}
          >
            <span className="primary-red-text bold mh-3">
              {strings.seeQuotation}
            </span>
          </button>
        ) : null}
      </div>
      <div className="d-flex f-row justify-end">
        {date ? (
          <div className="d-flex f-row f-wrap">
            <span className="grey-400-text medium-text mr-3">
              {`          
              ${strings.demandFrom}
              ${format(new Date(date?.split(' ').join('T')), 'dd MMMM yyyy', { locale: frLocale })}
            `}
            </span>

          </div>
        ) : null}
      </div>
    </div>
  );
};

QuotationDemandContent.propTypes = {
  number: PropTypes.string,
  amount: PropTypes.string,
  date: PropTypes.string,
  state: PropTypes.string,
  fileId: PropTypes.string,
};

QuotationDemandContent.defaultProps = {
  number: null,
  amount: null,
  date: null,
  state: null,
  fileId: null,
};

export default QuotationDemandContent;
