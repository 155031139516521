import client from './client';
import clientRPC from './clientRPC';

const getMonthlyResults = ({ agencyId, year, month }) => client.get('/monthly-results',
  { params: { agencyId, year, month } });
const createMonthlyResult = (monthlyResult) => client.post('/monthly-results', monthlyResult);
const updateMonthlyResult = (id, monthlyResult) => client.put(`/monthly-results/${id}`, monthlyResult);

const url = ({ agencyId, month, year }) => `/my-agency-dashboard-monthly-results/${agencyId}/${year}${month}`;
const urlAnnual = ({ agencyId, year }) => `/my-agency-dashboard-year-results/${agencyId}/${year}`;
const dashboardMonthlyResults = (urlParams) => clientRPC.get(url(urlParams));
const dashboardAnnualResults = (urlParams) => clientRPC.get(urlAnnual(urlParams));

const networkDashboardMonthlyResults = ({ month, year }) => clientRPC.get(
  `/network-dashboard-monthly-results/${year}${month}`,
);
const networkDashboardAnnualResults = ({ year }) => clientRPC.get(
  `/network-dashboard-year-results/${year}`,
);

const getMonthlyResultEquipments = () => client.get('/monthly-result-equipments');

export default {
  getMonthlyResults,
  createMonthlyResult,
  updateMonthlyResult,
  dashboardMonthlyResults,
  dashboardAnnualResults,
  networkDashboardMonthlyResults,
  networkDashboardAnnualResults,
  getMonthlyResultEquipments,
};
