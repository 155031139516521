import client from './client';
import clientRPC from './clientRPC';

const postSignatureDocuments = (data) => client.post('/signature-documents', data);

const getSignatureDocuments = (data) => client.get('/signature-documents', { params: data });
const getSignatureFiles = (id) => client.get(`/files/${id}`, { responseType: 'blob' });
const getSignatureCount = (data) => clientRPC.get('/signature-counts-by-agency', { params: data });

export default {
  postSignatureDocuments,
  getSignatureDocuments,
  getSignatureFiles,
  getSignatureCount,
};
