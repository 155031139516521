import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ClosedEye } from '../../../images/eye-off.svg'
import { ReactComponent as OpenedEye } from '../../../images/eye-on.svg'
import '../../../styles/input.scss'
import { useMeasure } from 'react-use'

const InputComponent = ({
  value,
  onChange,
  label,
  type,
  id,
  name,
  errorMessage,
  isError,
  onBlur,
  disabled,
  placeholder
}) => {
  const [localType, setLocalType] = useState(type)
  const [eyeImg, setEyeImg] = useState('off')
  const [labelRef, { width: labelWidth }] = useMeasure()
  const [imgRef, { width: imgWidth }] = useMeasure()
  const [containerRef, { width: containerWidth }] = useMeasure()

  const togglePassword = (e) => {
    e.stopPropagation()
    const newImg = eyeImg === 'off' ? 'on' : 'off'
    const newType = localType === 'password' ? 'text' : 'password'
    setEyeImg(newImg)
    setLocalType(newType)
  }

  const getInputWidth = () => {
    return containerWidth - (labelWidth + imgWidth + 30)
  }

  return (
    <div ref={containerRef} className='d-flex f-column'>
      <div
        className={`d-flex ${
          getInputWidth() < 30 ? 'f-column' : 'f-row'
        } align-center justify-between grey-100-background input-container p-3 radius-5`}
        style={{
          opacity: disabled ? 0.5 : 1,
          borderColor: isError ? '#e13c32' : 'rgba(31,35,44,0.12)'
        }}
      >
        <div
          className={`d-flex ${
            getInputWidth() < 30 ? 'f-column full-width' : 'f-row'
          }`}
        >
          <label
            className='input-label w-fit-content'
            ref={labelRef}
            htmlFor={id}
          >
            <span
              className='mr-2 regular-text'
              style={{ color: value ? '#8890a1' : '#1f232c' }}
            >
              {label}
            </span>
          </label>
          <input
            style={{
              width:
                getInputWidth() < 30 ? containerWidth - 30 : getInputWidth()
            }}
            type={localType}
            id={id}
            name={name || id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onWheel={(event) => event.currentTarget.blur()}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholder}
          />
        </div>
        {type === 'password' ? (
          <div
            ref={imgRef}
            className='d-flex align-center'
            onClick={togglePassword}
          >
            {eyeImg === 'on' ? <OpenedEye /> : <ClosedEye />}
          </div>
        ) : null}
      </div>
      {isError ? (
        <div className='ml-1'>
          <span className='light-red-text medium-weight regular-text'>
            {errorMessage}
          </span>
        </div>
      ) : null}
    </div>
  )
}

InputComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
}

InputComponent.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  errorMessage: 'Error',
  isError: false,
  onBlur: () => {},
  disabled: false,
  placeholder: '',
  value: ''
}

export default InputComponent
