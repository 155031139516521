import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWithArrow } from '@zolteam/axenergie-ui-library';
import strings from '../../constants/strings';
import FilterButton from '../atoms/FilterButton';

const AnnouncementFilters = ({
  width, setType, type, energyTypes, energy, setEnergy,
}) => (
  <div
    className="white-background pv-3 mb-2 d-flex f-row f-wrap align-center justify-between"
    style={{ width }}
  >
    {/* Announcements type filters */}
    <div className="d-flex f-row f-wrap">
      <div className="mh-4 mv-2">
        <ButtonWithArrow
          onClick={() => setType((state) => (state === 'DEMANDE' ? undefined : 'DEMANDE'))}
          isSelected={type === 'DEMANDE'}
          title={strings.demands}
        />
      </div>
      <div className="mh-4 mv-2">
        <ButtonWithArrow
          onClick={() => setType((state) => (state === 'OFFRE' ? undefined : 'OFFRE'))}
          isSelected={type === 'OFFRE'}
          title={strings.OFFER}
        />
      </div>
    </div>
    {/* Energy type filters */}
    <div>
      {
        energyTypes?.map(({ code }) => (
          <FilterButton
            key={code}
            onClick={() => setEnergy((state) => (state === code ? undefined : code))}
            isSelected={energy === code}
            title={code}
          />
        ))
      }
    </div>
  </div>
);

AnnouncementFilters.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setType: PropTypes.func.isRequired,
  setEnergy: PropTypes.func.isRequired,
  type: PropTypes.string,
  energy: PropTypes.string,
  energyTypes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  })),
};

AnnouncementFilters.defaultProps = {
  energyTypes: null,
  type: null,
  energy: null,
};

export default AnnouncementFilters;
