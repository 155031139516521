import client from './client';

/**
 * Initialize payment flow
 * @param contractId
 * @returns {Promise<AxiosResponse<any>>}
 */
const initializePayment = (contractId) => client.post(`/agency-contract-invoices/${contractId}:initialize-payment`);

const getPaymentStatus = (id) => client.get(`/agency-contract-invoices/${id}/payment-status`);

export default {
  initializePayment,
  getPaymentStatus,
};
