import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/masonry.scss'

const isServer = () => typeof window === 'undefined'

const MasonryComponent = ({ children, columnWidth, containerWidth }) => {
  const containerRef = useRef()

  useEffect(() => {}, [])

  useEffect(() => {
    if (containerRef.current) {
      if (!isServer()) {
        import('masonry-layout').then((Masonry) => {
          // eslint-disable-next-line no-new,new-cap
          new Masonry.default(containerRef.current, {
            itemSelector: '.grid-item',
            columnWidth,
            horizontalOrder: true
          })
        })
      }
    }
  }, [containerRef, containerWidth, children])

  return (
    <div className='grid' ref={containerRef}>
      {children}
    </div>
  )
}

MasonryComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  columnWidth: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired
}

export default MasonryComponent
