import client from './client';
import clientRPC from './clientRPC';

// ADHERENTS

const getAdherents = () => client.get('/adherents');
const getAdherent = ({ id }) => client.get(`/adherents/${id}`);
const createAdherent = ({ name, regionId }) => client.post('/adherents', {
  name,
  regionId,
});
const updateAdherent = ({ name, regionId, id }) => client.put(`/adherents/${id}`, {
  name,
  regionId,
  id,
});

// REGIONS

const getRegions = () => client.get('/regions');

const myAdherentDashboardContacts = (agencyId) => clientRPC.get(`/my-adherent-dashboard-contacts/${agencyId}`);
const networkDashboardContacts = () => clientRPC.get('/network-dashboard-contacts');

export default {
  getAdherents,
  getRegions,
  createAdherent,
  getAdherent,
  updateAdherent,
  myAdherentDashboardContacts,
  networkDashboardContacts,
};
