import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { CheckboxComponent } from '@zolteam/axenergie-ui-library';
// Constants
import strings from '../../../constants/strings';
// Hooks
import useIsMobile from '../../../hooks/useIsMobile';

const ContactDemandContent = ({
  message,
  source,
  optInNewsletter,
  isMorning,
  isAfternoon,
  isNoon,
  isAllDay,
  date,
  hasNewsletter,
}) => {
  const [isMobile] = useIsMobile();
  return (
    <div>
      <div
        className="d-flex f-row justify-between align-center f-wrap"
      >
        <div
          className="mr-4"
          style={{
            maxHeight: 150,
            maxWidth: isMobile ? '100%' : '65%',
            overflow: 'auto',
          }}
        >
          <span className="medium-text normal-weight grey-800-text ws-pre-wrap">
            {message}
          </span>
        </div>
        <div className="d-flex f-column justify-between full-height">
          <div className="d-flex f-row f-wrap mb-4">
            <span className="grey-400-text medium-text normal-weight mr-2">
              {`${strings.demandSentFrom} : `}
            </span>
            <span className="grey-800-text medium-text normal-weight">
              {source}
            </span>
          </div>
          {hasNewsletter ? (
            <CheckboxComponent
              value={optInNewsletter}
              handleChange={() => {
              }}
              label={(
                <span className="grey-400-text medium-text normal-weight">
                  {strings.wantsToReceiveNewsletter}
                </span>
            )}
              id="disabled-checkbox"
              disabled
            />
          ) : (
            <div>
              <span className="grey-400-text medium-text normal-weight mr-2">
                {`${strings.availibilities} : `}
              </span>

              <div className="d-flex f-row f-wrap">
                <div className="mr-3">
                  <CheckboxComponent
                    value={isMorning}
                    handleChange={() => {}}
                    label={(
                      <span className="grey-400-text medium-text normal-weight">
                        {strings.morning}
                      </span>
                  )}
                    id="disabled-morning-checkbox"
                    disabled
                  />
                </div>
                <div className="mr-3">

                  <CheckboxComponent
                    value={isNoon}
                    handleChange={() => {}}
                    label={(
                      <span className="grey-400-text medium-text normal-weight">
                        {strings.contactNoon}
                      </span>
                  )}
                    id="disabled-afternoon-checkbox"
                    disabled
                  />
                </div>
                <div className="mr-3">

                  <CheckboxComponent
                    value={isAfternoon}
                    handleChange={() => {}}
                    label={(
                      <span className="grey-400-text medium-text normal-weight">
                        {strings.afternoon}
                      </span>
                  )}
                    id="disabled-afternoon-checkbox"
                    disabled
                  />
                </div>
                <div className="mr-3">

                  <CheckboxComponent
                    value={isAllDay}
                    handleChange={() => {}}
                    label={(
                      <span className="grey-400-text medium-text normal-weight">
                        {strings.contactAllDay}
                      </span>
                  )}
                    id="disabled-afternoon-checkbox"
                    disabled
                  />
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
      {date ? (
        <div className="d-flex f-row f-wrap justify-end mt-3">
          <span className="grey-400-text medium-text">
            {`
              ${strings.demandFrom}
              ${format(new Date(date?.split(' ').join('T')), 'dd MMMM yyyy', { locale: frLocale })}
            `}
          </span>
        </div>
      ) : null}
    </div>
  );
};

ContactDemandContent.propTypes = {
  message: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  hasNewsletter: PropTypes.bool.isRequired,
  optInNewsletter: PropTypes.bool,
  isMorning: PropTypes.bool,
  isAfternoon: PropTypes.bool,
  isNoon: PropTypes.bool,
  isAllDay: PropTypes.bool,
  date: PropTypes.string,
};

ContactDemandContent.defaultProps = {
  optInNewsletter: null,
  isMorning: null,
  isAfternoon: null,
  isNoon: null,
  isAllDay: null,
  date: null,
};

export default ContactDemandContent;
