import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';

// Components
import DashboardPanel from './DashboardPanel';

// Icons
import { ReactComponent as AddIcon } from '../../assets/images/add-expand.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/minus-subtract.svg';

const CollapsingPanel = ({
  openOnMount, children, headerText, headerIcon, hasChildren,
}) => {
  const [isOpen, setIsOpen] = useState(openOnMount);

  const [content, { height: contentHeight }] = useMeasure();
  const childHeight = `${contentHeight / 16}rem`;

  const handleToggleClick = () => {
    setIsOpen(((open) => !open));
  };

  const renderIcon = () => (
    isOpen ? (
      <MinusIcon
        style={{
          width: 50,
          minWidth: 50,
          maxWidth: 50,
        }}
      />
    ) : (
      <AddIcon style={{
        width: 50,
        minWidth: 50,
        maxWidth: 50,
      }}
      />
    )
  );

  return (
    <DashboardPanel className="mv-4">
      <div
        className="d-flex f-wrap justify-center"
      >
        <div>{headerIcon}</div>
        <div style={{ flexGrow: '1' }} className="ml-3">
          <button
            type="button"
            onClick={() => {
              if (hasChildren) {
                handleToggleClick();
              }
            }}
            className="d-flex justify-between align-center full-width"
          >
            <h4 className="m-0 medium-weight">
              { headerText }
            </h4>
            {hasChildren ? renderIcon() : null}
          </button>
          <div
            className="collapsing-panel"
            style={{
              maxHeight: isOpen ? childHeight : 0,
            }}
          >
            <div ref={content}>{children}</div>
          </div>
        </div>
      </div>
    </DashboardPanel>
  );
};

CollapsingPanel.propTypes = {
  openOnMount: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headerIcon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headerText: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
};

CollapsingPanel.defaultProps = {
  openOnMount: false,
  hasChildren: true,
};

export default CollapsingPanel;
