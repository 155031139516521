import client from './client';

const getCategories = ({ base }) => client.get('/document-base-item-categories', {
  params: {
    documentBaseId: base,
  },
});

const getTypes = ({ category }) => client.get('/document-base-item-types', {
  params: {
    categoryId: category,
  },
});

const getBases = () => client.get('/document-bases');

const getItems = ({
  base, categoryId, typeId, search, pageNumber, pageSize, isPublic,
}) => client.get('/document-base-items', {
  params: {
    documentBaseId: base,
    categoryId,
    typeId,
    search,
    pageNumber,
    pageSize,
    isPublic,
  },
});

const getOneDoc = ({ id }) => client.get(`/document-base-items/${id}`);

const addDoc = ({ document }) => client.post('/document-base-items', document);
const updateDoc = ({ document }) => client.put(`/document-base-items/${document.id}`, document);
const deleteDoc = (id) => client.delete(`/document-base-items/${id}`);

export default {
  getCategories,
  getTypes,
  getItems,
  getBases,
  addDoc,
  updateDoc,
  getOneDoc,
  deleteDoc,
};
