import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({
  link, isOpen, title, icon, countTag, className,
}) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${className}`}
    >
      <Link
        className={`menu-icon ${pathname === link ? 'active' : ''}`}
        to={link}
      >

        {countTag > 0 ? (
          <span className="menu-icon-count-tag">
            {countTag}
          </span>
        ) : null}

        <div className="d-flex f-row align-center mv-2">
          {icon}
          {isOpen
            ? (
              <div className="d-flex f-row align-center">
                <span
                  className="ws-nowrap white-text mh-2 regular-text first-letter-capitalize"
                >
                  {title}
                </span>
              </div>
            )
            : null}
        </div>
      </Link>
    </div>
  );
};

MenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  countTag: PropTypes.number,
  className: PropTypes.string,
};

MenuItem.defaultProps = {
  icon: null,
  className: 'ph-2 menu-item-height',
  countTag: 0,
};

export default MenuItem;
