import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import colors from '../../constants/colors';

const DragNDropList = ({
  data, renderItem, doReorder, theme,
}) => {
  const [reorderedList, setReorderedList] = useState(data);

  useEffect(() => {
    setReorderedList(data);
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return false;
    }
    const newList = reorder(reorderedList, result.source.index, result.destination.index);
    const draggedItem = reorderedList[result.source.index];
    const newPrecedingItem = newList[newList.indexOf(draggedItem) - 1] || null;
    doReorder(draggedItem, newPrecedingItem);
    setReorderedList(newList);
    return 'reorder';
  };

  const getItemBackgroundColor = (isDragging) => {
    if (theme === 'dark') {
      return isDragging ? colors.white : colors.grey100;
    }
    return isDragging ? colors.grey100 : colors.white;
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    backgroundColor: getItemBackgroundColor(isDragging),
    borderRadius: 5,
    ...draggableStyle,
  });

  const getListStyle = () => ({
    backgroundColor: theme === 'dark' ? colors.grey100 : colors.white,
  });

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <Droppable droppableId="list-item">
        {
          (provided, snapshot) => (
            <div
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {
                reorderedList.map((item, index) => (
                  <Draggable
                    draggableId={item.id.toString()}
                    className="full-width prestation-line radius-5"
                    key={`prestation-${item.id}`}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.draggableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.dragHandleProps}
                        style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                      >
                        {renderItem(item)}
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )
            }
      </Droppable>
    </DragDropContext>
  );
};

DragNDropList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
  doReorder: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

DragNDropList.defaultProps = {
  theme: 'dark',
};

export default DragNDropList;
