import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

// Components
import { ButtonComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/strings';

// Images
import { ReactComponent as IwantItIcon } from '../../assets/images/iwantit.svg';
import { ReactComponent as IhaveIt } from '../../assets/images/ihaveit.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as CheckboxIcon } from '../../assets/images/checkbox-circle.svg';

const AnnouncementCard = ({
  className,
  width,
  category,
  type,
  title,
  content,
  endDate,
  agency,
  author,
  contact,
  action,
  isEditable,
  isExpired,
  deleteAction,
  editAction,
  hasResponse,
}) => {
  const renderActionButtons = () => {
    if (isEditable) {
      return (
        <div>
          <button
            type="button"
            onClick={deleteAction}
            className="tooltip d-flex justify-center"
          >
            <CheckboxIcon width={18} height={18} />
            <span className="tooltiptext">{strings.demandOk}</span>
          </button>
          <button
            type="button"
            onClick={editAction}
            className="tooltip d)flex justify-center"
          >
            <EditIcon width={18} height={18} />
            <span className="tooltiptext">{strings.edit}</span>
          </button>
        </div>
      );
    }

    return (
      <div className="with-polygon">
        <ButtonComponent onClick={action} theme={hasResponse ? 'primary' : 'outline'}>
          <div className="d-flex align-center">
            <span className="ws-nowrap mr-2">{type === 'DEMANDE' ? strings.gotIt : strings.wantIt}</span>
            {type === 'DEMANDE'
              ? <IhaveIt width={24} height={24} />
              : <IwantItIcon width={24} height={24} />}
          </div>
        </ButtonComponent>
      </div>
    );
  };
  return (
    <div className={`${className} relative`}>
      <div
        className="d-flex f-column align-start radius-5"
        style={{
          border: '1px solid #EDEEF1',
          width,
        }}
      >
        <div className="d-flex f-row justify-between align-center full-width">
          <div className="d-flex f-row justify-center align-center">
            <span className="bold small-text grey-300-text pl-3 pt-1">{strings[type]}</span>
            <span className="small-text uppercase primary-red-text ph-2 pt-1">{category}</span>
          </div>
          {isExpired
            ? <h5 className="bold grey-300-text uppercase mh-3 mv-0">{strings.expired}</h5>
            : null}
        </div>

        <h3 className="grey-800-text medium-weight ph-3 mb-3 mt-2">{title}</h3>
        <div
          className="d-flex f-column align-start grey-100-background"
          style={{ width }}
        >
          <div
            className="description-text ph-3 mv-2"
          >
            <span className="ws-pre-wrap word-break-word">{content}</span>
          </div>
          {endDate ? (
            <p className="small-text grey-400-text ph-3 mv-0">
              {`${strings.expireThe} ${format(new Date(endDate?.split(' ').join('T')), 'dd.MM.yyyy')}`}
            </p>
          ) : null}
        </div>
        <div className="d-flex f-column align-center grey-100-background" style={{ width }}>
          <div className="line" style={{ opacity: 0.1, width: width - 20 }} />
          <div className="d-flex f-row full-width justify-between align-center mv-3 f-wrap">
            <div className="d-flex f-column full-width">
              <span
                className="medium-text grey-400-text text-align-left mh-3"
              >
                {agency}
              </span>
              <div className="d-flex f-row grey-800-text align-center justify-between f-wrap">
                <div className="d-flex f-column ml-3">
                  <span className="medium-weight mr-2 ws-nowrap">{author}</span>
                  <span>{contact}</span>
                </div>
                <div className="mh-3">
                  {renderActionButtons()}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

AnnouncementCard.propTypes = {
  className: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  agency: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  isExpired: PropTypes.bool.isRequired,
  hasResponse: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
};

AnnouncementCard.defaultProps = {
  isEditable: false,
  deleteAction: () => {},
  editAction: () => {},
};

export default AnnouncementCard;
