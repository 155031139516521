import React from 'react';
import PropTypes from 'prop-types';

const Stat = ({
  valueColor,
  value,
  styles,
  label,
}) => (
  <div
    style={styles}
    className="d-flex f-column align-center"
  >
    <div style={{ fontSize: '60px', color: valueColor }}>{value}</div>
    <div
      style={{ lineHeight: '23px' }}
      className="text-align-center grey-400-text regular-text"
    >
      {label}
    </div>
  </div>
);

Stat.propTypes = {
  valueColor: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  styles: PropTypes.shape(),
};

Stat.defaultProps = {
  valueColor: 'white',
  styles: {},
};

export default Stat;
