import { Redirect } from 'react-router-dom';
import HomeAdmin from '../views/Intranet/HomeAdmin';
import UsersAdmin from '../views/Intranet/Admins/UsersAdmin';
import EditUserAdmin from '../views/Intranet/Admins/EditUserAdmin';
import Adherents from '../views/Intranet/Adherent/Adherents';
import EditAdherent from '../views/Intranet/Adherent/EditAdherent';
import EditAdherentAdmin from '../views/Intranet/AdherentAdmin/EditAdherentAdmin';
import EditAgency from '../views/Intranet/Agency/EditAgency';
import Prestations from '../views/Intranet/Prestation/Prestations';
import EditPrestation from '../views/Intranet/Prestation/EditPrestations';
import EditEquipment from '../views/Intranet/Prestation/EditEquipment';
import AgencyPage from '../views/Intranet/Agency/AgencyPage';
import NetworkNews from '../views/Intranet/News/NetworkNews';
import EditAgencyContent from '../views/Intranet/Agency/EditAgencyContent';
import NetworkNewsDetails from '../views/Intranet/News/NetworkNewsDetails';
import EditNetworkNews from '../views/Intranet/News/EditNetworkNews';
import NetworkAgencyContent from '../views/Intranet/Agency/NetworkAgencyContent';
import AdherentUsers from '../views/Intranet/AdherentUsers/AdherentUsers';
import EditAdherentUser from '../views/Intranet/AdherentUsers/EditAdherentUser';
import MyAgency from '../views/Intranet/Agency/MyAgency';
import SpareParts from '../views/Intranet/SpareParts/SpareParts';
import DocBaseList from '../views/Intranet/DocBase/DocBaseList';
import EditDocument from '../views/Intranet/DocBase/EditDocument';
import DocDetails from '../views/Intranet/DocBase/DocDetails';
import ForumList from '../views/Intranet/Forum/ForumList';
import CreateThread from '../views/Intranet/Forum/CreateThread';
import Thread from '../views/Intranet/Forum/Thread';
import Applications from '../views/Intranet/Applications/Applications';
import JobOffers from '../views/Intranet/JobOffer/JobOffers';
import EditJobOffer from '../views/Intranet/JobOffer/EditJobOffer';
import MonthlyResult from '../views/Intranet/MonthlyResult/MonthlyResult';
import DemandList from '../views/Intranet/Demand/DemandList';
import AllDemands from '../views/Intranet/Demand/AllDemands';
import Signature from '../views/Intranet/Signature/Signature';
import strings from '../constants/strings';
import AdherentBookList from '../views/Intranet/AdherentBookList/AdherentBookList';
import AdherentBookMap from '../views/Intranet/AdherentBookMap/AdherentBookMap';

const commonPath = '/intranet';

export default [
  {
    path: `${commonPath}`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <HomeAdmin /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/users`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <UsersAdmin /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/user`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditUserAdmin /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherents`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <Adherents /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAdherent /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent-admin`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAdherentAdmin /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/agency`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAgency /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/prestations`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <Prestations /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/prestation`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditPrestation /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/equipment`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditEquipment /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/agency-page`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <AgencyPage /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/network-news`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <NetworkNews /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/agency-page-content`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAgencyContent /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/network-agency-page-content`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAgencyContent /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/network-agency-page-contents`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <NetworkAgencyContent /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/network-news/:id`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <NetworkNewsDetails /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/network-news-edit`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditNetworkNews /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent-users`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <AdherentUsers /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent-user`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditAdherentUser /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/my-agency`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <MyAgency /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/spare-parts`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <SpareParts /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/doc-base/:base`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <DocBaseList /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/doc-base/:base/edit-doc`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditDocument /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/doc-base/:base/:id`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <DocDetails /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/forum/:forum`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <ForumList /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/forum/:forum/create-thread`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <CreateThread /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/forum/:forum/thread/:id`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <Thread /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent-book-list`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <AdherentBookList /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/adherent-book-map`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <AdherentBookMap /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/applications`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <Applications /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/job-offers`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <JobOffers /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/job-offer`,
    exact: false,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <EditJobOffer /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/monthly-result`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <MonthlyResult /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/demands`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? (
        <DemandList
          // eslint-disable-next-line max-len
          contactTypes="SITE_CONTACT,EXTRANET_AGENCY_CONTACT,CLIENT_PHONES_CHANGE_CONTACT,CLIENT_EMAIL_CHANGE_CONTACT,SOLUTIONS_CONTACT,AGENCY_CONTRACT_INVOICE_PAID_CONTACT,FREE_INVOICE_PAYMENT_CONTACT"
          title={strings.demandFromSite}
          subtitle={strings.demandPageSubtitle}
        />
      ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/quotations`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? (
        <DemandList
          contactTypes="AGENCY_CONTRACT_QUOTATION_ACCEPTED_CONTACT,AGENCY_CONTRACT_QUOTATION_REFUSED_CONTACT"
          title={strings.quotationFromSite}
          subtitle={strings.quotationPageSubtitle}
        />
      ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/invoices`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? (
        <DemandList
          contactTypes="AGENCY_CONTRACT_INVOICE_PAID_CONTACT,FREE_INVOICE_PAYMENT_CONTACT"
          title={strings.invoiceFromSite}
          subtitle={strings.invoicePageSubtitle}
        />
      ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/all-demands`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth
        ? (
          <AllDemands
            // eslint-disable-next-line max-len
            contactTypes="SITE_CONTACT,EXTRANET_AGENCY_CONTACT,CLIENT_PHONES_CHANGE_CONTACT,CLIENT_EMAIL_CHANGE_CONTACT,SOLUTIONS_CONTACT"
            title={strings.demandFromSite}
            subtitle={strings.demandPageSubtitle}
          />
        ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/all-quotations`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth
        ? (
          <AllDemands
            contactTypes="AGENCY_CONTRACT_QUOTATION_ACCEPTED_CONTACT,AGENCY_CONTRACT_QUOTATION_REFUSED_CONTACT"
            title={strings.quotationFromSite}
            subtitle={strings.quotationPageSubtitle}
          />
        ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/all-invoices`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth
        ? (
          <AllDemands
            contactTypes="AGENCY_CONTRACT_INVOICE_PAID_CONTACT,FREE_INVOICE_PAYMENT_CONTACT"
            title={strings.invoiceFromSite}
            subtitle={strings.invoicePageSubtitle}
          />
        ) : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
  {
    path: `${commonPath}/signature`,
    exact: true,
    render: (auth, { location }) => {
      const redirection = location.pathname.includes(commonPath) ? '/intranet/login' : '/extranet/login';
      return auth ? <Signature /> : <Redirect to={{ pathname: redirection, state: { from: location } }} />;
    },
  },
];
