import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Hooks
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// Components
import {
  ButtonComponent, InputComponent, LoaderComponent, SlashContainer,
} from '@zolteam/axenergie-ui-library';

// Services
import { useMeasure } from 'react-use';
import AuthService from '../services/AuthService';

// Utils
import colors from '../constants/colors';
import strings from '../constants/strings';

// Images
import logo from '../assets/images/logo.webp';
import arrow from '../assets/images/arrow-down.svg';

const ForgotPassword = ({ isAdmin }) => {
  // Local states
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showSuccessContent, setShowSuccessContent] = useState(false);

  // Hooks
  const history = useHistory();

  const [containerRef, { height: containerHeight }] = useMeasure();

  const resetPassword = useMutation('reset-password',
    (resetPasswordData) => {
      if (isAdmin) {
        return AuthService.adminResetPassword(resetPasswordData);
      }
      return AuthService.clientResetPassword(resetPasswordData);
    });

  // Methods
  const goToLogin = () => {
    history.push(isAdmin ? '/intranet/login' : '/extranet/login');
  };

  const validateField = (setError, value) => {
    setError(!value);
  };

  const onSubmit = () => {
    if (emailError) {
      return false;
    }
    return resetPassword.mutate({ email, context: 'reset' });
  };

  useEffect(() => {
    if (resetPassword.isSuccess) {
      setShowSuccessContent(true);
    }
  }, [resetPassword.isSuccess]);

  return (
    <div
      ref={containerRef}
      className="relative d-flex justify-center align-center grey-100-background min-height-full-screen"
      style={{ padding: '2rem' }}
    >
      <div className="full-size absolute">
        <SlashContainer width="42vw" backgroundColor={colors.grey800} offset={40}>
          <div
            className="full-screen"
            style={{ height: `calc(${containerHeight}px + 4rem)` }}
          />
        </SlashContainer>
      </div>
      <div
        className="d-flex f-column justify-center align-center
        white-background fgIndex radius-5 form-white-container"
      >
        <img src={logo} alt="" width={200} />
        {!showSuccessContent
          ? (
            <>
              <h3 className="medium-weight">{strings.resetPassword}</h3>
              <p className="grey-400-text">{strings.forgotPasswordSubtitle}</p>
              {resetPassword.isSuccess
                ? (
                  <p
                    className="green-text bold form-input-width"
                  >
                    {strings.resetPasswordSuccess}
                  </p>
                )
                : null}
              <div className="m-3 form-input-width">
                <InputComponent
                  onChange={setEmail}
                  id="email-input"
                  value={email}
                  label={strings.mail}
                  isError={emailError}
                  errorMessage={strings.errors.pleaseFillField}
                  onBlur={() => validateField(setEmailError, email)}
                />
              </div>
              <div className="m-3">
                <ButtonComponent onClick={onSubmit}>
                  <div className="d-flex justify-center align-center form-input-button">
                    {resetPassword.isLoading
                      ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                      : <span className="white-text uppercase bold mv-2">{strings.goOn}</span>}
                  </div>
                </ButtonComponent>
              </div>
              <div className="m-3">
                <ButtonComponent onClick={goToLogin} theme="grey">
                  <div className="d-flex justify-center align-center">
                    <img
                      alt="back-arrow"
                      className="rotate-90"
                      src={arrow}
                      width={20}
                    />
                    <span>{strings.back}</span>
                  </div>
                </ButtonComponent>
              </div>
            </>
          )
          : (
            <>
              <p className="form-input-width grey-600-text pv-5">{strings.resetPasswordSuccess}</p>
              <ButtonComponent onClick={goToLogin} theme="grey">
                <div className="d-flex justify-center align-center">
                  <img
                    alt="back-arrow"
                    className="rotate-90"
                    src={arrow}
                    width={20}
                  />
                  <span>{strings.back}</span>
                </div>
              </ButtonComponent>
            </>
          )}
        { !isAdmin
          ? (
            <p className="very-small-text grey-400-text form-input-width">
              <span className="mr-1">{strings.rgpd.forgotPassword}</span>
              <a
                className="underline"
                href={`https://${process.env.REACT_APP_SITE_DOMAIN}/politique-de-confidentialite`}
              >
                <span>{strings.rgpd.privacyPolicy}</span>
              </a>
            </p>
          )
          : null}
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default ForgotPassword;
