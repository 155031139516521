import React, { useState, useEffect } from 'react';
import {
  ButtonComponent, InputComponent, LoaderComponent,
} from '@zolteam/axenergie-ui-library';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useMutation, useQuery } from 'react-query';
import htmlToDraftjs from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { useHistory } from 'react-router-dom';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import AgencyContentsComponent from '../../../components/Organisms/AgencyContentsComponent';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';

// Service
import AgencyService from '../../../services/AgencyService';
import FileService from '../../../services/FileService';

// Hooks
import { useAppContext } from '../../../store/AppContext';

const AgencyPage = () => {
  const [subTitle, setSubTitle] = useState('');
  const [description, setDescription] = useState(() => EditorState.createEmpty());
  const [descriptionError, setDescriptionError] = useState(false);

  const [logo1, setLogo1] = useState(null);
  const [logo2, setLogo2] = useState(null);
  const [logo3, setLogo3] = useState(null);
  const [logo4, setLogo4] = useState(null);
  const [logo5, setLogo5] = useState(null);
  const [logo6, setLogo6] = useState(null);
  const logos = [
    [logo1, setLogo1],
    [logo2, setLogo2],
    [logo3, setLogo3],
    [logo4, setLogo4],
    [logo5, setLogo5],
    [logo6, setLogo6],
  ];
  const [image, setImage] = useState(null);
  const [imageId, setImageId] = useState('');
  const [logoIds, setLogoIds] = useState([]);
  const [advertisement, setAdvertisement] = useState(null);
  const [advertisementId, setAdvertisementId] = useState('');

  // Store
  const [{ currentAgency }, appDispatch] = useAppContext();
  const history = useHistory();

  // Queries
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(['getAgency', currentAgency],
    () => {
      if (currentAgency) {
        return AgencyService.getAgency({ id: currentAgency || undefined });
      }
      return null;
    });

  const updateAgency = useMutation('update-agency',
    async (updateAgencyData) => {
      let illustrationId = imageId;
      let logoFiles = logoIds.reduce((acc, item) => {
        if (Object.values(item)[0]) {
          return [...acc, { fileId: Object.values(item)[0] }];
        }
        return acc;
      }, []);
      let adId = advertisementId;
      if (image) {
        const uploadedImage = await FileService.addFile({ binary: image, typeId: 4 });
        setImageId(uploadedImage.data.id);
        illustrationId = uploadedImage.data.id;
      }
      if (logo1 || logo2 || logo3 || logo4 || logo5 || logo6) {
        const logoToUpload = logos.reduce((acc, [logo]) => {
          if (logo) {
            return [...acc, logo];
          }
          return acc;
        }, []);
        const requests = logoToUpload.map((logo) => FileService.addFile({ binary: logo, typeId: 5 }));
        const results = await Promise.all(requests);
        logoFiles = [...logoFiles, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      if (advertisement) {
        const uploadedAdvertisement = await FileService.addFile({ binary: advertisement, typeId: 17 });
        setAdvertisementId(uploadedAdvertisement.data.id);
        adId = uploadedAdvertisement.data.id;
      }
      return AgencyService.updateAgency({
        agency: {
          ...updateAgencyData,
          pageIllustrationFileId: illustrationId,
          pageLogosFiles: logoFiles,
          publicAdvertisementFileId: adId,
        },
      });
    });

  // Methods
  const editAgency = (pageIllustrationFileId = imageId, pageLogosFiles = logoIds) => {
    updateAgency.mutate({
      ...data?.data,
      pageTitle: subTitle,
      pageDescription: draftToHtml(convertToRaw(description.getCurrentContent())),
      pageIllustrationFileId,
      pageLogosFiles: pageLogosFiles.reduce((acc, id) => {
        if (Object.values(id)[0]) {
          return [...acc, { fileId: parseInt(Object.values(id), 10) }];
        }
        return acc;
      }, []),
    });
  };

  const goToContentDetailPage = (id = null) => history.push(`/intranet/agency-page-content${id ? `?id=${id}` : ''}`);

  const onSubmit = () => {
    if (descriptionError) {
      return false;
    }
    editAgency();
    return true;
  };

  useEffect(() => {
    if (isSuccess && data) {
      setSubTitle(data.data.pageTitle);
      setDescription(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.pageDescription)),
      ));
      setImageId(data.data.pageIllustrationFileId);
      setLogoIds(data.data.pageLogosFiles?.map(({ fileId }, index) => ({ [index + 1]: fileId })));
      setImage(null);
      setLogo1(null);
      setLogo2(null);
      setLogo3(null);
      setLogo4(null);
      setLogo5(null);
      setLogo6(null);
      setAdvertisement(null);
      setAdvertisementId(data.data.publicAdvertisementFileId);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyLoading, isSuccess: false },
      });
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (updateAgency.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.updateAgency, isSuccess: true },
      });
    }
    if (updateAgency.isError) {
      const toDisplay = Object.values(updateAgency?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAgency, isSuccess: false },
      });
    }
  }, [updateAgency.isSuccess, updateAgency.isError]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <h3 className="mv-1 mr-2 text-align-left">{`${strings.myAgencyPage}`}</h3>
        <h3 className="mv-1 text-align-left">{` > ${strings.myContents}`}</h3>
      </div>
      { isLoading
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <div className="pv-4 d-flex f-column f-wrap full-width align-start">
            <div className="full-width mv-3">
              <InputComponent
                onChange={setSubTitle}
                id="agency-page-subtitle"
                value={subTitle}
                label={strings.agencyPageSubtitle}
              />
            </div>
            <div className="d-flex f-row m-3 f-wrap">
              <h3 className="mv-1 text-align-left">{`${strings.whoAreWe}`}</h3>
            </div>
            <div className="full-width mv-3">
              <TextEditorComponent
                hasTitles
                label={strings.agencyPageDescription}
                setValue={setDescription}
                value={description}
                isError={descriptionError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => (validateNotEmptyField(setDescriptionError,
                  convertToRaw(description.getCurrentContent()).blocks[0].text)
                )}
              />
            </div>
            <div className="form-file-input-width mv-3">
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setImage(file);
                  setImageId(null);
                }}
                file={image}
                canDelete={image || imageId}
                label={imageId ? strings.changeImage : strings.illustrationImage}
                fileType={4}
                initName={strings.changeImage}
              />
            </div>
            <div className="d-flex f-row f-wrap">
              {
                    logos.map(([logo, setLogo], index) => (
                      <div
                          // eslint-disable-next-line react/no-array-index-key
                        key={`logo-${index}`}
                        className="form-file-input-width small mr-3 mv-3"
                      >
                        <FileInputWithAcceptAndSize
                          setFile={(file) => {
                            setLogo(file);
                            setLogoIds((state) => state.filter((id) => {
                              if (file !== null) return true;
                              if (Object.keys(id)[0] === (index + 1).toString()) {
                                return false;
                              }
                              return true;
                            }).map((id) => {
                              if (Object.keys(id)[0] === (index + 1).toString()) {
                                return { [index + 1]: null };
                              }
                              return id;
                            }));
                          }}
                          file={logo}
                          canDelete={logo || logoIds[index]}
                          label={logoIds[index] ? `${strings.changeLogo} ${index + 1}` : `${strings.logo} ${index + 1}`}
                          fileType={5}
                          initName={`${strings.changeLogo} ${index + 1}`}
                        />
                      </div>
                    ))
                }
            </div>
            <div className="d-flex f-row m-3 f-wrap">
              <h3 className="mv-1 text-align-left">{strings.manageMyAdvertisement}</h3>
            </div>
            <div className="form-file-input-width mv-3">
              <p className="grey-500-text small-text mt-0">{strings.optimalAdImageSize('800', '600')}</p>
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setAdvertisement(file);
                  setAdvertisementId(null);
                }}
                file={advertisement}
                canDelete={advertisement || advertisementId}
                label={advertisementId ? strings.changeMyAdvertisement : strings.uploadMyAdvertisement}
                fileType={17}
                initName={strings.changeMyAdvertisement}
              />
            </div>
            <div className="pb-4">
              { !convertToRaw(description.getCurrentContent()).blocks[0].text
                ? null : (
                  <div className="mv-3">
                    <ButtonComponent onClick={onSubmit}>
                      <div className="mv-2">
                        {updateAgency.isLoading
                          ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                          : <span className="uppercase m-5">{strings.save}</span>}
                      </div>
                    </ButtonComponent>
                  </div>
                )}
            </div>
          </div>
        )}
      {
        currentAgency
          ? <AgencyContentsComponent agencyId={currentAgency} handleEdit={goToContentDetailPage} />
          : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default AgencyPage;
