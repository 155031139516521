import React, { useState, useEffect } from 'react';

// Hooks
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Components
import { ButtonComponent, InputComponent, SelectComponent } from '@zolteam/axenergie-ui-library';
import Switch from 'react-switch';
import DataTable from '../../../components/molecules/DataTable';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';
import utils from '../../../utils/utils';

// Services and hooks
import AdherentService from '../../../services/AdherentService';
import { useAppContext } from '../../../store/AppContext';
import UserService from '../../../services/UserService';
import AuthService from '../../../services/AuthService';
import AgencyService from '../../../services/AgencyService';
import AdherentAdminService from '../../../services/AdherentAdminService';

// Images
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';

const EditAdherent = () => {
  // Local states
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [region, setRegion] = useState(null);

  // Store
  const [, appDispatch] = useAppContext();
  const queryCache = useQueryClient();

  // Navigation
  const history = useHistory();
  const parameters = utils.useQueryParameters();

  // Mutations
  const createAdherent = useMutation('create-adherent',
    (createAdherentData) => AdherentService.createAdherent(createAdherentData));

  const updateAdherent = useMutation('update-adherent',
    (updateAdherentData) => AdherentService.updateAdherent(updateAdherentData));

  const updateUser = useMutation('update-adherent-admin',
    (updateUserData) => AdherentAdminService.updateAdherentAdmins(updateUserData));

  const updateAgency = useMutation('update-agency',
    (updateAgencyData) => AgencyService.updateAgency(updateAgencyData));

  const sendConnectionLink = useMutation('send-password',
    (sendPasswordData) => AuthService.adminResetPassword(sendPasswordData));

  // Queries
  const {
    isLoading: isRegionLoading, isError: isRegionError, data: regions,
  } = useQuery('getRegions', AdherentService.getRegions);

  const {
    isSuccess: getAdherentSuccess, data: adherents, isError: getAdherentError,
  } = useQuery('getAdherent', () => {
    if (parameters.get('id')) {
      return AdherentService.getAdherent({ id: parameters.get('id') });
    }
    return null;
  });

  const {
    isSuccess: getUserSuccess, data: users, isError: getUserError, isLoading: getUserLoading,
  } = useQuery(['getUsers', updateUser.data], () => {
    if (parameters.get('id')) {
      return AdherentAdminService.getAdherentAdmins({ adherentId: parameters.get('id') });
    }
    return null;
  });

  const {
    isSuccess: getAgenceSuccess, data: agencies, isError: getAgencyError, isLoading: getAgencyLoading,
  } = useQuery(['getAgencies', updateAgency.data], () => {
    if (parameters.get('id')) {
      return AgencyService.getAgencies({ adherentId: parameters.get('id') });
    }
    return null;
  });

  const {
    isLoading: isRoleLoading, isError: isRoleError, data: roles,
  } = useQuery('getRoles', UserService.getRoles);

  // Methods

  const onSubmit = () => {
    if (nameError || !region) {
      return false;
    }
    if (parameters.get('id')) {
      return updateAdherent.mutate({
        name,
        regionId: region.value,
        id: parameters.get('id'),
      });
    }
    return createAdherent.mutate({
      name,
      regionId: region.value,
    });
  };

  const goToList = () => history.push('/intranet/adherents');
  const goToAdherentAdmin = (id = '') => history.push(
    `/intranet/adherent-admin?adherent=${adherents.data.name}&adherent-id=${adherents.data.id}${id ? '&id=' : ''}${id}`,
  );
  const goToAgency = (id = '') => history.push(
    `/intranet/agency?adherent=${adherents.data.name}&adherent-id=${adherents.data.id}${id ? '&id=' : ''}${id}`,
  );

  const renderEditButton = (handleClick) => (
    <button type="button" onClick={handleClick}>
      <EditIcon width={30} />
    </button>
  );

  const updateUserIsActive = (user) => {
    if (user) {
      updateUser.mutate({ adherentAdmin: { ...user, isActive: !user.isActive } });
    }
  };

  const updateAgencyIsActive = (agency) => {
    if (agency) {
      updateAgency.mutate({ agency: { ...agency, isActive: !agency.isActive } });
    }
  };

  const onSendLink = (email) => {
    if (email) {
      sendConnectionLink.mutate({ email, context: 'init' });
    }
  };

  const renderToggleButton = (value, item, isUser = false) => (
    <Switch
      checked={value}
      onChange={() => (isUser ? updateUserIsActive(item) : updateAgencyIsActive(item))}
      onColor="#8d9bba"
      onHandleColor={colors.primaryRed}
      handleDiameter={25}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      className="react-switch"
      id="disable-admin-switch"
    />
  );

  const getRoleName = (id) => roles?.data?.roles.find((role) => role.id === id)?.name;

  const renderConnectionLink = (email) => (
    <ButtonComponent onClick={() => onSendLink(email)} theme="outline">
      <span className="uppercase medium-text">{strings.connectionLink}</span>
    </ButtonComponent>
  );

  const loginAs = (adherentInfo) => {
    appDispatch({
      type: 'SET_LOGIN_AS',
      payload: adherentInfo,
    });
    queryCache.clear();
    history.push('/intranet');
  };

  const renderLoginAs = (adherentInfo) => (
    <ButtonComponent
      onClick={() => loginAs(adherentInfo)}
      theme="outline"
    >
      <span className="uppercase medium-text">{strings.logMeInAs}</span>
    </ButtonComponent>
  );

  const formatAgenciesTableData = (allAgencies = []) => allAgencies.reduce((acc, agency) => [
    ...acc,
    {
      name: agency.name,
      edit: renderEditButton(() => goToAgency(agency.id)),
      disabled: renderToggleButton(agency.isActive, agency, false),
    },
  ], []);

  const formatUserTableData = (allUsers = []) => allUsers.reduce((acc, user) => [
    ...acc,
    {
      name: `${user.firstName} ${user.lastName}`,
      position: user.position,
      mail: user.emailAddress,
      phone: user.phone,
      role: getRoleName(user.roleId),
      edit: renderEditButton(() => goToAdherentAdmin(user.id)),
      disabled: renderToggleButton(user.isActive, user, true),
      connectionLink: renderConnectionLink(user.emailAddress),
      loginAs: renderLoginAs({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        role: user.roleId,
        regionId: adherents?.data?.regionId,
        adherentId: parameters.get('id'),
      }),
    },
  ], []);

  useEffect(() => {
    if (getAdherentSuccess && adherents && parameters.get('id')) {
      setName(adherents.data.name);
      setRegion({
        value: adherents.data.regionId,
        label: regions?.data?.regions?.find(({ id }) => id === adherents.data.regionId)?.name,
      });
    }
    if (getAdherentError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.adherentLoading, isSuccess: false },
      });
      goToList();
    }
    if (getUserError || isRoleError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
    if (getAgencyError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAgencies, isSuccess: false },
      });
    }
  }, [getAdherentSuccess, adherents, getAdherentError, getAgencyError, getUserError, isRoleError]);

  useEffect(() => {
    if (isRegionError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getRegion, isSuccess: false },
      });
    }
    if (createAdherent.isSuccess || updateAdherent.isSuccess) {
      goToList();
    }
    if (createAdherent.isError) {
      const toDisplay = Object.values(createAdherent?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createAdherent, isSuccess: false },
      });
    }
    if (updateAdherent.isError) {
      const toDisplay = Object.values(updateAdherent?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAdherent, isSuccess: false },
      });
    }
    if (updateUser.isError) {
      const toDisplay = Object.values(updateUser?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateUser, isSuccess: false },
      });
    }
    if (updateAgency.isError) {
      const toDisplay = Object.values(updateAgency?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAgency, isSuccess: false },
      });
    }
    if (sendConnectionLink.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.sendConnectionLink, isSuccess: true },
      });
    }
    if (sendConnectionLink.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.sendConnectionLink, isSuccess: false },
      });
    }
  }, [
    isRegionError,
    createAdherent.isSuccess,
    createAdherent.isError,
    updateAdherent.isSuccess,
    updateAdherent.isError,
    updateUser.isSuccess,
    updateUser.isError,
    updateAgency.isSuccess,
    updateAgency.isError,
    sendConnectionLink.isSuccess,
    sendConnectionLink.isError,
  ]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap full-width align-center justify-start">
        <Link to="/intranet/adherents">
          <h3 className="mr-3">{strings.adherents}</h3>
        </Link>
        <h3 className="m-0 text-align-left">{` > ${adherents?.data?.name || strings.newAdherent}`}</h3>
      </div>
      <div className="mv-4 d-flex f-row f-wrap full-width align-start">
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setName}
            id="adherent-name"
            value={name}
            label={strings.adherentName}
            isError={nameError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setNameError, name)}
          />
        </div>
        { isRegionError ? null : (
          <div className="form-input-width m-3">
            <SelectComponent
              isLoading={isRegionLoading}
              data={utils.formatRegionsData(regions?.data?.regions) || []}
              name="adherent-region"
              value={region}
              setValue={setRegion}
              theme="dark"
              placeholder={strings.regions}
            />
          </div>
        )}
        { !name
            || !region ? null : (
              <div className="m-3">
                <ButtonComponent onClick={onSubmit}>
                  <div className="mv-2">
                    <span className="uppercase m-5">{strings.save}</span>
                  </div>
                </ButtonComponent>
              </div>
          )}
      </div>
      {parameters.get('id') ? (
        <>
          <div className="ph-3">
            <div className="d-flex f-row align-center ph-4 f-wrap mb-3">
              <h3 className="mr-5">{strings.userAdherents}</h3>
              <ButtonComponent onClick={() => goToAdherentAdmin()}>
                <span>{strings.addUser}</span>
              </ButtonComponent>
            </div>
            <div className="table-container full-width">
              <DataTable
                isLoading={getUserLoading || getUserError || isRoleLoading || isRoleError}
                data={getUserSuccess
                  ? formatUserTableData(users?.data?.adherentAdmins) : []}
                columns={[
                  strings.name,
                  strings.job,
                  strings.mail,
                  strings.phone,
                  strings.role,
                  ' ',
                  ' ',
                  ' ',
                ]}
              />
            </div>
          </div>
          <div>
            <div className="d-flex f-row align-center ph-4 mt-5 f-wrap mb-3">
              <h3 className="mr-5">{strings.agencies}</h3>
              <ButtonComponent onClick={() => goToAgency()}>
                <span>{strings.addAgency}</span>
              </ButtonComponent>
            </div>
            <div className="table-container full-width ml-3">
              <DataTable
                isLoading={getAgencyLoading || getAdherentError}
                data={getAgenceSuccess ? formatAgenciesTableData(agencies?.data?.agencies) : []}
                columns={[
                  strings.name,
                  ' ',
                  ' ',
                ]}
              />
            </div>
          </div>
        </>
      ) : null}
    </TemplateWithMenuAndHeader>
  );
};

export default EditAdherent;
