import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

// Components
import {
  ButtonComponent, InputComponent, LoaderComponent,
} from '@zolteam/axenergie-ui-library';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useMutation, useQuery } from 'react-query';
import draftToHtml from 'draftjs-to-html';
import htmlToDraftjs from 'html-to-draftjs';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ModalComponent from '../../../components/atoms/ModalComponent';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';

// Hooks
import { useAppContext } from '../../../store/AppContext';

// Constants
import strings from '../../../constants/strings';

// Utils
import utils from '../../../utils/utils';
import { validateNotEmptyField } from '../../../utils/validators';

// Services
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import AgencyService from '../../../services/AgencyService';
import FileService from '../../../services/FileService';
import colors from '../../../constants/colors';

const EditAgencyContent = () => {
  const [title, setTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [details, setDetails] = useState(() => EditorState.createEmpty());
  const [detailsError, setDetailsError] = useState(false);
  const [image, setImage] = useState(undefined);
  const [imageId, setImageId] = useState(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Navigation
  const parameters = utils.useQueryParameters();
  const isNetworkContent = useLocation()?.pathname.includes('network');
  const history = useHistory();

  // Queries
  const createContent = useMutation('create-content',
    async (createContentData) => {
      if (image) {
        const uploadedFile = await FileService.addFile({ binary: image, typeId: 6 });
        setImageId(uploadedFile.data.id);
        return AgencyService.addAgencyContents({
          content: {
            ...createContentData,
            illustrationFileId: uploadedFile.data.id,
          },
        });
      }
      return AgencyService.addAgencyContents({
        content: {
          ...createContentData,
        },
      });
    });
  const updateContent = useMutation('update-content',
    async (updateContentData) => {
      if (image) {
        const uploadedFile = await FileService.addFile({ binary: image, typeId: 6 });
        setImageId(uploadedFile.data.id);
        return AgencyService.updateAgencyContents({
          content: {
            ...updateContentData,
            illustrationFileId: uploadedFile.data.id,
          },
        });
      }
      return AgencyService.updateAgencyContents({
        content: {
          ...updateContentData,
        },
      });
    });
  const {
    isSuccess, data, isError,
  } = useQuery('getContent', () => {
    if (parameters.get('id')) {
      return AgencyService.getAgencyContent({ id: parameters.get('id') });
    }
    return null;
  });
  const deleteContent = useMutation('deleteContent',
    (id) => AgencyService.deleteAgencyContents(id));

  // Store
  const [{ currentAgency }, appDispatch] = useAppContext();

  const addContent = () => {
    createContent.mutate({
      title,
      videoUrl: videoUrl || null,
      content: draftToHtml(convertToRaw(details.getCurrentContent())),
      illustrationFileId: imageId,
      agencyId: currentAgency || undefined,
    });
  };

  const goToListPage = () => {
    if (isNetworkContent) {
      return history.push('/intranet/network-agency-page-contents');
    }
    return history.push('/intranet/agency-page');
  };

  const editContent = (illustrationFileId = imageId) => {
    updateContent.mutate({
      ...data?.data,
      title,
      videoUrl: videoUrl || null,
      content: draftToHtml(convertToRaw(details.getCurrentContent())),
      illustrationFileId: illustrationFileId || undefined,
      agencyId: currentAgency || undefined,
    });
  };

  useEffect(() => {
    if (deleteContent.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteContent, isSuccess: true },
      });
      goToListPage();
    }
    if (deleteContent.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteContent, isSuccess: false },
      });
    }
  }, [deleteContent.isError, deleteContent.isSuccess]);

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setTitle(data.data.title);
      setVideoUrl(data.data.videoUrl);
      setDetails(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.content)),
      ));
      setImageId(data.data.illustrationFileId);
      setImage(null);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.contentLoading, isSuccess: false },
      });
      goToListPage();
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (
      createContent.isSuccess
    || updateContent.isSuccess
    ) {
      goToListPage();
    }
    if (updateContent.isError) {
      const toDisplay = Object.values(updateContent?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateContent, isSuccess: false },
      });
    }
    if (createContent.isError) {
      const toDisplay = Object.values(createContent?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createContent, isSuccess: false },
      });
    }
  }, [
    createContent.isSuccess,
    updateContent.isSuccess,
    createContent.isError,
    updateContent.isError,
  ]);

  const deleteOne = () => {
    if (parameters.get('id')) {
      deleteContent.mutate({ id: parameters.get('id') });
      setIsDeleteModalOpen(false);
    }
  };

  const onSubmit = () => {
    if (
      titleError
      || detailsError
    ) {
      return false;
    }
    if (parameters.get('id')) {
      return editContent(imageId);
    }
    return addContent();
  };

  return (
    <TemplateWithMenuAndHeader>
      { isNetworkContent ? (
        <div className="d-flex f-row m-3 f-wrap">
          <Link to="/intranet/network-agency-page-contents">
            <h3 className="mr-3 mv-1 text-align-left">{strings.networkContent}</h3>
          </Link>
          <h3 className="mv-1 text-align-left">{` > ${data?.data?.title || strings.newContent}`}</h3>
        </div>
      ) : (
        <div className="d-flex f-row m-3 f-wrap">
          <Link to="/intranet/agency-page">
            <h3 className="mr-3 mv-1 text-align-left">{strings.myAgencyPage}</h3>
          </Link>
          <Link to="/intranet/agency-page">
            <h3 className="mr-3 mv-1 text-align-left">{` > ${strings.myContents} `}</h3>
          </Link>
          <h3 className="mv-1 text-align-left">{` > ${data?.data?.title || strings.newContent}`}</h3>
        </div>
      )}
      <div className="pv-4 d-flex f-column f-wrap full-width align-start">
        <div className="form-input-width mv-3">
          <InputComponent
            onChange={setTitle}
            id="agency-name"
            value={title}
            label={strings.title}
            isError={titleError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setTitleError, title)}
          />
        </div>
        <div className="full-width mv-3">
          <InputComponent
            onChange={setVideoUrl}
            id="agency-video-url"
            value={videoUrl}
            label={strings.agencyPageVideoUrl}
          />
        </div>
        <div className="full-width mv-3">
          <TextEditorComponent
            label={strings.contentDetails}
            hasTitles
            setValue={setDetails}
            value={details}
            isError={detailsError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setDetailsError,
              convertToRaw(details.getCurrentContent()).blocks[0].text)
            )}
          />
        </div>
        <div className="form-file-input-width mv-3">
          <FileInputWithAcceptAndSize
            setFile={(file) => {
              setImage(file);
              setImageId(null);
            }}
            file={image}
            canDelete={image || imageId}
            label={imageId ? strings.changeImage : strings.illustrationImage}
            fileType={6}
            initName={strings.changeImage}
          />
        </div>
      </div>
      <div className="pb-4 d-flex f-row align-center justify-start f-wrap">
        { !parameters.get('id')
          ? null : (
            <div className="mv-3 mr-3">
              <ButtonComponent
                onClick={() => setIsDeleteModalOpen(true)}
                theme="outline"
              >
                <div className="mv-2">
                  <span className="uppercase m-5">{strings.delete}</span>
                </div>
              </ButtonComponent>
            </div>
          )}
        { !title
          || !convertToRaw(details.getCurrentContent()).blocks[0].text

          ? null : (
            <div className="mv-3">
              <ButtonComponent onClick={onSubmit}>
                <div className="mv-2">
                  {
                  updateContent?.isLoading || createContent?.isLoading
                    ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                    : <span className="uppercase m-5">{strings.save}</span>
                }
                </div>
              </ButtonComponent>
            </div>
          )}
      </div>
      {
        isDeleteModalOpen ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div className="full-width">
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deletePrestation}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => deleteOne()}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default EditAgencyContent;
