import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';

// Components

// Hooks
import { format } from 'date-fns';
import { useAppContext } from '../../store/AppContext';

// Service
import AgencyContractService from '../../services/AgencyContractService';

// Constants
import strings from '../../constants/strings';
import utils from '../../utils/utils';
import colors from '../../constants/colors';

const DocumentTab = ({ agencyContractId }) => {
  const [, appDispatch] = useAppContext();

  const [contractDocuments, setContractDocuments] = useState(null);

  const getMyAgencyContractDocuments = useQuery(['get-my-agency-contract-documents'], () => {
    if (agencyContractId) {
      return AgencyContractService.getMyAgencyContractDocuments({ agencyContractId });
    }
    return null;
  });

  const getAgencyContractDocument = useMutation('get-agency-contract-document',
    (fileId) => AgencyContractService.getAgencyContractFile(fileId));

  useEffect(() => {
    if (getMyAgencyContractDocuments.isSuccess) {
      setContractDocuments(
        getMyAgencyContractDocuments?.data?.data?.agencyContractDocuments
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .filter(({ hasFile }) => hasFile),
      );
    }
    if (getMyAgencyContractDocuments.isError) {
      setContractDocuments(null);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyContractDocuments, isSuccess: false },
      });
    }
  }, [getMyAgencyContractDocuments.isSuccess, getMyAgencyContractDocuments.isError]);

  useEffect(() => {
    if (getAgencyContractDocument.isSuccess) {
      const file = getAgencyContractDocument?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractDocument.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractDocument.isSuccess, getAgencyContractDocument.isError]);

  const handleDocumentClick = (fileId) => {
    getAgencyContractDocument.mutate(fileId);
  };

  const displayKey = (d) => `document-${d.id}`;

  const formatDate = (doc) => {
    if (doc.date) {
      return `${strings.the} ${format(new Date(doc?.date?.split(' ').join('T')), 'dd.MM.yyyy')}`;
    }
    return '';
  };

  return (
    <>
      <div className="d-flex f-column white-background radius-5 pv-3 ph-5">
        {contractDocuments === null && <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
        {contractDocuments !== null && contractDocuments?.length === 0 && <p className="mb-0">{strings.noDocuments}</p>}
        <ul className="p-0 mv-0" style={{ listStyle: 'none', flexGrow: 1 }}>
          {contractDocuments?.map((document) => (
            <li key={displayKey(document)} className="list pv-4">
              <div className="d-flex justify-between align-center f-wrap">
                <div className="d-flex f-column">
                  <div className="medium-weight d-flex f-row f-wrap">
                    <span className="mr-4">
                      {document.description}
                    </span>
                    {document.date ? (
                      <span className="grey-400-text">
                        {formatDate(document)}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex align-center">
                  { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                  <a
                    role="button"
                    tabIndex={0}
                    onKeyDown={null}
                    onClick={() => handleDocumentClick(document.id)}
                    className="m-0 primary-red-text bold mr-4 pointer"
                  >
                    {strings.download}
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

DocumentTab.propTypes = {
  agencyContractId: PropTypes.string.isRequired,
};

export default DocumentTab;
