import React from 'react';
import PropTypes from 'prop-types';
import { ModalTemplate } from '@zolteam/axenergie-ui-library';

const AdvertisementModal = ({
  onClose,
  advertisement,
}) => (
  <ModalTemplate closeModal={onClose}>
    <img
      className="m-4"
      alt="Publicité de l'agence"
      src={`${process.env.REACT_APP_IMAGE_URL}/${advertisement}`}
      width={800}
      height={600}
      style={{ objectFit: 'contain' }}
    />
  </ModalTemplate>
);

AdvertisementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  advertisement: PropTypes.string,
};

AdvertisementModal.defaultProps = {
  advertisement: '',
};

export default AdvertisementModal;
