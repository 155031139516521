import React, {
  useEffect, useRef, useState,
} from 'react';
import { ButtonComponent, LoaderComponent, PaginationComponent } from '@zolteam/axenergie-ui-library';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ForumFormPost from '../../../components/molecules/ForumFormPost';
import ForumAnswerBlock from '../../../components/molecules/ForumAnswerBlock';
import ForumThreadInitMessageBlock from '../../../components/molecules/ForumThreadInitMessageBlock';
import ThreadForm from '../../../components/Forms/ThreadForm';

// Images
import arrow from '../../../assets/images/arrow-down.svg';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Hooks and services
import { useAppContext } from '../../../store/AppContext';
import ForumService from '../../../services/ForumService';
import FileService from '../../../services/FileService';

// Utils
import utils from '../../../utils/utils';
import useForumModeration from '../../../hooks/useForumModeration';

const Thread = () => {
  const [forumName, setForumName] = useState('');
  const [theme, setTheme] = useState(null);
  // Display element related variables
  const [showAnswerBlock, setShowAnswerBlock] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // Pagination related variables
  const [currentList, setCurrentList] = useState([]);
  const [totalLength, setTotalLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  // Measure related states
  const answerFormRef = useRef();

  // Hooks
  const history = useHistory();
  const { forum, id } = useParams();
  const [{ forums, currentAgency }, appDispatch] = useAppContext();
  const [canEditMessage] = useForumModeration();

  // Queries
  const deleteThread = useMutation('deleteThread', (idToDelete) => {
    if (idToDelete) {
      return ForumService.deleteThread(idToDelete);
    }
    return false;
  });
  const updateThread = useMutation('updateThread', async ({
    file1,
    file1Id,
    file1Name,
    file2,
    file2Id,
    file2Name,
    thread,
  }) => {
    const joinedFiles = [];
    if (!file1Id && file1) {
      const uploadedFile = await FileService.addFile({ binary: file1, typeId: 13 });
      joinedFiles.push({
        fileName: file1Name,
        fileId: uploadedFile.data.id,
      });
    } else if (file1Id) {
      joinedFiles.push({
        fileName: file1Name,
        fileId: file1Id,
      });
    }
    if (!file2Id && file2) {
      const uploadedFile = await FileService.addFile({ binary: file2, typeId: 13 });
      joinedFiles.push({
        fileName: file2Name,
        fileId: uploadedFile.data.id,
      });
    } else if (file2Id) {
      joinedFiles.push({
        fileName: file2Name,
        fileId: file2Id,
      });
    }
    return ForumService.updateThread({
      thread: {
        ...thread,
        joinedFiles: joinedFiles.length ? joinedFiles : undefined,
      },
    });
  });
  const {
    isLoading, isError, data, refetch,
  } = useQuery(['getThread', updateThread.isSuccess],
    () => ForumService.getOneThread(id));

  // Refetch thread when route changes
  useEffect(() => {
    if (data && !isLoading) {
      refetch();
    }
  }, [id]);

  const getThemes = useQuery('getThemes', () => ForumService.getThemes({ forum }));

  const getFileAndDownload = useMutation('getFileAndDownload',
    async ({ fileId, fileName }) => {
      const file = await FileService.getFile({ id: fileId });
      return utils.downloadBlob(file.data, fileName);
    });

  const addPost = useMutation('addPost', async ({
    file1, file2, file1Name, file2Name, content,
  }) => {
    const joinedFiles = [];
    if (file1) {
      const uploadedFile = await FileService.addFile({ binary: file1, typeId: 13 });
      joinedFiles.push({
        fileName: file1Name,
        fileId: uploadedFile.data.id,
      });
    }
    if (file2) {
      const uploadedFile = await FileService.addFile({ binary: file2, typeId: 13 });
      joinedFiles.push({
        fileName: file2Name,
        fileId: uploadedFile.data.id,
      });
    }
    return ForumService.addPost({
      post: {
        agencyId: currentAgency || undefined,
        forumThreadId: id,
        content,
        joinedFiles: joinedFiles.length ? joinedFiles : undefined,
      },
    });
  });

  const deletePost = useMutation('deletePost', (toDelete) => ForumService.deletePost(toDelete));

  const updatePost = useMutation('updatePost', async ({
    file1,
    file1Id,
    file1Name,
    file2,
    file2Id,
    file2Name,
    post,
  }) => {
    const joinedFiles = [];
    if (!file1Id && file1) {
      const uploadedFile = await FileService.addFile({ binary: file1, typeId: 13 });
      joinedFiles.push({
        fileName: file1Name,
        fileId: uploadedFile.data.id,
      });
    } else if (file1Id) {
      joinedFiles.push({
        fileName: file1Name,
        fileId: file1Id,
      });
    }
    if (!file2Id && file2) {
      const uploadedFile = await FileService.addFile({ binary: file2, typeId: 13 });
      joinedFiles.push({
        fileName: file2Name,
        fileId: uploadedFile.data.id,
      });
    } else if (file2Id) {
      joinedFiles.push({
        fileName: file2Name,
        fileId: file2Id,
      });
    }
    return ForumService.updatePost({
      post: {
        ...post,
        joinedFiles: joinedFiles.length ? joinedFiles : undefined,
      },
    });
  });

  const getPosts = useQuery(
    ['getPosts', currentPage, id, addPost.isSuccess, updatePost.isSuccess, deletePost.isSuccess],
    () => ForumService.getPosts({
      thread: id,
      pageSize: itemsPerPage,
      pageNumber: currentPage,
    }),
  );

  // Methods
  const goBackToList = () => history.push(`/intranet/forum/${forum}`);

  const scrollToAnswerForm = () => {
    if (answerFormRef && answerFormRef.current) {
      answerFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleAnswerClick = () => {
    if (!currentList.length) {
      setShowAnswerBlock(true);
    }
    scrollToAnswerForm();
  };
  const renderEmptyList = () => {
    if (getPosts.isLoading
        || getPosts.isError) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>
      );
    }
    return null;
  };

  const onSubmit = ({
    file1,
    file1Id,
    file1Name,
    file2,
    file2Id,
    file2Name,
    hasError,
    theme: newTheme,
    title,
    content,
  }) => {
    if (hasError) {
      return false;
    }
    return updateThread.mutate({
      file1,
      file1Id,
      file1Name,
      file2,
      file2Id,
      file2Name,
      thread: {
        ...data?.data,
        agencyId: currentAgency || undefined,
        forumId: forum,
        forumThemeId: newTheme,
        title,
        content,
      },
    });
  };

  const editPost = (post, {
    file1,
    file1Id,
    file1Name,
    file2,
    file2Id,
    file2Name,
    hasError,
    content,
  }) => {
    if (hasError) {
      return false;
    }
    return updatePost.mutate({
      file1,
      file1Id,
      file1Name,
      file2,
      file2Id,
      file2Name,
      post: {
        ...post,
        forumThreadId: id,
        content,
      },
    });
  };

  const openCloseThread = (isClosed) => {
    updateThread.mutate({
      thread: {
        ...data?.data,
        isClosed,
      },
      file1Id: data?.data?.joinedFiles?.[0]?.fileId,
      file1Name: data?.data?.joinedFiles?.[0]?.fileName,
      file2Id: data?.data?.joinedFiles?.[1]?.fileId,
      file2Name: data?.data?.joinedFiles?.[1]?.fileName,
    });
  };

  const renderThreadBlock = () => {
    if (isEditing) {
      return (
        <ThreadForm
          themes={getThemes?.data?.data?.forumThemes}
          isLoading={updateThread.isLoading}
          onSubmit={onSubmit}
          initValues={{
            title: data?.data?.title,
            theme: data?.data?.forumThemeId,
            content: data?.data?.content,
            joinedFiles: data?.data?.joinedFiles,
          }}
          onCancel={() => setIsEditing(false)}
        />
      );
    }
    return (
      <ForumThreadInitMessageBlock
        publicationDate={data?.data?.publicationDate}
        updateDate={data?.data?.lastUpdateDate}
        author={data?.data?.author}
        agencyName={data?.data?.agencyName}
        content={data?.data?.content}
        isClosed={data?.data?.isClosed}
        theme={theme}
        joinedFiles={data?.data?.joinedFiles}
        getFileAndDownload={({ fileId, fileName }) => getFileAndDownload.mutate({ fileId, fileName })}
        handleAnswerClick={handleAnswerClick}
        edit={() => setIsEditing(true)}
        openClose={openCloseThread}
        deleteThread={() => deleteThread.mutate(data?.data?.id)}
        isEditable={canEditMessage(data?.data?.authorId)}
      />
    );
  };

  useEffect(() => {
    if (showAnswerBlock) {
      scrollToAnswerForm();
    }
  }, [showAnswerBlock]);

  useEffect(() => {
    if (updateThread.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateThread, isSuccess: false },
      });
      setIsEditing(false);
    }
    if (updateThread.isSuccess) {
      setIsEditing(false);
    }
  }, [updateThread.isError, updateThread.isSuccess]);

  useEffect(() => {
    if (updatePost.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updatePost, isSuccess: false },
      });
    }
    if (updatePost.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.updatePost, isSuccess: true },
      });
    }
  }, [updatePost.isError, updatePost.isSuccess, updatePost.data]);

  useEffect(() => {
    if (deleteThread.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteThread, isSuccess: false },
      });
    }
    if (deleteThread.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteThread, isSuccess: true },
      });
      goBackToList();
    }
  }, [deleteThread.isError, deleteThread.isSuccess]);

  useEffect(() => {
    if (deletePost.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deletePost, isSuccess: false },
      });
    }
    if (deletePost.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deletePost, isSuccess: true },
      });
    }
  }, [deleteThread.isError, deleteThread.isSuccess]);

  useEffect(() => {
    if (getFileAndDownload.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.fileDownload, isSuccess: false },
      });
    }
  }, [getFileAndDownload.isError]);

  useEffect(() => {
    if (addPost.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.createPost, isSuccess: false },
      });
    }
    if (addPost.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.createPost, isSuccess: true },
      });
      setShowAnswerBlock(false);
    }
  }, [addPost.isError, addPost.isSuccess]);

  useEffect(() => {
    if (forums) {
      setForumName(forums?.find(({ id: forumId }) => forumId.toString() === forum)?.title);
    }
  }, [forums, forum]);

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getThread, isSuccess: false },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (getPosts.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAnswers, isSuccess: false },
      });
    }
    if (getPosts.isSuccess && getPosts.data) {
      setCurrentList(getPosts.data?.data?.forumPosts);
      setTotalLength(getPosts.data?.data?.pageCount);
    }
  }, [getPosts.isError, getPosts.isSuccess, getPosts.data]);

  useEffect(() => {
    if (getThemes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getForumThemes, isSuccess: false },
      });
    }
    if (getThemes.isSuccess && getThemes.data && data?.data) {
      setTheme(getThemes.data?.data?.forumThemes?.find(
        ({ id: themeId }) => themeId === data?.data?.forumThemeId,
      )?.name);
    }
  }, [getThemes.isError, getThemes.isSuccess, getThemes.data, data]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex justify-start f-column align-start">
        <div className="m-3 d-flex align-center">
          <ButtonComponent onClick={goBackToList} theme="white">
            <div className="d-flex justify-center align-center">
              <img
                alt="back-arrow"
                className="rotate-90"
                src={arrow}
                width={20}
              />
              <span className="normal-weight">{strings.back}</span>
            </div>
          </ButtonComponent>
          <span className="small-text grey-400-text ml-3">
            {`${strings.forum} / ${forumName}`}
          </span>
        </div>
        <div className="mh-3">
          <h2 className="grey-800-text medium-weight">{data?.data?.title}</h2>
        </div>
        {/* Thread block */}
        <div className="white-background max-list-container-width full-width">
          {isLoading || updateThread.isLoading
            ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
            : renderThreadBlock()}
        </div>
        {/* Answers block */}
        <div className="max-list-container-width full-width">
          {
              currentList?.length && !getPosts.isLoading
                ? currentList.map((post) => (
                  <ForumAnswerBlock
                    key={post.id}
                    author={post.author}
                    publicationDate={post.publicationDate}
                    content={post.content}
                    agencyName={post.agencyName}
                    joinedFiles={post.joinedFiles}
                    getFileAndDownload={({ fileId, fileName }) => getFileAndDownload.mutate({ fileId, fileName })}
                    edit={(newValues) => editPost(post, newValues)}
                    deletePost={() => deletePost.mutate(post.id)}
                    isEditable={canEditMessage(post.authorId)}
                  />
                ))
                : renderEmptyList()
            }
          {totalLength > 1 ? (
            <div className="white-background full-width">
              <div className="p-4">
                <PaginationComponent
                  setCurrentList={setCurrentList}
                  totalPage={totalLength || 1}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          ) : null}
          {
            ((currentList?.length && !getPosts.isLoading) || showAnswerBlock) && !data?.data?.isClosed
              ? (
                <div ref={answerFormRef}>
                  <ForumFormPost
                    onSubmit={(values) => addPost.mutate(values)}
                  />
                </div>
              )
              : null
          }
        </div>
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default Thread;
