import client from './client';

const getContacts = ({
  agencyId, isProcessed, search, contactTypes,
}) => client.get('/contacts', {
  params: {
    agencyId,
    isProcessed,
    search,
    contactTypes,
  },
});

const setAsProcessed = (id) => client.put(`/contacts/${id}:set-processed`);
const setAsToProcess = (id) => client.put(`/contacts/${id}:set-to-process`);

export default {
  getContacts,
  setAsProcessed,
  setAsToProcess,
};
