import React, { useState, useEffect } from 'react';
import { useMeasure, useWindowSize } from 'react-use';

// Components
import { useMutation, useQuery } from 'react-query';
import {
  ButtonComponent, LoaderComponent, ModalTemplate, PaginationComponent, MasonryComponent,
} from '@zolteam/axenergie-ui-library';
import jwtDecode from 'jwt-decode';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import AnnouncementCard from '../../../components/molecules/AnnouncementCard';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import SparePartsService from '../../../services/SparePartsService';

// Hooks
import { useAppContext } from '../../../store/AppContext';
import TabComponent from '../../../components/molecules/TabComponent';
import ModalComponent from '../../../components/atoms/ModalComponent';

// Images
import { ReactComponent as AddCircleIcon } from '../../../assets/images/add-circle.svg';
import AnnouncementFilters from '../../../components/molecules/AnnouncementFilters';
import AnnouncementForm from '../../../components/Forms/AnnouncementForm';
import useIsMobile from '../../../hooks/useIsMobile';

const SpareParts = () => {
  // Pagination related variables
  const [currentList, setCurrentList] = useState([]);
  const [totalLength, setTotalLength] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const itemsMargin = 24;
  // Filters related variables
  const [isNetwork, setIsNetwork] = useState(true);
  const [typeFilter, setTypeFilter] = useState(undefined);
  const [energyFilter, setEnergyFilter] = useState(undefined);
  // Modal related variables
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  // Measures relative variables
  const [isMobile] = useIsMobile();
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const columnWidth = screenWidth < 350 + 2 * itemsMargin + 16 ? screenWidth - 2 * itemsMargin - 16 : 350;

  // Store
  const [{ auth, currentAgency }, appDispatch] = useAppContext();

  // Queries
  const deleteAnnouncement = useMutation((id) => SparePartsService.deleteAnnouncement(id));
  const createAnnouncement = useMutation('createAnnouncement',
    (announcement) => SparePartsService.createAnnouncement({
      announcement: { ...announcement, agencyId: currentAgency || undefined },
    }));
  const updateAnnouncement = useMutation('updateAnnouncement',
    (announcement) => SparePartsService.updateAnnouncement({
      announcement: { ...announcement, agencyId: currentAgency || undefined },
    }));
  const answerAnnouncement = useMutation('answerAnnouncement', ({ typeCode, announcement }) => {
    if (typeCode === 'DEMANDE') {
      return SparePartsService.gotIt({
        agencyId: currentAgency || undefined,
        sparePartAnnouncementId: announcement,
      });
    }
    return SparePartsService.wantIt({
      agencyId: currentAgency || undefined,
      sparePartAnnouncementId: announcement,
    });
  });

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(['getAnnouncements',
    currentPage,
    isNetwork,
    typeFilter,
    energyFilter,
    deleteAnnouncement.isSuccess,
    updateAnnouncement.isSuccess,
    createAnnouncement.isSuccess,
    answerAnnouncement.isSuccess,
  ],
  () => SparePartsService.getAnnouncements({
    pageNumber: currentPage,
    pageSize: itemsPerPage,
    typeCode: typeFilter,
    energyTypeCode: energyFilter,
    authorId: isNetwork ? undefined : jwtDecode(auth)?.sub,
    isExpired: isNetwork ? 'false' : undefined,
  }));

  const getEnergyTypes = useQuery('getEnergyTypes',
    () => SparePartsService.getSparePartEnergyTypes());

  useEffect(() => {
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAnnouncements, isSuccess: false },
      });
    }
    if (isSuccess) {
      setCurrentList(data?.data?.sparePartAnnouncements);
      setTotalLength(data?.data?.pageCount);
    }
    if (getEnergyTypes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAnnouncementsEnergyTypes, isSuccess: false },
      });
    }
  }, [isError, isSuccess, data, getEnergyTypes.isError]);

  useEffect(() => {
    if (deleteAnnouncement.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteAnnouncement, isSuccess: false },
      });
    }
    if (deleteAnnouncement.isSuccess) {
      setIsDeleteModalOpen(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteAnnouncement, isSuccess: true },
      });
    }
  }, [deleteAnnouncement.isError, deleteAnnouncement.isSuccess]);

  useEffect(() => {
    if (answerAnnouncement.isError) {
      const toDisplay = strings.errors.spareParts?.[answerAnnouncement?.error?.response?.data?.code];
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.answerAnnouncement, isSuccess: false },
      });
    }
    if (answerAnnouncement.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.answerAnnouncement, isSuccess: true },
      });
    }
  }, [answerAnnouncement.isError, answerAnnouncement.isSuccess]);

  useEffect(() => {
    if (
      createAnnouncement.isSuccess
        || updateAnnouncement.isSuccess
    ) {
      setCurrentAnnouncement(null);
      setIsCreateModalOpen(false);
    }
    if (updateAnnouncement.isError) {
      setCurrentAnnouncement(null);
      setIsCreateModalOpen(false);
      const toDisplay = Object.values(updateAnnouncement?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateAnnouncement, isSuccess: false },
      });
    }
    if (createAnnouncement.isError) {
      setCurrentAnnouncement(null);
      setIsCreateModalOpen(false);
      const toDisplay = Object.values(createAnnouncement?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createAnnouncement, isSuccess: false },
      });
    }
  }, [
    createAnnouncement.isSuccess,
    updateAnnouncement.isSuccess,
    createAnnouncement.isError,
    updateAnnouncement.isError,
  ]);

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 3) {
      nbColumns = 3;
    }
    return (nbColumns * columnWidth) + itemsMargin;
  };

  const deleteOne = () => {
    deleteAnnouncement.mutate({ id: currentAnnouncement });
  };

  const handleTabClick = (isNetworkTab) => {
    setIsNetwork(isNetworkTab);
    setTypeFilter(undefined);
    setEnergyFilter(undefined);
  };

  const renderEmptyList = () => {
    if (isLoading
        || isError) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noAnnouncement}</span>
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      <div
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
        ref={containerRef}
      >
        <div
          className={`d-flex f-row f-wrap justify-between align-center mt-4 ${isMobile ? 'mb-4' : ''}`}
          style={{ width: isMobile ? '100%' : getContainerWidth() }}
        >
          <div
            className="mr-5 d-flex f-column align-start"
            style={{ width: isMobile ? '100%' : '50%' }}
          >
            <h2 className="medium-weight mh-0">{strings.SOSSpareParts}</h2>
            <span className="grey-400-text mb-5">{strings.SOSSparePartsSubtitle}</span>
          </div>
          <ButtonComponent onClick={() => setIsCreateModalOpen(true)}>
            <div className="d-flex align-center pv-2 ph-4">
              <span className="ws-nowrap mr-2">{strings.newAnnouncement}</span>
              <AddCircleIcon width={24} height={24} />
            </div>
          </ButtonComponent>
        </div>
        <TabComponent tabs={[
          {
            onClick: () => handleTabClick(true),
            isActive: isNetwork,
            title: strings.networkAnnouncements,
          },
          {
            onClick: () => handleTabClick(false),
            isActive: !isNetwork,
            title: strings.myInProgressAnnouncements,
          },
        ]}
        />
        {/* Filters */}
        {isNetwork ? (
          <AnnouncementFilters
            setType={setTypeFilter}
            width={isMobile ? '100%' : getContainerWidth()}
            energy={energyFilter}
            setEnergy={setEnergyFilter}
            energyTypes={getEnergyTypes.data?.data?.sparePartEnergyTypes}
            type={typeFilter}
          />
        ) : null}
        <div style={{ width: isMobile ? '100%' : getContainerWidth() }}>
          <div
            className="white-background"
            style={{
              paddingLeft: isMobile ? (width - getContainerWidth()) / 2 + (itemsMargin / 2)
                : itemsMargin / 2,
              paddingTop: itemsMargin,
            }}
          >
            {
              currentList?.length && !isLoading
                ? (
                  <MasonryComponent containerWidth={getContainerWidth()} columnWidth={columnWidth}>
                    {
                currentList.map(({
                  description,
                  energyTypeCode,
                  typeCode,
                  title,
                  expiryDate,
                  author,
                  contact,
                  id,
                  isExpired,
                  agencyName,
                  hasResponse,
                }) => (
                  <AnnouncementCard
                    width={columnWidth - itemsMargin}
                    key={id}
                    content={description}
                    className="grid-item"
                    category={energyTypeCode}
                    type={typeCode}
                    title={title}
                    endDate={expiryDate}
                    agency={agencyName || ''}
                    author={author}
                    contact={contact}
                    hasResponse={hasResponse}
                    action={() => answerAnnouncement.mutate({ typeCode, announcement: id })}
                    isEditable={!isNetwork}
                    isExpired={isExpired}
                    deleteAction={() => {
                      setCurrentAnnouncement(id);
                      setIsDeleteModalOpen(true);
                    }}
                    editAction={() => {
                      setCurrentAnnouncement({
                        description,
                        energyTypeCode,
                        typeCode,
                        title,
                        contact,
                        id,
                      });
                      setIsCreateModalOpen(true);
                    }}
                  />
                ))
              }
                  </MasonryComponent>
                )
                : renderEmptyList()
            }
            <PaginationComponent
              setCurrentList={setCurrentList}
              totalPage={totalLength || 1}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        {
          isDeleteModalOpen && currentAnnouncement ? (
            <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
              <div>
                <h3 style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.deleteAnnouncement}
                </h3>
                <div className="d-flex f-row justify-between align-center mt-4">
                  <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                    <span>{strings.cancel}</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteOne(currentAnnouncement)}
                  >
                    <span>{strings.confirm}</span>
                  </button>
                </div>
              </div>
            </ModalComponent>
          ) : null
        }
        {
          isCreateModalOpen ? (
            <ModalTemplate
              closeModal={() => {
                setIsCreateModalOpen(false);
                setCurrentAnnouncement(null);
              }}
              title={strings.newSparePartAnnouncement}
            >
              <AnnouncementForm
                energyTypes={getEnergyTypes.data?.data?.sparePartEnergyTypes}
                saveLoading={currentAnnouncement?.id ? updateAnnouncement?.isLoading : createAnnouncement?.isLoading}
                saveAnnouncement={(toSave) => (currentAnnouncement?.id
                  ? updateAnnouncement.mutate({ ...toSave, id: currentAnnouncement.id })
                  : createAnnouncement.mutate(toSave))}
                defaultType={currentAnnouncement?.typeCode}
                defaultEnergy={currentAnnouncement?.energyTypeCode}
                defaultTitle={currentAnnouncement?.title}
                defaultDescription={currentAnnouncement?.description}
                defaultContact={currentAnnouncement?.contact}
              />
            </ModalTemplate>
          ) : null
        }
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default SpareParts;
