/* eslint-disable no-use-before-define */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Services
import FileService from '../../services/FileService';
import SignatureService from '../../services/SignatureService';

// Utils

import { useAppContext } from '../../store/AppContext';

// Components
import FileInputWithAcceptAndSize from '../atoms/FileInputWithAcceptAndSize';

const validationSchema = Yup.object({
  clientFirstName: Yup.string().matches(
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
    { message: strings.errors.latinLetters },
  ).required(strings.errors.pleaseFillField),
  clientLastName: Yup.string().matches(
    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
    { message: strings.errors.latinLetters },
  ).required(strings.errors.pleaseFillField),
  clientEmailAddress: Yup.string().email(strings.errors.mail).required(strings.errors.pleaseFillField),
  title: Yup.string().required(strings.errors.pleaseFillField),
});

const SignatureForm = ({ onCancel }) => {
  const [{ currentAgency }, appDispatch] = useAppContext();

  const [files, setFiles] = useState([{
    file: null,
    pdfFileId: null,
  }]);

  const sendDocuments = useMutation('sendDocuments',
    async (form) => {
      const filesUploaded = await Promise.all(files.map(async (file) => {
        const uploaded = await FileService.addFile({ binary: file.file, typeId: 19 });
        return { pdfFileId: uploaded.data.id };
      }));

      await SignatureService.postSignatureDocuments({
        ...form,
        pdfFiles: filesUploaded,
      });
    }, {
      onSuccess: () => {
        appDispatch({
          type: 'SET_POPOVER',
          payload: {
            isOpen: true,
            title: strings.signatureForm.success,
            isSuccess: true,
          },
        });
        setFiles([{
          file: null,
          pdfFileId: null,
        }]);
        formik.resetForm();
      },
      onError: (e) => {
        const { status, data } = e.response;
        if (status === 400) {
          if (data?.code === 'file_sanitization_failed') {
            appDispatch({
              type: 'SET_POPOVER',
              payload: {
                isOpen: true,
                title: data.error,
                isSuccess: false,
              },
            });
          } else if (data?.code === 'validation_failed') {
            const errors = Object.keys(data.errors).reduce((acc, key) => {
              acc[key] = data.errors[key][0];
              return acc;
            },
            {});
            formik.setErrors(errors);
          } else if (data?.code === 'yousign_invalid_document' || data?.code === 'yousign_obsolete_pdf_version') {
            appDispatch({
              type: 'SET_POPOVER',
              payload: {
                isOpen: true,
                title: strings.signatureForm[`${data.code}`],
                isSuccess: false,
              },
            });
          } else {
            appDispatch({
              type: 'SET_POPOVER',
              payload: {
                isOpen: true,
                title: strings.signatureForm.error,
                isSuccess: false,
              },
            });
          }
        }
        setFiles([{
          file: null,
          pdfFileId: null,
        }]);
      },
    });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      clientFirstName: '',
      clientLastName: '',
      clientEmailAddress: '',
      title: '',
      agencyId: currentAgency,
    },
    validateOnBlur: true,
    // validate: validateForm,
    onSubmit: (values) => {
      sendDocuments.mutate(values);
    },
  });

  // const isFormReady = Object.keys(validateForm(formik.values)).length !== 0 || files[0].file === null;
  const isFormReady = Object.keys(formik.errors).length !== 0 || files[0].file === null;

  return (
    <form onSubmit={formik.handleSubmit} className="white-background max-list-container-width ph-3">
      <div className="mv-3 form-input-width">
        <InputComponent
          id="clientLastName"
          name="clientLastName"
          label={strings.name}
          value={formik.values.clientLastName}
          onChange={(value) => formik.setFieldValue('clientLastName', value, true)}
          onBlur={formik.handleBlur}
          isError={formik.touched.clientLastName && formik.errors.clientLastName}
          errorMessage={formik.errors.clientLastName}
        />
      </div>

      <div className="mv-3 form-input-width">
        <InputComponent
          id="clientFirstName"
          name="clientFirstName"
          label={strings.firstname}
          value={formik.values.clientFirstName}
          onChange={(value) => formik.setFieldValue('clientFirstName', value, true)}
          onBlur={formik.handleBlur}
          isError={formik.touched.clientFirstName && formik.errors.clientFirstName}
          errorMessage={formik.errors.clientFirstName}
        />
      </div>
      <div className="mv-3 form-input-width">
        <InputComponent
          id="clientEmailAddress"
          name="clientEmailAddress"
          label={strings.mail}
          value={formik.values.clientEmailAddress}
          onChange={(value) => formik.setFieldValue('clientEmailAddress', value, true)}
          onBlur={formik.handleBlur}
          isError={formik.touched.clientEmailAddress && formik.errors.clientEmailAddress}
          errorMessage={formik.errors.clientEmailAddress}
        />
      </div>
      <div className="mv-3 form-input-width">
        <InputComponent
          id="title"
          name="title"
          label={strings.title}
          value={formik.values.title}
          onChange={(value) => formik.setFieldValue('title', value, true)}
          onBlur={formik.handleBlur}
          isError={formik.touched.title && formik.errors.title}
          errorMessage={formik.errors.title}
        />
      </div>

      <div className="form-file-input-width mv-3">
        {files.map((file, index1) => (
          <div key={file.id} className="mb-2">
            <FileInputWithAcceptAndSize
              setFile={(newFile) => {
                setFiles(
                  files.map((item, index2) => {
                    if (index1 === index2) {
                      return { ...item, file: newFile };
                    }
                    return item;
                  }),
                );
              }}
              file={file.file}
              canDelete={file.file || file.pdfFileId}
              label={file.pdfFileId ? strings.changeFile : strings.fileToSign}
              fileType={19}
              initName={strings.changeFile}
            />
          </div>
        ))}
        <ButtonComponent
          theme="grey"
          onClick={() => {
            setFiles([...files, {
              file: null,
              pdfFileId: null,
            }]);
          }}
        >
          <div className="mv-2">
            {strings.addAnotherFile}
          </div>
        </ButtonComponent>
      </div>
      {isFormReady ? null : (
        <div className="w-fit-content mt-5 d-flex align-center f-wrap">
          <div className="mr-5 mb-3">
            <ButtonComponent type="submit">
              <div className="mv-2">
                {sendDocuments.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : (
                    <span className="m-5 normal-weight">
                      {strings.send}
                    </span>
                  )}
              </div>
            </ButtonComponent>
          </div>
          {onCancel ? (
            <div className="mb-3">
              <ButtonComponent onClick={onCancel} theme="grey">
                <span className="m-5 normal-weight">{strings.cancel}</span>
              </ButtonComponent>
            </div>
          ) : null}
        </div>
      )}
    </form>
  );
};

SignatureForm.propTypes = {
  onCancel: PropTypes.func,
};

SignatureForm.defaultProps = {
  onCancel: null,
};

export default SignatureForm;
