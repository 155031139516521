import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

// Components
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import Switch from 'react-switch';
import { useLockBodyScroll, useToggle } from 'react-use';
import { useAppContext } from '../../../store/AppContext';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ModalComponent from '../../../components/atoms/ModalComponent';
import Accordeon from '../../../components/atoms/Accordeon';
import PriceInput from '../../../components/atoms/PriceInput';
import PostalCodesSelector from '../../../components/molecules/PostalCodesSelector';
import DragNDropList from '../../../components/molecules/DragNDropList';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import PrestationService from '../../../services/PrestationService';

// Images
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg';
import { ReactComponent as EyeIcon } from '../../../assets/images/eye.svg';
import { ReactComponent as DragIcon } from '../../../assets/images/three-dots.svg';
import PrestationDetailsModal from '../../../components/Organisms/PrestationDetailsModal';

const Prestations = () => {
  // Local state
  const [equipmentsWithPrestations, setEquipmentsWithPrestations] = useState([]);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPrestation, setCurrentPrestation] = useState(null);
  const [areOpened, setAreOpened] = useState([]);
  const [isFormattingDone, setIsFormattingDone] = useState(true);
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  const history = useHistory();

  // Store
  const [{ permissions, currentAgency }, appDispatch] = useAppContext();

  // Permissions check
  const canEditNetworkPrestation = () => JSON.parse(permissions).includes('network_prestation_administration');
  const canEditPostalCodes = () => JSON.parse(permissions).includes('agency_postal_codes_administration');

  // Queries
  const updatePrestation = useMutation('updatePrestation',
    (prestationData) => {
      if (canEditNetworkPrestation()) {
        return PrestationService.updateNetworkPrestation(prestationData);
      }
      return PrestationService.updateAgencyPrestation(prestationData);
    });

  const reorderPrestation = useMutation('reorderPrestation',
    (reorderData) => {
      if (canEditNetworkPrestation()) {
        return PrestationService.networkPrestationReorder(reorderData);
      }
      return PrestationService.agencyPrestationReorder(reorderData);
    });

  const updateNetworkAgencyPrestation = useMutation('updateAgencyNetwork',
    (updateData) => PrestationService.updateNetworkAgencyPrestation(updateData));

  const createNetworkAgencyPrestation = useMutation('createAgencyNetwork',
    (createData) => PrestationService.createNetworkAgencyPrestation(createData));

  const deletePrestation = useMutation('deletePrestation',
    (deleteData) => {
      if (canEditNetworkPrestation()) {
        return PrestationService.deleteNetworkPrestation(deleteData);
      }
      return PrestationService.deleteAgencyPrestation(deleteData);
    });

  const {
    isLoading: equipmentLoading, isSuccess: equipmentSuccess, isError: equipmentError, data: equipments,
  } = useQuery('getEquipmentTypes', () => PrestationService.getEquipmentTypes());

  const {
    isLoading: prestationsLoading,
    isSuccess: prestationsSuccess,
    isError: prestationsError,
    data: prestations,
  } = useQuery(['getPrestations',
    currentAgency,
    updatePrestation.isSuccess,
    deletePrestation.isSuccess,
    updateNetworkAgencyPrestation.isSuccess,
    createNetworkAgencyPrestation.isSuccess],
  () => {
    if (canEditNetworkPrestation()) {
      return PrestationService.getNetworkPrestations();
    }
    if (currentAgency) {
      return PrestationService.getAllAgencyPrestations({ agencyId: currentAgency });
    }
    return null;
  });

  // Format received data
  const orderPrestationByEquipment = (equipmentsTypes, prestationList) => equipmentsTypes.reduce((acc, equipment) => [
    ...acc,
    {
      ...equipment,
      prestations: {
        networkPrestations: prestationList?.filter(({ equipmentTypeId }) => equipmentTypeId === equipment.id),
      },
    },
  ], []);

  const orderAllPrestations = (allPresta) => allPresta?.reduce((acc, {
    id, name, networkPrestations, agencyPrestations,
  }) => [
    ...acc,
    {
      id,
      name,
      prestations: {
        networkPrestations,
        agencyPrestations,
      },
    },
  ], []);

  const getDraggablePrestationsByEquipement = (equipment) => (canEditNetworkPrestation()
    ? equipment?.prestations?.networkPrestations
    : equipment?.prestations?.agencyPrestations);

  // Actions methods
  const setNetworkAgencyPrestation = (prestation, price, isActive) => {
    if (prestation?.agencyNetworkPrestation) {
      // Update
      return updateNetworkAgencyPrestation.mutate({
        prestation: {
          ...prestation?.agencyNetworkPrestation,
          price,
          isActive,
        },
      });
    }
    // create
    return createNetworkAgencyPrestation.mutate({
      prestation: {
        agencyId: currentAgency || undefined,
        networkPrestationId: prestation.id,
        price,
        isActive,
      },
    });
  };

  const updateIsActive = (prestation) => {
    if (prestation?.agencyId || canEditNetworkPrestation()) {
      updatePrestation.mutate({ prestation: { ...prestation, isActive: !prestation.isActive } });
      setIsActivationModalOpen(false);
      toggleLocked(false);
    } else {
      setNetworkAgencyPrestation(
        prestation,
        prestation?.agencyNetworkPrestation ? prestation?.agencyNetworkPrestation.price : null,
        prestation?.agencyNetworkPrestation ? !prestation?.agencyNetworkPrestation.isActive : false,
      );
      setIsActivationModalOpen(false);
      toggleLocked(false);
    }
  };

  const deleteOne = (prestation) => {
    if (prestation) {
      deletePrestation.mutate({ id: prestation.id });
      setIsDeleteModalOpen(false);
      toggleLocked(false);
    }
  };

  useEffect(() => {
    setIsFormattingDone(true);
  }, [equipmentsWithPrestations]);

  useEffect(() => {
    if (equipmentSuccess && prestationsSuccess) {
      setIsFormattingDone(false);
      const formattedResult = canEditNetworkPrestation()
        ? orderPrestationByEquipment(equipments?.data?.equipmentTypes,
          prestations?.data?.networkPrestations) : orderAllPrestations(prestations?.data?.equipmentTypes);
      setEquipmentsWithPrestations(formattedResult);
    }
    if (updatePrestation.isError || updateNetworkAgencyPrestation.isError || createNetworkAgencyPrestation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updatePrestation, isSuccess: false },
      });
    }
    if (equipmentError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.equipmentLoading, isSuccess: false },
      });
    }
    if (prestationsError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.prestationLoading, isSuccess: false },
      });
    }
  }, [
    equipmentSuccess,
    equipmentError,
    equipments,
    prestationsSuccess,
    prestationsLoading,
    prestationsError,
    prestations,
    updatePrestation.isError,
    updateNetworkAgencyPrestation.isError,
    createNetworkAgencyPrestation.isError,
  ]);

  useEffect(() => {
    if (deletePrestation.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deletePrestation, isSuccess: true },
      });
    }
    if (deletePrestation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deletePrestation, isSuccess: false },
      });
    }
  }, [deletePrestation.isSuccess, deletePrestation.isError]);

  const goToPrestation = (equipmentId, equipmentName, prestationId) => history.push(
    `/intranet/prestation?equipment=${equipmentId}&equipment-name=${equipmentName}
    ${prestationId ? `&prestation=${prestationId}` : ''}`,
  );

  const goToEquipment = (equipmentId) => history.push(
    `/intranet/equipment?id=${equipmentId}`,
  );

  const renderEquipmentHeader = (equipment) => (
    <>
      <div className="d-flex f-row align-center justify-between mv-2 full-width f-wrap">
        <div className="d-flex f-row">
          <h2 className="mv-2 mr-3">{equipment.name}</h2>
          <button type="button" onClick={() => goToEquipment(equipment.id)}>
            <EditIcon width={30} height={30} />
          </button>
        </div>
        <ButtonComponent onClick={() => goToPrestation(equipment.id, equipment.name)}>
          <span>{strings.addPrestation}</span>
        </ButtonComponent>
      </div>
    </>
  );

  const renderPrestationActions = (prestation, equipment) => {
    if (prestation.agencyId || canEditNetworkPrestation()) {
      return (
        <div className="d-flex f-row">
          <button
            type="button"
            onClick={() => goToPrestation(equipment.id, equipment.name, prestation.id)}
          >
            <EditIcon width={20} height={20} />
          </button>
          <button
            type="button"
            onClick={() => {
              setIsDeleteModalOpen(true);
              toggleLocked(true);
              setCurrentPrestation(prestation);
            }}
          >
            <DeleteIcon width={20} height={20} />
          </button>
          <Switch
            checked={prestation.isActive}
            onChange={() => {
              setIsActivationModalOpen(true);
              toggleLocked(true);
              setCurrentPrestation(prestation);
            }}
            onColor="#8d9bba"
            onHandleColor={colors.primaryRed}
            handleDiameter={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
            height={15}
            width={30}
            className="react-switch"
            id="disable-admin-switch"
          />
        </div>
      );
    }
    return (
      <div className="d-flex f-row align-center">
        <button
          type="button"
          onClick={() => {
            setCurrentPrestation(prestation);
            setIsDetailsModalOpen(true);
            toggleLocked(true);
          }}
        >
          <EyeIcon width={20} height={20} />
        </button>
        <PriceInput
          id={`price-input-${prestation.id}`}
          onPriceChange={(price) => setNetworkAgencyPrestation(
            prestation,
            price,
            // if details doesn't exist isActive is true
            prestation?.agencyNetworkPrestation ? prestation?.agencyNetworkPrestation.isActive : true,
          )}
          defaultPrice={prestation?.agencyNetworkPrestation?.price}
        />
        {!prestation?.isMandatory
          ? (
            <div className="ml-2">
              <Switch
                checked={prestation?.agencyNetworkPrestation?.isActive}
                onChange={() => {
                  setIsActivationModalOpen(true);
                  toggleLocked(true);
                  setCurrentPrestation(prestation);
                }}
                onColor="#8d9bba"
                onHandleColor={colors.primaryRed}
                handleDiameter={20}
                checkedIcon={false}
                uncheckedIcon={false}
                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
                height={15}
                width={30}
                className="react-switch"
                id="disable-admin-switch"
              />
            </div>
          )
          : null}
      </div>
    );
  };

  return (
    <TemplateWithMenuAndHeader>
      {
        canEditNetworkPrestation()
          ? (
            <h1 className="mb-6">
              {strings.networkPrestations}
            </h1>
          )
          : (
            <>
              <h1 className="mb-0">
                {strings.myPrestationsAndVisibility}
              </h1>
              <h5 className="normal-weight">{strings.myPrestationSubTitle}</h5>
            </>
          )
      }
      {
        canEditPostalCodes() && currentAgency
          ? (
            <PostalCodesSelector agencyId={currentAgency} />
          )
          : null
      }

      {equipmentLoading || prestationsLoading || updatePrestation.isLoading || !isFormattingDone
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <div>
            { equipmentsWithPrestations
          && equipmentsWithPrestations.map((equipment) => (
            <div
              key={`equipment-${equipment.id}`}
              className="d-flex justify-center align-start f-column full-width"
            >
              <Accordeon
                header={renderEquipmentHeader(equipment)}
                setOpenedList={setAreOpened}
                id={equipment.id}
                openedList={areOpened}
                hasChildren={
                  equipment?.prestations?.networkPrestations?.length > 0
                  || equipment?.prestations?.agencyPrestations?.length > 0
                }
              >
                {
                  !canEditNetworkPrestation() && equipment?.prestations?.networkPrestations
                    ? equipment?.prestations?.networkPrestations?.map((prestation) => (
                      <div
                        className="full-width prestation-line radius-5"
                        key={prestation.id}
                      >
                        <div className="d-flex f-row align-center justify-between ph-5 f-wrap">
                          <h6 className="medium-weight mv-3">{prestation.title}</h6>
                          { renderPrestationActions(prestation, equipment)}
                        </div>
                      </div>

                    )) : null
                }
                <DragNDropList
                  data={getDraggablePrestationsByEquipement(equipment) || []}
                  doReorder={(draggedItem, precedingItem) => reorderPrestation.mutate({
                    reorderedPrestationId: draggedItem?.id,
                    newPrecedingPrestationId: precedingItem ? precedingItem.id : 0,
                  })}
                  renderItem={(prestation) => (
                    <div
                      className="full-width d-flex align-center prestation-line radius-5 ml-2"
                      key={`prestation-${prestation.id}`}
                    >
                      <DragIcon width={15} height={15} />
                      <div className="d-flex f-row align-center justify-between pl-4 pr-5 f-wrap full-width">
                        <h6 className="medium-weight mv-3">{prestation.title}</h6>
                        { renderPrestationActions(prestation, equipment)}
                      </div>
                    </div>
                  )}
                />
              </Accordeon>
            </div>
          ))}
          </div>
        )}
      {
        isActivationModalOpen && currentPrestation ? (
          <ModalComponent
            closeModal={() => {
              setIsActivationModalOpen(false);
              setCurrentPrestation(null);
              toggleLocked(false);
            }}
          >
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {currentPrestation.isActive
                  ? strings.confirmationMessages.deactivatePrestation
                  : strings.confirmationMessages.activatePrestation}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsActivationModalOpen(false);
                    setCurrentPrestation(null);
                    toggleLocked(false);
                  }}
                >
                  <span>{strings.cancel}</span>
                </button>
                <button type="button" onClick={() => updateIsActive(currentPrestation)}>
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
      {
        isDeleteModalOpen && currentPrestation ? (
          <ModalComponent closeModal={() => {
            setIsDeleteModalOpen(false);
            setCurrentPrestation(null);
            toggleLocked(false);
          }}
          >
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deletePrestation}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button
                  type="button"
                  onClick={() => {
                    setIsDeleteModalOpen(false);
                    setCurrentPrestation(null);
                    toggleLocked(false);
                  }}
                >
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => deleteOne(currentPrestation)}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
      {
        isDetailsModalOpen && currentPrestation ? (
          <PrestationDetailsModal
            closeModal={() => {
              setIsDetailsModalOpen(false);
              toggleLocked(false);
              setCurrentPrestation(null);
            }}
            prestation={currentPrestation}
          />
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default Prestations;
