import React from 'react';

// Constants
import { useHistory } from 'react-router-dom';
import strings from '../../../constants/strings';

// Services
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import AgencyContentsComponent from '../../../components/Organisms/AgencyContentsComponent';

const NetworkAgencyContent = () => {
  const history = useHistory();
  const goToContentDetailPage = (id = null) => history.push(
    `/intranet/network-agency-page-content${id ? `?id=${id}` : ''}`,
  );
  return (
    <TemplateWithMenuAndHeader>
      <h1 className="mb-5 text-align-left">{strings.theNetworkContent}</h1>
      <AgencyContentsComponent agencyId={null} title="" handleEdit={goToContentDetailPage} />
    </TemplateWithMenuAndHeader>
  );
};
export default NetworkAgencyContent;
