import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
// Constants
import { useMeasure } from 'react-use';
import strings from '../../constants/strings';

const ForumThreadCard = ({
  theme,
  title,
  publicationDate,
  author,
  agencyName,
  isClosed,
  content,
  numberOfAnswers,
  lastUpdateDate,
  open,
}) => {
  const [containerRef, { width: containerWidth }] = useMeasure();
  const [leftBlockRef, { width: leftBlockWidth }] = useMeasure();
  return (
    <div
      role="button"
      className="white-background full-width mb-2 pointer"
      onClick={open}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          open();
        }
      }}
    >
      <div className="d-flex f-column pv-3 ph-4">
        <div className="d-flex f-row justify-between align-center f-wrap">
          <div>
            <span className="uppercase very-small-text primary-red-text normal-weight">
              {theme}
            </span>
            <h3 className="grey-800-text m-0 medium-weight">{title}</h3>
            <div className="d-flex f-row f-wrap">
              <p className="small-text grey-400-text m-0">
                {`${strings.the} ${format(new Date(publicationDate?.split(' ').join('T')), 'dd.MM.yyyy')}`}
              </p>
              <p className="small-text grey-400-text ml-1 m-0">
                {`${strings.by} ${author}`}
              </p>
              {
                  agencyName
                    ? (
                      <p className="small-text grey-400-text ml-1 m-0">
                        {`- ${agencyName}`}
                      </p>
                    )
                    : null
                }
            </div>
          </div>
          <span className={`medium-weight ${isClosed ? 'grey-300-text line-through' : 'green-text'}`}>
            {isClosed ? strings.over : strings.open}
          </span>
        </div>
        <div ref={containerRef} className="line full-width mv-1" style={{ opacity: 0.3 }} />
        <div className="d-flex f-row justify-end align-center f-wrap">
          <div
            style={{
              width: containerWidth - leftBlockWidth - 16 < leftBlockWidth
                ? '100%'
                : `calc(100% - ${leftBlockWidth}px - 16px)`,
            }}
            className="description-text mv-2 wysiwyg-text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div ref={leftBlockRef} className="ml-3 d-flex f-column align-end">
            <span className="grey-600-text medium-text ws-nowrap normal-weight">
              {`${numberOfAnswers} ${numberOfAnswers > 1 ? strings.answers : strings.answer}`}
            </span>
            <span className="lowercase grey-400-text small-text normal-weight ws-nowrap">
              {`${strings.lastUpdate} ${strings.the} ${
                format(new Date(lastUpdateDate?.split(' ').join('T')), 'dd.MM.yyyy')
              }`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

ForumThreadCard.propTypes = {
  numberOfAnswers: PropTypes.number.isRequired,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,
  lastUpdateDate: PropTypes.string.isRequired,
  publicationDate: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isClosed: PropTypes.bool.isRequired,
  agencyName: PropTypes.string,
};

ForumThreadCard.defaultProps = {
  agencyName: null,
  theme: null,
};

export default ForumThreadCard;
