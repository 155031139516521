import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/button.scss'

const ButtonWithArrow = ({ onClick, isSelected, title }) => (
  <button
    className={`button-with-arrow pv-3 ph-5 radius-5
                ${isSelected ? 'active' : ''}`}
    type='button'
    onClick={onClick}
  >
    <span className='capitalize'>{title}</span>
  </button>
)

ButtonWithArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}

export default ButtonWithArrow
