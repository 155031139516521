import React, { useEffect, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { useMeasure } from 'react-use';
import draftToHtml from 'draftjs-to-html';

// Constants
import htmlToDraftjs from 'html-to-draftjs';
import strings from '../../constants/strings';
import colors from '../../constants/colors';

// Utils
import { validateNotEmptyField } from '../../utils/validators';

// Components
import FilterButton from '../atoms/FilterButton';
import TextEditorComponent from '../atoms/TextEditorComponent';
import FileInputWithAcceptAndSize from '../atoms/FileInputWithAcceptAndSize';
import useIsMobile from '../../hooks/useIsMobile';

const ThreadForm = ({
  themes, onSubmit, isLoading, initValues, onCancel,
}) => {
  const [fileBlockRef, { width }] = useMeasure();
  // form related states
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [theme, setTheme] = useState(null);
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [contentError, setContentError] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file1Id, setFile1Id] = useState(null);
  const [file1Name, setFile1Name] = useState('');
  const [file1NameError, setFile1NameError] = useState(false);
  const [file2, setFile2] = useState(null);
  const [file2Id, setFile2Id] = useState(null);
  const [file2Name, setFile2Name] = useState('');
  const [file2NameError, setFile2NameError] = useState(false);

  const [isMobile] = useIsMobile();

  useEffect(() => {
    if (initValues) {
      setTitle(initValues?.title);
      setTheme(initValues?.theme);
      if (initValues?.content) {
        setContent(EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraftjs(initValues?.content)),
        ));
      }
      setFile1Name(initValues.joinedFiles?.[0]?.fileName);
      setFile1Id(initValues.joinedFiles?.[0]?.fileId);
      setFile2Name(initValues.joinedFiles?.[1]?.fileName);
      setFile2Id(initValues.joinedFiles?.[1]?.fileId);
    }
  }, [initValues]);

  const isFileError = (file, name, fileId) => ((file || fileId) && !name) || ((!file && !fileId) && name);

  const handleSubmit = () => onSubmit({
    file1,
    file1Id,
    file1Name,
    file2,
    file2Id,
    file2Name,
    theme,
    title,
    hasError: titleError || !theme || contentError
        || isFileError(file1, file1Name, file1Id) || isFileError(file2, file2Name, file2Id),
    content: draftToHtml(convertToRaw(content.getCurrentContent())),
  });

  return (
    <div className="white-background max-list-container-width full-width">
      <div className="p-4">
        <div className="d-flex f-row f-wrap full-width">
          {/* Left block */}
          <div
            className="d-flex f-column mr-4"
            style={{
              width: isMobile ? '100%' : `calc(100% - ${width}px - 6rem)`,
            }}
          >
            <div
              className="mv-3"
              style={{ width: '100%' }}
            >
              <InputComponent
                onChange={setTitle}
                id="thread-title"
                value={title}
                label={strings.title}
                isError={titleError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => validateNotEmptyField(setTitleError, title)}
              />
            </div>
            <div
              className="d-flex f-row f-wrap mb-3"
            >
              {
                themes?.map(({ name, id }) => (
                  <FilterButton
                    key={id}
                    onClick={() => setTheme((state) => (state === id ? undefined : id))}
                    isSelected={theme === id}
                    title={name}
                  />
                ))
              }
            </div>
            <div className="line large" />
            <div
              className="mv-3 full-width"
            >
              <TextEditorComponent
                label={strings.message}
                setValue={setContent}
                value={content}
                isError={contentError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => (validateNotEmptyField(setContentError,
                  convertToRaw(content.getCurrentContent()).blocks[0].text)
                )}
              />
            </div>
            {
            !title
            || !theme
            || !convertToRaw(content.getCurrentContent()).blocks[0].text
            || isFileError(file1, file1Name, file1Id)
            || isFileError(file2, file2Name, file2Id)
              ? null
              : (
                <div className="w-fit-content mt-3 d-flex align-center f-wrap">
                  <div className="mr-5 mb-3">
                    <ButtonComponent onClick={handleSubmit}>
                      <div className="mv-2">
                        {isLoading
                          ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                          : <span className="m-5 normal-weight">{strings.publish}</span>}
                      </div>
                    </ButtonComponent>
                  </div>
                  {
                    onCancel
                      ? (
                        <div className="mb-3">
                          <ButtonComponent onClick={onCancel} theme="grey">
                            <span className="m-5 normal-weight">{strings.cancel}</span>
                          </ButtonComponent>
                        </div>
                      ) : null
                  }
                </div>
              )
            }
          </div>
          {/* File block */}
          <div
            className="border-thin border-solid grey-100-border radius-5 d-flex f-column justify-center pv-4 ph-2"
            ref={fileBlockRef}
          >
            <h5 className="grey-800-text medium-weight m-0">
              {strings.attachFiles}
            </h5>
            <div className="form-file-input-width mb-2 mt-4">
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setFile1(file);
                  setFile1Id(null);
                }}
                file={file1}
                canDelete={file1 || file1Id}
                label={file1Id ? `${strings.changeFile} 1` : `${strings.file} 1`}
                fileType={13}
                initName={strings.changeFile}
              />
            </div>
            <div className="form-input-width mb-3">
              <InputComponent
                onChange={setFile1Name}
                id="file-1-name"
                value={file1Name}
                label={`${strings.fileName} 1`}
                isError={file1NameError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => setFile1NameError(file1 ? !file1Name : false)}
              />
            </div>
            <div className="form-file-input-width mv-2">
              <FileInputWithAcceptAndSize
                setFile={(file) => {
                  setFile2(file);
                  setFile2Id(null);
                }}
                file={file2}
                canDelete={file2 || file2Id}
                label={file2Id ? `${strings.changeFile} 2` : `${strings.file} 2`}
                fileType={13}
                initName={strings.changeFile}
              />
            </div>
            <div className="form-input-width">
              <InputComponent
                onChange={setFile2Name}
                id="file-2-name"
                value={file2Name}
                label={`${strings.fileName} 2`}
                isError={file2NameError}
                errorMessage={strings.errors.pleaseFillField}
                onBlur={() => setFile2NameError(file2 ? !file2Name : false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ThreadForm.propTypes = {
  themes: PropTypes.arrayOf(shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  initValues: PropTypes.shape({
    title: PropTypes.string,
    theme: PropTypes.number,
    content: PropTypes.string,
    joinedFiles: PropTypes.arrayOf(PropTypes.shape({
      fileName: PropTypes.string,
      fileId: PropTypes.number,
    })),
  }),
};

ThreadForm.defaultProps = {
  themes: [],
  initValues: null,
  onCancel: null,
};

export default ThreadForm;
