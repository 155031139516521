import client from './client';

const refreshToken = () => client.post('/auth/refresh', {
  refreshToken: localStorage.getItem('refresh'),
});

const adminLogin = ({ email, password }) => client.post('/auth/admin', {
  emailAddress: email,
  password,
});

const adminResetPassword = ({ email, context }) => client.post(`/admins/${email}/open-password-reset`, {
  context,
});

const adminSetPassword = ({ password, token }) => client.post(`/admins/${token}/close-password-reset`, {
  password,
});

const clientLogin = ({ email, password }) => client.post('/auth/client', {
  emailAddress: email,
  password,
});

const clientOpenEmailVerification = ({ email, password }) => client.post(`/clients/${email}:open-email-verification`, {
  password,
});

const clientCloseEmailVerification = (token) => client.post(`/clients/${token}:close-email-verification`);

const clientResetPassword = ({ email, context }) => client.post(`/clients/${email}:open-password-reset`, {
  context,
});

const clientSetPassword = ({ password, token }) => client.post(`/clients/${token}:close-password-reset`, {
  password,
});

export default {
  refreshToken,
  adminLogin,
  adminResetPassword,
  adminSetPassword,
  clientLogin,
  clientOpenEmailVerification,
  clientCloseEmailVerification,
  clientResetPassword,
  clientSetPassword,
};
