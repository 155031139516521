import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useMeasure, useWindowSize } from 'react-use';

// Components
import ClientMenu from '../molecules/ClientMenu';
import Header from '../molecules/Header';
import ClientBottomMenu from '../molecules/ClientBottomMenu';
import useIsMobile from '../../hooks/useIsMobile';

const TemplateMenuHeaderClient = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);
  const { width } = useWindowSize();
  const [isMobile] = useIsMobile();

  const [contentHeight, { height }] = useMeasure();

  return (
    <div
      className="grey-100-background relative"
      style={{
        height: isMobile ? '100%' : '',
        minHeight: isMobile ? '' : menuHeight,
        maxHeight: '100%',
      }}
    >
      <div className="min-height-full-screen">
        {!(width < 1400)
        && (
        <ClientMenu
          setMenuHeight={setMenuHeight}
          smallMenuCloseHeight={headerHeight}
          greySlashHeight={height}
        />
        ) }
        <Header setHeight={setHeaderHeight} isAdmin={false} />
        <div ref={contentHeight}>
          {children}
        </div>
        { width < 1400 && <ClientBottomMenu headerHeight={headerHeight} /> }
      </div>
    </div>
  );
};

TemplateMenuHeaderClient.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TemplateMenuHeaderClient.defaultProps = {
};

export default TemplateMenuHeaderClient;
