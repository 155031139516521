import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
import { format } from 'date-fns';
import { useMeasure } from 'react-use';

// Constants
import strings from '../../constants/strings';

// Images
import { ReactComponent as DownloadIcon } from '../../assets/images/download-black.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/checkbox-circle.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

// Components
import ModalComponent from '../atoms/ModalComponent';
import useIsMobile from '../../hooks/useIsMobile';

const ForumThreadInitMessageBlock = ({
  publicationDate,
  author,
  agencyName,
  content,
  theme,
  joinedFiles,
  getFileAndDownload,
  handleAnswerClick,
  edit,
  updateDate,
  openClose,
  isClosed,
  deleteThread,
  isEditable,
}) => {
  const [fileBlockRef, { width }] = useMeasure();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMobile] = useIsMobile();

  return (
    <div className="p-4">
      <div className="d-flex f-row f-wrap full-width">
        {/* Left block */}
        <div
          className="d-flex f-column mr-4"
          style={{ width: isMobile ? '100%' : `calc(100% - ${width}px - 6rem)` }}
        >
          {/* First line */}
          <div className="d-flex f-row align-center full-width f-wrap">
            {/* Theme */}
            <div
              className="grey-100-background ph-2 pv-1 radius-5 mr-4"
            >
              <span className="primary-red-text uppercase small-text ws-nowrap">
                {theme}
              </span>
            </div>
            {/* Date and author */}
            <div className="d-flex f-row f-wrap">
              <p className="grey-400-text m-0">
                {`${strings.the} ${
                  format(new Date(publicationDate?.split(' ').join('T')), 'dd.MM.yyyy')}`}
              </p>
              <p className="grey-400-text ml-1 m-0">
                {`${strings.by} ${author}`}
              </p>
              {
                agencyName
                  ? (
                    <p className="grey-400-text ml-1 m-0">
                      {`- ${agencyName}`}
                    </p>
                  )
                  : null
              }
            </div>
          </div>

          <div className="line large mv-4" />

          {/* content */}
          <div
            className="d-flex f-column align-start justify-start full-width wysiwyg-text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {updateDate
            ? (
              <p className="grey-300-text medium-text m-0 mt-4">
                {`${strings.modifiedThe} ${
                  format(new Date(updateDate?.split(' ').join('T')), 'dd.MM.yyyy')}`}
              </p>
            )
            : null }
          <div className="line large mb-4" />

        </div>
        {/* File block */}
        {joinedFiles?.length
          ? (
            <div
              ref={fileBlockRef}
              className={`border-thin border-solid grey-100-border radius-5 d-flex 
              ${isMobile ? 'f-row f-wrap align-center' : 'f-column'} p-4`}
            >
              <h5 className="grey-800-text medium-weight mh-2 mt-0 mb-4 full-width">
                {strings.attachedFiles}
              </h5>
              {
                joinedFiles?.map(({ fileName, fileId }) => (
                  <div
                    className="m-2"
                    key={fileName}
                  >
                    <ButtonComponent
                      onClick={() => getFileAndDownload({ fileId, fileName })}
                      theme="outline"
                    >
                      <div className="d-flex f-row align-center ph-3">
                        <DownloadIcon width={24} height={24} />
                        <span className="ml-3">{fileName}</span>
                      </div>
                    </ButtonComponent>
                  </div>
                ))
              }
            </div>
          ) : null}
      </div>
      {isClosed && isEditable
        ? (
          <div className="d-flex f-row align-center">
            <button type="button" onClick={() => openClose(false)}>
              <div className="d-flex f-row align-center ph-3">
                <CheckCircleIcon width={24} height={24} />
                <span className="ml-1 primary-red-text bold">{strings.openThread}</span>
              </div>
            </button>
          </div>
        )
        : (
          <div className="d-flex f-row align-center f-wrap mv-2">
            <div className="mr-3">
              <ButtonComponent onClick={handleAnswerClick}>
                <div className="mv-2">
                  <span className="m-4 normal-weight">{strings.toAnswer}</span>
                </div>
              </ButtonComponent>
            </div>
            {isEditable ? (
              <>
                <div className="mv-2 mr-3">
                  <button type="button" onClick={() => openClose(true)}>
                    <div className="d-flex f-row align-center">
                      <CheckCircleIcon width={24} height={24} />
                      <span className="ml-1 primary-red-text bold">{strings.markAsOver}</span>
                    </div>
                  </button>
                </div>
                <div className="mv-2 mr-3">
                  <button type="button" onClick={edit}>
                    <div className="d-flex f-row align-center">
                      <EditIcon width={24} height={24} />
                      <span className="ml-1 primary-red-text bold">{strings.edit}</span>
                    </div>
                  </button>
                </div>
                <div className="mv-2 mr-3">
                  <button type="button" onClick={() => setIsDeleteModalOpen(true)}>
                    <div className="d-flex f-row align-center">
                      <DeleteIcon width={24} height={24} />
                      <span className="ml-1 primary-red-text bold">{strings.delete}</span>
                    </div>
                  </button>
                </div>
              </>
            ) : null}
          </div>
        )}
      {
        isDeleteModalOpen ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deleteThread}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    deleteThread();
                    setIsDeleteModalOpen(false);
                  }}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </div>
  );
};

ForumThreadInitMessageBlock.propTypes = {
  publicationDate: PropTypes.string.isRequired,
  updateDate: PropTypes.string,
  author: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  theme: PropTypes.string,
  joinedFiles: PropTypes.arrayOf(PropTypes.shape({
    fileName: PropTypes.string,
    fileId: PropTypes.number,
  })),
  agencyName: PropTypes.string,
  getFileAndDownload: PropTypes.func.isRequired,
  handleAnswerClick: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  openClose: PropTypes.func.isRequired,
  deleteThread: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

ForumThreadInitMessageBlock.defaultProps = {
  updateDate: '',
  agencyName: '',
  joinedFiles: [],
  theme: '',
};

export default ForumThreadInitMessageBlock;
