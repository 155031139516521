import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';

// Components

// Constants
import { format } from 'date-fns';
import strings from '../../constants/strings';
// Services
import PaymentService from '../../services/PaymentService';

const InvoiceElement = ({ invoice, handleDocumentClick }) => {
  // States
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  // Queries
  const initializePayment = useMutation('initalizePayment', (id) => PaymentService.initializePayment(id));

  const doPayment = () => {
    initializePayment.mutate(invoice.id);
  };

  const getErrorPaymentMessage = (error) => {
    switch (error.code) {
      case 'nothing_to_pay': {
        return strings.errors.nothingToPay;
      }
      case 'amount_too_low': {
        return strings.errors.amountTooLow;
      }
      case 'amount_too_high': {
        return strings.errors.amountTooHigh;
      }
      case 'invalid_payment_email': {
        return strings.errors.invalidPaymentEmail;
      }
      case 'payment_already_succeeded': {
        return strings.errors.paymentAlreadyExist;
      }
      case 'validation_failed': {
        return `${Object.keys(error.errors || {})?.[0]} : ${Object.values(error.errors || {})?.[0]?.join(' ')}`;
      }
      default: {
        return strings.errors.payment;
      }
    }
  };

  useEffect(() => {
    if (initializePayment.isSuccess) {
      window.location.href = initializePayment.data?.data?.url;
    }
    if (initializePayment.isError) {
      setFeedbackMessage(getErrorPaymentMessage(initializePayment?.error?.response?.data));
    }
  }, [initializePayment.isSuccess, initializePayment.isError]);

  const sendDatalayer = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'download',
      category: 'facture',
    });
  };

  const renderActionButton = () => {
    if (invoice.isPaidLocally) {
      return (
        <p className="m-0 grey-400-text medium-weight mv-2 mr-4">
          {strings.pendingValidation}
        </p>
      );
    }
    if (invoice.leftToPayIncludingTax === 0) {
      return null;
    }
    return (
      <div className="mr-4">
        <ButtonComponent
          onClick={doPayment}
          theme="outline"
        >
          {strings.payInvoice}
        </ButtonComponent>
      </div>
    );
  };

  return (
    <li className="list pv-2 full-width">
      <div className="d-flex justify-between align-center f-wrap full-width">
        <div className="d-flex f-column">
          <div className="medium-weight d-flex f-row f-wrap">
            <span className="mr-4">
              {strings.invoice(invoice.number)}
            </span>
            <span className="primary-red-text">{`${invoice.leftToPayIncludingTax / 100} € TTC`}</span>
          </div>
        </div>
        {feedbackMessage ? (
          <p className="primary-red-text">{feedbackMessage}</p>
        ) : null}
        <div className="d-flex align-center f-wrap mv-2">
          {renderActionButton()}
          {invoice.leftToPayIncludingTax === 0
            ? (
              <div className="d-flex f-column align-center mr-4">
                <p className="m-0 green-text medium-weight">
                  {strings.payedWith(invoice.paymentMean)}
                </p>
                <p style={{ lineHeight: '10px' }} className="m-0 small-text grey-400-text">
                  {invoice.paymentDate
                    ? strings.payedWhen(format(new Date(invoice.paymentDate?.split(' ').join('T')), 'dd-MM-yyyy'))
                    : null}
                </p>
              </div>
            )
            : null}
          {invoice.hasFile || invoice.fileId ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              role="button"
              tabIndex={0}
              onKeyDown={null}
              onClick={() => {
                sendDatalayer();
                handleDocumentClick(invoice.fileId);
              }}
              className="m-0 primary-red-text bold mr-4 pointer mv-2"
            >
              {strings.download}
            </a>
          ) : null}
        </div>
      </div>
    </li>
  );
};
InvoiceElement.propTypes = {
  invoice: PropTypes.shape().isRequired,
  handleDocumentClick: PropTypes.func.isRequired,
};

export default InvoiceElement;
