import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// Components
import TemplateMenuHeaderClient from '../../components/Organisms/TemplateMenuHeaderClient';
import Footer from '../../components/atoms/Footer';
import TabComponent from '../../components/molecules/TabComponent';
import Accordeon from '../../components/atoms/Accordeon';

// Hooks and services
import useIntranetSpacing from '../../hooks/useIntranetSpacing';
import ContentfulService from '../../services/ContentfulService';
import { useAppContext } from '../../store/AppContext';

// Constants
import strings from '../../constants/strings';
import colors from '../../constants/colors';

const Faq = () => {
  const [, appDispatch] = useAppContext();
  const contentSpacing = useIntranetSpacing();
  const categories = [
    strings.maintenance,
    strings.repair,
    strings.contract,
    strings.bill,
    strings.userSpace,
  ];
  const [areOpen, setAreOpen] = useState([]);
  const [category, setCategory] = useState(categories[0]);

  const questions = useQuery(['getQuestions', category],
    () => ContentfulService.faqByCategory(category?.toLowerCase()));

  useEffect(() => {
    appDispatch({
      type: 'SET_SHOW_EXTRANET_BACK_BUTTON',
      payload: true,
    });
  }, []);

  useEffect(() => {
    if (questions.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getQuestions, isSuccess: false },
      });
    }
  }, [questions.isError]);

  const renderEmptyList = () => {
    if (questions.isLoading
        || questions.isError || questions.isFetching) {
      return (
        <div className="full-width d-flex justify-center align-center">
          <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        </div>

      );
    }
    return (
      <div className="full-width white-background pv-4 d-flex justify-center">
        <span className="grey-400-text text-align-center uppercase">{strings.noQuestions}</span>
      </div>
    );
  };

  return (
    <TemplateMenuHeaderClient>
      <div className="mh-5" style={{ paddingLeft: contentSpacing }}>
        <h1 className="medium-weight grey-800-text">
          FAQ
        </h1>
        <TabComponent tabs={
          categories.map((cate) => (
            {
              onClick: () => {
                setCategory(cate);
                setAreOpen([]);
              },
              isActive: category === cate,
              title: cate,
            }
          ))
        }
        />
        <div className="white-background radius-5 pv-4 ph-4 mb-5">
          {
            questions?.data?.items?.length
              ? questions?.data?.items?.map(({ fields, sys }) => (
                <Accordeon
                  key={sys.id}
                  hasChildren
                  setOpenedList={setAreOpen}
                  openedList={areOpen}
                  id={sys.id}
                  buttonRight
                  header={(
                    <h5 className="medium-weight grey-800-text m-0">
                      {fields?.question}
                    </h5>
                  )}
                >
                  <div className="ph-3 mt-3">
                    <span className="wysiwyg-text grey-600-text normal-weight regular-text">
                      {documentToReactComponents(fields.rponse)}
                    </span>
                  </div>
                </Accordeon>
              ))
              : renderEmptyList()
          }
        </div>
        <Footer />
      </div>
    </TemplateMenuHeaderClient>
  );
};

export default Faq;
