import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/button.scss'

const ButtonComponent = ({ children, onClick, theme, type }) => {
  const getClassNames = () => {
    switch (theme) {
      case 'primary':
        return 'btn btn-primary'
      case 'outline':
        return 'btn btn-outline'
      case 'outline-transparent':
        return 'btn btn-outline-transparent'
      case 'outline-black':
        return 'btn btn-outline-black'
      case 'black':
        return 'btn btn-black'
      case 'grey':
        return 'btn btn-grey'
      case 'dark-grey':
        return 'btn btn-dark-grey'
      case 'white':
        return 'btn btn-white'
      default:
        return 'btn btn-primary'
    }
  }

  return (
    <button type={type} className={getClassNames()} onClick={onClick}>
      <div className='fgIndex'> {children} </div>
    </button>
  )
}

ButtonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  theme: PropTypes.oneOf([
    'primary',
    'outline',
    'outline-transparent',
    'black',
    'outline-black',
    'grey',
    'white',
    'dark-grey'
  ])
}

ButtonComponent.defaultProps = {
  type: 'button',
  theme: 'primary'
}

export default ButtonComponent
