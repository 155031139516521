import React, { useEffect, useState } from 'react';

// Components
import { useMutation, useQuery } from 'react-query';
import { ButtonComponent, LoaderComponent, ModalTemplate } from '@zolteam/axenergie-ui-library';
import { useMeasure, useWindowSize } from 'react-use';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import ApplicationCard from '../../../components/atoms/ApplicationCard';
import ApplicationForm from '../../../components/Forms/ApplicationForm';
import ModalComponent from '../../../components/atoms/ModalComponent';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services
import ApplicationService from '../../../services/ApplicationService';
import FileService from '../../../services/FileService';

// Hooks
import useIsMobile from '../../../hooks/useIsMobile';
import { useAppContext } from '../../../store/AppContext';

// Images
import { ReactComponent as AddCircleIcon } from '../../../assets/images/add-circle.svg';

const SpareParts = () => {
  // Modal related variables
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentApplication, setCurrentApplication] = useState(null);
  // Measures relative variables
  const [isMobile] = useIsMobile();
  const [containerRef, { width }] = useMeasure();
  const { width: screenWidth } = useWindowSize();
  const itemsMargin = 16;
  const columnWidth = screenWidth < 400 + 2 * itemsMargin ? screenWidth - 2 * itemsMargin : 432;

  // Store
  const [{ permissions }, appDispatch] = useAppContext();

  // Queries
  const createApp = useMutation('createApplication',
    async (application) => {
      let { fileId } = application;
      if (application.file) {
        const uploaded = await FileService.addFile({ binary: application.file, typeId: 11 });
        fileId = uploaded.data.id;
      }
      return ApplicationService.addApplication({
        title: application.title,
        url: application.url,
        illustrationFileId: fileId,
      });
    });
  const updateApp = useMutation('updateApplication',
    async (application) => {
      let { fileId } = application;
      if (application.file) {
        const uploaded = await FileService.addFile({ binary: application.file, typeId: 11 });
        fileId = uploaded.data.id;
      }
      return ApplicationService.updateApplication({
        ...currentApplication,
        title: application.title,
        url: application.url,
        illustrationFileId: fileId,
      });
    });
  const deleteApp = useMutation('deleteApplication',
    (id) => ApplicationService.deleteApp(id));

  const {
    isLoading, isError, data, isSuccess,
  } = useQuery(['getApplications', createApp.isSuccess, updateApp.isSuccess, deleteApp.isSuccess],
    () => ApplicationService.getApplications());

  useEffect(() => {
    if (isSuccess) {
      setCurrentApplication(null);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getAnnouncements, isSuccess: false },
      });
    }
  }, [isError, isSuccess, data]);

  useEffect(() => {
    if (createApp.isError) {
      const toDisplay = Object.values(createApp?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createApp, isSuccess: false },
      });
    }
    if (createApp.isSuccess) {
      setIsCreateModalOpen(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.createApp, isSuccess: true },
      });
    }
  }, [createApp.isError, createApp.isSuccess]);

  useEffect(() => {
    if (updateApp.isError) {
      const toDisplay = Object.values(updateApp?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateApp, isSuccess: false },
      });
    }
    if (updateApp.isSuccess) {
      setIsCreateModalOpen(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.updateApp, isSuccess: true },
      });
    }
  }, [updateApp.isError, updateApp.isSuccess]);

  useEffect(() => {
    if (deleteApp.isError) {
      setIsDeleteModalOpen(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteApp, isSuccess: false },
      });
    }
    if (deleteApp.isSuccess) {
      setIsDeleteModalOpen(false);
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteApp, isSuccess: true },
      });
    }
  }, [deleteApp.isError, deleteApp.isSuccess]);

  const getContainerWidth = () => {
    let nbColumns = Math.floor(width / columnWidth);
    if (nbColumns < 1) {
      nbColumns = 1;
    } else if (nbColumns > 3) {
      nbColumns = 3;
    }
    return (nbColumns * columnWidth);
  };

  const onSubmit = ({
    file,
    fileId,
    url,
    title,
    hasError,
  }) => {
    if (hasError) {
      return false;
    }
    if (currentApplication) {
      return updateApp.mutate({
        file,
        fileId,
        url,
        title,
      });
    }
    return createApp.mutate({
      file,
      fileId,
      url,
      title,
    });
  };

  return (
    <TemplateWithMenuAndHeader>
      <div
        ref={containerRef}
        className={`d-flex justify-start f-column align-start ${isMobile ? 'pl-2' : 'pl-4'}`}
      >
        <div
          className={`full-width d-flex f-row f-wrap align-center mt-4 ${isMobile ? 'mb-4' : ''}`}
        >
          <div
            className="mr-5 d-flex f-column align-start"
          >
            <h2 className="medium-weight mh-0">{strings.applicationsAxenergie}</h2>
            <span className="grey-400-text mb-5">{strings.applicationsAxenergieSubTitle}</span>
          </div>
          {permissions?.includes('network_application_administration')
            ? (
              <ButtonComponent onClick={() => setIsCreateModalOpen(true)}>
                <div className="d-flex align-center pv-2 ph-4">
                  <span className="ws-nowrap mr-2">{strings.newApp}</span>
                  <AddCircleIcon width={24} height={24} />
                </div>
              </ButtonComponent>
            )
            : null }
        </div>
        <div
          className="white-background max-list-container-width"
          style={{ width: isMobile ? '100%' : getContainerWidth() }}
        >
          {
            isLoading || isError
              ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
              : (
                <div className="d-flex f-row f-wrap justify-center">
                  {
                    data?.data?.networkApplications?.map(({
                      id, title, url, illustrationFileId,
                    }) => (
                      <div key={id} className="m-3">
                        <ApplicationCard
                          title={title}
                          url={url}
                          image={illustrationFileId}
                          width={columnWidth - 2 * itemsMargin}
                          isEditable={permissions?.includes('network_application_administration')}
                          onEdit={() => {
                            setCurrentApplication({
                              id, title, url, illustrationFileId,
                            });
                            setIsCreateModalOpen(true);
                          }}
                          onDelete={() => {
                            setCurrentApplication({
                              id, title, url, illustrationFileId,
                            });
                            setIsDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    ))
                  }
                </div>
              )
          }
        </div>
        {
          isDeleteModalOpen && currentApplication ? (
            <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
              <div>
                <h3 style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.deleteApp}
                </h3>
                <div className="d-flex f-row justify-between align-center mt-4">
                  <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                    <span>{strings.cancel}</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteApp.mutate(currentApplication?.id)}
                  >
                    <span>{strings.confirm}</span>
                  </button>
                </div>
              </div>
            </ModalComponent>
          ) : null
        }
        {
          isCreateModalOpen ? (
            <ModalTemplate
              closeModal={() => {
                setIsCreateModalOpen(false);
                setCurrentApplication(null);
              }}
              title={strings.newApp}
            >
              <ApplicationForm
                onSubmit={onSubmit}
                isLoading={createApp?.isLoading}
                initValues={currentApplication}
              />
            </ModalTemplate>
          ) : null
        }
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default SpareParts;
