import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';
// Constants
import strings from '../../../constants/strings';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import EquipmentDetailsForm from '../../../components/Forms/EquipmentDetailsForm';

// Utils
import utils from '../../../utils/utils';

// Service and hooks
import PrestationService from '../../../services/PrestationService';
import { useAppContext } from '../../../store/AppContext';

// Constants
import colors from '../../../constants/colors';

const EditEquipment = () => {
  const parameters = utils.useQueryParameters();
  const initDetails = {
    title: '',
    prestationTitle: '',
    argument1: '',
    argument2: '',
    argument3: '',
    argument4: '',
  };
  const [savDetails, setSavDetails] = useState(initDetails);
  const [installationDetails, setInstallationDetails] = useState(initDetails);
  const [adviceDetails, setAdviceDetails] = useState(initDetails);

  // Store
  const [{ permissions, currentAgency }, appDispatch] = useAppContext();
  const history = useHistory();

  // Permissions check
  const canEditNetworkPrestation = () => JSON.parse(permissions).includes('network_prestation_administration');

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(['getEquipment', currentAgency],
    () => PrestationService.getEquipmentType(
      { id: parameters.get('id'), agencyId: canEditNetworkPrestation() ? 0 : currentAgency },
    ));

  const updateDetail = useMutation('updateDetail', (updateData) => PrestationService.updateDetail(updateData));
  const createDetail = useMutation('createDetail', (createData) => PrestationService.addDetail(createData));

  const getDetailByType = (prestationType) => {
    switch (prestationType) {
      case 1:
        return savDetails;
      case 2:
        return installationDetails;
      case 3:
        return adviceDetails;
      default:
        return {};
    }
  };

  const editDetail = (prestationType) => {
    const newData = getDetailByType(prestationType);
    return updateDetail.mutate({
      detail: {
        ...data?.data.details.find((detail) => detail.prestationTypeId === prestationType),
        agencyId: canEditNetworkPrestation() ? null : currentAgency,
        title: newData.title,
        prestationsTitle: newData.prestationTitle,
        arguments: [
          { argument: newData.argument1 },
          { argument: newData.argument2 },
          { argument: newData.argument3 },
          { argument: newData.argument4 },
        ],
      },
    });
  };

  const addDetail = (prestationType) => {
    const newData = getDetailByType(prestationType);
    return createDetail.mutate({
      detail: {
        agencyId: canEditNetworkPrestation() ? null : currentAgency,
        equipmentTypeId: parameters.get('id'),
        prestationTypeId: prestationType,
        title: newData.title,
        prestationsTitle: newData.prestationTitle,
        arguments: [
          { argument: newData.argument1 },
          { argument: newData.argument2 },
          { argument: newData.argument3 },
          { argument: newData.argument4 },
        ],
      },
    });
  };

  useEffect(() => {
    if (isSuccess && data?.data) {
      data?.data?.details.forEach((detail) => {
        const details = {
          title: detail.title,
          prestationTitle: detail.prestationsTitle,
          argument1: detail.arguments.length > 0 ? detail.arguments[0]?.argument : '',
          argument2: detail.arguments.length > 1 ? detail.arguments[1]?.argument : '',
          argument3: detail.arguments.length > 2 ? detail.arguments[2]?.argument : '',
          argument4: detail.arguments.length > 3 ? detail.arguments[3]?.argument : '',
        };
        if (detail.prestationTypeId === 1) {
          setSavDetails(details);
        } else if (detail.prestationTypeId === 2) {
          setInstallationDetails(details);
        } else {
          setAdviceDetails(details);
        }
      });
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.equipmentDetailsLoading, isSuccess: false },
      });
    }
  }, [isLoading, isError, isSuccess, data]);

  useEffect(() => {
    if (updateDetail.isError || createDetail.isError) {
      const toDisplay = Object.values(
        updateDetail.isError
          ? updateDetail?.error?.response?.data?.errors
          : createDetail?.error?.response?.data?.errors
              || {},
      )?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.equipmentDetailsUpdate, isSuccess: false },
      });
    }
    if (updateDetail.isSuccess || createDetail.isSuccess) {
      history.push('/intranet/prestations');
    }
  }, [updateDetail.isError, createDetail.isError, updateDetail.isSuccess, createDetail.isSuccess]);

  const onSubmit = () => {
    if (canEditNetworkPrestation()) {
      Array.from({ length: 3 }, (v, k) => k + 1).map((prestationType) => {
        if (data?.data?.details?.find(({ prestationTypeId }) => prestationTypeId === prestationType)) {
          editDetail(prestationType);
        } else if (
          (prestationType === 1 && JSON.stringify(savDetails) !== JSON.stringify(initDetails))
            || (prestationType === 2 && JSON.stringify(installationDetails) !== JSON.stringify(initDetails))
            || (prestationType === 3 && JSON.stringify(adviceDetails) !== JSON.stringify(initDetails))
        ) {
          addDetail(prestationType);
        }
        return prestationType;
      });
    } else {
      Array.from({ length: 3 }, (v, k) => k + 1).map((prestationType) => {
        if (
          data?.data?.details?.find(
            ({ prestationTypeId, agencyId }) => prestationTypeId === prestationType && agencyId === currentAgency,
          )) {
          editDetail(prestationType);
        } else if (
          (prestationType === 1 && JSON.stringify(savDetails) !== JSON.stringify(initDetails))
            || (prestationType === 2 && JSON.stringify(installationDetails) !== JSON.stringify(initDetails))
            || (prestationType === 3 && JSON.stringify(adviceDetails) !== JSON.stringify(initDetails))
        ) {
          addDetail(prestationType);
        }
        return prestationType;
      });
    }
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/prestations">
          <h3 className="mr-3 mv-1 text-align-left">
            {canEditNetworkPrestation() ? strings.networkPrestations : strings.myPrestationsAndVisibility}
          </h3>
        </Link>
        <h3 className="mv-1 text-align-left">{` > ${data?.data?.name}`}</h3>
      </div>
      <div className="d-flex f-row f-wrap">
        <div className="d-flex f-column">
          <h5>{strings.forSavPrestations}</h5>
          <EquipmentDetailsForm
            defaultValues={savDetails}
            prestationType={1}
            setValue={setSavDetails}
          />
        </div>
        <div className="d-flex f-column">
          <h5>{strings.forInstallationPrestations}</h5>
          <EquipmentDetailsForm
            defaultValues={installationDetails}
            prestationType={2}
            setValue={setInstallationDetails}
          />
        </div>
        <div className="d-flex f-column">
          <h5>{strings.forAdivcePrestations}</h5>
          <EquipmentDetailsForm
            defaultValues={adviceDetails}
            prestationType={3}
            setValue={setAdviceDetails}
          />
        </div>
      </div>

      <div className="m-3">
        <ButtonComponent onClick={onSubmit}>
          <div className="mv-2">
            {updateDetail.isLoading || createDetail.isLoading || isLoading
              ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
              : <span className="uppercase m-5">{strings.save}</span>}
          </div>
        </ButtonComponent>
      </div>

    </TemplateWithMenuAndHeader>
  );
};

export default EditEquipment;
