import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { ButtonComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';

// Components
import DataTable from '../../../components/molecules/DataTable';
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import DragNDropList from '../../../components/molecules/DragNDropList';

// Images
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as DragIcon } from '../../../assets/images/three-dots.svg';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Services and hooks
import UserService from '../../../services/UserService';
import AuthService from '../../../services/AuthService';
import { useAppContext } from '../../../store/AppContext';

const UsersAdmin = () => {
  const [pendingReorder, setPendingReorder] = useState(false);

  // Queries
  const updateAdmin = useMutation('updateAdmin', (updateData) => UserService.updateAdmin(updateData));

  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(['getAdmins', updateAdmin.isSuccess, pendingReorder], UserService.getAdmins);

  const {
    isLoading: isRoleLoading, isError: isRoleError, data: roles,
  } = useQuery('getRoles', UserService.getRoles);

  const sendConnectionLink = useMutation('send-password',
    (sendPasswordData) => AuthService.adminResetPassword(sendPasswordData));

  const reorderAdmin = useMutation('reorderAdmin',
    (reorderData) => UserService.adminsReorder(reorderData));

  // Navigation
  const history = useHistory();

  // Store
  const [, appDispatch] = useAppContext();

  // Methods
  const addUser = () => history.push('/intranet/user');

  const editUser = (id) => history.push(`/intranet/user?id=${id}`);

  const renderEditButton = (id) => (
    <button type="button" onClick={() => editUser(id)}>
      <EditIcon width={30} />
    </button>
  );

  const updateIsActive = (admin) => {
    if (admin) {
      updateAdmin.mutate({ admin: { ...admin, isActive: !admin.isActive } });
    }
  };

  const onSendLink = (email) => {
    if (email) {
      sendConnectionLink.mutate({ email, context: 'init' });
    }
  };

  useEffect(() => {
    if (updateAdmin.isSuccess) {
      history.push('/intranet/users');
    }
    if (updateAdmin.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.updateUser, isSuccess: false },
      });
    }
    if (isError || isRoleError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getUser, isSuccess: false },
      });
    }
    if (sendConnectionLink.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.sendConnectionLink, isSuccess: true },
      });
    }
    if (sendConnectionLink.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.sendConnectionLink, isSuccess: false },
      });
    }
  },
  [
    updateAdmin.isSuccess,
    updateAdmin.isError,
    isError,
    isRoleError,
    sendConnectionLink.isSuccess,
    sendConnectionLink.isError,
  ]);

  const renderToggleButton = (value, admin) => (
    <Switch
      checked={value}
      onChange={() => updateIsActive(admin)}
      onColor="#8d9bba"
      onHandleColor={colors.primaryRed}
      handleDiameter={25}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
      height={20}
      width={40}
      className="react-switch"
      id="disable-admin-switch"
      disabled={admin.roleId === 1}
    />
  );

  const renderConnectionLink = (email) => (
    <ButtonComponent onClick={() => onSendLink(email)} theme="outline">
      <span className="uppercase">{strings.connectionLink}</span>
    </ButtonComponent>
  );

  const getRoleName = (id) => roles?.data?.roles.find((role) => role.id === id)?.name;

  const formatTableData = (admins) => admins.reduce((acc, admin) => [
    ...acc,
    {
      name: `${admin.firstName} ${admin.lastName}`,
      position: admin.position,
      mail: admin.emailAddress,
      phone: admin.phone,
      role: getRoleName(admin.roleId),
      edit: renderEditButton(admin.id),
      disabled: renderToggleButton(admin.isActive, admin),
      connectionLink: renderConnectionLink(admin.emailAddress),
    },
  ], []);

  return (
    <TemplateWithMenuAndHeader>
      <h1 className="mb-5 text-align-left">{strings.handleAdminUsers}</h1>
      <div className="d-flex f-row justify-start align-center ph-4 f-wrap mb-3">
        <h3 className="mr-5 text-align-left">{strings.myUsers}</h3>
        <div className="mr-3 mv-2">
          <ButtonComponent onClick={addUser}>
            <span>{strings.addUser}</span>
          </ButtonComponent>
        </div>
        <div className="mv-2">
          <ButtonComponent onClick={() => setPendingReorder((state) => !state)}>
            <span>{pendingReorder ? strings.over : strings.orderAdmin}</span>
          </ButtonComponent>
        </div>
      </div>
      <div className="table-container full-width white-background">
        {!pendingReorder ? (
          <DataTable
            isLoading={isLoading || isError || isRoleLoading || isRoleError || updateAdmin.isLoading}
            data={isSuccess ? formatTableData(data?.data?.networkAdmins, false) : []}
            columns={[
              strings.name,
              strings.job,
              strings.mail,
              strings.phone,
              strings.role,
              ' ',
              ' ',
              ' ',
            ]}
          />
        ) : (
          <div className="radius-5 white-background full-width">

            {isSuccess && data?.data?.networkAdmins.length ? (
              <DragNDropList
                theme="light"
                data={isSuccess ? data?.data?.networkAdmins : []}
                doReorder={(draggedItem, precedingItem) => reorderAdmin.mutate({
                  reorderedAdminId: draggedItem?.id,
                  newPrecedingAdminId: precedingItem?.id || 0,
                })}
                renderItem={(admin) => (
                  <div className="d-flex f-row pv-4">
                    <div className="mh-2">
                      <DragIcon width={15} height={15} />
                    </div>
                    <div className="bold">
                      {`${admin.firstName} ${admin.lastName}`}
                    </div>
                    <div className="mh-2">
                      {admin.position}
                    </div>
                  </div>
                )}
              />
            ) : <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />}
          </div>
        )}
      </div>
    </TemplateWithMenuAndHeader>
  );
};

export default UsersAdmin;
