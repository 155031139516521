import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

const usePagination = ({
  recordsCount, pageSize, siblingCount, pageNumber,
}) => {
  // eslint-disable-next-line consistent-return
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(recordsCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + pageNumber + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(pageNumber - siblingCount + 1, 1);
    const rightSiblingIndex = Math.min(
      pageNumber + siblingCount - 1,
      totalPageCount,
    );

    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);
      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount,
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex - 1, rightSiblingIndex + 1);
      return [...middleRange, DOTS, lastPageIndex];
    }
  }, [recordsCount, pageSize, siblingCount, pageNumber]);

  return paginationRange;
};

export default usePagination;
