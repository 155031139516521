import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Components
import { InputComponent } from '@zolteam/axenergie-ui-library';
// Constants
import strings from '../../constants/strings';

const EquipmentDetailsForm = ({ defaultValues, prestationType, setValue }) => {
  const [title, setTitle] = useState('');
  const [prestationTitle, setPrestationTitle] = useState('');
  const [argument1, setArgument1] = useState('');
  const [argument2, setArgument2] = useState('');
  const [argument3, setArgument3] = useState('');
  const [argument4, setArgument4] = useState('');

  useEffect(() => {
    setTitle(defaultValues.title);
    setPrestationTitle(defaultValues.prestationTitle);
    setArgument1(defaultValues.argument1);
    setArgument2(defaultValues.argument2);
    setArgument3(defaultValues.argument3);
    setArgument4(defaultValues.argument4);
  }, [defaultValues]);

  const save = () => {
    setValue({
      title,
      prestationTitle,
      argument1,
      argument2,
      argument3,
      argument4,
    });
  };

  return (
    <div className="mb-4 d-flex f-column align-center">
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setTitle}
          id={`equipment-title-${prestationType}`}
          value={title}
          label={strings.title}
          onBlur={() => save()}
        />
      </div>
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setPrestationTitle}
          id={`equipment-prestation-title-${prestationType}`}
          value={prestationTitle}
          label={strings.prestationTitle}
          onBlur={() => save()}
        />
      </div>
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setArgument1}
          id={`equipment-argument-1-${prestationType}`}
          value={argument1}
          label={`${strings.oneArgument} 1`}
          onBlur={() => save()}
        />
      </div>
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setArgument2}
          id={`equipment-argument-2-${prestationType}`}
          value={argument2}
          label={`${strings.oneArgument} 2`}
          onBlur={() => save()}
        />
      </div>
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setArgument3}
          id={`equipment-argument-3-${prestationType}`}
          value={argument3}
          label={`${strings.oneArgument} 3`}
          onBlur={() => save()}
        />
      </div>
      <div className="form-input-width m-3">
        <InputComponent
          onChange={setArgument4}
          id={`equipment-argument-4-${prestationType}`}
          value={argument4}
          label={`${strings.oneArgument} 4`}
          onBlur={() => save()}
        />
      </div>
    </div>
  );
};

EquipmentDetailsForm.propTypes = {
  defaultValues: PropTypes.shape({
    title: PropTypes.string,
    prestationTitle: PropTypes.string,
    argument1: PropTypes.string,
    argument2: PropTypes.string,
    argument3: PropTypes.string,
    argument4: PropTypes.string,
  }),
  prestationType: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
};

EquipmentDetailsForm.defaultProps = {
  defaultValues: {
    title: '',
    prestationTitle: '',
    argument1: '',
    argument2: '',
    argument3: '',
    argument4: '',
  },
};

export default EquipmentDetailsForm;
