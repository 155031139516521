import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

// Utils
import jwtDecode from 'jwt-decode';
import utils from '../../../utils/utils';

// Hooks and services
import { useAppContext } from '../../../store/AppContext';
import AdherentUserService from '../../../services/AdherentUserService';

// Constants
import strings from '../../../constants/strings';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import UserFormComponent from '../../../components/Forms/UserFormComponent';

const EditAdherentUser = () => {
  const [firstname, setFirstname] = useState('');
  const [name, setName] = useState('');
  const [job, setJob] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [profile, setProfile] = useState({});
  const [profileId, setProfileId] = useState(null);
  const [agencies, setAgencies] = useState([]);

  // Navigation
  const parameters = utils.useQueryParameters();
  const history = useHistory();

  // Queries
  const createUser = useMutation('create-user', (createData) => AdherentUserService.createAdherentUser(createData));
  const updateUser = useMutation('update-user', (updateData) => AdherentUserService.updateAdherentUser(updateData));

  const {
    isSuccess, data, isError,
  } = useQuery('getUser', () => {
    if (parameters.get('id')) {
      return AdherentUserService.getAdherentUser({ id: parameters.get('id') });
    }
    return null;
  });

  // Store
  const [{ auth, loginAs }, appDispatch] = useAppContext();

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setFirstname(data.data.firstName);
      setName(data.data.lastName);
      setJob(data.data.position);
      setPhone(data.data.phone);
      setMail(data.data.emailAddress);
      setProfileId(data.data.roleId);
      setAgencies(data.data.agencies?.map(({ agencyId }) => agencyId));
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.userLoading, isSuccess: false },
      });
      history.push('/intranet/adherent-users');
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (createUser.isSuccess || updateUser.isSuccess) {
      history.push('/intranet/adherent-users');
    }
    if (updateUser.isError) {
      const toDisplay = Object.values(updateUser?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateUser, isSuccess: false },
      });
    }
    if (createUser.isError) {
      const toDisplay = Object.values(createUser?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createUser, isSuccess: false },
      });
    }
  }, [createUser.isSuccess, updateUser.isSuccess, createUser.isError, updateUser.isError]);

  const onSubmit = () => {
    if (parameters.get('id')) {
      return updateUser.mutate({
        user: {
          ...data?.data,
          emailAddress: mail,
          firstName: firstname,
          lastName: name,
          position: job,
          phone,
          roleId: profile.value,
          agencies:
            agencies.map((id) => ({ agencyId: id })),
        },
      });
    }
    return createUser.mutate({
      user: {
        emailAddress: mail,
        firstName: firstname,
        lastName: name,
        position: job,
        phone,
        roleId: profile.value,
        agencies:
          agencies.map((id) => ({ agencyId: id })),
      },
    });
  };

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to="/intranet/adherent-users">
          <h3 className="mr-3 mv-1">{strings.users}</h3>
        </Link>
        <h3 className="mv-1 text-align-left">
          {` > ${data?.data?.lastName || strings.newUser} ${data?.data?.firstName || ''}`}
        </h3>
      </div>
      <UserFormComponent
        setMail={setMail}
        profile={profile}
        profileId={profileId}
        phone={phone}
        setPhone={setPhone}
        submit={onSubmit}
        setProfile={setProfile}
        firstname={firstname}
        name={name}
        job={job}
        setName={setName}
        setFirstname={setFirstname}
        mail={mail}
        setJob={setJob}
        adminType={3}
        adminId={loginAs ? JSON.parse(loginAs)?.id : jwtDecode(auth)?.sub}
        agencies={agencies}
        setAgencies={setAgencies}
        isLoading={createUser?.isLoading || updateUser?.isLoading}
      />
    </TemplateWithMenuAndHeader>
  );
};

export default EditAdherentUser;
