import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

// Services
import FileService from '../../services/FileService';

// Utils
import utils from '../../utils/utils';

// Images
import defaultImage from '../../assets/images/logo-grey.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

const ApplicationCard = ({
  title,
  image,
  url,
  width,
  isEditable,
  onDelete,
  onEdit,
}) => {
  const [base64, setBase64] = useState(null);

  const {
    isSuccess, data,
  } = useQuery(['getFile', image], () => {
    if (image) {
      return FileService.getFile({ id: image });
    }
    return Promise.resolve();
  });

  useEffect(() => {
    if (isSuccess && data) {
      utils.getBase64(data.data).then((result) => {
        setBase64(result);
      });
    }
  }, [isSuccess, data]);

  return (
    <div
      className="d-flex f-column justify-center align-center radius-5 pv-4 relative"
      style={{ border: '1px solid #EDEEF1', width: width - 2 }}
    >
      <a href={url} target="_blank" rel="noreferrer">
        <div className="mv-4 d-flex full-width justify-center">
          <img
            className="bg-contain"
            height={100}
            src={image && base64 ? base64 : defaultImage}
            alt="application-logo"
            style={{
              opacity: image ? 1 : 0.3,
            }}
          />
        </div>
        <h3 className="grey-800-text medium-weight ph-3 mt-3 mb-0 text-align-center">
          {title}
        </h3>
      </a>
      {isEditable ? (
        <div className="absolute-top-right-corner m-2">
          <button type="button" onClick={onEdit}>
            <EditIcon width={20} height={20} />
          </button>
          <button type="button" onClick={onDelete}>
            <DeleteIcon width={20} height={20} />
          </button>
        </div>
      )
        : null}
    </div>

  );
};

ApplicationCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  image: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

ApplicationCard.defaultProps = {
  image: null,
  isEditable: false,
  onEdit: () => {},
  onDelete: () => {},
};

export default ApplicationCard;
