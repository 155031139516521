import client from './client';

const addFile = ({ binary, typeId }) => {
  const data = new FormData();
  data.append('typeId', typeId);
  data.append('file', binary);
  return client.post('/files', data, { timeout: 60000 });
};

const getFile = ({ id }) => client.get(`/files/${id}`, {
  headers: {
    'Content-Type': 'text/html',
  },
  responseType: 'blob',
});

const getFileTypes = () => client.get('/file-types');

export default {
  addFile,
  getFile,
  getFileTypes,
};
