import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import {
  ButtonComponent, InputComponent, LoaderComponent, SlashContainer,
} from '@zolteam/axenergie-ui-library';

// Hooks
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../store/AppContext';

// Utils
import utils from '../utils/utils';
import colors from '../constants/colors';
import strings from '../constants/strings';
import { validateFieldPassword, validatePasswordConfirmation } from '../utils/validators';

// Services
import AuthService from '../services/AuthService';

// Images
import logo from '../assets/images/logo.webp';
import arrow from '../assets/images/arrow-down.svg';

const ResetPassword = ({ isAdmin }) => {
  // Local states
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [showSuccessContent, setShowSuccessContent] = useState(false);

  // Hooks
  const setPwd = useMutation('set-password',
    (setPasswordData) => {
      if (isAdmin) {
        return AuthService.adminSetPassword(setPasswordData);
      }
      return AuthService.clientSetPassword(setPasswordData);
    });

  const parameters = utils.useQueryParameters();

  const history = useHistory();

  const [, appDispatch] = useAppContext();

  // Methods

  const onSubmit = () => {
    if (confirmationError || passwordError) {
      return false;
    }
    return setPwd.mutate({ password, token: parameters.get('token') });
  };

  const goToLogin = () => {
    history.push(isAdmin ? '/intranet/login' : '/extranet/login');
  };

  useEffect(() => {
    if (setPwd.isSuccess) {
      setShowSuccessContent(true);
    }
    if (setPwd.isError) {
      const errorMessage = setPwd.error.response.data.code === 'validation_failed'
        ? setPwd.error.response.data.errors.password[0] : strings.errors.setPwd;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: errorMessage, isSuccess: false,
        },
      });
    }
  }, [setPwd.isSuccess, setPwd.isError]);

  return (
    <div className="relative d-flex justify-center align-center grey-100-background">
      <div className="full-size">
        <SlashContainer width="42vw" backgroundColor={colors.grey800} offset={40}>
          <div className="full-screen" />
        </SlashContainer>
      </div>
      <div
        className="d-flex f-column justify-center align-center
        white-background absolute fgIndex radius-5 form-white-container"
      >
        <img src={logo} alt="" width={200} />
        {
          !showSuccessContent
            ? (
              <>
                <h3 className="medium-weight">{strings.setPassword}</h3>
                <div className="m-3 form-input-width">
                  <InputComponent
                    onChange={setPassword}
                    id="password-input"
                    value={password}
                    label={strings.password}
                    type="password"
                    isError={passwordError}
                    errorMessage={strings.errors.password}
                    onBlur={() => validateFieldPassword(setPasswordError, password)}
                  />
                </div>
                <div className="m-3 form-input-width">
                  <InputComponent
                    onChange={setConfirmation}
                    id="confirmation-input"
                    value={confirmation}
                    label={strings.confirmation}
                    type="password"
                    isError={confirmationError}
                    errorMessage={strings.errors.confirmation}
                    onBlur={() => validatePasswordConfirmation(setConfirmationError, confirmation, password)}
                  />
                </div>
                <div className="m-3">
                  <ButtonComponent onClick={onSubmit}>
                    <div className="d-flex justify-center align-center form-input-button">
                      {setPwd.isLoading
                        ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                        : <span className="white-text uppercase bold mv-2">{strings.goOn}</span>}
                    </div>
                  </ButtonComponent>
                </div>
              </>
            )
            : (
              <>
                <p className="form-input-width">{strings.setPasswordSuccess}</p>
                <ButtonComponent onClick={goToLogin} theme="grey">
                  <div className="d-flex justify-center align-center">
                    <img alt="back-arrow" className="rotate-90" src={arrow} width={20} />
                    <span>{strings.back}</span>
                  </div>
                </ButtonComponent>
              </>
            )
        }
      </div>
    </div>

  );
};

ResetPassword.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default ResetPassword;
