import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, InputComponent, LoaderComponent } from '@zolteam/axenergie-ui-library';

// Constants
import { useMutation } from 'react-query';
import strings from '../../constants/strings';
import { validateEmailField } from '../../utils/validators';
import colors from '../../constants/colors';
import AgencyContractService from '../../services/AgencyContractService';
import { useAppContext } from '../../store/AppContext';

const ContactInfoTab = ({
  email: defaultEmail, phone: defaultPhone, mobile: defaultMobile, contractId,
}) => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [phone, setPhone] = useState(null);

  const [, appDispatch] = useAppContext();

  // Queries
  const changeEmail = useMutation('changeEmail', () => AgencyContractService.changeMyContractAddress({
    contractId,
    email,
  }));
  const changePhones = useMutation('changePhones', () => AgencyContractService.changeMyContactPhones({
    contractId,
    phone,
    mobile: mobilePhone,
  }));

  useEffect(() => {
    setEmail(defaultEmail);
    setMobilePhone(defaultMobile);
    setPhone(defaultPhone);
  }, [defaultEmail, defaultPhone, defaultMobile]);

  useEffect(() => {
    if (changeEmail.isSuccess) {
      const message = changeEmail?.data?.status === 204
        ? strings.success.changeEmailContact : strings.success.changeEmailVerification;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: message, isSuccess: true, dontHide: changeEmail?.data?.status === 202,
        },
      });
    }
    if (changeEmail.isError) {
      const errorMessage = changeEmail.error.response.data.code === 'same_email'
        ? strings.errors.setEmailNoChange : strings.errors.setEmail;
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: errorMessage, isSuccess: false,
        },
      });
    }
  }, [changeEmail.isSuccess, changeEmail.isError]);

  useEffect(() => {
    if (changePhones.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true,
          title: strings.success.changePhones,
          isSuccess: true,
          dontHide: changeEmail?.data?.status === 202,
        },
      });
    }
    if (changePhones.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.setPhones, isSuccess: false,
        },
      });
    }
  }, [changePhones.isSuccess, changePhones.isError]);

  return (
    <>
      <div className="d-flex f-column white-background radius-5 pv-3 ph-5">
        <div className="d-flex f-row f-wrap align-center">
          <div className="form-input-width m-3">
            <InputComponent
              onChange={setEmail}
              id="my-email"
              value={email}
              label={strings.mail}
              isError={emailError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateEmailField(setEmailError, email)}
            />
          </div>
          <div className="m-3">
            <ButtonComponent onClick={() => changeEmail.mutate({})}>
              <div className="mh-3 mv-2 align-center">
                {changeEmail.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="white-text uppercase bold">{strings.modify}</span>}
              </div>
            </ButtonComponent>
          </div>
        </div>

        <div className="d-flex f-row f-wrap align-center">
          <div>
            <div className="form-input-width m-3">
              <InputComponent
                onChange={setMobilePhone}
                id="my-mobile-phone"
                value={mobilePhone}
                label={strings.mobilePhone}
              />
            </div>
            <div className="form-input-width m-3">
              <InputComponent
                onChange={setPhone}
                id="my-phone"
                value={phone}
                label={strings.landlinePhone}
              />
            </div>
          </div>
          <div className="m-3">
            <ButtonComponent onClick={() => changePhones.mutate({})}>
              <div className="mh-3 mv-2 align-center">
                {changePhones.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="white-text uppercase bold">{strings.modify}</span>}
              </div>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </>
  );
};

ContactInfoTab.propTypes = {
  email: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  phone: PropTypes.string,
};

ContactInfoTab.defaultProps = {
  mobile: '',
  phone: '',
};

export default ContactInfoTab;
