import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  ButtonComponent, InputComponent, LoaderComponent, SelectComponent, RadioButtons,
} from '@zolteam/axenergie-ui-library';
import { useMutation, useQuery } from 'react-query';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraftjs from 'html-to-draftjs';

// Constants
import strings from '../../../constants/strings';
import colors from '../../../constants/colors';

// Components
import TemplateWithMenuAndHeader from '../../../components/Organisms/TemplateWithMenuAndHeader';
import TextEditorComponent from '../../../components/atoms/TextEditorComponent';
import ModalComponent from '../../../components/atoms/ModalComponent';

// Utils
import { validateNotEmptyField } from '../../../utils/validators';
import utils from '../../../utils/utils';

// Service
import { useAppContext } from '../../../store/AppContext';
import FileInputWithAcceptAndSize from '../../../components/atoms/FileInputWithAcceptAndSize';
import DocBaseService from '../../../services/DocBaseService';
import FileService from '../../../services/FileService';

const EditDocument = () => {
  const [baseName, setBaseName] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [type, setType] = useState(null);
  const [types, setTypes] = useState(null);
  const [summary, setSummary] = useState('');
  const [summaryError, setSummaryError] = useState(false);
  const [content, setContent] = useState(() => EditorState.createEmpty());
  const [contentError, setContentError] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const attachments = [
    [file1, setFile1],
    [file2, setFile2],
    [file3, setFile3],
    [file4, setFile4],
  ];
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const images = [
    [image1, setImage1],
    [image2, setImage2],
  ];
  const [imageIds, setImageIds] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [receiver, setReceiver] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const receivers = [
    { code: strings.onlyAdherent, id: 0 },
    { code: strings.everyone, id: 1 },
  ];

  // Store
  const [{ docBases }, appDispatch] = useAppContext();
  const { base } = useParams();

  // Navigation
  const history = useHistory();
  const parameters = utils.useQueryParameters();

  // Format
  const formatFilesForApiCall = (toFormat) => toFormat.reduce((acc, id) => {
    if (Object.values(id)[0]) {
      return [...acc, { fileId: parseInt(Object.values(id), 10) }];
    }
    return acc;
  }, []);

  // Queries
  const getCategories = useQuery('getCategories', () => DocBaseService.getCategories({ base }));
  const getTypes = useQuery(['getTypes', category], () => {
    if (category?.value) {
      return DocBaseService.getTypes({ category: category.value });
    }
    return false;
  });
  const { isSuccess, data, isError } = useQuery('getNews',
    () => {
      if (parameters.get('id')) {
        return DocBaseService.getOneDoc({ id: parameters.get('id') });
      }
      return null;
    });

  const createDoc = useMutation('createDoc',
    async (document) => {
      let newImages = formatFilesForApiCall(imageIds);
      let newAttachements = formatFilesForApiCall(attachmentIds);
      if (image1 || image2) {
        const imageToUpload = images.reduce((acc, [image]) => {
          if (image) {
            return [...acc, image];
          }
          return acc;
        }, []);
        const imageRequests = imageToUpload.map((image) => FileService.addFile({ binary: image, typeId: 7 }));
        const results = await Promise.all(imageRequests);
        newImages = [...newImages, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      if (file1 || file2 || file3 || file4) {
        const fileToUpload = attachments.reduce((acc, [file]) => {
          if (file) {
            return [...acc, file];
          }
          return acc;
        }, []);
        const fileRequests = fileToUpload.map((file) => FileService.addFile({ binary: file, typeId: 8 }));
        const results = await Promise.all(fileRequests);
        newAttachements = [...newAttachements, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      return DocBaseService.addDoc({
        document: {
          ...document,
          illustrationFiles: newImages,
          joinedFiles: newAttachements,
        },
      });
    });
  const updateDoc = useMutation('updateDoc',
    async (document) => {
      let newImages = formatFilesForApiCall(imageIds);
      let newAttachements = formatFilesForApiCall(attachmentIds);
      if (image1 || image2) {
        const imageToUpload = images.reduce((acc, [image]) => {
          if (image) {
            return [...acc, image];
          }
          return acc;
        }, []);
        const imageRequests = imageToUpload.map((image) => FileService.addFile({ binary: image, typeId: 7 }));
        const results = await Promise.all(imageRequests);
        newImages = [...newImages, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      if (file1 || file2 || file3 || file4) {
        const fileToUpload = attachments.reduce((acc, [file]) => {
          if (file) {
            return [...acc, file];
          }
          return acc;
        }, []);
        const fileRequests = fileToUpload.map((file) => FileService.addFile({ binary: file, typeId: 8 }));
        const results = await Promise.all(fileRequests);
        newAttachements = [...newAttachements, ...results?.map(({ data: { id } }) => ({ fileId: id }))];
      }
      return DocBaseService.updateDoc({
        document: {
          ...document,
          illustrationFiles: newImages,
          joinedFiles: newAttachements,
        },
      });
    });

  const deleteDoc = useMutation('deleteDoc', (id) => {
    DocBaseService.deleteDoc(id);
  });

  // Methods

  const deleteOne = (id) => {
    deleteDoc.mutate(id);
  };

  const addDoc = () => {
    createDoc.mutate({
      title,
      shortDescription: summary,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
      illustrationFiles: formatFilesForApiCall(imageIds),
      joinedFiles: formatFilesForApiCall(attachmentIds),
      isPublic: !!receiver,
      categoryId: category.value,
      typeId: type?.value || null,
      documentBaseId: Number(base),
    });
  };

  const editDoc = () => {
    updateDoc.mutate({
      ...data?.data,
      title,
      shortDescription: summary,
      content: draftToHtml(convertToRaw(content.getCurrentContent())),
      illustrationFiles: formatFilesForApiCall(imageIds),
      joinedFiles: formatFilesForApiCall(attachmentIds),
      isPublic: !!receiver,
      categoryId: category.value,
      typeId: type?.value || null,
      documentBaseId: Number(base),
    });
  };

  const onSubmit = () => {
    if (titleError || !category || (!type && types?.length) || summaryError || contentError || receiver === null) {
      return false;
    }
    if (parameters.get('id')) {
      editDoc();
    } else {
      addDoc();
    }
    return true;
  };

  const formatForSelect = (toFormat) => toFormat?.map(({ id, name }) => ({ label: name, value: id }));

  const goToList = () => history.push(`/intranet/doc-base/${base}`);

  useEffect(() => {
    if (docBases) {
      setBaseName(docBases?.find(({ id }) => id.toString() === base)?.name?.toLowerCase());
    }
  }, [docBases, base]);

  useEffect(() => {
    if (deleteDoc.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.success.deleteDoc, isSuccess: true },
      });
      setIsDeleteModalOpen(false);
      goToList();
    }
    if (deleteDoc.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.deleteDoc, isSuccess: false },
      });
    }
  }, [deleteDoc.isSuccess, deleteDoc.isError]);

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      setTitle(data.data.title);
      setContent(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraftjs(data.data.content)),
      ));
      setSummary(data.data.shortDescription);
      setReceiver(data?.data?.isPublic ? 1 : 0);
      setImageIds(data.data.illustrationFiles?.map(({ fileId }, index) => ({ [index + 1]: fileId })) || []);
      setAttachmentIds(data.data.joinedFiles?.map(({ fileId }, index) => ({ [index + 1]: fileId })) || []);
      setImage1(null);
      setImage2(null);
      setFile1(null);
      setFile2(null);
      setFile3(null);
      setFile4(null);
    }
    if (isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.newsLoading, isSuccess: false },
      });
      goToList();
    }
  }, [isSuccess, data, isError]);

  useEffect(() => {
    if (!isSuccess || !data || !parameters.get('id')) {
      setCategory(null);
      setType(null);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccess && data && parameters.get('id')) {
      if (categories && data.data.categoryId && !category) {
        setCategory(categories.find(({ value }) => value === data.data.categoryId));
      }
      if (types && data.data.typeId && !type) {
        setType(types.find(({ value }) => value === data.data.typeId));
      }
    }
  }, [isSuccess, data, categories, types]);

  useEffect(() => {
    if (getCategories.isSuccess && getCategories.data) {
      setCategories(formatForSelect(getCategories.data?.data?.documentBaseItemCategories));
    }
    if (getCategories.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getCategories, isSuccess: false },
      });
    }
  }, [getCategories.isSuccess, getCategories.isError, getCategories.data]);

  useEffect(() => {
    if (getTypes.isSuccess && getTypes.data) {
      setTypes(formatForSelect(getTypes.data?.data?.documentBaseItemTypes));
    }
    if (getTypes.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getTypes, isSuccess: false },
      });
    }
  }, [getTypes.isSuccess, getTypes.isError, getTypes.data]);

  useEffect(() => {
    if (createDoc.isSuccess || updateDoc.isSuccess) {
      goToList();
    }
    if (createDoc.isError) {
      const toDisplay = Object.values(createDoc?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.createDoc, isSuccess: false },
      });
    }
    if (updateDoc.isError) {
      const toDisplay = Object.values(updateDoc?.error?.response?.data?.errors || {})?.[0]?.join(' ');
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: toDisplay || strings.errors.updateDoc, isSuccess: false },
      });
    }
  }, [createDoc.isSuccess, createDoc.isError, updateDoc.isSuccess, updateDoc.isError]);

  return (
    <TemplateWithMenuAndHeader>
      <div className="d-flex f-row m-3 f-wrap">
        <Link to={`/intranet/doc-base/${base}`}>
          <h3 className="mr-3 mv-1">
            <span>{strings.docBase}</span>
            <span className="ml-2">{baseName}</span>
          </h3>
        </Link>
        <h3
          className="mh-0 mv-1 text-align-left"
        >
          {` > ${data?.data?.title || strings.newDoc} `}
        </h3>
      </div>
      <div className="pv-4 d-flex f-column f-wrap full-width align-start">
        <div className="d-flex f-row align-end f-wrap full-width">
          <div className="form-input-width mv-3 mr-3">
            <InputComponent
              onChange={setTitle}
              id="doc-title"
              value={title}
              label={strings.title}
              isError={titleError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setTitleError, title)}
            />
          </div>
          {categories?.length ? (
            <div className="form-input-width mv-3 mr-3 text-align-left">
              <SelectComponent
                data={categories}
                name="doc-categories"
                value={category}
                setValue={(value) => {
                  setCategory(value);
                  setType(null);
                }}
                theme="dark"
                isLoading={getCategories.isLoading}
                placeholder={strings.category}
                label={strings.category}
              />
            </div>
          ) : null}
          { types?.length
            ? (
              <div className="form-input-width mv-3 text-align-left">
                <SelectComponent
                  data={types}
                  name="doc-types"
                  value={type || null}
                  setValue={setType}
                  theme="dark"
                  isLoading={getTypes.isLoading}
                  placeholder={strings.subCategory}
                  label={strings.subCategory}
                />
              </div>
            ) : null}
          <div className="full-width mv-3 mr-3">
            <InputComponent
              onChange={setSummary}
              id="doc-summary"
              value={summary}
              label={strings.shortDescription}
              isError={summaryError}
              errorMessage={strings.errors.pleaseFillField}
              onBlur={() => validateNotEmptyField(setSummaryError, summary)}
            />
          </div>
        </div>
        <div className="d-flex f-row f-wrap">
          {
                  images.map(([image, setImage], index) => (
                    <div
                          // eslint-disable-next-line react/no-array-index-key
                      key={`image-${index}`}
                      className="form-file-input-width mr-3 mv-3"
                    >
                      <FileInputWithAcceptAndSize
                        setFile={(file) => {
                          setImage(file);
                          setImageIds((state) => state.filter((id) => {
                            if (file !== null) return true;
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return false;
                            }
                            return true;
                          }).map((id) => {
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return { [index + 1]: null };
                            }
                            return id;
                          }));
                        }}
                        file={image}
                        canDelete={image || imageIds[index]}
                        label={`${imageIds[index] ? strings.changeImage : strings.illustrationImage} ${index + 1}`}
                        fileType={7}
                        initName={`${strings.changeImage} ${index + 1}`}
                      />
                    </div>
                  ))
              }
        </div>
        <div className="full-width mv-3">
          <TextEditorComponent
            hasTitles
            label={strings.docContent}
            setValue={setContent}
            value={content}
            isError={contentError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => (validateNotEmptyField(setContentError,
              convertToRaw(content.getCurrentContent()).blocks[0].text)
            )}
          />
        </div>
        <div className="d-flex f-row f-wrap">
          {
                  attachments.map(([file, setFile], index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`logo-${index}`}
                      className="form-file-input-width small mr-3 mv-3"
                    >
                      <FileInputWithAcceptAndSize
                        setFile={(newFile) => {
                          setFile(newFile);
                          setAttachmentIds((state) => state.filter((id) => {
                            if (newFile !== null) return true;
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return false;
                            }
                            return true;
                          }).map((id) => {
                            if (Object.keys(id)[0] === (index + 1).toString()) {
                              return { [index + 1]: null };
                            }
                            return id;
                          }));
                        }}
                        file={file}
                        canDelete={file || attachmentIds[index]}
                        label={`${attachmentIds[index] ? strings.changeFile : strings.file} ${index + 1}`}
                        fileType={8}
                        initName={`${strings.changeFile} ${index + 1}`}
                      />
                    </div>
                  ))
              }
        </div>
        <h4 className="medium-weight">{strings.receivers}</h4>
        <div className="d-flex f-row align-center justify-start f-wrap">
          <RadioButtons setValue={setReceiver} value={receiver} options={receivers} />
        </div>
      </div>
      { !title
      || !category
      || (!type && types?.length)
      || !summary
      || !convertToRaw(content.getCurrentContent()).blocks[0].text
      || receiver === null
        ? null : (
          <div className="mv-3 text-align-left d-flex">
            <ButtonComponent onClick={onSubmit}>
              <div className="mv-2">
                {createDoc.isLoading || updateDoc.isLoading
                  ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                  : <span className="uppercase m-5">{strings.save}</span>}
              </div>
            </ButtonComponent>
            {
              parameters.get('id') && data?.data
                ? (
                  <div className="mh-2">
                    <ButtonComponent onClick={() => setIsDeleteModalOpen(true)}>
                      <div className="mv-2">
                        {deleteDoc.isLoading
                          ? <LoaderComponent size={20} color={colors.white} borderWidth={3} />
                          : <span className="uppercase m-5">{strings.delete}</span>}
                      </div>
                    </ButtonComponent>
                  </div>
                )
                : null
            }
          </div>
        )}
      {
        isDeleteModalOpen ? (
          <ModalComponent closeModal={() => setIsDeleteModalOpen(false)}>
            <div>
              <h3 style={{ width: 300, maxWidth: '100%' }}>
                {strings.confirmationMessages.deleteDoc}
              </h3>
              <div className="d-flex f-row justify-between align-center mt-4">
                <button type="button" onClick={() => setIsDeleteModalOpen(false)}>
                  <span>{strings.cancel}</span>
                </button>
                <button
                  type="button"
                  onClick={() => deleteOne(data?.data?.id)}
                >
                  <span>{strings.confirm}</span>
                </button>
              </div>
            </div>
          </ModalComponent>
        ) : null
      }
    </TemplateWithMenuAndHeader>
  );
};

export default EditDocument;
