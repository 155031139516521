import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useClickAway, useMeasure } from 'react-use';

// Components
import { LoaderComponent } from '@zolteam/axenergie-ui-library';
import jwtDecode from 'jwt-decode';
import { useQuery } from 'react-query';
import NestedMenuItem from '../atoms/NestedMenuItem';
import MenuItem from '../atoms/MenuItem';

// Images
import axLogo from '../../assets/images/AX.svg';
import whiteLogo from '../../assets/images/logo-white.webp';
import { ReactComponent as ListIcon } from '../../assets/images/list-icon.svg';
import { ReactComponent as UsersIcon } from '../../assets/images/user.svg';
import { ReactComponent as NewsIcon } from '../../assets/images/newspaper.svg';
import { ReactComponent as FileHeartIcon } from '../../assets/images/file-heart.svg';
import { ReactComponent as HouseHeartIcon } from '../../assets/images/house-heart.svg';
import { ReactComponent as BoxIcon } from '../../assets/images/box-icon.svg';
import { ReactComponent as DocIcon } from '../../assets/images/document.svg';
import { ReactComponent as ForumIcon } from '../../assets/images/users.svg';
import { ReactComponent as FileIcon } from '../../assets/images/file.svg';
import { ReactComponent as ApplicationIcon } from '../../assets/images/applications.svg';
import { ReactComponent as JobIcon } from '../../assets/images/job.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/message.svg';

// Constants
import colors from '../../constants/colors';
import strings from '../../constants/strings';

// Hooks and services
import { useAppContext } from '../../store/AppContext';
import useIsMobile from '../../hooks/useIsMobile';
import UserService from '../../services/UserService';
import DocBaseService from '../../services/DocBaseService';
import ForumService from '../../services/ForumService';
import ContactTypesCount from '../../services/ContactTypesCount';

const Menu = ({ smallMenuCloseHeight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [role, setRole] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isMobile] = useIsMobile();

  const [containerRef, { height }] = useMeasure();
  const menuRef = useRef(null);

  useClickAway(menuRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  // Store
  const [{ auth, currentAgency, loginAs }, appDispatch] = useAppContext();

  const {
    isLoading: isRoleLoading, isError: isRoleError, isSuccess: isRoleSuccess, data: roles,
  } = useQuery('getRoles', UserService.getRoles);

  const {
    data: unprocessedContacts,
  } = useQuery(
    'getUnprocessedContacts',
    () => ContactTypesCount.getUnprocessedContactCount({ agencyId: currentAgency }),
    { select: (data) => data?.data },
  );

  const getDocBases = useQuery('getDocBases', () => DocBaseService.getBases());
  const getForums = useQuery('getForums', () => ForumService.getForums());

  const getCurrentRole = (loginAsObject, decoded) => {
    const myRole = roles.data.roles.find(({ id }) => id === decoded?.data?.roleId);
    const loginAsRole = roles.data.roles.find(({ id }) => id === loginAsObject?.role);
    return loginAsRole || myRole;
  };

  useEffect(() => {
    const decoded = jwtDecode(auth);
    if (isRoleSuccess && roles) {
      const loginAsObject = loginAs ? JSON.parse(loginAs) : null;
      const currentRole = getCurrentRole(loginAsObject, decoded);
      setRole(decoded?.data?.roleId);
      setUserRole(currentRole);
      setPermissions(currentRole?.macroPermissions?.map(({ code }) => code));
      appDispatch({ type: 'SET_PERMISSIONS', payload: currentRole?.macroPermissions?.map(({ code }) => code) });
      appDispatch({ type: 'SET_ROLES', payload: roles.data?.roles });
      // If user is logged as adherent admin, set the user state with adherent admin values
      if (loginAsObject?.id) {
        appDispatch({
          type: 'SET_USER',
          payload: {
            ...decoded,
            data: {
              // don't forget to add every values of the token needed in the rest of the views
              roleId: loginAsObject?.role,
              regionId: loginAsObject?.regionId,
            },
          },
        });
      } else {
        appDispatch({ type: 'SET_USER', payload: decoded });
      }
    }
    if (isRoleError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.errors.roleLoading, isSuccess: false,
        },
      });
    }
  },
  [auth, isRoleSuccess, roles, isRoleError]);

  useEffect(() => {
    if (getDocBases.isSuccess) {
      appDispatch({ type: 'SET_DOC_BASES', payload: getDocBases.data?.data?.documentBases });
    }
    if (getForums.isSuccess) {
      appDispatch({ type: 'SET_FORUMS', payload: getForums?.data?.data.forums });
    }
  }, [getDocBases.isSuccess, getForums.isSuccess]);

  return (
    <div
      ref={containerRef}
      className="absolute menu-container"
      style={{
        height: isMobile && !isOpen ? smallMenuCloseHeight : '',
      }}
    >
      {isRoleLoading
        ? <LoaderComponent size={30} borderWidth={5} color={colors.grey800} />
        : (
          <div
            style={{
              width: isOpen ? '290px' : '90px',
              overflow: 'hidden',
            }}
            className="grey-800-background"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <div
              ref={menuRef}
              className={`ph-4 d-flex f-column grey-800-background
              ${!isMobile || isOpen ? 'align-start' : 'justify-center align-center'}`}
              style={isMobile && !isOpen
                ? { height: smallMenuCloseHeight }
                : { minHeight: height }}
            >
              <div>
                {isOpen
                  ? (
                    <Link className="menu-icon" style={{ opacity: 1 }} to="/intranet">
                      <img
                        className="mt-4"
                        src={whiteLogo}
                        width={140}
                        style={{ marginTop: -10, marginBottom: -40 }}
                        alt=""
                      />
                    </Link>
                  )
                  : (
                    <button
                      type="button"
                      aria-label="menu"
                      className={`menu-icon ${isMobile ? 'pt-1' : 'pt-4'}`}
                      style={{ opacity: 1 }}
                      onClick={() => setIsOpen(true)}
                    >
                      <img src={axLogo} width={40} alt="" />
                    </button>
                  )}
                {isMobile && !isOpen ? null
                  : (
                    <div className="d-flex f-column mt-3">
                      {/* demands menu */}
                      {permissions?.includes('contact_administration') ? (
                        <NestedMenuItem
                          title={userRole.id === 1 ? strings.allDemands : strings.demands}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          links={userRole.id === 1 ? [
                            '/intranet/all-demands',
                            '/intranet/all-quotations',
                            '/intranet/all-invoices',
                          ] : [
                            '/intranet/demands',
                            '/intranet/quotations',
                            '/intranet/invoices',
                          ]}
                          icon={<MessageIcon width={20} height={20} alt="contacts" />}
                        >
                          <MenuItem
                            title={userRole.id === 1 ? strings.allContacts : strings.contacts}
                            isOpen={isOpen}
                            link={userRole.id === 1 ? '/intranet/all-demands' : '/intranet/demands'}
                            className=""
                            countTag={unprocessedContacts?.external}
                          />
                          <MenuItem
                            title={userRole.id === 1 ? strings.allQuotations : strings.quotations}
                            isOpen={isOpen}
                            link={userRole.id === 1 ? '/intranet/all-quotations' : '/intranet/quotations'}
                            className=""
                            countTag={unprocessedContacts?.quotation}
                          />
                          <MenuItem
                            title={userRole.id === 1 ? strings.allInvoices : strings.invoices}
                            isOpen={isOpen}
                            link={userRole.id === 1 ? '/intranet/all-invoices' : '/intranet/invoices'}
                            className=""
                            countTag={unprocessedContacts?.invoice}
                          />
                        </NestedMenuItem>
                      ) : null}
                      {/* network news menu */}
                      <MenuItem
                        title={strings.networkNews}
                        isOpen={isOpen}
                        link="/intranet/network-news"
                        icon={(
                          <NewsIcon width={20} height={20} alt="users" />
                        )}
                      />
                      {/* document base menu */}
                      {
                      permissions?.includes('document_base_reading')
                      || permissions?.includes('document_base_administration')
                        ? (
                          <NestedMenuItem
                            title={strings.docBase}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            links={[
                              '/intranet/doc-base',
                            ]}
                            icon={<DocIcon width={20} height={20} alt="documents" />}
                          >
                            {
                            getDocBases?.isSuccess && getDocBases?.data?.data.documentBases?.length
                              ? getDocBases.data.data.documentBases.map(({ id, name }) => {
                                if (id === 1
                                  || (id === 2 && permissions?.includes('document_base_ca_reading'))
                                    || (id === 3 && permissions?.includes('document_base_region_reading'))
                                    || (id === 4 && permissions?.includes('document_base_region_reading'))
                                    || (id === 5 && permissions?.includes('document_base_region_reading'))
                                    || (id === 6 && permissions?.includes('document_base_region_reading'))
                                    || (id === 7 && permissions?.includes('document_base_region_reading'))
                                ) {
                                  return (
                                    <MenuItem
                                      key={`menu-doc-${id}`}
                                      title={name.toLowerCase()}
                                      isOpen={isOpen}
                                      link={`/intranet/doc-base/${id}`}
                                      className=" "
                                    />
                                  );
                                }
                                return null;
                              })
                              : null
                          }
                          </NestedMenuItem>
                        ) : null
                    }
                      {/* forum menu */}
                      {
                      permissions?.includes('forum_adherent_administration')
                      || permissions?.includes('forum_admin_administration')
                      || permissions?.includes('forum_tech_administration')
                      || permissions?.includes('forum_ca_administration')
                        ? (
                          <NestedMenuItem
                            title={strings.forums}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            links={[
                              '/intranet/forum',
                            ]}
                            icon={<ForumIcon width={20} height={20} alt="forums" />}
                          >
                            {
                                  getForums?.isSuccess && getForums?.data?.data.forums?.length
                                    ? getForums?.data?.data.forums.map(({ id, title }) => {
                                      if (
                                        (id === 1 && permissions?.includes('forum_adherent_administration'))
                                        || (id === 2 && permissions?.includes('forum_tech_administration'))
                                        || (id === 3 && permissions?.includes('forum_admin_administration'))
                                        || (id === 4 && permissions?.includes('forum_ca_administration'))
                                      ) {
                                        const link = '/intranet/forum/';
                                        return (
                                          <MenuItem
                                            key={id}
                                            title={title}
                                            isOpen={isOpen}
                                            link={link + id}
                                            className=" "
                                          />
                                        );
                                      }
                                      return null;
                                    })
                                    : null
                                }
                          </NestedMenuItem>
                        ) : null
                    }
                      {/* spare part content menu */}
                      {
                      permissions?.includes('spare_part_announcement_administration')
                        ? (
                          <MenuItem
                            title={strings.SOSSpareParts}
                            isOpen={isOpen}
                            link="/intranet/spare-parts"
                            icon={(
                              <BoxIcon
                                width={20}
                                height={20}
                                alt="spare-part-page"
                              />
                            )}
                          />
                        ) : null
                    }
                      {/* signature menu */}
                      <MenuItem
                        title={strings.signature}
                        isOpen={isOpen}
                        link="/intranet/signature"
                        icon={(
                          <DocIcon
                            width={20}
                            height={20}
                            alt="signature"
                          />
                            )}
                      />
                      {/* applications menu */}
                      {permissions?.includes('network_application_read') ? (
                        <MenuItem
                          title={strings.applications}
                          isOpen={isOpen}
                          link="/intranet/applications"
                          icon={(
                            <ApplicationIcon width={20} height={20} alt="applications-page" />
                            )}
                        />
                      ) : null}
                      {/* adherent book menu */}
                      {role === 8 ? null : (
                        <NestedMenuItem
                          title={strings.regionBook}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          links={[
                            '/intranet/adherent-book-list',
                            '/intranet/adherent-book-map',
                          ]}
                          icon={(
                            <FileIcon
                              width={20}
                              height={20}
                              alt="adherent-book-page"
                            />
                        )}
                        >
                          <MenuItem
                            title={strings.adherentList}
                            isOpen={isOpen}
                            link="/intranet/adherent-book-list"
                            className=""
                          />
                          <MenuItem
                            title={strings.adherentMap}
                            isOpen={isOpen}
                            link="/intranet/adherent-book-map"
                            className=""
                          />
                        </NestedMenuItem>
                      )}

                      {isOpen && <div className="menu-separator m-2" />}
                      {/* users menu */}
                      {
                      permissions?.includes('network_admins_administration')
                      || permissions?.includes('adherents_administration')
                        ? (
                          <NestedMenuItem
                            title={strings.users}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            links={['/intranet/users', '/intranet/adherents']}
                            icon={<UsersIcon width={20} height={20} alt="users" />}
                          >
                            {permissions?.includes('network_admins_administration')
                              ? (
                                <MenuItem
                                  title={strings.admins}
                                  isOpen={isOpen}
                                  link="/intranet/users"
                                  className=" "
                                />
                              ) : null}
                            {/* adherents */}
                            {permissions?.includes('adherents_administration')
                              ? (
                                <MenuItem
                                  title={strings.adherents}
                                  isOpen={isOpen}
                                  link="/intranet/adherents"
                                  className=" "
                                />
                              ) : null}
                          </NestedMenuItem>
                        ) : null
                    }
                      {/* prestations menu */}
                      {permissions.includes('network_prestation_administration')
                        || permissions.includes('agency_prestation_administration')
                        ? (
                          <MenuItem
                            title={strings.prestations}
                            isOpen={isOpen}
                            link="/intranet/prestations"
                            icon={(
                              <ListIcon width={20} height={20} alt="prestations" />
                        )}
                          />
                        ) : null}
                      {/* job offer menu */}
                      {permissions?.includes('job_offer_administration')
                        ? (
                          <MenuItem
                            title={strings.jobOffers}
                            isOpen={isOpen}
                            link="/intranet/job-offers"
                            icon={(
                              <JobIcon
                                width={20}
                                height={20}
                                alt="job-offers"
                              />
                        )}
                          />
                        ) : null}
                      {/* network agency content menu */}
                      {
                      permissions?.includes('network_agency_page_content_administration')
                        ? (
                          <MenuItem
                            title={strings.networkContent}
                            isOpen={isOpen}
                            link="/intranet/network-agency-page-contents"
                            icon={(
                              <FileHeartIcon
                                width={20}
                                height={20}
                                alt="network-agency-page"
                              />
                              )}
                          />
                        ) : null
                    }
                      {/* my agency info menu */}
                      {
                      permissions?.includes('agency_info_administration') && currentAgency
                        ? (
                          <MenuItem
                            title={strings.myAgency}
                            isOpen={isOpen}
                            link="/intranet/my-agency"
                            icon={(
                              <HouseHeartIcon width={20} height={20} alt="agency-page" />
                              )}
                          />
                        ) : null
                    }
                      {/* my agency page menu */}
                      {permissions?.includes('agency_info_administration') && currentAgency ? (
                        <MenuItem
                          title={strings.agencyPage}
                          isOpen={isOpen}
                          link="/intranet/agency-page"
                          icon={(
                            <FileHeartIcon width={20} height={20} alt="agency-page" />
                        )}
                        />
                      ) : null}
                      {/* agency users menu */}
                      {
                      permissions?.includes('adherent_users_administration')
                        ? (
                          <MenuItem
                            title={strings.users}
                            isOpen={isOpen}
                            link="/intranet/adherent-users"
                            icon={(
                              <UsersIcon width={20} height={20} alt="users" />
                              )}
                          />
                        ) : null
                    }
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}

    </div>

  );
};

Menu.propTypes = {
  smallMenuCloseHeight: PropTypes.number,
};

Menu.defaultProps = {
  smallMenuCloseHeight: 105,
};

export default Menu;
